import React, { useMemo, useState } from "react";
import { useDeleteGroupMutation, useGetGroupFieldsQuery, useUpdateGroupMutation } from "../../../redux/api/fieldsApi";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { t } from "i18next";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import GroupModal from "./GroupModal";

const GroupFields = () => {
  const name = "Groups";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });
  const { data, isFetching, refetch } = useGetGroupFieldsQuery(query);

  const [deleteGroup] = useDeleteGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    type: null,
    id: null,
  });

  const toggle = () => {
    setModalOptions({ isOpen: !modalOptions.isOpen });
  };

  const handleTogglePublished = async (data) => {
    console.log(data);
    const updatedData = { ...data, isPublished: !data.isPublished };
    await updateGroup({ id: data?.id, group: updatedData });
    await refetch();
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("Name"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          view={true}
          link="groups"
          modal={setModalOptions}
          disableLink={true}
          handleTogglePublished={handleTogglePublished}
        />
      ),
    },
    {
      accessorKey: "alias",
      accessorFn: (row) => row.alias,
      header: t("caymland.core.alias"),
      size: 150,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);

  const actionButtons = ActionButtons({
    link: "groups",
    refetch: refetch,
    openModal: setModalOptions,
    clone: false,
    deleteItem: deleteGroup,
    permissionKey: "lead:fields",
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "groups",
    refetch: refetch,
    permissionKey: "lead:fields",
  });
  console.log(modalOptions);
  return (
    <div>
      <GroupModal modalOptions={modalOptions} setModalOptions={setModalOptions} refetch={refetch} />
      <Header
        buttons={[
          {
            name: "addNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: toggle,
          },
        ]}
        title={t("caymland.lead.field.group")}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        linkNavigate={null}
        toolbarActions={{
          search: true,
        }}
        isFetching={isFetching}
      />{" "}
    </div>
  );
};

export default GroupFields;
