import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Text } from "../reusableComponents/Inputs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { SlideAlert } from "../reusableComponents/Alerts";
import companyData from "../../assets/companies.json";
import Loader from "../common/loader";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [logo, setLogo] = useState(null);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [backgorundToast, setBackgroundToast] = useState("");

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");
  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [loadingBackground, setLoadingBackground] = useState(true);

  const sendResetPasswordEmail = async () => {
    try {
      const endpoint = `${api}/passwordresetV2`;
      const response = await axios.post(endpoint, { identifier: username, url: url });
      if (response.status === 200) {
        setShow(true);
        setMessage(t("A new reset password link has been sent to you. Please check your email."));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 6000);
      }
    } catch (error) {
      console.error("Failed to send email", error);
    }
  };

  useEffect(() => {
    const currentDomain = window.location.href;
    let company = companyData.companies.find((c) => currentDomain.includes(c.domain));


    if (!company && companyData.companies.length > 0) {
      company = companyData.companies[0];
    } 

    if (company) {
      try {
        const localLogo = require(`../../assets/logo/${company.logo.sidebarLogoPath}`);
        setLogo(localLogo);
      } catch (error) {
        setLogo(company.logo.url);
      }

      if (company?.loginBackground) {
        setBackgroundUrl(company.loginBackground);
        setLoadingBackground(false);
      } else {
        // Otherwise, fetch a new image
        fetchBackgroundImage();

        // Set an interval to refresh the background every hour
        const interval = setInterval(() => {
          fetchBackgroundImage();
        }, 60 * 60 * 1000); // 1 hour

        // Cleanup function to clear interval when component unmounts
        return () => clearInterval(interval);
      }


    } else {
      try {
        const localLogo = require(`../../assets/logo/sidebar_logo.png`);
        setLogo(localLogo);
      } catch (error) {
        setLogo(company.logo.url);
      }
    }
  }, []);

  const fetchBackgroundImage = async () => {
    try {
      setLoadingBackground(true);

      const response = await axios.get(
        "https://api.unsplash.com/photos/random?query=nature&client_id=iOkMPl0_Ai2kMnxIbWO7t14QXE7sx_HSUa6YFG9VD00",
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "no-cors",
            "Referrer-Policy": "no-referrer",
          },
        }
      );
      const data = await response;
      console.log(data);

      // Extract the first landscape image URL
      const firstItem = data?.data?.batchrsp?.items?.[0];
      const landscapeImageUrl = firstItem ? JSON.parse(firstItem.item).ad.landscapeImage.asset : null;

      if (data) {
        // Save image and fetch time to localStorage
        localStorage.setItem("backgroundUrl", data.data.urls.raw);
        localStorage.setItem("backgroundFetchTime", Date.now());
        setBackgroundUrl(data.data.urls.raw);
      } else {
        console.error("No landscape image found in the API response.");
      }
    } catch (error) {
      console.error("Error fetching background image:", error);
    } finally {
      setLoadingBackground(false);
    }
  };

  // useEffect(() => {
  //   // Check if a background image already exists in localStorage
  //   const storedBackgroundUrl = localStorage.getItem("backgroundUrl");
  //   const backgroundFetchTime = localStorage.getItem("backgroundFetchTime");
  //   const currentTime = Date.now();

  //   // If the image exists and was fetched within the last hour, use it
  //   if (
  //     storedBackgroundUrl &&
  //     backgroundFetchTime &&
  //     currentTime - backgroundFetchTime < 60 * 60 * 1000 // 1 hour in milliseconds
  //   ) {
  //     setBackgroundUrl(storedBackgroundUrl);
  //   } else {
  //     // Otherwise, fetch a new image
  //     fetchBackgroundImage();
  //   }

  //   // Set an interval to refresh the background every hour
  //   const interval = setInterval(() => {
  //     fetchBackgroundImage();
  //   }, 60 * 60 * 1000); // 1 hour

  //   return () => clearInterval(interval); // Cleanup interval on unmount
  // }, []);

  return (
    <Fragment>
      <SlideAlert background={backgorundToast} message={message} show={show} />
      <div className="page-wrapper">
        <div
         className="auth-bg"
         style={{
           backgroundImage: loadingBackground ? (
             <Loader />
           ) : (
             `url(${backgroundUrl || "http://localhost:3000/static/media/auth-bg-1.8184142c45a47fd52a9c.jpg"})`
           ),
         }}
        >
          <div className="authentication-box">
            <div className="tw-flex justify-center text-center">
              <img src={logo} alt="company logo"  style={{ maxWidth: "185px" }} />
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className=" text-center tw-mb-[10px]">
                  <h4>{t("Reset Password")}</h4>
                </div>
                <Text
                  name="_username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  label={t("Username or Email")}
                />
                <div className="forgotPassword">
                  <Link to="/login">{t("Back to Login")}</Link>
                </div>
                <div className="form-group form-row mt-3 mb-0 d-grid">
                  <button className="btn active-btn" onClick={sendResetPasswordEmail}>
                    {t("Reset Password")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
