import AsyncSelect from "react-select/async";
import React, { useEffect, useRef, useState } from "react";
import { Label } from "./index";
import _ from "lodash";
import { height } from "@mui/system";
import { NonceProvider } from "react-select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { faHourglassEmpty } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from '@mui/material/styles';

const CustomSelectInput = ({
  name,
  value,
  onChange,
  bundle,
  options,
  label,
  placeholder,
  isClearable = true,
  isSearchable = true,
  errors = false,
  isMulti = false,
  labelField = "label",
  valueField = "value",
  required = false,
  tooltip,
  loading = false,
  isDisabled = false,
  trigger = null,
  margin,
  viewEdit = false,
  onBlur,
  document = false,
  extraStyle,
  autoFocus = false,
  labelHidden = false,
  isCreatable = false,
}) => {
  const { t } = useTranslation();
  const [defaultValue, setDefaultValue] = useState();
  const selectRef = useRef(null);
  const { palette } = useTheme();
  

  const defaultPlaceholder = placeholder || t("caymland.core.select");

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(() => {
    delay(50).then(() => {
      if (!selectRef.current?.props) return;

      let options = selectRef.current.props.options;
      if (_.has(options[0], "options")) {
        options = options
          .map(function (val) {
            return val.options;
          })
          .reduce(function (pre, cur) {
            return pre.concat(cur);
          })
          .map(function (e, i) {
            return { label: e[labelField], value: e[valueField] };
          });
      }

      if (value) {
        if (Array.isArray(value)) {
          if (
            value.some((v) => {
              return typeof v === "object";
            })
          ) {
            setDefaultValue(value);
          } else {
            let values = options
              .map((o) => {
                return value.includes(parseInt(o[valueField])) ? o : undefined;
              })
              .filter(Boolean);
            setDefaultValue(values);
          }
        } else if (value) {
          if (typeof value === "object") {
            setDefaultValue(value);
          } else {
            setDefaultValue(
              options.find((o) => {
                const optionValue = o[valueField];
                const stringValue = value?.toString();
                return optionValue !== undefined && optionValue.toString() === stringValue;
              })
            );
          }
        }
      } else {
        setDefaultValue(null);
      }
    });
  }, [value]);

  const handleOnChange = (data) => {
    if (!data) {
      onChange(null);
      return;
    }

    if (data && Array.isArray(data)) {
      let choices = data.map((ff) => {
        return { [labelField]: ff[labelField], [valueField]: ff[valueField] };
      });
      onChange(data);
      return;
    }

    if (_.has(data, [valueField])) {
      onChange(data);
      return;
    }

    onChange(null);
  };

  const getOptions = (inputValue) =>
    new Promise(async (resolve) => {
      if (typeof options !== "function") {
        if (inputValue?.length) {
          const regex = new RegExp(inputValue, "i");
          let lists = [];
          if (_.has(options[0], "options")) {
            lists = options.map((o) => {
              return {
                label: o.label,
                options: o.options.filter((oo) => regex.test(oo.label)),
              };
            });
          } else {
            lists = options.filter((o) => regex.test(o.label));
          }
          resolve(lists);
          return;
        }
        resolve(options);
        return;
      }

      const results = await options(inputValue, bundle, trigger);
      const createNewOption =
        bundle === "contacts"
          ? { title: t("Create New"), id: "create_new" }
          : { label: t("Create New"), value: "create_new" };
      if (isCreatable) {
        resolve([createNewOption, ...results]);
      } else {
        resolve(results);
      }
    });

  return (
    <div
      className={`w-full ${extraStyle?.margin ? "" : !margin && "mb-20px"}`}
      style={{ minWidth: !extraStyle?.minWidth ? "" : "150px" }}
    >
      {label && label?.length && (
        <Label
          name={name}
          label={label}
          tooltip={tooltip}
          required={required}
          // isLoading={isLoading || loading}
          errors={errors}
          labelHidden={labelHidden}
        />
      )}

      <AsyncSelect
        cacheOptions
        id={name}
        key={options?.length}
        ref={selectRef}
        onBlur={onBlur}
        onFocus={(e) => {}}
        name={name}
        autoComplete="off"
        value={defaultValue}
        required={required}
        placeholder={defaultPlaceholder}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isSearchable={isSearchable}
        defaultOptions
        autoFocus={autoFocus} // Ensures the dropdown is focused
        menuPlacement="auto"
        menuPortalTarget={document ? document.body : null}
        maxMenuHeight={300}
        loadOptions={getOptions}
        getOptionLabel={(data) => data[labelField]}
        getOptionValue={(data) => data[valueField]}
        isMulti={isMulti}
        onChange={handleOnChange}
        onKeyDown={(e) => {
          if (e.key === "ArrowUp") {
          }
          if (e.key === "ArrowDown") {
          }
          if (e.key === "Enter") {
          }
          if (selectRef.current) {
            selectRef.current.focus(); // Ensure dropdown stays focused
          }
        }}
        aria-errormessage={errors}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: palette?.primary?.main,
            primary: palette?.primary?.main,
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            outline: "none !important",
            minHeight: viewEdit ? "34px" : "40px",
            fontSize: "15px",
            backgroundColor: viewEdit ? "inherit" : "white",
            border: viewEdit ? "0" : "",
            marginBottom: viewEdit ? "3px" : "0",
            boxShadow: viewEdit ? "none" : "",
            borderColor: errors ? "#d32f2f" : base.borderColor,
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: viewEdit ? "none" : "scale(1.003)",
              boxShadow: viewEdit ? "none" : "0 6px 20px rgba(0, 0, 0, 0.1)",
              borderColor: errors ? "#d32f2f" : palette?.primary?.lightPrimaryColor,
            },
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? palette?.primary?.lightPrimaryColor : "white",
            color: state.isFocused ? "white" : "#333",
            padding: "10px 2px",
            fontSize: "15px",
            transition: "background-color 0.2s ease",
            paddingLeft: "20px",
            "&:active": {
              color: "white",
              backgroundColor: palette?.primary?.main,
            },
            "&:hover": {
              color: "white",
              backgroundColor: palette?.primary?.main,
            },
            "&:focus": {
              color: "white",
            },
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            color: palette?.text?.primary,
            ":hover": {
              backgroundColor: palette?.primary?.main,
              color: "white",
            },
          }),
          multiValue: (styles) => ({
            ...styles,
            fontSize: "15px",
            backgroundColor: " #e7e7e7",
            borderRadius: "5px",
            flexWrap: "nowrap",
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            fontSize: "15px",
            color: palette?.text?.primary,
            flexWrap: "nowrap",
          }),
          placeholder: (styles) => ({
            ...styles,
            color: "#6C757D",
            fontSize: viewEdit ? "13px" : "15px",
          }),
          group: (base, state) => ({
            ...base,
            // backgroundColor: "#f6f6f6",
            padding: "10px 2px",
          }),

          groupHeading: (base, state) => ({
            ...base,
            fontWeight: "bold",
            paddingLeft: "5px",
            color: "#333",
          }),
          menu: (provided) => ({ ...provided, zIndex: 99999999 }),
        }}
      />
    </div>
  );
};

export default CustomSelectInput;
