import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { logout } from "../features/authSlice";
import companyData from "../../assets/companies.json";

// Get the current browser URL and extract the subdomain
const url = window.location.hostname;
const isCaymLandDomain = url.endsWith("caym.land");

// Extract subdomain if present (for example, "subdomain.caym.land" -> "subdomain")
const subdomainParts = url.split(".");
const subdomain = subdomainParts[0];

// Check if the current domain matches any company's domain and use the backendDomain if available
const matchedCompany = companyData.companies.find((company) => company.domain == url);

// If a matching company is found and it has a backendDomain, use that as the API base URL
const api =
  matchedCompany && matchedCompany.backendDomain
    ? `https://${matchedCompany.backendDomain}`
    : isCaymLandDomain
    ? `https://${subdomain}.caymland.dev/`
    : process.env.REACT_APP_DEV_URL;

console.log("Current URL:", url);
console.log("Selected API URL:", api);
console.log("Subdomain:", subdomain);

console.log(url);
console.log(api);

// Define the baseQuery with the selected API URL
const baseQuery = fetchBaseQuery({
  baseUrl: api,
  timeout: 180000,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `JWT ${token}`);
    }
    headers.set("X-Requested-With", "XMLHttpRequest");
    return headers;
  },
});

export const customFetchBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }

  return result;
};
