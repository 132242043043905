import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import Data from "./addEditReports/Data";
import Graphs from "./addEditReports/Graphs";
import Sidebar from "./addEditReports/Sidebar";
import Details from "./addEditReports/Details";
import Schedule from "./addEditReports/Schedule";
import { Success, Error } from "../../reusableComponents/Alerts";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import {
  useGetReportQuery,
  useAddReportMutation,
  useUpdateReportMutation,
  useGetDataTabQuery,
} from "../../../redux/api/reportsApi";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import Swal from "sweetalert2";
import TitleHeader from "../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";
import { useTheme } from "@mui/material/styles";

const transformSelectData = (labelData) => {
  if (typeof labelData.list !== "object") {
    return null;
  }

  return Object.entries(labelData.list).map(([listValue, listLabel]) => ({
    label: listLabel,
    value: listValue,
  }));
};

const transformData = (data) => {
  let transformedColumns = [];
  if (data) {
    Object.entries(data).forEach(([value, label], index) => {
      let column = {
        label: label?.alias || label?.label || label,
        value: label?.value || value,
        index: index,
        type: label?.type,
      };

      if (label?.type === "select" || label?.type === "multiselect") {
        column.options = transformSelectData(label);
      }

      transformedColumns.push(column);
    });
  }
  return transformedColumns;
};

function AddorEditReport() {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [currentId, setCurrentId] = useState(null);
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;
  const ClonePath = location.pathname.includes("clone");
  const [graphs, setGraphs] = useState([]);
  const [aggregators, setAggregators] = useState([]);
  const [groupBy, setGroupBy] = useState([]);
  const [filters, setFilters] = useState([]);
  const [columns, setColumns] = useState([]);
  const [modifiedData2, setModifiedData2] = useState({});
  const [modifiedData, setModifiedData] = useState({
    name: "",
    description: null,
    isPublished: true,
    system: false,
    isScheduled: false,
    source: null,
    createdBy: null,
    columns: [],
    filters: {},
    tableOrder: {},
    graphs: [],
    groupBy: {},
    settings: {
      showGraphsAboveTable: null,
      showDynamicFilters: null,
      hideDateRangeFilter: null,
    },
    aggregators: {},
    scheduleUnit: null,
    toAddress: null,
    scheduleDay: null,
    scheduleMonthFrequency: null,
    scheduleFormat: "csv",
    scheduleTime: "08:00",
  });

  const { data, isLoading: mainLoading } = useGetReportQuery(id, { skip: !id });
  const [updateReport, { isLoading: isEditLoading }] = useUpdateReportMutation(id);
  const [addReport, { isLoading: isAddLoading }] = useAddReportMutation();
  const isSaving = isEditLoading || isAddLoading;

  const { data: dataTab } = useGetDataTabQuery({ source: modifiedData?.source });

  useEffect(() => {
    if (dataTab) {
      setColumns(transformData(dataTab?.columns));
      setGroupBy(transformData(dataTab?.groupBy));
      setFilters({
        filterList: transformData(dataTab?.filters.filterList),
        filterOperatorList: transformData(dataTab?.filters.filterOperatorList),
        filterDefinitions: transformData(dataTab?.filters.filterDefinitions),
      });
      setAggregators({
        function: [],
        column: transformData(dataTab?.columns),
      });
      setGraphs(transformData(dataTab?.graphList));
    }
  }, [dataTab]);

  const checkIfFormIsModified = () => {
    return JSON.stringify(modifiedData) !== JSON.stringify(modifiedData2);
  };

  useEffect(() => {
    if (id && data?.report) {
      let reportData = data?.report;
      if (ClonePath) {
        reportData = { ...reportData, isPublished: false };
      }
      setModifiedData(
        Array.isArray(data?.report.settings)
          ? {
              ...reportData,
              settings: {
                showGraphsAboveTable: null,
                showDynamicFilters: null,
                hideDateRangeFilter: null,
              },
            }
          : reportData
      );
      setModifiedData2(
        Array.isArray(data?.report.settings)
          ? {
              ...reportData,
              settings: {
                showGraphsAboveTable: null,
                showDynamicFilters: null,
                hideDateRangeFilter: null,
              },
            }
          : reportData
      );
    }
    setCurrentId(id);
  }, [id, data]);

  const handleAddEditReports = async (params) => {
    if (modifiedData.name.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }

    if (modifiedData?.source == null) {
      errors.source = true;
      setErrors({ ...errors });
    } else {
      delete errors.source;
      setErrors({ ...errors });
    }
    const hasErrors = Object.values(errors).some((value) => value === true);
    if (hasErrors) {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3500);
    }
    if (Object.keys(errors).length === 0) {
      try {
        let response;

        let clonedObject = Object.assign({}, modifiedData);

        clonedObject.scheduleUnit = clonedObject?.scheduleUnit?.value;
        clonedObject.scheduleMonthFrequency = clonedObject?.scheduleMonthFrequency?.value;
        clonedObject.scheduleDay = clonedObject?.scheduleDay?.value;

        if (currentId && !ClonePath) {
          response = await updateReport(clonedObject);
        } else {
          response = await addReport(clonedObject);
        }

        if (!response.error) {
          Success(
            `${t("caymland.message.type.successfully", {
              type: currentId ? t("caymland.core.type.edited") : t("caymland.dashboard.create.past.tense"),
            })}!`
          );
          if (params === "save") {
            navigate(`/reports/view/${response.data.report.id}`);
          } else {
            navigate(`/reports/edit/${response.data.report.id}`);
          }
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (e) {
        Error(t("caymland.message.error"));
      }
    }
  };

  const changeDynamicTab = (e) => {
    setPrimaryColorTab(e);
  };

  const sortOptionsByIndex = (options) => {
    if (!Array.isArray(options)) {
      return [];
    }
    // return [...options];
    return [...options].sort((a, b) => a.index - b.index);
  };

  const filterOptionsBySearch = (options, searchValue) => {
    if (!searchValue) {
      return options;
    }
    const lowercasedSearchValue = searchValue.toLowerCase();
    return options.filter((option) => option.label.toLowerCase().includes(lowercasedSearchValue));
  };

  const createHandleSelectAll = (left, right, setLeft, setRight) => () => {
    setRight([...right, ...left]);
    setLeft([]);
  };

  const createHandleDeselectAll = (left, right, setLeft, setRight) => () => {
    setLeft([...left, ...right]);
    setRight([]);
  };

  const handleSelectChange = (left, right, setLeft, setRight) => (label) => {
    const selectedOptionIndex = left.findIndex((option) => option.label === label);

    if (selectedOptionIndex !== -1) {
      const selectedOptionObject = {
        ...left[selectedOptionIndex],
        insertionOrder: Date.now(),
      };
      setLeft((prevLeft) => prevLeft.filter((option) => option.label !== label));
      setRight((prevRight) => [...prevRight, selectedOptionObject]);
    } else {
      const selectedOptionIndexInRight = right.findIndex((option) => option.label === label);
      const selectedOptionObject = right[selectedOptionIndexInRight];
      setRight((prevRight) => prevRight?.filter((option) => option.label !== label));

      setLeft((prevLeft) => [...prevLeft, selectedOptionObject]);
    }
  };

  const createHandleSearchChange = (setSearchValue) => (event) => {
    setSearchValue(event.target.value);
  };

  const handleCancel = () => {
    if (checkIfFormIsModified()) {
      Swal.fire({
        title: t("caymland.mailbox.message.delete.title"),
        text: t("caymland.validation.message.changes"),
        icon: "question",
        confirmButtonText: t("caymland.core.form.yes"),
        cancelButtonText: t("caymland.core.form.cancel"),
        showCancelButton: true,
        customClass: {
          confirmButton: "swal-custom confirm-component",
          cancelButton: "swal-custom result-component",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/reports");
        } else {
          return;
        }
      });
    } else {
      navigate("/reports");
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddEditReports("save"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddEditReports(""),
    },
  ];



  return (
    <>
      {mainLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          showAlert={showAlert}
          alertMessage={alertMessage}
          loading={isSaving}
          title={
            <TitleHeader
              name={t("caymland.report.reports")}
              clone={ClonePath}
              title={modifiedData?.name}
              loading={mainLoading}
              id={id}
            />
          }
        />
      )}
      <Wrapper
        childrenClassOne={"w-75"}
        childrenOne={
          mainLoading ? (
            <DetailsSkeleton applyPadding />
          ) : (
            <>
              <Nav className="nav-primary w-full flex" tabs>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => changeDynamicTab(1)}
                    style={{
                      color: primarycolorTab === 1 ? "white" : palette.text.primary,
                      textTransform: "capitalize",
                      cursor: "pointer",
                      backgroundColor: primarycolorTab === 1 ? palette?.primary?.main : "",
                    }}

                  >
                    {t("caymland.core.details")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => changeDynamicTab(2)}
                    style={{
                      color: primarycolorTab === 2 ? "white" : palette.text.primary,
                      textTransform: "capitalize",
                      cursor: "pointer",
                      backgroundColor: primarycolorTab === 2 ? palette?.primary?.main : "",
                    }}
                  >
                    {t("caymland.report.tab.data")}
                  </NavLink>
                </NavItem>
                {graphs.length > 0 && (
                  <NavItem>
                    <NavLink
                      href="#"
                      onClick={() => changeDynamicTab(3)}
                      style={{
                        color: primarycolorTab === 3 ? "white" : palette.text.primary,
                        textTransform: "capitalize",
                        cursor: "pointer",
                        backgroundColor: primarycolorTab === 3 ? palette?.primary?.main : "",
                      }}
                    >
                      {t("caymland.report.tab.graphs")}
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => changeDynamicTab(4)}
                    style={{
                      color: primarycolorTab === 4 ? "white" : palette.text.primary,
                      textTransform: "capitalize",
                      cursor: "pointer",
                      backgroundColor: primarycolorTab === 4 ? palette?.primary?.main : "",
                    }}
                  >
                    {t("caymland.report.tab.schedule")}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={primarycolorTab} style={{ padding: "20px" }}>
                <TabPane tabId={1}>
                  <Details
                    modifiedData={modifiedData}
                    setModifiedData={setModifiedData}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </TabPane>
                <TabPane tabId={2}>
                  <Data
                    modifiedData={modifiedData}
                    setModifiedData={setModifiedData}
                    sortOptionsByIndex={sortOptionsByIndex}
                    filterOptionsBySearch={filterOptionsBySearch}
                    createHandleSelectAll={createHandleSelectAll}
                    createHandleDeselectAll={createHandleDeselectAll}
                    handleSelectChange={handleSelectChange}
                    createHandleSearchChange={createHandleSearchChange}
                    aggregators={aggregators}
                    groupBy={groupBy}
                    filters={filters}
                    columns={columns}
                    id={id}
                  />
                </TabPane>
                <TabPane tabId={3}>
                  <Graphs
                    graphs={graphs}
                    modifiedData={modifiedData}
                    setModifiedData={setModifiedData}
                    sortOptionsByIndex={sortOptionsByIndex}
                    filterOptionsBySearch={filterOptionsBySearch}
                    createHandleSelectAll={createHandleSelectAll}
                    createHandleDeselectAll={createHandleDeselectAll}
                    handleSelectChange={handleSelectChange}
                    createHandleSearchChange={createHandleSearchChange}
                    id={id}
                  />
                </TabPane>
                <TabPane tabId={4}>
                  <Schedule modifiedData={modifiedData} setModifiedData={setModifiedData} />
                </TabPane>
              </TabContent>
            </>
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          mainLoading ? (
            <SidebarSkeleton />
          ) : (
            <Sidebar
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              errors={errors}
              setErrors={setErrors}
            />
          )
        }
      />
    </>
  );
}

export default AddorEditReport;
