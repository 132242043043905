import React, { Fragment, useEffect, useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { Text, Checkbox, Select, Label } from "../../../../reusableComponents/Inputs";
import { t } from "i18next";
import CustomizedAccordions from "../../../../reusableComponents/Accordion/Accordion";
import CustomEditor from "../../../../reusableComponents/Editor/CustomEditor";

const top = "margin-top";
const left = "margin-left";
const bottom = "margin-bottom";
const right = "margin-right";

function Settings({ errors, setErrors, modifiedData, setModifiedData, retrieveProperty, formProperties }) {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;

    useEffect(() => {
      if (!modifiedData?.type && retrieveProperty?.typeChoices?.length > 0) {
        console.log(modifiedData);
        setModifiedData((prevData) => ({
          ...prevData,
          type: retrieveProperty.typeChoices[0]?.value,
        }));
      }

      if (!modifiedData?.properties?.when && retrieveProperty?.when?.length > 0) {
        setModifiedData((prevData) => ({
          ...prevData,
          properties: {
            ...prevData?.properties,
            when: retrieveProperty.when[0]?.value,
          },
        }));
      }
    }, [modifiedData, retrieveProperty, setModifiedData]);

    consoile.log(modifiedData);

    if (type === "text") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
      const isProperty = Object.keys(modifiedData?.properties).includes(name);
      if (name === "top" || name === "bottom" || name === "left" || name === "right") {
        setModifiedData({
          ...modifiedData,
          properties: {
            ...modifiedData?.properties,
            [`margin-${name}`]: value,
          },
        });
      } else if (isProperty) {
        setModifiedData({
          ...modifiedData,
          properties: {
            ...modifiedData?.properties,
            [name]: value,
          },
        });
      } else {
        setModifiedData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else if (type === "checkbox") {
      const isProperty = Object.keys(modifiedData?.properties).includes(name);
      if (!isProperty) {
        setModifiedData({
          ...modifiedData,
          [name]: newValue,
        });
      } else {
        setModifiedData({
          ...modifiedData,
          properties: {
            ...modifiedData?.properties,
            [name]: newValue,
          },
        });
      }
    }
  };

  console.log(retrieveProperty);

  const handleSegmentsAdd = (e) => {
    if (e === null) {
      setModifiedData({ ...modifiedData, type: [] });
      errors.type = true;
      setErrors({ ...errors });
    } else {
      setModifiedData({ ...modifiedData, type: e.value });
      delete errors.type;
      setErrors({ ...errors });
    }
  };
  const accordionsDesc = [
    {
      name: t("caymland.core.additional") + " " + "CSS",
      component: (
        <CustomEditor
          value={modifiedData?.css}
          onChange={(e) => setModifiedData({ ...modifiedData, css: e })}
          defLanguage="css"
        />
      ),
    },
  ];
  return (
    <Fragment>
      <div className="d-flex gap-10">
        <div className="w-50">
          <Select
            name="types"
            label={t("caymland.core.type")}
            isClearable={false}
            options={retrieveProperty?.typeChoices}
            value={retrieveProperty?.typeChoices.find((e) =>
              modifiedData?.type ? e.value === modifiedData?.type : e.value === retrieveProperty.typeChoices[0]?.value
            )}
            onChange={(e) => {
              if (!modifiedData?.type && retrieveProperty?.typeChoices.length > 0) {
                handleSegmentsAdd(retrieveProperty?.typeChoices[0]);
              } else {
                handleSegmentsAdd(e);
              }
            }}
          />

          <Select
            name="typeChoices"
            label={t("caymland.focus.form.when")}
            isClearable={false}
            errors={errors.when}
            options={retrieveProperty.when}
            value={retrieveProperty?.when.find((e) =>
              modifiedData?.properties?.when
                ? e.value === modifiedData?.properties?.when
                : e.value === retrieveProperty.when[0]?.value
            )}
            onChange={(e) => {
              setModifiedData((prevData) => ({
                ...prevData,
                properties: { ...prevData.properties, when: e.value },
              }));
            }}
          />

          <Text
            name="timeout"
            value={modifiedData?.properties?.timeout}
            onChange={(e) => handleChange(e)}
            label={t("caymland.focus.form.timeout")}
            errors={errors.properties?.timeout}
            extraText="sec"
          />
          <Select
            name="frequency"
            label={t("caymland.focus.form.frequency")}
            isClearable={false}
            options={retrieveProperty.frequency}
            value={retrieveProperty?.frequency.find((e) => e.value === modifiedData?.properties?.frequency)}
            onChange={(e) =>
              setModifiedData({
                ...modifiedData,
                properties: { ...modifiedData?.properties, frequency: e.value },
              })
            }
          />
        </div>
        <div className="w-50">
          <div className="d-flex flex-col justify-center items-center h-full tw-flex tw-flex-col  tw-gap-[10px]">
            <div className="tw-flex tw-flex-col  tw-gap-[10px]">
              <Checkbox
                name="animate"
                value={modifiedData?.properties?.animate === 0 ? false : true}
                onChange={(e) => handleChange(e)}
                label={t("caymland.focus.form.animate")}
              />
              <Checkbox
                name="link_activation"
                value={modifiedData?.properties?.link_activation === 0 ? false : true}
                onChange={(e) => handleChange(e)}
                label={t("caymland.focus.form.activate_for_links")}
              />
              {!(modifiedData.type === "notice") && (
                <Checkbox
                  name="stop_after_conversion"
                  value={modifiedData?.properties?.stop_after_conversion !== 0}
                  onChange={(e) => handleChange(e)}
                  label={t("caymland.focus.form.engage_after_conversion")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Label
        label={t("caymland.focus.container.margin")}
        tooltip={t("caymland.focus.form.engage_after_conversion.tooltip")}
      />
      <div className="d-flex gap-10">
        <div className="w-50">
          <Text
            name="top"
            value={modifiedData?.properties ? modifiedData.properties[top] : ""}
            onChange={(e) => handleChange(e)}
            label={t("caymland.focus.form.placement.top")}
            errors={errors?.properties}
            extraText="px"
          />
        </div>
        <div className="w-50">
          <Text
            name="right"
            value={modifiedData?.properties ? modifiedData.properties[right] : ""}
            onChange={(e) => handleChange(e)}
            label={t("caymland.core.right")}
            errors={errors?.properties}
            extraText="px"
          />
        </div>
      </div>
      <div className="d-flex gap-10">
        <div className="w-50">
          <Text
            name="left"
            value={modifiedData?.properties ? modifiedData.properties[left] : ""}
            onChange={(e) => handleChange(e)}
            label={t("caymland.core.left")}
            errors={errors?.properties}
            extraText="px"
          />
        </div>
        <div className="w-50">
          <Text
            name="bottom"
            value={modifiedData?.properties ? modifiedData.properties[bottom] : ""}
            onChange={(e) => handleChange(e)}
            label={t("caymland.focus.form.placement.bottom")}
            errors={errors?.properties}
            extraText="px"
          />
        </div>
      </div>

      {modifiedData?.execution && (
        <>
          <p className="pb-0 mb-0 required">{t("caymland.core.form.interval")}</p>
          <Select
            id="type"
            name="type"
            isSearchable
            isClearable
            class="jump-input"
            //   options={intervals}
            placeholder={t("caymland.query.builder.select.placeholder")}
            value={modifiedData?.intervalExecution}
            onChange={(e) => setModifiedData({ ...modifiedData, intervalExecution: e })}
          />
          <p className="pb-0 mb-0 required">{t("caymland.core.form.time.execute")}</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label=""
              value={modifiedData?.timeExecution}
              onChange={(e) => setModifiedData({ ...modifiedData, timeExecution: e })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </>
      )}

      <CustomizedAccordions accordions={accordionsDesc} />
    </Fragment>
  );
}

export default Settings;
