import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../reusableComponents/Buttons/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { DatePicker, Select, Text } from "../../../reusableComponents/Inputs";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useGetAllChannelsQuery, useUpdateContactChannelsMutation } from "../../../../redux/api/contactsApi";
import moment from "moment";
import { t } from "i18next";

const ChannelBatchModal = ({ modalOptions, setModalOptions, setShow, setMessage, setBackgroundToast }) => {
  const closeModal = () => {
    setModalOptions(() => ({
      isOpen: false,
      title: "",
    }));
    reset();
  };

  const [updateChannels] = useUpdateContactChannelsMutation();
  const { data } = useGetAllChannelsQuery();

  const handleChannels = async (formData) => {
    const idsArray = modalOptions?.id || [];
    const idsString = JSON.stringify(idsArray);

    // Initialize subscribed_channels with an empty array
    let subscribedChannels = [];

    const leadChannels = Object.entries(formData).reduce((acc, [key, value]) => {
      // Check if the key indicates a channel subscription and the value is true
      if (key.startsWith("subscribed_channels_") && value) {
        // Extract the channelKey from the form field name and add it to subscribedChannels
        const channelKey = key.replace("subscribed_channels_", "");
        subscribedChannels.push(channelKey);
      } else if (value !== "" && value !== null && value !== undefined) {
        // Handle other fields as before
        acc[key] = value;
      }
      return acc;
    }, {});

    // Add the collected subscribed channels to the data to send
    const dataToSend = {
      ...leadChannels,
      ids: idsString,
      subscribed_channels: watch("subscribed_channels") ? Object.values(data) : subscribedChannels, // Use the dynamically collected channels
    };

    const response = await updateChannels({ contact_channels: dataToSend });
    console.log(response);
    if (!response.error) {
      closeModal();
      setShow(true);
      setMessage(t("caymland.message.type.successfully", { type: "edited" }));
      setBackgroundToast("1");
      setTimeout(() => {
        setShow(false);
        setMessage("");
      }, 3000);
    } else {
      setShow(true);
      setMessage(response.error.data?.message);
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 3000);
    }
  };

  const { control, handleSubmit, watch, reset } = useForm();

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: closeModal,
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(handleChannels),
    },
  ];

  const options = [
    { label: t("caymland.core.time.days"), value: "DAY" },
    { label: t("caymland.core.time.weeks"), value: "WEEK" },
    { label: t("caymland.core.time.months"), value: "MONTH" },
  ];

  return (
    <Modal show={modalOptions.isOpen && modalOptions.type === "channels"} onHide={closeModal}   backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            padding: "4px",
            fontSize: "18px",
          }}
        >
          {modalOptions.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div // Header row for channel configuration
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr 6fr 2fr",
            backgroundColor: "#F3F3F3",
            color: "black",
            padding: "0.7em 0 0.7em 0",
          }}
        >
          <Controller
            control={control}
            name={`subscribed_channels`}
            render={({ field }) => (
              <input
                type="checkbox"
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                style={{ width: "14px", height: "14px", marginLeft: "17px", marginTop: "6px" }}
              />
            )}
          />
          <span>{t(`caymland.core.channels`)}</span>
          <span>{t("caymland.lead.preferred.frequency")}</span>
          <span style={{ justifySelf: "center" }}>{t("caymland.lead.preferred.channels")}</span>
        </div>

        {data &&
          Object.values(data).map((channelKey, index) => {
            const channelData = data[channelKey]; // Extract data for the current channel
            return (
              <React.Fragment key={channelKey}>
                {index > 0 && (
                  <div // Divider for visual separation between channel configurations
                    style={{
                      borderTop: "1px solid #E9ECEF",
                      margin: "20px 0",
                    }}
                  />
                )}

                <div // Main configuration row for channel
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 6fr 2fr",
                    color: "#8393a2",
                    padding: "0.7em 0 0.7em 0",
                    marginTop: "10px",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    control={control}
                    name={`subscribed_channels_${channelKey}`}
                    render={({ field: { onChange, name, value } }) => (
                      <input
                        type="checkbox"
                        name={name}
                        checked={!!value} // Ensure checked is a boolean based on the value presence.
                        onChange={(e) => onChange(e.target.checked ? channelKey : false)} // Toggle based on checked state.
                        style={{ width: "14px", height: "14px", marginLeft: "17px" }}
                      />
                    )}
                  />
                  <span style={{ color: watch(`subscribed_channels_${channelKey}`) ? "black" : "#8393a2" }}>
                    {t(`caymland.${channelKey}.${channelKey}`)}
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 3fr",
                      }}
                    >
                      <Controller
                        control={control}
                        name={`frequency_number_${channelKey}`}
                        render={({ field }) => <Text {...field} type="number" label="" />}
                      />
                      <span style={{ alignSelf: "center", justifySelf: "center" }}>
                        {t("caymland.lead.list.frequency.times")}
                      </span>
                      <Controller
                        control={control}
                        name={`frequency_time_${channelKey}`}
                        render={({ field: { name, onChange, value } }) => (
                          <Select
                            name={name}
                            value={value}
                            onChange={(selectedOption) => onChange(selectedOption.value)}
                            options={options}
                            label=""
                          />
                        )}
                      />
                    </div>
                  </div>
                  <Controller
                    control={control}
                    name="preferred_channel"
                    render={({ field }) => (
                      <input
                        type="radio"
                        {...field}
                        checked={field.value === channelKey}
                        onChange={() => field.onChange(channelKey)}
                      />
                    )}
                  />
                </div>
                {(watch(`subscribed_channels_${channelKey}`) || watch("subscribed_channels")) && (
                  <div // Pause dates row for channel
                    style={{
                      display: "flex",
                      gridTemplateColumns: "1fr 2fr 6fr 2fr",
                      padding: "10px 20px",
                      marginTop: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <span className="input-group-text" style={{ height: "40px" }}>
                        {t("caymland.lead.frequency.dates.label")}
                      </span>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Controller
                          control={control}
                          name={`contact_pause_start_date_${channelKey}`}
                          render={({ field: { name, onChange, value } }) => (
                            <DatePicker
                              name={name}
                              className={`form-control no-border-radius`}
                              showTimeSelect={false}
                              dateFormat="dd-MM-yyyy"
                              value={value}
                              onChange={(date) => onChange(date ? moment(date).format("YYYY-MM-DD") : null)}
                              isClearable
                            />
                          )}
                        />
                        <span className="input-group-text" style={{ height: "40px" }}>
                          {t("caymland.core.date.to")}
                        </span>
                        <Controller
                          control={control}
                          name={`contact_pause_end_date_${channelKey}`}
                          render={({ field: { name, onChange, value } }) => (
                            <DatePicker
                              name={name}
                              className={`form-control no-border-radius`}
                              showTimeSelect={false}
                              dateFormat="dd-MM-yyyy"
                              value={value}
                              onChange={(date) => onChange(date ? moment(date).format("YYYY-MM-DD") : null)}
                              isClearable
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default ChannelBatchModal;
