import React, { Fragment, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./addEmailContent/Sidebar";
import Advanced from "./addEmailContent/Advanced";
import CustomJson from "./addEmailContent/CustomJson";
import CustomHtml from "./addEmailContent/CustomHtml";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Error, Success } from "../../../reusableComponents/Alerts";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { Checkbox } from "../../../reusableComponents/Inputs";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import { useAddEmailMutation, useGetAddEditEmailsQuery } from "../../../../redux/api/emailsApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import TitleHeader from "../../../reusableComponents/TitleHeader";
import { t } from "i18next";
import Builder from "./addEmailContent/Builder";
import ThemeModal from "./Modal/ThemeModal";
import { useTheme } from "@mui/material/styles";

function AddEmail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const builderRef = useRef(null);
  const { palette } = useTheme();

  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const [themes, setThemes] = useState([]);
  const [errors, setErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [response, setResponse] = useState();
  const [pagesOptions, setPagesOptions] = useState();
  const [isOpenModal, setIsOpenModal] = useState();

  useEffect(() => {
    setIsOpenModal(Object.keys(themes).length > 0);
  }, [themes]);

  const [modifiedData, setModifiedData] = useState({
    name: "",
    subject: "",
    fromAddress: "",
    fromName: "",
    replyToAddress: "",
    bccAddress: "",
    isPublished: true,
    publishUp: null,
    publishDown: null,
    language: "de",
    customHtml: null,
    customJson: null,
    ignoreDOI: false,
    ignoreDNC: false,
    plainText: "",
    previewText: "",
    template: "",
    emailType: "template",
    translationChildren: [],
    segmentTranslationParent: null,
    templateTranslationParent: null,
    category: null,
    unsubscribeForm: null,
    isTemplate: false,
    preferenceCenter: null,
    lists: [],
    scheduleEmail: false,
    assetAttachments: [],
    headers: [],
    utmTags: {
      utmCampaign: "",
      utmContent: "",
      utmMedium: "",
      utmSource: "",
    },
  });

  const { data, refetch, isFetching, isLoading } = useGetAddEditEmailsQuery({});

  const [addEmail, { isLoading: isAddLoading }] = useAddEmailMutation();

  useEffect(() => {
    if (modifiedData.name && modifiedData.subject) {
      delete errors.subject;
      delete errors.name;
      setErrors({ ...errors });
    }
  }, [modifiedData.name, modifiedData.subject]);

  useEffect(() => {
    if (data?.pages) {
      setThemes(data?.themes);
      retrievePreferencePage();
    }
  }, [data]);

  const groupOptionsByLanguage = (pages) => {
    const grouped = pages.reduce((acc, page) => {
      // Group initialization
      if (!acc[page.language]) {
        acc[page.language] = {
          label: page.language.toUpperCase(),
          options: [],
        };
      }

      // Adding the page to the respective group
      acc[page.language].options.push({
        label: page.title,
        value: page.id,
      });

      return acc;
    }, {});

    // Convert the grouped object into an array
    return Object.values(grouped);
  };

  const retrievePreferencePage = async (inputValue) => {
    return new Promise(async (resolve) => {
      const PageList = data?.pages?.pages;
      const groupedPages = groupOptionsByLanguage(PageList);
      resolve(groupedPages);
      setPagesOptions(groupedPages);
    });
  };

  const changeDynamicTab = (e) => {
    setPrimaryColorTab(e);
  };
  const queryParams = new URLSearchParams(location.search);

  const handleAddEmail = async (params, openBuilderAfterSave = false) => {
    if (modifiedData?.subject.length < 1) {
      errors.subject = true;
      setErrors({ ...errors });
    } else {
      delete errors.subject;
      setErrors({ ...errors });
    }

    if (modifiedData?.name.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }

    if (modifiedData?.emailType === "list" && !modifiedData?.segmentTranslationParent) {
      if (!modifiedData?.lists.length && !modifiedData.segmentTranslationParent) {
        errors.lists = true;
        setErrors({ ...errors });
      } else {
        delete errors.lists;
        setErrors({ ...errors });
      }
    }
    const hasErrors = Object.values(errors).some((value) => value === true);

    if (hasErrors) {
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3500);
    }

    if (Object.keys(errors).length === 0) {
      const headersList = Array.isArray(modifiedData?.headers)
        ? modifiedData.headers.map((item) => ({
            label: item.label,
            value: item.value,
          }))
        : [];

      try {
        if (modifiedData?.headers) {
          modifiedData.headers = {
            list: headersList,
          };
        }

        if (modifiedData?.category) {
          modifiedData.category = modifiedData.category.id;
        }

        // if (modifiedData?.lists) {
        //   modifiedData.lists = modifiedData.lists.map((x) => x.id);
        // }

        if (modifiedData.assetAttachments) {
          if (Array.isArray(modifiedData.assetAttachments)) {
            // Handle the case where it's already an array of asset IDs
            modifiedData.assetAttachments = modifiedData.assetAttachments.map((x) => x.id);
          } else if (typeof modifiedData.assetAttachments === "object") {
            // Handle the case where it's an object or single asset
            modifiedData.assetAttachments = [modifiedData.assetAttachments.id];
          }
        } else {
          // Handle the case where modifiedData.assetAttachments is undefined or falsy
          modifiedData.assetAttachments = [];
        }

        if (modifiedData.language?.value) {
          modifiedData.language = modifiedData.language?.value;
        }

        if (modifiedData?.translationChildren) {
          modifiedData.translationChildren = modifiedData.translationChildren.map((x) => x.id);
        }

        if (modifiedData?.translationParent) {
          modifiedData.translationParent = modifiedData.translationParent.id;
        }

        if (modifiedData?.unsubscribeForm) {
          modifiedData.unsubscribeForm = modifiedData.unsubscribeForm.id;
        }

        if (modifiedData?.preferenceCenter) {
          modifiedData.preferenceCenter = modifiedData.preferenceCenter;
        }
        let response;
        response = await addEmail(modifiedData);
        setResponse(response);
        if (!response.error) {
          refetch();
          Success(
            `${t("caymland.message.queue.report.success")}, ${t("caymland.message.type.successfully", {
              type: t("caymland.dashboard.create.past.tense"),
            })}!`
          ).then((result) => {
            if (result.isConfirmed && params === "apply") {
              navigate(`/emails/edit/${response?.data.email?.id || response?.email?.id}`);
            } else if (result.isConfirmed && params === "applyBuilder") {
              navigate(`/emails/edit/${response?.data.email?.id || response?.email?.id}`);
            } else if (result.isConfirmed) {
              navigate(`/emails/view/${response?.data?.email?.id || response?.email?.id}`);
            }
          });
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (e) {
        console.log(e);

        Error(t("caymland.message.error"));
      }
    }
  };

  const handleSubmit = async (params) => {
    if (params === "apply") {
      await builderRef.current.transformData(); // Call transformData function from Builder component
      await builderRef.current.applyAndCloseTemplate("applyBuilder"); // Call applyAndCloseTemplate function from Builder component
    } else if (params === "save") {
      await builderRef.current.transformData(); // Call transformData function from Builder component
      await builderRef.current.applyAndCloseTemplate("savebuilder"); // Call applyAndCloseTemplate function from Builder component
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/emails"),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleSubmit("save"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleSubmit("apply"),
    },
  ];

  return (
    <Fragment>
      <ThemeModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        modifiedData={modifiedData}
        setModifiedData={setModifiedData}
        themes={themes}
      />
      <Header
        showAlert={showAlert}
        alertMessage={alertMessage}
        buttons={buttons}
        loading={isAddLoading}
        title={<TitleHeader name={t("caymland.core.type.email")} title={modifiedData?.name} loading={isLoading} />}
      />

      <Wrapper
        childrenClassOne={"w-75 json-html-style"}
        childrenOne={
          <>
            <Nav className="nav-primary w-full flex" tabs>
              <NavItem style={{ paddingBottom: "15px" }}>
                <NavLink
                  href="#"
                  onClick={() => changeDynamicTab(1)}
                  style={{
                    color: primarycolorTab === 1 ? "white" : palette.text.primary,
                    textTransform: "capitalize",
                    cursor: "pointer",
                    backgroundColor: primarycolorTab === 1 ? palette?.primary?.main : "",
                  }}
                >
                  {"Builder"}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => changeDynamicTab(2)}
                  style={{
                    color: primarycolorTab === 2 ? "white" : palette.text.primary,
                    textTransform: "capitalize",
                    cursor: "pointer",
                    backgroundColor: primarycolorTab === 2 ? palette?.primary?.main : "",
                  }}
                >
                  {t("caymland.core.advanced")}
                </NavLink>
              </NavItem>

              <div style={{ marginLeft: "auto", paddingRight: "10px", display: "flex" }}>
                <div className="d-flex gap-10">
                  <p style={{ marginBottom: "0", display: "flex", alignItems: "center" }}>
                    {t("caymland.email.template")}
                  </p>
                  <Checkbox
                    name="isTemplate"
                    alignSelf
                    marginBottom="mb-8px"
                    value={modifiedData?.isTemplate}
                    onChange={(e) =>
                      setModifiedData({
                        ...modifiedData,
                        isTemplate: e.target.checked,
                      })
                    }
                  />
                </div>
              </div>
            </Nav>
            <TabContent activeTab={primarycolorTab} style={{ display: "flex" }}>
              <TabPane tabId={2} style={{ flex: "1" }}>
                <div className="py-20 px-10">
                  <Advanced modifiedData={modifiedData} setModifiedData={setModifiedData} pagesOptions={pagesOptions} />
                </div>
              </TabPane>
              {/* <TabPane tabId={3} style={{ flex: "1" }}>
                <CustomJson modifiedData={modifiedData} setModifiedData={setModifiedData} themes={themes} />
              </TabPane>
              <TabPane tabId={4} style={{ flex: "1" }}>
                <CustomHtml modifiedData={modifiedData} setModifiedData={setModifiedData} />
              </TabPane> */}
              <TabPane tabId={1} style={{ flex: "1" }}>
                <Builder
                  setModifiedData={setModifiedData}
                  modifiedData={modifiedData}
                  response={response}
                  isOpenModal={isOpenModal}
                  setIsOpenModal={setIsOpenModal}
                  apply={handleAddEmail}
                  themes={themes}
                  title={
                    <TitleHeader name={t("caymland.core.type.email")} title={modifiedData?.name} loading={isLoading} />
                  }
                  ref={builderRef} // Pass the ref to the Builder component
                />
              </TabPane>
            </TabContent>
          </>
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton />
          ) : (
            <Sidebar
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              preferenceCenterPages={retrievePreferencePage}
              errors={errors}
              setErrors={setErrors}
            />
          )
        }
      />
    </Fragment>
  );
}

export default AddEmail;
