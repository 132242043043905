import React, { useCallback, useEffect, useRef, useState } from "react";
import StaticCompanySelect from "./StaticCompanySelect";
import {
  Select,
  Text,
  TextArea,
  DatePicker,
  Label,
  ButtonGroup,
  TimePicker,
  LookupInput,
} from "../../reusableComponents/Inputs";
import { Controller } from "react-hook-form";
import TimezoneSelect from "../../reusableComponents/ReusableSelects/Timezone";
import moment from "moment";
import CountriesSelect from "../../reusableComponents/ReusableSelects/Countries";
import LocalesSelect from "../../reusableComponents/ReusableSelects/Locales";
import RegionsSelect from "../../reusableComponents/ReusableSelects/Regions";
import { t } from "i18next";
import { useGetContactsQuery, useGetContactsSearchQuery } from "../../../redux/api/contactsApi";
import { debounce } from "lodash";

export const FieldsRenderSection = ({
  aliases,
  filteredFields,
  title,
  isW50 = false,
  setContactImg,
  setFormChanged,
  control,
  watch = () => {},
  getValues,
  noBorder = false,
  errors,
  setValue,
  setDisabled,
}) => {
  return (
    <div className="group-fields-style" style={{ border: noBorder ? "none" : "1px solid #135f9517 " }}>
      <h5 style={{ fontWeight: "600" }}>{title}</h5>
      <div className="row">
        {filteredFields
          ?.sort((a, b) => a.order - b.order)
          .filter((field) => {
            return Array.isArray(aliases) ? aliases.includes(field.alias) : true;
          })
          .map((field) => {
            if (field.alias === "company") {
              return (
                <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
                  <StaticCompanySelect control={control} watch={watch} setValue={setValue} getValues={getValues} />
                </div>
              );
            } else {
              return (
                <FiledsRenderReusable
                  key={field.id}
                  field={field}
                  isW50={isW50}
                  setContactImg={setContactImg}
                  setFormChanged={setFormChanged}
                  control={control}
                  watch={watch}
                  errors={errors}
                  setDisabled={setDisabled}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

const FiledsRenderReusable = ({ field, isW50, control, watch, errors, setDisabled = () => {} }) => {
  const yesLabel = field?.properties?.yes || t("caymland.core.form.yes");
  const noLabel = field?.properties?.no || t("caymland.core.form.no");
  const toggleButtons = [
    { value: 3, label: "X" },
    { value: 0, label: noLabel },
    { value: 1, label: yesLabel },
  ];

  const fieldValue = watch(field.alias);
  const [debouncedFieldValue, setDebouncedFieldValue] = useState("");
  const previousFieldValue = useRef(fieldValue); // Track previous value

  // Debounce field value changes for `isUniqueIdentifier`
  useEffect(() => {
    if (field.isUniqueIdentifier) {
      const handler = debounce(() => {
        setDebouncedFieldValue(fieldValue);
      }, 500); // 500ms debounce

      handler(); // Trigger debounced function
      return () => handler.cancel(); // Cleanup
    }
  }, [fieldValue, field.isUniqueIdentifier]);

  // Check if the value is valid (you can extend this to other types)
  const isValidValue = (value) => {
    if (!value) return false;
    if (field.type === "email") {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    }
    // Add other type-specific validation if necessary
    return true;
  };

  // Query to check for unique identifier
  const { data: uniqueCheckQuery } = useGetContactsSearchQuery(
    { search: debouncedFieldValue },
    { skip: !field.isUniqueIdentifier || !isValidValue(debouncedFieldValue) }
  );
  useEffect(() => {
    if (field.isUniqueIdentifier && previousFieldValue.current !== fieldValue) {
      previousFieldValue.current = fieldValue; // Update the previous value

      const isUnique = !uniqueCheckQuery?.data?.some(
        (item) => item?.fields?.all?.[field.alias] === debouncedFieldValue
      );
    }
  }, [uniqueCheckQuery, debouncedFieldValue, fieldValue, field.isUniqueIdentifier, setDisabled]);

  const renderUniqueIdentifierSpan = () => {
    if (field.isUniqueIdentifier && uniqueCheckQuery?.data) {
      const existingItems = uniqueCheckQuery?.data.filter((item) => item?.fields?.all?.[field.alias] === fieldValue);
      if (existingItems.length > 0) {
        return (
          <span className="tw-text-[14px] tw-text-[#6C757D]">
            {t("caymland.lead.exists.by.field")}:{" "}
            {existingItems.map((item, index) => (
              <React.Fragment key={item?.fields?.all?.id}>
                <a
                  href={`/contacts/view/${item?.fields?.all?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tw-text-black"
                >
                  {item?.fields?.all?.firstname} {item?.fields?.all?.lastname} ({item?.fields?.all?.id})
                </a>
                {index !== existingItems.length - 1 && ", "}
              </React.Fragment>
            ))}
          </span>
        );
      }
    }
    return null;
  };

  let content;
  let options = [];

  switch (field.type) {
    case "text":
    case "number":
    case "tel":
    case "url":
    case "email":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Text
                name={name}
                type={field.type}
                value={value}
                onChange={onChange}
                label={field.label}
                required={field.isRequired}
                labelHidden={true}
                errors={errors && errors[name]}
              />
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );
      break;

    // case "email":
    //   content = (
    //     <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
    //       <Controller
    //         control={control}
    //         name={field.alias}
    //         render={({ field: { name, onChange, value } }) => (
    //           <Text
    //             name={name}
    //             type="email"
    //             value={value}
    //             onChange={onChange}
    //             label={field.label}
    //             required={field.isRequired}
    //             labelHidden={true}
    //             errors={errors?.[name]}
    //           />
    //         )}
    //       />
    //       <div className="mb-20px">
    //         {contactQuery?.data && contactQuery?.data.some((item) => item?.fields?.all?.email === watch("email")) && (
    //           <span className="tw-text-[14px] tw-text-[#6C757D]">
    //             {t("caymland.lead.exists.by.field")}:{" "}
    //             {contactQuery?.data
    //               ?.filter((item) => item?.fields?.all?.email === watch("email")) // Filter items by email
    //               .map(
    //                 (item, index) => (
    //                   setDisabled(true),
    //                   (
    //                     <React.Fragment key={item?.fields?.all?.id}>
    //                       <a
    //                         href={`/contacts/view/${item?.fields?.all?.id}`}
    //                         target="_blank"
    //                         rel="noopener noreferrer"
    //                         className="tw-text-black"
    //                       >
    //                         {item?.fields?.all?.firstname} {item?.fields?.all?.lastname} ({item?.fields?.all?.id})
    //                       </a>
    //                       {index !==
    //                         contactQuery.data.filter((i) => i?.fields?.all?.email === watch("email")).length - 1 &&
    //                         ", "}
    //                     </React.Fragment>
    //                   )
    //                 )
    //               )}
    //           </span>
    //         )}
    //       </div>
    //     </div>
    //   );
    //   break;

    case "boolean":
      const defaultBooleanValue = field?.defaultValue == 1 ? 1 : field?.defaultValue == 0 ? 0 : 3;
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Label name={name} label={field.label} required={field?.isRequired} errors={errors && errors[name]} />
                <ButtonGroup
                  buttons={toggleButtons}
                  defaultValue={defaultBooleanValue}
                  value={value}
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value, 10);
                    onChange(!isNaN(newValue) ? newValue : event.target.value);
                  }}
                />
              </>
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );
      break;

    case "date":
    case "datetime":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <DatePicker
                label={field.label}
                required={field.isRequired}
                name={name}
                isClearable={false}
                value={value}
                showTimeSelect={field.type === "datetime" ? true : false}
                onChange={(date) => {
                  // Format the date to "YYYY-MM-DD HH:mm:ss"
                  const formattedDate =
                    field.type === "datetime"
                      ? moment(date).format("YYYY-MM-DD HH:mm:ss")
                      : moment(date).format("YYYY-MM-DD");
                  onChange(formattedDate); // Use the formatted date
                }}
              />
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );
      break;

    case "country":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <CountriesSelect
                label={field.label}
                name={name}
                onChange={onChange}
                value={value}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );
      break;
    case "locale":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <LocalesSelect
                label={field.label}
                name={name}
                onChange={onChange}
                value={value}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );
      break;
    case "region":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <RegionsSelect
                label={field.label}
                name={name}
                onChange={onChange}
                value={value}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );

      break;

    case "lookup":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value, onBlur } }) => (
              <>
                <LookupInput
                  field={field}
                  control={control}
                  value={value}
                  label={field.label}
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  style={{
                    height: "40px", // Input height
                    width: "100%", // Full width
                    padding: "10px 14px", // Input padding
                    fontSize: "15px", // Input text size
                    backgroundColor: "#fff", // Input background color
                    border: "1px solid #ccc", // Default border
                    borderRadius: "4px", // Standard border-radius
                    boxShadow: "none", // No shadow by default
                    transition: "border-color 0.2s ease, box-shadow 0.2s ease", // Smooth transition for focus state
                  }}
                />
              </>
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );

      break;

    case "select":
      if (Array.isArray(field?.properties?.list)) {
        options = field.properties.list.map((i) => ({ label: i.label, value: i.value }));
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({ label: i, value: i }));
      }

      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                label={field.label}
                isRequired={field.isRequired}
                isMulti={field.type === "multiselect" ? true : false}
                options={options}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value}
                errors={errors && errors[name]}
              />
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );
      break;
    case "multiselect":
      if (Array.isArray(field?.properties?.list)) {
        options = field.properties.list.map((i) => ({ label: i.label, value: i.value }));
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({ label: i, value: i }));
      }

      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => {
              // Transform the value back to an array of option objects
              const selectedOptions = options.filter((option) => value?.includes(option.value));

              return (
                <Select
                  name={name}
                  label={field.label}
                  isRequired={field.isRequired}
                  errors={errors && errors[name]}
                  isMulti={field.type === "multiselect"}
                  options={options}
                  value={selectedOptions} // Set the selected options as the value
                  onChange={(selectedOptions) => {
                    // Transform the selected options into an array of their values
                    const newValue = selectedOptions.map((option) => option.value);

                    // Call the original onChange method with the new array of values
                    onChange(newValue);
                  }}
                />
              );
            }}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );
      break;

    case "textarea":
      content = (
        <div key={field.id} className="w-50 w-full small-editor">
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TextArea
                name={name}
                errors={errors && errors[name]}
                label={field.label}
                required={field.isRequired}
                data={value ?? ""}
                onChange={onChange}
              />
            )}
          />
        </div>
      );
      break;

    case "time":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimePicker
                label={name}
                name={field.alias}
                errors={errors && errors[name]}
                value={value}
                required={field.isRequired}
                onChange={onChange}
              />
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );
      break;

    case "timezone":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimezoneSelect
                errors={errors && errors[name]}
                label={field.alias}
                name={name}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value ? { label: value, value } : null}
                required={field.isRequired}
              />
            )}
          />
          {renderUniqueIdentifierSpan()}
        </div>
      );
      break;

    default:
      content = (
        <div className="pb-0 mb-0">
          <p>
            {t("caymland.core.needs.done")} ({field.type})
          </p>
        </div>
      );
  }
  return content;
};

export default FiledsRenderReusable;
