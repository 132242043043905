import React from "react";
import Button from "../../../../reusableComponents/Buttons/Button";
import { Modal } from "react-bootstrap";
import SidebarSkeleton from "../../../../reusableComponents/SidebarSkeleton";
import { t } from "i18next";

const CustomModal = ({ show, handleClose, title, modalOptions, automatic, manual, cachedHtml }) => {
  const selectAllText = (e) => {
    e.target.select();
  };
  const decodeHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.documentElement.textContent;
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}   backdrop="static">
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
          closeButton
        >
          <Modal.Title
            style={{
              padding: "4px",
              fontSize: "18px",
            }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: "20px" }}>
          {modalOptions.type === "automatic" ? (
            <div className="p-no-margin" style={{}}>
              <p style={{ fontSize: "12px", padding: "8px", borderBottom: "1px solid gainsboro" }}>
                {automatic?.description}
              </p>
              <h4 style={{ padding: "10px 0" }}>{automatic?.headerViaJs}</h4>
              <textarea
                id="textArea"
                rows="4"
                style={{ width: "100%", color: "#6d6d6d" }}
                className="cursor-disabled"
                onClick={(e) => selectAllText(e)}
                onFocus={(e) => selectAllText(e)}
                onChange={(e) => selectAllText(e)}
                value={decodeHtml(automatic?.viaJs)}
              />
              <h4 style={{ padding: "10px 0" }}>{automatic?.headerViaIframe}</h4>
              <textarea
                id="textArea"
                rows="4"
                style={{ width: "100%", color: "#6d6d6d" }}
                className="cursor-disabled"
                onClick={(e) => selectAllText(e)}
                onFocus={(e) => selectAllText(e)}
                onChange={(e) => selectAllText(e)}
                value={decodeHtml(automatic?.viaIframe)}
              />
              <span>{automatic?.note}</span>
            </div>
          ) : (
            <div className="p-no-margin">
              <p style={{ fontSize: "12px", padding: "8px", borderBottom: "1px solid gainsboro" }}>
                {manual?.description}
              </p>
              <h5 style={{ fontSize: "16px", padding: "10px 0" }}>{manual?.headerFormScript}</h5>
              <textarea
                id="textArea"
                rows="4"
                style={{ width: "100%", color: "#6d6d6d" }}
                className="cursor-disabled"
                onClick={(e) => selectAllText(e)}
                onFocus={(e) => selectAllText(e)}
                onChange={(e) => selectAllText(e)}
                value={decodeHtml(manual?.formScript)}
              />
              <h5 style={{ fontSize: "16px", padding: "10px 0" }}>{manual?.headerFormContent}</h5>
              <textarea
                id="textArea"
                rows="4"
                style={{ width: "100%", color: "#6d6d6d" }}
                className="cursor-disabled"
                onClick={(e) => selectAllText(e)}
                onFocus={(e) => selectAllText(e)}
                onChange={(e) => selectAllText(e)}
                value={decodeHtml(manual?.formContent)}
              />
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            buttons={[
              {
                title: t("caymland.core.close"),
                onClick: handleClose,
              },
            ]}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
