import { Controller } from "react-hook-form";
import React, { useState } from "react";
import { Select } from "../../../../reusableComponents/Inputs";
import Button from "../../../../reusableComponents/Buttons/Button";
import { useGetSmsesQuery } from "../../../../../redux/api/smsApi";
import { t } from "i18next";

export default function StaticSmsFields({ control, errors }) {
  const { data: smsOptions, refetch } = useGetSmsesQuery({});
  const [selectedSms, setSelectedSms] = useState(null); // State to track selected value

  // Process smsOptions to group by language
  const groupedOptions =
    smsOptions?.data
      ?.filter((sms) => sms.smsType === "template") // Filter only templates
      ?.reduce((acc, sms) => {
        const languageLabel = sms.language || "Unknown"; // Fallback if language is missing
        if (!acc[languageLabel]) {
          acc[languageLabel] = [];
        }
        acc[languageLabel].push({
          label: sms.name, // Name for the option label
          value: sms.id, // ID for the option value
        });
        return acc;
      }, {}) || {};

  // Convert grouped options to format Select component expects
  const selectOptions = Object.entries(groupedOptions).map(([language, options]) => ({
    label: language, // Group label (language)
    options, // Array of SMS templates under this language
  }));

  return (
    <div className="tw-mt-4">
      {/* Static Select Dropdown */}
      <Controller
        control={control}
        name="properties.sms"
        render={({ field: { name, value, onChange } }) => {
          return (
            <Select
              name={name}
              value={value}
              label="Select SMS Option"
              required
              options={selectOptions} // Grouped by language
              onChange={(selectedValue) => {
                onChange(selectedValue?.value);
                setSelectedSms(selectedValue); // Update state with selected value
              }}
              errors={errors?.properties?.[name]}
            />
          );
        }}
      />

      {/* Static Buttons */}
      <div style={{ marginTop: 10, display: "flex", gap: 10 }}>
        {/* Print1 - Opens /sms/new */}
        <Button
          buttons={[
            {
              name: "Print1",
              title: t("caymland.sms.send.new.sms"),
              onClick: () => window.open("/sms/new", "_blank"), // Open in new tab
              float: "left",
              margin: "0 10px 0 0",
            },
          ]}
        />

        {/* Print2 - Opens /sms/edit/{value} */}
        <Button
          buttons={[
            {
              name: "Print2",
              title: t("caymland.sms.send.edit.sms"),
              onClick: () => selectedSms && window.open(`/sms/edit/${selectedSms?.value}`, "_blank"), // Open in new tab if selected
              float: "left",
              margin: "0 10px 0 0",
              not_active: !selectedSms, // Disable if no selection
            },
          ]}
        />
      </div>
    </div>
  );
}
