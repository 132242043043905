import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../reusableComponents/Buttons/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Text, TextArea, Checkbox, Select } from "../../reusableComponents/Inputs";
import { t } from "i18next";
import { useCreateGroupMutation, useGetGroupByIdQuery, useUpdateGroupMutation } from "../../../redux/api/fieldsApi";
import { Error, Success } from "../../reusableComponents/Alerts";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required(t("validation.required")),
});

const CustomModal = ({ modalOptions, setModalOptions, refetch }) => {
  const { data } = useGetGroupByIdQuery(modalOptions.id, { skip: !modalOptions.id });

  const [addGroup] = useCreateGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();

  // Initialize useForm with default values and validation schema
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      isPublished: false,
    },
  });
  const toggle = () => {
    setModalOptions({ isOpen: !modalOptions.isOpen });
  };

  // Effect to set form values when editing
  useEffect(() => {
    if (data && modalOptions.type == "edit") {
      setValue("name", data?.data?.name || "");
      setValue("alias", data?.data?.alias || "");
      setValue("isPublished", data?.data?.isPublished || false);
    } else {
      reset({ name: "", alias: "", isPublished: false });
    }
  }, [data, setValue, modalOptions, reset]);

  const onSubmit = async (formData) => {
    // Remove the alias property if it's null
    if (formData.alias === "") {
      delete formData.alias;
    }

    console.log(formData);

    if (modalOptions.type === "edit") {
      console.log("Updating data:", formData);
      const response = await updateGroup({ id: modalOptions.id, group: formData });
      if (!response.error) {
        refetch();
        Success(
          `${t("caymland.core.update.step.success")}`,
          t("caymland.message.type.successfully", { type: t("caymland.core.type.edited") })
        );
      } else {
        Error(t("caymland.message.error"));
      }
    } else {
      console.log("Creating new data:", formData);
      const response = await addGroup({ group: formData });
      if (!response.error) {
        refetch();
        Success(
          `${t("caymland.core.update.step.success")}`,
          t("caymland.message.type.successfully", { type: t("caymland.lead.segments.contacts.added") })
        );
      } else {
        Error(t("caymland.message.error"));
      }
    }

    // Close the modal after submission
    reset();
    toggle();
  };

  const buttons = [
    {
      name: "close",
      title: `${t("caymland.core.close")}`,
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => toggle(),
    },
    {
      name: "save",
      title: modalOptions?.type === "edit" ? t("caymland.core.form.update") : t("caymland.core.form.add"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSubmit(onSubmit),
    },
  ];

  return (
    <Modal show={modalOptions.isOpen} onHide={toggle}   backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {modalOptions?.type === "edit"
            ? `${t("caymland.core.form.edit")} ${data?.data?.name}`
            : t("caymland.lead.field.group") + " " + t("caymland.core.form.add")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Text
                label={t("Name")}
                required
                {...field} // Connects the field to react-hook-form's state
                errors={errors.name?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="isPublished"
            render={({ field: { name, value, onChange } }) => (
              <Checkbox name={name} value={value} onChange={onChange} label={t("caymland.core.form.published")} />
            )}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
