import Select from "react-select";
import { useEffect } from "react";
import { t } from "i18next";
import { useTheme } from "@mui/material/styles";

export const FiltersFieldSelector = ({ options, handleOnChange, additionalData, value, isDisabled, loading }) => {

    const { palette } = useTheme();
  
  function getOptionLabel(options, name) {
    for (let group of options) {
      for (let field of group.options) {
        if (field.name === name) {
          return field.label;
        }
      }
    }
    return `${t("caymland.core.select")}...`;
  }

  let selectedLabel;

  selectedLabel = getOptionLabel(options, value.field);

  useEffect(() => {
    selectedLabel = getOptionLabel(options, value.field);
  }, [options, value]);

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: palette?.primary?.main,
      primary: palette?.primary?.main,
    },
  });

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: "15px",
      padding: "1px 0",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.003)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        borderColor: base["&:hover"].borderColor,
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "white",
    }),
    group: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: "10px 2px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      color: "#333",
      padding: "10px 2px",
      fontSize: "15px",
      transition: "background-color 0.2s ease",
      paddingLeft: "20px",
      "&:active": {
        color: "white",
        backgroundColor: palette?.primary?.main,
      },
      "&:hover": {
        color: "white",
        backgroundColor: palette?.primary?.main,
      },
      "&:focus": {
        color: "white",
      },
    }),
  };

  return (
    <div style={{ width: "30%", alignSelf: "center" }}>
      <Select
        size="lg"
        isDisabled={isDisabled}
        isLoading={loading}
        options={options}
        value={loading ? null : { label: selectedLabel }}
        placeholder={loading ? t("caymland.core.loading") : `${t("caymland.core.select")}...`}
        menuPlacement="auto"
        onChange={(e) => {
          handleOnChange(
            e?.list
              ? {
                  field: e?.name,
                  type: e?.type,
                  input: e?.type,
                  object: e?.object,
                  id: e?.name,
                  operators: e?.operators,
                  list: e?.list ? e?.list : [],
                }
              : {
                  field: e?.name,
                  type: e?.type,
                  input: e?.type,
                  object: e?.object,
                  id: e?.name,
                  operators: e?.operators,
                }
          );
        }}
        theme={selectTheme}
        styles={selectStyles}
      />
    </div>
  );
};
