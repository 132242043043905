import React from "react";
import Dropzone from "react-dropzone-uploader";
import { Label } from "../../reusableComponents/Inputs";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { useDownloadDocumentMutation } from "../../../redux/api/ordersApi";
import { useTheme } from '@mui/material/styles';

const MainContent = ({ getUploadParams, handleChangeStatus, isFetching, control, errors, watch }) => {
  const { t } = useTranslation();
  const source = watch("source");
  const { palette } = useTheme();

  // Use the download document mutation hook
  const [downloadDocument, { isLoading }] = useDownloadDocumentMutation();

  // Handle download button click
  const handleDownload = async () => {
    if (!source) {
      console.error("Source is not defined"); // Handle cases where source is undefined
      return;
    }

    try {
      // Trigger the mutation with source as the body parameter
      const response = await downloadDocument({ source }).unwrap();

      // Create a blob URL and trigger download
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", source); // Set the file name to the source
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Failed to download document", error);
    }
  };

  return isFetching ? (
    <DetailsSkeleton applyPading />
  ) : (
    <>
      <div className="flex justify-between" style={{ gap: "10px", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", width: "100%", gap: "20px" }}>
          <div style={{ width: "100%" }} className="mb-20px">
            <form className="dropzone digits mt-10px" id="singleFileUpload" action="/upload.php">
              <div className="dz-message needsclick h-200-dropzone">
                <div className="tw-flex tw-items-center tw-justify-between tw-mb-5">
                  <Label
                    label={t("caymland.lead.lead.activity.documents")}
                    tooltip={source ? source : null}
                    required={true}
                    errors={errors && errors["source"]}
                  />
                  {watch("source") && (
                    <button
                      type="button"
                      onClick={handleDownload}
                      style={{
                        display: "inline-block",
                        padding: "10px 20px",
                        backgroundColor: palette.primary.main,
                        color: "#fff",
                        textDecoration: "none",
                        borderRadius: "5px",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                      disabled={isLoading || !source}
                    >
                      {t("caymland.core.download")}
                    </button>
                  )}
                </div>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  maxFiles={1}
                  multiple={false}
                  required
                  canCancel={false}
                  inputContent={t("caymland.brochures.templates.uploader")}
                  accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  styles={{
                    dropzoneActive: { borderColor: "green" },
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContent;
