import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox, DatePicker, Select, Text, TextArea } from "../../../../reusableComponents/Inputs";
import CustomCreatableSelectInput from "../../../../reusableComponents/Inputs/InputSelect";
import {
  useAddNoteActionMutation,
  useAddNoteTagsMutation,
  useGetContractNotesActionsQuery,
  useGetContractNotesTagsQuery,
} from "../../../../../redux/api/contractsApi";
import { useSelector } from "react-redux";
import { t } from "i18next";

const General = ({ control, errors, noteData, currentIndex, userOptions }) => {
  const { data: tagOptions } = useGetContractNotesTagsQuery();
  const { data: actionOptions } = useGetContractNotesActionsQuery();

  const user = useSelector((state) => state.auth.user);

  const { isAdmin, name } = useSelector((state) => state?.auth?.user?.role || {});

  const [addNoteTag] = useAddNoteTagsMutation();
  const [addNoteAction] = useAddNoteActionMutation();

  const handleCreateOption = async (inputValue, onChange, currentValue) => {
    const newTag = { label: inputValue, value: inputValue };

    onChange([...(currentValue || []), newTag]);

    try {
      await addNoteTag({ tag: inputValue });
    } catch (error) {
      console.error("Error adding new tag:", error);
    }
  };

  const handleCreateOptionAction = async (inputValue, onChange) => {
    const newTag = { label: inputValue, value: inputValue };

    onChange(newTag);

    try {
      await addNoteAction({ action: inputValue });
    } catch (error) {
      console.error("Error adding new action:", error);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {/* Subject */}
      <Controller
        control={control}
        name={`notes[${currentIndex}].subject`}
        render={({ field: { name, onChange, value } }) => (
          <Text
            name={name}
            value={value}
            onChange={onChange}
            marginBottom
            label={t("caymland.email.subject")}
            required
            errors={errors?.notes?.[currentIndex]?.subject}
          />
        )}
      />

      {/* Text */}
      <Controller
        control={control}
        name={`notes[${currentIndex}].text`}
        render={({ field: { name, onChange, value } }) => (
          <TextArea
            name={name}
            value={value}
            onChange={onChange}
            marginBottom
            label="Text"
            required
            errors={errors?.notes?.[currentIndex]?.text}
          />
        )}
      />
      <Controller
        control={control}
        name={`notes[${currentIndex}].user`}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={userOptions.find((item) => item.value == (value?.id || value)) || user.id}
            onChange={(selectedOption) => {
              // Ensure the selected option is passed back in the proper format
              onChange(selectedOption ? { id: selectedOption.value } : null);
            }}
            margin
            isClearable={false}
            options={userOptions}
            label={t("caymlandb2b.opportunity.user")}
            errors={errors && errors[name]}
          />
        )}
      />
      {/* DateTime */}
      <Controller
        control={control}
        name={`notes[${currentIndex}].dateTime`}
        render={({ field: { name, onChange, value } }) => (
          <DatePicker
            name={name}
            value={value}
            onChange={onChange}
            label={t("caymland.core.type.datetime")}
            showTimeSelect
            dateFormat="yyyy-MM-dd HH:mm"
            marginBottom={false}
            errors={errors?.notes?.[currentIndex]?.dateTime}
          />
        )}
      />
      {isAdmin || name === "Company Admin" ? (
        <Controller
          control={control}
          name={`notes[${currentIndex}].noteTags`}
          render={({ field: { name, onChange, value } }) => (
            <CustomCreatableSelectInput
              name={name}
              value={value?.map((tag) => ({ value: tag.id, label: tag.tag }))} // Transform for select
              onChange={(selectedOptions) =>
                onChange(selectedOptions.map((option) => ({ id: option.value, tag: option.label })))
              }
              isMulti
              margin
              options={tagOptions}
              label={t("caymland.campaign.lead.tags")}
              errors={errors?.notes?.[currentIndex]?.tags}
              onCreateOption={(inputValue) => handleCreateOption(inputValue, onChange, value)}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name={`notes[${currentIndex}].tags`}
          render={({ field: { name, onChange, value } }) => (
            <Select
              name={name}
              value={value?.map((tag) => ({ value: tag.id, label: tag.tag }))} // Transform for select
              onChange={(selectedOptions) =>
                onChange(selectedOptions.map((option) => ({ id: option.value, tag: option.label })))
              }
              isMulti
              margin
              options={tagOptions}
              label={t("caymland.campaign.lead.tags")}
              errors={errors?.notes?.[currentIndex]?.tags}
            />
          )}
        />
      )}

      {/* Is Reminder */}
      <Controller
        control={control}
        name={`notes[${currentIndex}].isReminder`}
        render={({ field: { name, onChange, value } }) => (
          <Checkbox
            name={name}
            value={value}
            onChange={(e) => onChange(e.target.checked)}
            label={t("caymland.contract.is.reminder")}
            errors={errors?.notes?.[currentIndex]?.isReminder}
          />
        )}
      />

      {/* Reminder DateTime */}
      <Controller
        control={control}
        name={`notes[${currentIndex}].reminderDateTime`}
        render={({ field: { name, onChange, value } }) => (
          <DatePicker
            name={name}
            value={value}
            onChange={onChange}
            label={t("caymland.contract.reminder.date.time")}
            showTimeSelect
            marginBottom={false}
            dateFormat="yyyy-MM-dd HH:mm"
            errors={errors?.notes?.[currentIndex]?.reminderDateTime}
          />
        )}
      />

      {/* Action */}
      {isAdmin || name === "Company Admin" ? (
        <Controller
          control={control}
          name={`notes[${currentIndex}].action`}
          render={({ field: { name, onChange, value } }) => (
            <CustomCreatableSelectInput
              name={name}
              value={actionOptions?.find((item) => item.label == value?.action) || value}
              onChange={onChange}
              options={actionOptions}
              label={t("caymland.page.tracking.form.action")}
              errors={errors?.notes?.[currentIndex]?.action}
              onCreateOption={(inputValue) => handleCreateOptionAction(inputValue, onChange)}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name={`notes[${currentIndex}].action`}
          render={({ field: { name, onChange, value } }) => (
            <Select
              name={name}
              value={value}
              onChange={onChange}
              options={actionOptions}
              label="Actions"
              errors={errors?.notes?.[currentIndex]?.action}
            />
          )}
        />
      )}
    </div>
  );
};

export default General;
