import React from "react";
import { Controller } from "react-hook-form";
import { Text, DatePicker } from "../../../../reusableComponents/Inputs";
import { t } from "i18next";

const Other = ({ control, errors, currentIndex, noteData }) => {
  // Find the note based on the current index
  const note = noteData?.[currentIndex];

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {/* Event Name */}
      <Controller
        control={control}
        name={`notes[${currentIndex}].noteEvent.name`} // Dynamic name using currentIndex
        render={({ field: { name, onChange, value } }) => (
          <Text
            name={name}
            value={value}
            onChange={onChange}
            label={t("caymland.contract.event.name")}
            marginBottom
            errors={errors?.notes?.[currentIndex]?.noteEvent?.name}
          />
        )}
      />

      {/* Event Location */}
      <Controller
        control={control}
        name={`notes[${currentIndex}].noteEvent.location`} // Dynamic name using currentIndex
        render={({ field: { name, onChange, value } }) => (
          <Text
            name={name}
            value={value}
            onChange={onChange}
            label={t("caymland.contract.event.location")}
            marginBottom
            errors={errors?.notes?.[currentIndex]?.noteEvent?.location}
          />
        )}
      />

      <div className="tw-flex tw-gap-2">
        {/* Event Start Date */}
        <Controller
          control={control}
          name={`notes[${currentIndex}].noteEvent.dateStart`} // Dynamic name using currentIndex
          render={({ field: { name, onChange, value } }) => (
            <DatePicker
              name={name}
              value={value}
              onChange={onChange}
              label={t("caymland.dashboard.label.appointments.start")}
              marginBottom={false}
              dateFormat="yyyy-MM-dd"
              errors={errors?.notes?.[currentIndex]?.noteEvent?.dateStart}
            />
          )}
        />

        {/* Event End Date */}
        <Controller
          control={control}
          name={`notes[${currentIndex}].noteEvent.dateEnd`} // Dynamic name using currentIndex
          render={({ field: { name, onChange, value } }) => (
            <DatePicker
              name={name}
              value={value}
              onChange={onChange}
              marginBottom={false}
              label={t("caymland.dashboard.label.appointments.end")}
              dateFormat="yyyy-MM-dd"
              errors={errors?.notes?.[currentIndex]?.noteEvent?.dateEnd}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Other;
