import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Text, TextArea } from "../../reusableComponents/Inputs";
import { TbDeviceFloppy } from "react-icons/tb";
import { ImCancelCircle } from "react-icons/im";
import Button from "../../reusableComponents/Buttons/Button";
import { t } from "i18next";

function CreateTemplete({
  modalTemplete,
  setModalTemplete,
  toggle,
  loading,
  customSubmit,
  nameChange,
  templateName,
  setTemplateDescription,
  templateDescription,
  errors,
  ...args
}) {
  return (
    <div>
      <Modal isOpen={modalTemplete} toggle={toggle} {...args} size="md" backdrop={false}>
        <ModalHeader toggle={toggle}>
          {" "}
          <h3>{t("caymland.dashboard.template.new")}</h3>
        </ModalHeader>
        <ModalBody>
          <div className="form-row d-flex">
            <div className="input-container col-sm-6 col-md-6 " style={{ marginBottom: "15px" }}>
              <Text
                name={"templateName"}
                errors={errors.templateName}
                label={t("caymland.dashboard.template.name")}
                required={true}
                value={templateName && templateName}
                onChange={nameChange}
              />
            </div>
            <div className="small-editor w-100 mt-20px col-sm-6 col-md-6 ">
              <TextArea
                name={"Template Description"}
                label={t("caymland.dashboard.template.description")}
                data={templateDescription && templateDescription}
                onChange={(data) => {
                  setTemplateDescription((prev) => ({
                    ...prev,
                    data,
                  }));
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            loading={loading}
            buttons={[
              {
                name: "close",
                title: t("caymland.core.form.close"),
                icon: <ImCancelCircle size={15} />,
                onClick: () => toggle(),
              },
              {
                name: "save",
                title: t("caymland.core.form.save"),
                icon: <TbDeviceFloppy size={15} />,
                onClick: (e) => customSubmit(e),
              },
            ]}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CreateTemplete;
