import { t } from "i18next";
import React, { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function SearchHelper() {
  const [modal, setModal] = useState();
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <FaQuestionCircle onClick={toggle} size={17} className="cursor-pointer question-margin-left" />
      <Modal size="lg" isOpen={modal} toggle={toggle}  backdrop={false}>
        <form>
          <ModalHeader toggle={toggle}>Search Help</ModalHeader>
          <ModalBody className="px-5">
            <strong>Search Operators:</strong>
            <br />
            <p>{t("caymland.core.search.help")}</p>
            <strong>Search Operators:</strong>
            <br />
            <ul>
              <li>{t("caymland.lead.lead.searchcommand.isanonymous")}</li>
              <li>{t("caymland.lead.lead.searchcommand.isunowned")}</li>
              <li>{t("caymland.core.searchcommand.ismine")}</li>
              <li>email:*</li>
              <li>{`segment:{segment_alias}`}</li>
              <li>name:*</li>
              <li>company:*</li>
              <li>owner:*</li>
              <li>ip:*</li>
              <li>ids:ID1,ID2 (comma separated IDs, no spaces)</li>
              <li>
                {`
                          common:{segment_alias} + {segment_alias} + ...`}
              </li>
              <li>tag:*</li>
              <li>stage:*</li>
              <li>email_sent:EMAIL_ID</li>
              <li>email_read:EMAIL_ID</li>
              <li>email_queued:EMAIL_ID</li>
              <li>email_pending:EMAIL_ID</li>
            </ul>
          </ModalBody>
        </form>
      </Modal>
    </>
  );
}

export default SearchHelper;
