import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Card, CardBody } from "reactstrap";
import { Text, Select } from "../../reusableComponents/Inputs";
import "react-dropzone-uploader/dist/styles.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Merge from "../../reusableComponents/Buttons/merge";
import DetailTabsCard from "./viewCompany/DetailTabsCard";
import { FaRegTimesCircle } from "react-icons/fa";
import Header from "../../reusableComponents/Header/Header";
import { useGetCompaniesReqQuery } from "../../../redux/api/companiesApi";
import CustomTable from "../../main-menu-sidebar/contacts/CustomTable/CustomTable";
import { useUpdateCompanyMutation } from "../../../redux/api/companiesApi";
import { usersApi } from "../../../redux/api/usersApi";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import { SlideAlert } from "../../reusableComponents/Alerts";
import { AiOutlineSave } from "react-icons/ai";
import QuickAdd from "../contacts/QuickAdd/QuickAdd";
import { useTranslation } from "react-i18next";
import { useGetCompanyFieldsQuery } from "../../../redux/api/fieldsApi";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { useRemoveCompanyToContactMutation } from "../../../redux/api/contactsApi";
import ContactsToCompanyModal from "./ContactsToCompany/ContactsToCompanyModal";

function ViewCompany(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;

  const [filteredTabsFields, setFilteredTabsFields] = useState([]);
  const [companyFields, setCompanyFields] = useState([]);
  const [companyFieldsData, setCompanyFieldsData] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [detailsTabsTitle, setDetailsTabsTitle] = useState(null);
  const [owner, setOwner] = useState("");
  const [score, setScore] = useState();
  const [editedScore, setEditedScore] = useState("");
  const [ownerData, setOwnerData] = useState([]);
  const [ownerEdit, setOwnerEdit] = useState(false);
  const [scoreEdit, setScoreEdit] = useState(false);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
    orderBy: "id",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const editRef = useRef(null);
  const scoreEditRef = useRef(null);

  const { data: companyReq, refetch, isFetching, isLoading } = useGetCompaniesReqQuery({ id, query });
  const [triggerUsersQuery] = usersApi.useLazyGetUsersQuery();
  const [editCompany] = useUpdateCompanyMutation();
  const [removeCompanyToContact] = useRemoveCompanyToContactMutation();
  const { data: fields } = useGetCompanyFieldsQuery();

  useEffect(() => {
    if (fields) {
      setCompanyFields(fields?.data);
    }
  }, [fields]);

  const navBack = () => {
    navigate("/companies");
  };
  useEffect(() => {
    if (companyReq?.company) {
      retrieveCompanyDetails();
    } else if (!isFetching) {
      navigate("/companies");
    }
    if (companyReq?.leads) {
      setData(companyReq?.leads.contacts);
    }
  }, [companyReq, navigate]);

  const retrieveCompanyDetails = async () => {
    setCompanyDetails(companyReq?.company.company);
    setOwner(companyReq?.company.company.owner);
    setOwnerData(companyReq?.company.company.owner);
    setCompanyFieldsData(companyReq?.company.company.fields);
    setEditedScore(companyReq?.company.company.score);

    let arr = [];
    let arr2 = [];
    for (const [tab, fields] of Object.entries(companyReq.company.company.fields)) {
      for (const [alias, field] of Object.entries(fields)) {
        arr.push(tab);
        arr2.push(field);
      }
    }
    let unique = [...new Set(arr)];
    let unique2 = [...new Set(arr2)];
    setDetailsTabsTitle(unique);
    setFilteredTabsFields(unique2.filter((x) => x?.group !== undefined));
  };

  const retrieveOwners = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (!triggerUsersQuery) resolve([]);
      const query = {};
      if (inputValue?.length) {
        query.search = inputValue;
      }

      const response = await triggerUsersQuery(query);
      if (!response.error) {
        const ownersData = response.data.data;

        const ownersArray = ownersData.map((owners) => ({
          label: owners?.firstName + " " + owners?.lastName,
          value: owners?.id,
        }));
        resolve(ownersArray);
      }
    });

  var md5 = require("md5");

  let emailHash = md5(`${companyFields.all?.email}`);

  let imageURL = `https://www.gravatar.com/avatar/${emailHash}?s=250&d=https://mir-s3-cdn-cf.behance.net/projects/404/914e5a101209147.5f198bca199a9.jpg
                    `;

  const handleCheckOwner = async () => {
    try {
      const ownerValue = typeof ownerData === "object" && ownerData !== null ? ownerData.id : ownerData;

      const updatedCompany = {
        owner: ownerValue,
      };
      const response = await editCompany({ id: id, ...updatedCompany }).unwrap();

      if (!response.error) {
        setOwnerEdit(false);
        setOwner(response?.company?.owner);
        setShowAlert(true);
        setAlertMessage(
          `${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`
        );
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 3500);
      } else {
        console.error("Failed to update company owner.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    setScore(companyDetails?.score || "");
  }, [companyDetails]);

  const handleCheckScore = async () => {
    try {
      const updatedCompany = {
        score: editedScore || null,
      };

      const response = await editCompany({ id: id, ...updatedCompany }).unwrap();

      if (!response.error) {
        setScoreEdit(false);
        setScore(editedScore);
        setShowAlert(true);
        setAlertMessage("Success, the company has been updated!");
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 3500);
      } else {
        console.error("Failed to update company score.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/companies"),
    },
  ];
  const handleCancelClick = (e) => {
    e.stopPropagation(); // Stop the event from bubbling up
    setScoreEdit(false); // Update the state
  }; // Dependencies array, add any props/state items that upon change should redefine this function
  const handleCancelClickOwner = (e) => {
    e.stopPropagation(); // Stop the event from bubbling up
    setOwnerEdit(false); // Update the state
  }; // Dependencies array, add any props/state items that upon change should redefine this function

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        accessorFn: (row) =>
          row?.firstname ? row?.firstname + " " + row?.lastname : row?.ipAddresses && row?.ipAddresses[0]?.ip,
        header: t("caymland.lottery.column.name.name"),
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "email",
        accessorFn: (row) => row?.email,
        header: t("caymland.core.type.email"),
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "Location",
        accessorFn: (row) => row.location,
        header: t("caymland.lead.lead.thead.location"),
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          const { state, city, country } = cell?.row?.original;
          let flagLetters = cell.row?.original?.fields?.country
            ? cell.row.original?.country === "Switzerland"
              ? "ch"
              : cell?.row?.original?.country.toLowerCase()
            : "";
          return state || city || country ? (
            <div>
              {flagLetters && <i className={`flag-icon flag-icon-${flagLetters.toLowerCase()}`}></i>} {city}
              {city && state && ", "} {state}
              {!city && !state && country}
            </div>
          ) : null;
        },
      },
      {
        accessorKey: "id",
        accessorFn: (row) => row.id,
        header: t("caymland.points.table.id"),
        size: 80,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            paddingRight: 2,
            verticalAlign: "bottom",
          },
        },
        muiTableBodyCellProps: {
          align: "right",
          sx: {
            paddingRight: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px",
          },
        },
      },
    ],
    [t]
  );

  const actionButtons = ActionButtons({
    link: "viewCompanies",
    clone: false,
    edit: false,
    _delete: false,
    removeItem: removeCompanyToContact,
    showRemoveCompany: true,
    folderId: id,
    refetch: refetch,
  });

  const extraButtonsRight = <Merge company={true} />;
  return (
    <>
      {isLoading ? (
        <HeaderSkeleton isView={true} />
      ) : (
        <Header
          buttons={buttons}
          title={`${t("caymland.core.permissions.view")} ${t("caymland.company.field.header.company")}`}
          extraButtonsRight={extraButtonsRight}
          alertMessage={alertMessage}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      )}

      <>
        {isLoading ? (
          <DetailsSkeleton company />
        ) : (
          <div
            className="view-contact-heading-bar flex-wrap"
            style={{ backgroundColor: "white", boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.1)", borderRadius: "5px" }}
          >
            <div className="flex items-center justify-between w-full">
              <div className="avatar flex items-center gap-20 view-contact-heading-bar-items">
                <img className="pro" alt="" src={imageURL} data-intro="This is Profile image" width="100" />
                <div className="name-position">
                  <h2 style={{ marginBottom: "0" }}>{companyFieldsData.core?.companyname?.value}</h2>
                  <br />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    ></div>

                    {ownerEdit ? (
                      <div className="flex items-center gap-10 w-full" ref={editRef}>
                        <div className="select-permisison" style={{ padding: "0", width: "100%" }}>
                          <Select
                            name="contact-permission"
                            label={t("caymland.lead.company.field.owner")}
                            options={retrieveOwners}
                            value={
                              ownerData?.id
                                ? {
                                    value: ownerData.id,
                                    label: ownerData.firstName + " " + owner.lastName,
                                  }
                                : ownerData
                            }
                            onChange={(e) => {
                              setOwnerData(e?.value);
                            }}
                          />
                        </div>
                        {ownerEdit && (
                          <group
                            style={{
                              minWidth: "fit-content",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <button className="normal-btn" onClick={handleCancelClickOwner}>
                              <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
                            </button>
                            <button
                              className="normal-btn"
                              onClick={(e) => {
                                handleCheckOwner();
                              }}
                            >
                              <AiOutlineSave color="#135F95" size={20} title={t("caymland.module.account.button")} />
                            </button>
                          </group>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{ padding: "0", display: "block" }}
                        onClick={() => setOwnerEdit(true)}
                        className="cursor-pointer"
                      >
                        <div
                          style={{
                            marginBottom: "0",
                            display: "flex",
                            gap: "5px",
                          }}
                          className={`${ownerEdit ? "" : "afterEdit-relative"}`}
                        >
                          {t("caymland.report.report.form.owner")}:{" "}
                          {ownerEdit ? null : owner && `${owner.firstName} ${owner.lastName}`}
                        </div>
                      </div>
                    )}
                  </div>
                  <br />

                  <div
                    ref={scoreEditRef}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div
                      style={{ padding: "0", display: "block" }}
                      onClick={() => setScoreEdit(true)}
                      className="cursor-pointer"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        className={`${scoreEdit ? "" : "afterEdit-relative"}`}
                      >
                        {scoreEdit ? (
                          <Text
                            label={t("caymland.company.score.label")}
                            type="number"
                            name="contact-permission"
                            value={editedScore}
                            onChange={(e) => setEditedScore(e.target.value)}
                          />
                        ) : (
                          <>
                            <span>{t("caymland.company.score")}: </span>
                            <div
                              className="badge badge-light"
                              style={{
                                minWidth: "30px",
                                textAlign: "center",
                                fontSize: "0.75",
                                fontWeight: "400",
                                border: "1px solid #55607F",
                                color: "#55607F",
                                background: "white",
                              }}
                            >
                              {score ? score : "0"}{" "}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {scoreEdit && (
                      <div
                        style={{
                          minWidth: "fit-content",
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          alignSelf: "center",
                          marginBottom: "14px",
                        }}
                      >
                        <button className="normal-btn" onClick={handleCancelClick}>
                          <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
                        </button>
                        <button className="normal-btn" onClick={handleCheckScore}>
                          <AiOutlineSave color="#135F95" size={20} title={t("caymland.module.account.button")} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="d-flex w-full ">
          <div className="col-md-5 col-sm-12 pr-10 tw-!w-[30%]" style={{ width: "30%" }}>
            {isLoading ? (
              <>
                <SidebarSkeleton applyPadding company />
              </>
            ) : (
              <Card
                style={{
                  boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.1)",
                  height: "auto",
                  minHeight: "612px",
                  // marginBottom: "20px",
                }}
              >
                <CardBody style={{ padding: "0" }}>
                  <DetailTabsCard
                    companyFields={companyFields}
                    data={companyFieldsData}
                    id={id}
                    setDetailsTabsTitle={setDetailsTabsTitle}
                    detailsTabsTitle={detailsTabsTitle}
                    retrieveCompanyDetails={retrieveCompanyDetails}
                    companyDetails={companyDetails}
                    navBack={navBack}
                    setFilteredTabsFields={setFilteredTabsFields}
                    filteredTabsFields={filteredTabsFields}
                    setCompanyDetails={setCompanyDetails}
                    refetch={refetch}
                    companyFields2={companyReq?.fields?.fields}
                    editCompany={editCompany}
                    isFetching={isFetching}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                    showAlert={showAlert}
                  />
                </CardBody>
              </Card>
            )}
          </div>

          <div className="col-md-7 col-sm-12 pl-10 tw-!w-[70%]" style={{ width: "70%" }}>
            {isLoading ? (
              <SidebarSkeleton applyPadding company />
            ) : (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "inherit",
                  boxShadow: "none",
                  height: "400px",
                  marginBottom: "10px",
                }}
              >
                <div className="w-full justify-between d-flex items-center mb-10">
                  <span style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: 16, marginBottom: "6px " }}>
                    {t("caymland.lead.leads")}
                  </span>
                  <ContactsToCompanyModal
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                    refetch={refetch}
                  />
                  {/* <QuickAdd plusIcon setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} id={id} /> */}
                </div>
                <CustomTable
                  data={data}
                  columns={columns}
                  actionButtons={actionButtons}
                  query={query}
                  setQuery={setQuery}
                  isFetching={isFetching}
                  footer
                  // viewTable={true}
                  mediumHeight
                  showDetailPanel={true}
                  linkNavigate="companyContacts"
                  contacts
                />
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
}

export default ViewCompany;
