import React, { useState, useRef, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button as MuiButton, Tooltip } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { upperCase } from "lodash";
import usePermissions from "./usePermissions";
import { useTheme } from '@mui/material/styles';

export default function Button({
  buttons,
  loading,
  color = "primary",
  notAllowed = "pointer",
  variant = "outlined",
  textFormat = upperCase,
  isDisabled = false,
  height = 40,
}) {
  const [openButtonName, setOpenButtonName] = useState(null);
  const [menuWidth, setMenuWidth] = useState(null);
  const anchorRef = useRef(null);
  const buttonGroupRef = useRef(null);
  const { hasPermission } = usePermissions();
  const { palette } = useTheme();


  useEffect(() => {
    if (buttonGroupRef.current) {
      setMenuWidth(buttonGroupRef.current.offsetWidth);
    }
  }, []);

  const handleToggle = (buttonName) => {
    if (openButtonName === buttonName) {
      setOpenButtonName(null);
    } else {
      setOpenButtonName(buttonName);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenButtonName(null);
  };

  return (
    <>
      {buttons?.map((button) => {
        if (!hasPermission(button.permissionKey, button.permissionValue)) {
          return null;
        }
        return (
          <div
            key={button.name}
            ref={buttonGroupRef}
            style={{
              display: "flex",
              alignItems: "center",
              opacity: button?.not_active && 0.4,
              float: button?.float,
              margin: button?.margin,
            }}
          >
            {button.childs ? (
              <ButtonGroup variant={variant} aria-label="split button">
                <Tooltip title={button.title} arrow>
                  <LoadingButton
                    size="small"
                    color={color}
                    onClick={button.onClick}
                    className={button.title ? "" : "no-text"}
                    loading={loading}
                    loadingPosition="start"
                    disabled={isDisabled}
                    startIcon={button.icon}
                    variant={variant}
                    sx={{
                      height: `${height}px`,
                      textTransform: textFormat,
                      backgroundColor: variant === "contained" ? "" : "white",
                      width: "100%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span style={{ display: "flex", alignSelf: "center" }}>{button.title}</span>
                  </LoadingButton>
                </Tooltip>
                {button.childs && (
                  <MuiButton
                    ref={anchorRef}
                    size="small"
                    aria-controls={openButtonName === button.name ? "split-button-menu" : undefined}
                    aria-expanded={openButtonName === button.name ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => handleToggle(button.name)}
                    sx={{ width: "30px", height: height, backgroundColor: "white", minWidth: "40px" }}
                    disabled={loading}
                  >
                    <ArrowDropDownIcon />
                  </MuiButton>
                )}
              </ButtonGroup>
            ) : (
              <ButtonGroup variant={variant} aria-label="split button">
                <LoadingButton
                  size="small"
                  color={color}
                  className={button.title ? "" : "no-text"}
                  onClick={button.onClick}
                  loading={loading}
                  loadingPosition="start"
                  disabled={button?.isDisabled || isDisabled}
                  startIcon={button.icon}
                  variant={variant}
                  sx={{
                    height: `${height}px`,
                    textTransform: textFormat,
                    backgroundColor: variant === "contained" ? "" : "white",
                    width: "100%",
                    gap: "6px",
                    whiteSpace: "nowrap",
                    "& .MuiButton-startIcon": {
                      marginRight: "0px",
                      marginLeft: "0px",
                    },
                    borderRadius: button?.noBorderRadius && "unset !important",
                    borderTopRightRadius: button?.noBorderRadiusRight && "unset !important",
                    borderBottomRightRadius: button?.noBorderRadiusRight && "unset !important",
                    borderTopLeftRadius: button?.noBorderRadiusLeft && "unset !important",
                    borderBottomLeftRadius: button?.noBorderRadiusLeft && "unset !important",
                    cursor: button?.not_active ? "not-allowed !important" : button?.noCursorPointer && "default",
                  }}
                >
                  <span
                    style={{
                      display: button.title ? "flex" : "none",
                      alignSelf: "center",
                    }}
                  >
                    {button.title}
                  </span>
                </LoadingButton>
              </ButtonGroup>
            )}

            {button.childs && openButtonName === button.name && (
              <Popper
                sx={{ zIndex: 11, width: menuWidth, minWidth: "138px" }}
                open={openButtonName === button.name}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          id="split-button-menu"
                          autoFocusItem
                          sx={{
                            padding: "0",
                          }}
                        >
                          {button.childs.map((child) => {
                            if (!hasPermission(child.permissionKey, child.permissionValue)) {
                              return null;
                            }
                            return (
                              <MenuItem
                                key={child.name}
                                onClick={child.onClick}
                                disabled={loading}
                                sx={{
                                  color: child.name === "delete" ? "rgba(255, 0, 0, 0.7);" : palette?.primary?.main,
                                  fontSize: "14px",
                                  padding: "auto 50px",
                                }}
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  sx={{
                                    color: child.name === "delete" ? "rgba(255, 0, 0, 0.7);" : palette?.primary?.main,
                                    fontSize: "14px",
                                    marginRight: 1,
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    component="span"
                                    sx={{
                                      color: child.name === "delete" ? "rgba(255, 0, 0, 0.7);" : palette?.primary?.main,
                                      fontSize: "14px",
                                      marginRight: 1,
                                    }}
                                  >
                                    {child.icon}
                                  </Box>
                                  {child.title}
                                </Box>
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )}
          </div>
        );
      })}
    </>
  );
}
