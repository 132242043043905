import React from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { Label } from "../Inputs";
import { t } from "i18next";
import { useTheme } from "@mui/material/styles";

const RegionsSelect = ({ onChange, value, label, name, errors = false, required, viewEdit = false, placeholder }) => {
  const regionOptions = useSelector((state) => state?.settings?.data?.regions);

  const { palette } = useTheme();

  const defaultPlaceholder = placeholder || t("caymland.core.select");

  const loadOptions = (inputValue, callback) => {
    if (!regionOptions || !Array.isArray(regionOptions)) {
      return;
    }

    const filteredGroups = regionOptions
      .map((group) => ({
        ...group,
        options: group.options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase())),
      }))
      .filter((group) => group.options.length > 0);

    setTimeout(() => {
      callback(filteredGroups);
    }, 1000);
  };

  // Find the selected option
  const selectedOption = regionOptions?.flatMap((group) => group.options)?.find((option) => option.value === value);
  return (
    <div className="">
      {" "}
      {label && label?.length && <Label name={name} label={label} required={required} />}
      <AsyncSelect
        loadOptions={loadOptions}
        defaultOptions
        isClearable
        placeholder={defaultPlaceholder}
        value={selectedOption ? { label: selectedOption.label, value: selectedOption.value } : null}
        onChange={(option) => onChange(option ? option.value : "")}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: palette?.primary?.main,
            primary: palette?.primary?.main,
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            outline: "none !important",
            height: viewEdit ? "34px" : "40px",
            minHeight: viewEdit ? "34px" : "40px",
            fontSize: "15px",
            backgroundColor: viewEdit ? "inherit" : "white",
            border: viewEdit ? "0" : "",
            marginBottom: viewEdit ? "3px" : "0",
            boxShadow: viewEdit ? "none" : "",
            borderColor: errors ? "#d32f2f" : base.borderColor,
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: viewEdit ? "none" : "scale(1.003)",
              boxShadow: viewEdit ? "none" : "0 6px 20px rgba(0, 0, 0, 0.1)",
              borderColor: errors ? "#d32f2f" : base["&:hover"].borderColor,
            },
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? palette?.primary?.lightPrimaryColor : "white",
            color: state.isSelected ? "white" : "#333",
            padding: "10px 2px",
            fontSize: "15px",
            transition: "background-color 0.2s ease",
            paddingLeft: "20px",
            "&:active": {
              color: "white",
              backgroundColor: palette?.primary?.main,
            },
            "&:hover": {
              color: "white",
              backgroundColor: palette?.primary?.main,
            },
            "&:focus": {
              color: "white",
            },
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            color: palette?.text.primary,
            ":hover": {
              backgroundColor: palette?.primary?.main,
              color: "white",
            },
          }),
          multiValue: (styles) => ({
            ...styles,
            fontSize: "15px",
            backgroundColor: " #e7e7e7",
            borderRadius: "5px",
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            fontSize: "15px",
            color: palette?.text.primary,
          }),
          placeholder: (styles) => ({
            ...styles,
            color: "#6C757D",
            fontSize: viewEdit ? "13px" : "15px",
          }),
          group: (base, state) => ({
            ...base,
            // backgroundColor: "#f6f6f6",
            padding: "10px 2px",
          }),

          groupHeading: (base, state) => ({
            ...base,
            fontWeight: "bold",
            paddingLeft: "5px",
            color: "#333",
          }),
          menu: (provided) => ({ ...provided, zIndex: 99999999 }),
        }}
      />
    </div>
  );
};

export default RegionsSelect;
