import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import Loading from "../../reusableComponents/loading";
import "react-dropzone-uploader/dist/styles.css";
import { FieldsRenderSection } from "../contacts/FieldsRenderer";

function TomasTab({ requiredFieldsText, contactFields, control, errors, watch }) {
  const [filteredFields, setFilteredFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (contactFields) {
      const sortedAndFilteredFields = [...contactFields]
        .sort((a, b) => a.order - b.order)
        .filter((x) => x.isPublished === true && x.group === "zusatzinformationen" && x.isVisible === true);
      setFilteredFields(sortedAndFilteredFields);
    }
  }, [contactFields]);

  return (
    <Form>
      <div>
        {loading ? (
          <div style={{ display: "grid", placeItems: "center" }}>
            <Loading />
          </div>
        ) : (
          <div className="gap-10" style={{ display: "grid", padding: "10px" }}>
            <FieldsRenderSection
              errors={errors}
              filteredFields={filteredFields}
              control={control}
              isW50={false}
              watch={watch}
            />
          </div>
        )}
      </div>
    </Form>
  );
}

export default TomasTab;
