import React, { useState, useEffect } from "react";
import { Checkbox, Select, DatePicker, TimePicker } from "../../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../../reusableComponents/retriveCategoriesByBundle";
import {
  categoriesApi,
  useAddCategoryMutation,
  useGetCategoriesOptionsQuery,
} from "../../../../../redux/api/categoriesApi";
import { t } from "i18next";
import { CategoryModalData } from "../../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import ModalGenerator from "../../../../reusableComponents/ModalGenerator/ModalGenerator";

function Sidebar({ modifiedData, setModifiedData }) {
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "point",
  });
  const [newItem, setNewItem] = useState();

  const [addCategory] = useAddCategoryMutation();

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const retrievedCategories = await retrieveCategories(null, "point");
      } catch (error) {}
    };
    fetchData();
  }, []);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === "create_new") {
      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
      }));
      return;
    }
    setModifiedData({ ...modifiedData, category: selectedOption });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setModifiedData({
      ...modifiedData,
      [name]: checked,
    });
  };

  return (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Select
        name="category"
        label={t("caymland.lead.lead.category")}
        options={retrieveCategories}
        trigger={triggerCategories}
        bundle="point"
        value={modifiedData?.category ?? null}
        onChange={handleSelectChange}
        isCreatable
        isClearable
      />
      <div className="tw-mb-[15px]">
        <Checkbox
          name="isPublished"
          value={modifiedData?.isPublished}
          onChange={(e) => handleToggleChange(e)}
          label={t("caymland.core.form.published")}
        />
      </div>
      <DatePicker
        label={t("caymland.core.form.publishup")}
        name={"Publish at (date/time)"}
        value={modifiedData?.publishUp ? new Date(modifiedData?.publishUp) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishUp: e })}
        maxDate={modifiedData?.publishDown}
      />
      <DatePicker
        label={t("caymland.core.form.publishdown")}
        name={"Unpublish at (date/time)"}
        value={modifiedData?.publishDown ? new Date(modifiedData?.publishDown) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishDown: e })}
        minDate={modifiedData?.publishUp}
      />
      {modifiedData?.execution && (
        <>
          <Select
            name="IntervalExecution"
            label={t("caymland.core.form.interval")}
            //   options={intervals}
            value={modifiedData?.category ?? null}
            onChange={(e) => setModifiedData({ ...modifiedData, intervalExecution: e })}
          />
          <TimePicker
            label={t("caymland.core.form.time.execute")}
            value={modifiedData?.timeExecution}
            onChange={(e) => setModifiedData({ ...modifiedData, timeExecution: e })}
          />
        </>
      )}
    </>
  );
}

export default Sidebar;
