import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const contractsApi = createApi({
  reducerPath: "contractsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Contracts"],
  keepUnusedDataFor: 5,
  endpoints: (build) => ({
    getContracts: build.query({
      query: (q) => {
        return `/api/contracts?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`;
      },
      transformResponse: customTransformResponse,
      keepUnusedDataFor: false,
    }),

    getContractById: build.query({
      query: (id) => `/api/contracts/${id}`,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: (result, error, { objectId }) => [{ type: "Contracts", id: objectId }],
    }),

    deleteContract: build.mutation({
      query: (id) => ({
        url: `/api/contracts/${id}/delete`,
        method: "DELETE",
      }),
    }),
    updateContract: build.mutation({
      query: ({ body, id }) => ({
        url: `/api/contracts/${id}/edit`,
        method: "PUT",
        body: body,
      }),
    }),
    createContract: build.mutation({
      query: (body) => {
        return {
          url: `/api/contracts/new`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Contracts" }],
    }),
    addDocumentNote: build.mutation({
      query: (body) => ({
        url: `/api/contracts/notes/document/new`,
        method: "POST",
        body,
      }),
      responseHandler: (response) => response.text(),
    }),
    //CURENCIES
    getCurrencies: build.query({
      query: (q) => {
        return `/api/contracts/currencies?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`;
      },
      transformResponse: customTransformResponse,
      keepUnusedDataFor: 120,
    }),
    getCurrency: build.query({
      query: (id) => `/api/contracts/currencies/${id}`,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
    }),
    createCurrency: build.mutation({
      query: (body) => {
        return {
          url: `/api/contracts/currencies/new`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Contracts" }],
    }),
    updateCurrency: build.mutation({
      query: ({ id, currency }) => ({
        url: `/api/contracts/currencies/${id}/edit`,
        method: "PUT",
        body: currency,
      }),
      invalidatesTags: ["Contracts"],
    }),
    deleteCurrency: build.mutation({
      query: (id) => ({
        url: `/api/contracts/currencies/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Contracts", id }],
    }),
    //TYPES
    getTypes: build.query({
      query: (q) => {
        return `/api/contracts/types?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`;
      },
      transformResponse: customTransformResponse,
      keepUnusedDataFor: 120,
    }),
    getType: build.query({
      query: (id) => `/api/contracts/types/${id}`,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
    }),
    createType: build.mutation({
      query: (body) => {
        return {
          url: `/api/contracts/types/new`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Contracts" }],
    }),
    updateType: build.mutation({
      query: ({ id, type }) => ({
        url: `/api/contracts/types/${id}/edit`,
        method: "PUT",
        body: type,
      }),
      invalidatesTags: ["Contracts"],
    }),
    deleteType: build.mutation({
      query: (id) => ({
        url: `/api/contracts/types/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Contracts", id }],
    }),
    //CHECKLISTS
    getChecklists: build.query({
      query: (q) => {
        return `/api/contracts/checklists?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`;
      },
      transformResponse: customTransformResponse,
      keepUnusedDataFor: 120,
    }),
    getChecklist: build.query({
      query: (id) => `/api/contracts/checklists/${id}`,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
    }),
    createChecklist: build.mutation({
      query: (body) => {
        return {
          url: `/api/contracts/checklists/new`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Contracts" }],
    }),
    updateChecklist: build.mutation({
      query: ({ id, checklist }) => ({
        url: `/api/contracts/checklists/${id}/edit`,
        method: "PUT",
        body: checklist,
      }),
      invalidatesTags: ["Contracts"],
    }),
    deleteChecklist: build.mutation({
      query: (id) => ({
        url: `/api/contracts/checklists/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Contracts", id }],
    }),
    //CONTRACT OPTIONS
    getContractOptions: build.query({
      query: ({ type }) => `/api/contracts/${type}/select`,
      keepUnusedDataFor: 120,
      providesTags: ["Contracts"],
    }),
    //NOTE TAGS
    getContractNotesTags: build.query({
      query: (q) => {
        return `/api/contracts/notes/tags/select`;
      },
      keepUnusedDataFor: false,
    }),
    getContractNotesActions: build.query({
      query: (q) => {
        return `/api/contracts/notes/actions/select`;
      },
      keepUnusedDataFor: false,
    }),
    addNoteTags: build.mutation({
      query: (body) => ({
        url: `/api/contracts/notes/tags/new`,
        method: "POST",
        body,
      }),
    }),
    addNoteAction: build.mutation({
      query: (body) => ({
        url: `/api/contracts/notes/actions/new`,
        method: "POST",
        body,
      }),
    }),
    downloadContractNoteDocument: build.mutation({
      query: (filename) => ({
        url: `/api/contracts/notes/documents/download/${filename}`,
        method: "POST",
        responseHandler: (response) => response.blob(), // Use response.blob() to handle binary data
      }),
    }),
  }),
});

export const {
  useGetContractsQuery,
  useGetContractByIdQuery,
  useDeleteContractMutation,
  useCreateContractMutation,
  useUpdateContractMutation,
  useAddDocumentNoteMutation,
  //CURRENCY
  useGetCurrenciesQuery,
  useCreateCurrencyMutation,
  useUpdateCurrencyMutation,
  useGetCurrencyQuery,
  useDeleteCurrencyMutation,
  //TYPES
  useGetTypesQuery,
  useGetTypeQuery,
  useCreateTypeMutation,
  useUpdateTypeMutation,
  useDeleteTypeMutation,
  //CHECKLISTS
  useGetChecklistsQuery,
  useGetChecklistQuery,
  useCreateChecklistMutation,
  useUpdateChecklistMutation,
  useDeleteChecklistMutation,
  //CONTRACT OPTIONS
  useGetContractOptionsQuery,
  //NOTE TAGS
  useGetContractNotesTagsQuery,
  useAddNoteTagsMutation,
  useGetContractNotesActionsQuery,
  useAddNoteActionMutation,
  useDownloadContractNoteDocumentMutation,
} = contractsApi;
