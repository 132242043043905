import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import moment from "moment";

export const campaignsApi = createApi({
  reducerPath: "campaignsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Campaigns"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    //CAMPAIGNS
    getCampaigns: build.query({
      query: (q) =>
        `/api/campaigns?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Campaigns"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getCampaignsOptions: build.query({
      query: (q) => `/api/campaigns/select?limit=200`,
      keepUnusedDataFor: false,
      providesTags: ["Campaigns"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    updateCampaigns: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/campaigns/${id}/edit`,
        method: "PUT",
        body: patch,
      }),
    }),
    deleteCampaign: build.mutation({
      query: (id) => ({
        url: `/api/campaigns/${id}/delete`,
        method: "DELETE",
      }),
    }),
    addNewCampaign: build.mutation({
      query: (body) => ({
        url: `/api/campaigns/new`,
        method: "POST",
        body,
      }),

      invalidatesTags: [{ type: "Campaigns", id: "LIST" }],
      validateStatus: (response, result) => response.status === 200 && !result.isError,
      responseHandler: (response) => response.text(),
    }),
    //Get contacts in campaigns
    getCampaignsContacts: build.query({
      query: ({ query, id }) =>
        `/api/campaigns/${id}/contacts?${qs.stringify({
          ...query,
          ...{ page: query.pageIndex + 1, limit: query.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Camapigns"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    //GET CAMPAINS BY ID
    getAddEditCamapigns: build.query({
      queryFn: async ({ id, ...body }, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        if (id) {
          let campaignById = await baseQuery({ url: `/api/campaigns/${id}` });
          let eventTypes = await baseQuery({
            url: `/api/campaigns/eventTypes`,
          });

          const campaign = campaignById.data;
          const eventType = eventTypes.data;

          const data = {
            campaign,
            eventType,
          };

          return { data: data };
        } else {
          let eventTypes = await baseQuery({
            url: `/api/campaigns/eventTypes`,
          });
          const eventType = eventTypes.data;

          const data = {
            eventType,
          };

          return { data: data };
        }
      },
      providesTags: (result, error, id) => [{ type: "Campaigns", id }],
    }),
    //FOLDERS
    //GET FOLDERS
    getFolderCampaigns: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=campaigns`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderCampaigns: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderCampaigns: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Campaigns", id }],
    }),
    //DELETE FOLDER
    deleteFolderCampaigns: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          campaignsApi.util.updateQueryData("getFolderCampaigns", undefined, (draft) => {
            draft.data = draft.data.filter((campaign) => campaign.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Campaigns", id }],
    }),
    //MOVE CAMPAIGN TO FOLDER
    updateCampaignsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/campaigns/${itemId}`,
        method: "PATCH",
      }),
    }),
    //REMOVE CAMPAIGN TO FOLDER
    removeCampaignsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/campaigns/${itemId}`,
        method: "DELETE",
      }),
    }),
    //Get Segments for Select
    getSelectSegments: build.query({
      query: (type) => `/api/segments?where[0][expr]=eq&where[0][col]=isPublished&where[0][val]=1`,
      providesTags: ["Campaigns"],
    }),
    getRunCampaigns: build.mutation({
      query: (id) => ({
        url: `api/campaigns/${id}/trigger`,
        method: "POST",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Campaigns", id }],
    }),
    getEventTypes: build.query({
      query: (type) => `/api/campaigns/eventTypes`,
      providesTags: ["Campaigns"],
    }),
    getCampaign: build.query({
      query: (id) => `/api/campaigns/${id}`,
      providesTags: (id) => [{ type: "Campaigns", id }],
      keepUnusedDataFor: false,
    }),
    campaignFields: build.query({
      query: (q) => `/api/campaigns/events/new?${qs.stringify(q)}`,
      providesTags: ["Campaigns"],
    }),
    getViewApi: build.query({
      queryFn: async (id, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        if (id) {
          let _campaign = await baseQuery({ url: `/api/campaigns/${id}` });
          let _recent = await baseQuery({ url: `/api/campaigns/${id}/recent`, method: "POST" });
          let _events = await baseQuery({ url: `/api/campaigns/${id}/events` });

          const campaign = _campaign?.data;
          const recent = _recent?.data;
          const events = _events?.data;

          const data = {
            campaign,
            recent,
            events,
          };

          return { data: data };
        }
      },
      providesTags: (result, error, id) => [{ type: "Campaigns", id }],
    }),
    getCampaignStats: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/campaigns/${id}/statistics`,
        method: "POST",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Campaigns", id }],
    }),
  }),
});

export const {
  useGetCampaignsQuery,
  useUpdateCampaignsMutation,
  useDeleteCampaignMutation,
  useGetCampaignQuery,
  useAddNewCampaignMutation,
  useCampaignFieldsQuery,
  useGetViewApiQuery,
  useGetCampaignStatsMutation,
  useGetCampaignsOptionsQuery,
  //GET SELECT SEGMENTS
  useGetSelectSegmentsQuery,
  //EDIT
  useGetCampaignsByIdQuery,
  useGetAddEditCamapignsQuery,
  //FOLDERS
  useGetFolderCampaignsQuery,
  useUpdateFolderCampaignsMutation,
  useDeleteFolderCampaignsMutation,
  useAddFolderCampaignsMutation,
  useUpdateCampaignsToFoldersMutation,
  useRemoveCampaignsToFoldersMutation,
  //RUN
  useGetRunCampaignsMutation,
  useGetCampaignsContactsQuery,
  useGetEventTypesQuery,
} = campaignsApi;
