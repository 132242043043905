import { t } from "i18next";

import { useTranslation } from "react-i18next";

export const languages = [
  { labelKey: "caymland.core.english", value: "en" },
  { labelKey: "caymland.core.german", value: "de" },
  { labelKey: "caymland.core.french", value: "fr" },
  { labelKey: "caymland.core.italian", value: "it" },
  { labelKey: "caymland.core.spanish", value: "es" },
  { labelKey: "caymland.core.russian", value: "ru" },
];

export const objectData = [
  { label: "caymland.lead.contact", value: "lead" },
  { label: "caymland.core.company", value: "company" },
  { label: "caymland.lead.extendedField", value: "extendedField" },
  { label: "caymland.lead.extendedFieldSecure", value: "extendedFieldSecure" },
];

export const groupData = [
  { label: "Core", value: "core" },
  { label: "Social", value: "social" },
  { label: "Personal", value: "personal" },
  { label: "Professional", value: "professional" },
  { label: "Mitarbeiter", value: "mitarbeiter" },
  { label: "Buchhaltung", value: "buchhaltung" },
];

export const roundingMode = [
  { label: "Round Up", value: 1 },
  { label: "Round Down", value: 2 },
  { label: "Round Half Up", value: 3 },
  { label: "Round Half Even", value: 4 },
  { label: "Round Half Down", value: 5 },
];

export const dataTypeData = [
  { label: "caymland.core.type.boolean", value: "boolean" },
  { label: "caymland.core.type.date", value: "date" },
  { label: "caymland.core.type.datetime", value: "datetime" },
  { label: "caymland.core.type.email", value: "email" },
  { label: "caymland.core.type.country", value: "country" },
  { label: "caymland.lead.field.type.locale", value: "locale" },
  { label: "caymland.lead.field.type.lookup", value: "lookup" },
  { label: "caymland.core.type.number", value: "number" },
  { label: "caymland.core.type.tel", value: "phone" },
  { label: "caymland.lead.field.type.region", value: "region" },
  { label: "caymland.core.type.select", value: "select" },
  { label: "caymland.lead.field.type.multiselect", value: "multiselect" },
  { label: "caymland.core.type.text", value: "text" },
  { label: "caymland.lead.field.type.textarea", value: "textarea" },
  { label: "caymland.lead.field.type.time", value: "time" },
  { label: "caymland.lead.field.type.timezone", value: "timezone" },
  { label: "URL", value: "url" },
];
export const booleanData = [
  { label: "Nein", value: 0 },
  { label: "Ja", value: 1 },
];

export const countryData = [
  { label: "Afghanistan", value: "AF" },
  { label: "land Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "AndorrA", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: 'Cote D"Ivoire', value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic Of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: 'Korea, Democratic People"S Republic of', value: "KP" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: 'Lao People"S Democratic Republic', value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory, Occupied", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "RWANDA", value: "RW" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Surilabel", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];
export const statesData = [
  {
    country: "United States",
    states: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
      "District of Columbia",
      "American Samoa",
      "Guam",
      "Northern Mariana Islands",
      "Puerto Rico",
      "Virgin Islands",
      "United States Minor Outlying Islands",
      "Armed Forces Europe",
      "Armed Forces Americas",
      "Armed Forces Pacific",
    ],
  },
  {
    country: "Liberia",
    states: [
      "Maryland",
      "Bomi",
      "Bong",
      "Grand Basaa",
      "Grand Cape Mount",
      "Grand Gedeh",
      "Grand Kru",
      "Lofa",
      "Margibi",
      "Montserrado",
      "Nimba",
      "Rivercess",
      "Sinoe",
    ],
  },
];

export const localeData = [
  { value: "af", label: "Afrikaans" },
  { value: "af_NA", label: "Afrikaans (Namibia)" },
  { value: "af_ZA", label: "Afrikaans (S\u00fcdafrika)" },
  { value: "ak", label: "Akan" },
  { value: "ak_GH", label: "Akan (Ghana)" },
  { value: "am", label: "Amharisch" },
  { value: "am_ET", label: "Amharisch (\u00c4thiopien)" },
  { value: "ar", label: "Arabisch" },
  { value: "ar_AE", label: "Arabisch (Vereinigte Arabische Emirate)" },
  { value: "ar_BH", label: "Arabisch (Bahrain)" },
  { value: "ar_DJ", label: "Arabisch (Dschibuti)" },
  { value: "ar_DZ", label: "Arabisch (Algerien)" },
  { value: "ar_EG", label: "Arabisch (\u00c4gypten)" },
  { value: "ar_EH", label: "Arabisch (Westsahara)" },
  { value: "ar_ER", label: "Arabisch (Eritrea)" },
  { value: "ar_IL", label: "Arabisch (Israel)" },
  { value: "ar_IQ", label: "Arabisch (Irak)" },
  { value: "ar_JO", label: "Arabisch (Jordanien)" },
  { value: "ar_KM", label: "Arabisch (Komoren)" },
  { value: "ar_KW", label: "Arabisch (Kuwait)" },
  { value: "ar_LB", label: "Arabisch (Libanon)" },
  { value: "ar_LY", label: "Arabisch (Libyen)" },
  { value: "ar_MA", label: "Arabisch (Marokko)" },
  { value: "ar_MR", label: "Arabisch (Mauretanien)" },
  { value: "ar_OM", label: "Arabisch (Oman)" },
  {
    value: "ar_PS",
    label: "Arabisch (Pal\u00e4stinensische Autonomiegebiete)",
  },
  { value: "ar_QA", label: "Arabisch (Katar)" },
  { value: "ar_SA", label: "Arabisch (Saudi-Arabien)" },
  { value: "ar_SD", label: "Arabisch (Sudan)" },
  { value: "ar_SO", label: "Arabisch (Somalia)" },
  { value: "ar_SS", label: "Arabisch (S\u00fcdsudan)" },
  { value: "ar_SY", label: "Arabisch (Syrien)" },
  { value: "ar_TD", label: "Arabisch (Tschad)" },
  { value: "ar_TN", label: "Arabisch (Tunesien)" },
  { value: "ar_YE", label: "Arabisch (Jemen)" },
  { value: "as", label: "Assamesisch" },
  { value: "as_IN", label: "Assamesisch (Indien)" },
  { value: "az", label: "Aserbaidschanisch" },
  { value: "az_AZ", label: "Aserbaidschanisch (Aserbaidschan)" },
  { value: "az_Cyrl", label: "Aserbaidschanisch (Kyrillisch)" },
  {
    value: "az_Cyrl_AZ",
    label: "Aserbaidschanisch (Kyrillisch, Aserbaidschan)",
  },
  { value: "az_Latn", label: "Aserbaidschanisch (Lateinisch)" },
  {
    value: "az_Latn_AZ",
    label: "Aserbaidschanisch (Lateinisch, Aserbaidschan)",
  },
  { value: "be", label: "Wei\u00dfrussisch" },
  { value: "be_BY", label: "Wei\u00dfrussisch (Belarus)" },
  { value: "bg", label: "Bulgarisch" },
  { value: "bg_BG", label: "Bulgarisch (Bulgarien)" },
  { value: "bm", label: "Bambara" },
  { value: "bm_ML", label: "Bambara (Mali)" },
  { value: "bn", label: "Bengalisch" },
  { value: "bn_BD", label: "Bengalisch (Bangladesch)" },
  { value: "bn_IN", label: "Bengalisch (Indien)" },
  { value: "bo", label: "Tibetisch" },
  { value: "bo_CN", label: "Tibetisch (China)" },
  { value: "bo_IN", label: "Tibetisch (Indien)" },
  { value: "br", label: "Bretonisch" },
  { value: "br_FR", label: "Bretonisch (Frankreich)" },
  { value: "bs", label: "Bosnisch" },
  { value: "bs_BA", label: "Bosnisch (Bosnien und Herzegowina)" },
  { value: "bs_Cyrl", label: "Bosnisch (Kyrillisch)" },
  {
    value: "bs_Cyrl_BA",
    label: "Bosnisch (Kyrillisch, Bosnien und Herzegowina)",
  },
  { value: "bs_Latn", label: "Bosnisch (Lateinisch)" },
  {
    value: "bs_Latn_BA",
    label: "Bosnisch (Lateinisch, Bosnien und Herzegowina)",
  },
  { value: "ca", label: "Katalanisch" },
  { value: "ca_AD", label: "Katalanisch (Andorra)" },
  { value: "ca_ES", label: "Katalanisch (Spanien)" },
  { value: "ca_FR", label: "Katalanisch (Frankreich)" },
  { value: "ca_IT", label: "Katalanisch (Italien)" },
  { value: "ce", label: "Tschetschenisch" },
  { value: "ce_RU", label: "Tschetschenisch (Russland)" },
  { value: "cs", label: "Tschechisch" },
  { value: "cs_CZ", label: "Tschechisch (Tschechien)" },
  { value: "cy", label: "Walisisch" },
  { value: "cy_GB", label: "Walisisch (Vereinigtes K\u00f6nigreich)" },
  { value: "da", label: "D\u00e4nisch" },
  { value: "da_DK", label: "D\u00e4nisch (D\u00e4nemark)" },
  { value: "da_GL", label: "D\u00e4nisch (Gr\u00f6nland)" },
  { value: "de", label: "Deutsch" },
  { value: "de_AT", label: "Deutsch (\u00d6sterreich)" },
  { value: "de_BE", label: "Deutsch (Belgien)" },
  { value: "de_CH", label: "Deutsch (Schweiz)" },
  { value: "de_DE", label: "Deutsch (Deutschland)" },
  { value: "de_IT", label: "Deutsch (Italien)" },
  { value: "de_LI", label: "Deutsch (Liechtenstein)" },
  { value: "de_LU", label: "Deutsch (Luxemburg)" },
  { value: "dz", label: "Dzongkha" },
  { value: "dz_BT", label: "Dzongkha (Bhutan)" },
  { value: "ee", label: "Ewe" },
  { value: "ee_GH", label: "Ewe (Ghana)" },
  { value: "ee_TG", label: "Ewe (Togo)" },
  { value: "el", label: "Griechisch" },
  { value: "el_CY", label: "Griechisch (Zypern)" },
  { value: "el_GR", label: "Griechisch (Griechenland)" },
  { value: "en", label: "Englisch" },
  { value: "en_AG", label: "Englisch (Antigua und Barbuda)" },
  { value: "en_AI", label: "Englisch (Anguilla)" },
  { value: "en_AS", label: "Englisch (Amerikanisch-Samoa)" },
  { value: "en_AT", label: "Englisch (\u00d6sterreich)" },
  { value: "en_AU", label: "Englisch (Australien)" },
  { value: "en_BB", label: "Englisch (Barbados)" },
  { value: "en_BE", label: "Englisch (Belgien)" },
  { value: "en_BI", label: "Englisch (Burundi)" },
  { value: "en_BM", label: "Englisch (Bermuda)" },
  { value: "en_BS", label: "Englisch (Bahamas)" },
  { value: "en_BW", label: "Englisch (Botsuana)" },
  { value: "en_BZ", label: "Englisch (Belize)" },
  { value: "en_CA", label: "Englisch (Kanada)" },
  { value: "en_CC", label: "Englisch (Kokosinseln)" },
  { value: "en_CH", label: "Englisch (Schweiz)" },
  { value: "en_CK", label: "Englisch (Cookinseln)" },
  { value: "en_CM", label: "Englisch (Kamerun)" },
  { value: "en_CX", label: "Englisch (Weihnachtsinsel)" },
  { value: "en_CY", label: "Englisch (Zypern)" },
  { value: "en_DE", label: "Englisch (Deutschland)" },
  { value: "en_DG", label: "Englisch (Diego Garcia)" },
  { value: "en_DK", label: "Englisch (D\u00e4nemark)" },
  { value: "en_DM", label: "Englisch (Dominica)" },
  { value: "en_ER", label: "Englisch (Eritrea)" },
  { value: "en_FI", label: "Englisch (Finnland)" },
  { value: "en_FJ", label: "Englisch (Fidschi)" },
  { value: "en_FK", label: "Englisch (Falklandinseln)" },
  { value: "en_FM", label: "Englisch (Mikronesien)" },
  { value: "en_GB", label: "Englisch (Vereinigtes K\u00f6nigreich)" },
  { value: "en_GD", label: "Englisch (Grenada)" },
  { value: "en_GG", label: "Englisch (Guernsey)" },
  { value: "en_GH", label: "Englisch (Ghana)" },
  { value: "en_GI", label: "Englisch (Gibraltar)" },
  { value: "en_GM", label: "Englisch (Gambia)" },
  { value: "en_GU", label: "Englisch (Guam)" },
  { value: "en_GY", label: "Englisch (Guyana)" },
  { value: "en_HK", label: "Englisch (Sonderverwaltungsregion Hongkong)" },
  { value: "en_IE", label: "Englisch (Irland)" },
  { value: "en_IL", label: "Englisch (Israel)" },
  { value: "en_IM", label: "Englisch (Isle of Man)" },
  { value: "en_IN", label: "Englisch (Indien)" },
  {
    value: "en_IO",
    label: "Englisch (Britisches Territorium im Indischen Ozean)",
  },
  { value: "en_JE", label: "Englisch (Jersey)" },
  { value: "en_JM", label: "Englisch (Jamaika)" },
  { value: "en_KE", label: "Englisch (Kenia)" },
  { value: "en_KI", label: "Englisch (Kiribati)" },
  { value: "en_KN", label: "Englisch (St. Kitts und Nevis)" },
  { value: "en_KY", label: "Englisch (Kaimaninseln)" },
  { value: "en_LC", label: "Englisch (St. Lucia)" },
  { value: "en_LR", label: "Englisch (Liberia)" },
  { value: "en_LS", label: "Englisch (Lesotho)" },
  { value: "en_MG", label: "Englisch (Madagaskar)" },
  { value: "en_MH", label: "Englisch (Marshallinseln)" },
  { value: "en_MO", label: "Englisch (Sonderverwaltungsregion Macau)" },
  { value: "en_MP", label: "Englisch (N\u00f6rdliche Marianen)" },
  { value: "en_MS", label: "Englisch (Montserrat)" },
  { value: "en_MT", label: "Englisch (Malta)" },
  { value: "en_MU", label: "Englisch (Mauritius)" },
  { value: "en_MW", label: "Englisch (Malawi)" },
  { value: "en_MY", label: "Englisch (Malaysia)" },
  { value: "en_NA", label: "Englisch (Namibia)" },
  { value: "en_NF", label: "Englisch (Norfolkinsel)" },
  { value: "en_NG", label: "Englisch (Nigeria)" },
  { value: "en_NL", label: "Englisch (Niederlande)" },
  { value: "en_NR", label: "Englisch (Nauru)" },
  { value: "en_NU", label: "Englisch (Niue)" },
  { value: "en_NZ", label: "Englisch (Neuseeland)" },
  { value: "en_PG", label: "Englisch (Papua-Neuguinea)" },
  { value: "en_PH", label: "Englisch (Philippinen)" },
  { value: "en_PK", label: "Englisch (Pakistan)" },
  { value: "en_PN", label: "Englisch (Pitcairninseln)" },
  { value: "en_PR", label: "Englisch (Puerto Rico)" },
  { value: "en_PW", label: "Englisch (Palau)" },
  { value: "en_RW", label: "Englisch (Ruanda)" },
  { value: "en_SB", label: "Englisch (Salomonen)" },
  { value: "en_SC", label: "Englisch (Seychellen)" },
  { value: "en_SD", label: "Englisch (Sudan)" },
  { value: "en_SE", label: "Englisch (Schweden)" },
  { value: "en_SG", label: "Englisch (Singapur)" },
  { value: "en_SH", label: "Englisch (St. Helena)" },
  { value: "en_SI", label: "Englisch (Slowenien)" },
  { value: "en_SL", label: "Englisch (Sierra Leone)" },
  { value: "en_SS", label: "Englisch (S\u00fcdsudan)" },
  { value: "en_SX", label: "Englisch (Sint Maarten)" },
  { value: "en_SZ", label: "Englisch (Swasiland)" },
  { value: "en_TC", label: "Englisch (Turks- und Caicosinseln)" },
  { value: "en_TK", label: "Englisch (Tokelau)" },
  { value: "en_TO", label: "Englisch (Tonga)" },
  { value: "en_TT", label: "Englisch (Trinidad und Tobago)" },
  { value: "en_TV", label: "Englisch (Tuvalu)" },
  { value: "en_TZ", label: "Englisch (Tansania)" },
  { value: "en_UG", label: "Englisch (Uganda)" },
  { value: "en_UM", label: "Englisch (Amerikanische \u00dcberseeinseln)" },
  { value: "en_US", label: "Englisch (Vereinigte Staaten)" },
  { value: "en_VC", label: "Englisch (St. Vincent und die Grenadinen)" },
  { value: "en_VG", label: "Englisch (Britische Jungferninseln)" },
  { value: "en_VI", label: "Englisch (Amerikanische Jungferninseln)" },
  { value: "en_VU", label: "Englisch (Vanuatu)" },
  { value: "en_WS", label: "Englisch (Samoa)" },
  { value: "en_ZA", label: "Englisch (S\u00fcdafrika)" },
  { value: "en_ZM", label: "Englisch (Sambia)" },
  { value: "en_ZW", label: "Englisch (Simbabwe)" },
  { value: "eo", label: "Esperanto" },
  { value: "es", label: "Spanisch" },
  { value: "es_AR", label: "Spanisch (Argentinien)" },
  { value: "es_BO", label: "Spanisch (Bolivien)" },
  { value: "es_BR", label: "Spanisch (Brasilien)" },
  { value: "es_BZ", label: "Spanisch (Belize)" },
  { value: "es_CL", label: "Spanisch (Chile)" },
  { value: "es_CO", label: "Spanisch (Kolumbien)" },
  { value: "es_CR", label: "Spanisch (Costa Rica)" },
  { value: "es_CU", label: "Spanisch (Kuba)" },
  { value: "es_DO", label: "Spanisch (Dominikanische Republik)" },
  { value: "es_EA", label: "Spanisch (Ceuta und Melilla)" },
  { value: "es_EC", label: "Spanisch (Ecuador)" },
  { value: "es_ES", label: "Spanisch (Spanien)" },
  { value: "es_GQ", label: "Spanisch (\u00c4quatorialguinea)" },
  { value: "es_GT", label: "Spanisch (Guatemala)" },
  { value: "es_HN", label: "Spanisch (Honduras)" },
  { value: "es_IC", label: "Spanisch (Kanarische Inseln)" },
  { value: "es_MX", label: "Spanisch (Mexiko)" },
  { value: "es_NI", label: "Spanisch (Nicaragua)" },
  { value: "es_PA", label: "Spanisch (Panama)" },
  { value: "es_PE", label: "Spanisch (Peru)" },
  { value: "es_PH", label: "Spanisch (Philippinen)" },
  { value: "es_PR", label: "Spanisch (Puerto Rico)" },
  { value: "es_PY", label: "Spanisch (Paraguay)" },
  { value: "es_SV", label: "Spanisch (El Salvador)" },
  { value: "es_US", label: "Spanisch (Vereinigte Staaten)" },
  { value: "es_UY", label: "Spanisch (Uruguay)" },
  { value: "es_VE", label: "Spanisch (Venezuela)" },
  { value: "et", label: "Estnisch" },
  { value: "et_EE", label: "Estnisch (Estland)" },
  { value: "eu", label: "Baskisch" },
  { value: "eu_ES", label: "Baskisch (Spanien)" },
  { value: "fa", label: "Persisch" },
  { value: "fa_AF", label: "Persisch (Afghanistan)" },
  { value: "fa_IR", label: "Persisch (Iran)" },
  { value: "ff", label: "Ful" },
  { value: "ff_CM", label: "Ful (Kamerun)" },
  { value: "ff_GN", label: "Ful (Guinea)" },
  { value: "ff_MR", label: "Ful (Mauretanien)" },
  { value: "ff_SN", label: "Ful (Senegal)" },
  { value: "fi", label: "Finnisch" },
  { value: "fi_FI", label: "Finnisch (Finnland)" },
  { value: "fo", label: "F\u00e4r\u00f6isch" },
  { value: "fo_DK", label: "F\u00e4r\u00f6isch (D\u00e4nemark)" },
  { value: "fo_FO", label: "F\u00e4r\u00f6isch (F\u00e4r\u00f6er)" },
  { value: "fr", label: "Franz\u00f6sisch" },
  { value: "fr_BE", label: "Franz\u00f6sisch (Belgien)" },
  { value: "fr_BF", label: "Franz\u00f6sisch (Burkina Faso)" },
  { value: "fr_BI", label: "Franz\u00f6sisch (Burundi)" },
  { value: "fr_BJ", label: "Franz\u00f6sisch (Benin)" },
  { value: "fr_BL", label: "Franz\u00f6sisch (St. Barth\u00e9lemy)" },
  { value: "fr_CA", label: "Franz\u00f6sisch (Kanada)" },
  { value: "fr_CD", label: "Franz\u00f6sisch (Kongo-Kinshasa)" },
  {
    value: "fr_CF",
    label: "Franz\u00f6sisch (Zentralafrikanische Republik)",
  },
  { value: "fr_CG", label: "Franz\u00f6sisch (Kongo-Brazzaville)" },
  { value: "fr_CH", label: "Franz\u00f6sisch (Schweiz)" },
  { value: "fr_CI", label: "Franz\u00f6sisch (C\u00f4te d\u2019Ivoire)" },
  { value: "fr_CM", label: "Franz\u00f6sisch (Kamerun)" },
  { value: "fr_DJ", label: "Franz\u00f6sisch (Dschibuti)" },
  { value: "fr_DZ", label: "Franz\u00f6sisch (Algerien)" },
  { value: "fr_FR", label: "Franz\u00f6sisch (Frankreich)" },
  { value: "fr_GA", label: "Franz\u00f6sisch (Gabun)" },
  { value: "fr_GF", label: "Franz\u00f6sisch (Franz\u00f6sisch-Guayana)" },
  { value: "fr_GN", label: "Franz\u00f6sisch (Guinea)" },
  { value: "fr_GP", label: "Franz\u00f6sisch (Guadeloupe)" },
  { value: "fr_GQ", label: "Franz\u00f6sisch (\u00c4quatorialguinea)" },
  { value: "fr_HT", label: "Franz\u00f6sisch (Haiti)" },
  { value: "fr_KM", label: "Franz\u00f6sisch (Komoren)" },
  { value: "fr_LU", label: "Franz\u00f6sisch (Luxemburg)" },
  { value: "fr_MA", label: "Franz\u00f6sisch (Marokko)" },
  { value: "fr_MC", label: "Franz\u00f6sisch (Monaco)" },
  { value: "fr_MF", label: "Franz\u00f6sisch (St. Martin)" },
  { value: "fr_MG", label: "Franz\u00f6sisch (Madagaskar)" },
  { value: "fr_ML", label: "Franz\u00f6sisch (Mali)" },
  { value: "fr_MQ", label: "Franz\u00f6sisch (Martinique)" },
  { value: "fr_MR", label: "Franz\u00f6sisch (Mauretanien)" },
  { value: "fr_MU", label: "Franz\u00f6sisch (Mauritius)" },
  { value: "fr_NC", label: "Franz\u00f6sisch (Neukaledonien)" },
  { value: "fr_NE", label: "Franz\u00f6sisch (Niger)" },
  {
    value: "fr_PF",
    label: "Franz\u00f6sisch (Franz\u00f6sisch-Polynesien)",
  },
  { value: "fr_PM", label: "Franz\u00f6sisch (St. Pierre und Miquelon)" },
  { value: "fr_RE", label: "Franz\u00f6sisch (R\u00e9union)" },
  { value: "fr_RW", label: "Franz\u00f6sisch (Ruanda)" },
  { value: "fr_SC", label: "Franz\u00f6sisch (Seychellen)" },
  { value: "fr_SN", label: "Franz\u00f6sisch (Senegal)" },
  { value: "fr_SY", label: "Franz\u00f6sisch (Syrien)" },
  { value: "fr_TD", label: "Franz\u00f6sisch (Tschad)" },
  { value: "fr_TG", label: "Franz\u00f6sisch (Togo)" },
  { value: "fr_TN", label: "Franz\u00f6sisch (Tunesien)" },
  { value: "fr_VU", label: "Franz\u00f6sisch (Vanuatu)" },
  { value: "fr_WF", label: "Franz\u00f6sisch (Wallis und Futuna)" },
  { value: "fr_YT", label: "Franz\u00f6sisch (Mayotte)" },
  { value: "fy", label: "Westfriesisch" },
  { value: "fy_NL", label: "Westfriesisch (Niederlande)" },
  { value: "ga", label: "Irisch" },
  { value: "ga_IE", label: "Irisch (Irland)" },
  { value: "gd", label: "Schottisches G\u00e4lisch" },
  {
    value: "gd_GB",
    label: "Schottisches G\u00e4lisch (Vereinigtes K\u00f6nigreich)",
  },
  { value: "gl", label: "Galicisch" },
  { value: "gl_ES", label: "Galicisch (Spanien)" },
  { value: "gu", label: "Gujarati" },
  { value: "gu_IN", label: "Gujarati (Indien)" },
  { value: "gv", label: "Manx" },
  { value: "gv_IM", label: "Manx (Isle of Man)" },
  { value: "ha", label: "Haussa" },
  { value: "ha_GH", label: "Haussa (Ghana)" },
  { value: "ha_NE", label: "Haussa (Niger)" },
  { value: "ha_NG", label: "Haussa (Nigeria)" },
  { value: "he", label: "Hebr\u00e4isch" },
  { value: "he_IL", label: "Hebr\u00e4isch (Israel)" },
  { value: "hi", label: "Hindi" },
  { value: "hi_IN", label: "Hindi (Indien)" },
  { value: "hr", label: "Kroatisch" },
  { value: "hr_BA", label: "Kroatisch (Bosnien und Herzegowina)" },
  { value: "hr_HR", label: "Kroatisch (Kroatien)" },
  { value: "hu", label: "Ungarisch" },
  { value: "hu_HU", label: "Ungarisch (Ungarn)" },
  { value: "hy", label: "Armenisch" },
  { value: "hy_AM", label: "Armenisch (Armenien)" },
  { value: "id", label: "Indonesisch" },
  { value: "id_ID", label: "Indonesisch (Indonesien)" },
  { value: "ig", label: "Igbo" },
  { value: "ig_NG", label: "Igbo (Nigeria)" },
  { value: "ii", label: "Yi" },
  { value: "ii_CN", label: "Yi (China)" },
  { value: "is", label: "Isl\u00e4ndisch" },
  { value: "is_IS", label: "Isl\u00e4ndisch (Island)" },
  { value: "it", label: "Italienisch" },
  { value: "it_CH", label: "Italienisch (Schweiz)" },
  { value: "it_IT", label: "Italienisch (Italien)" },
  { value: "it_SM", label: "Italienisch (San Marino)" },
  { value: "it_VA", label: "Italienisch (Vatikanstadt)" },
  { value: "ja", label: "Japanisch" },
  { value: "ja_JP", label: "Japanisch (Japan)" },
  { value: "ka", label: "Georgisch" },
  { value: "ka_GE", label: "Georgisch (Georgien)" },
  { value: "ki", label: "Kikuyu" },
  { value: "ki_KE", label: "Kikuyu (Kenia)" },
  { value: "kk", label: "Kasachisch" },
  { value: "kk_KZ", label: "Kasachisch (Kasachstan)" },
  { value: "kl", label: "Gr\u00f6nl\u00e4ndisch" },
  { value: "kl_GL", label: "Gr\u00f6nl\u00e4ndisch (Gr\u00f6nland)" },
  { value: "km", label: "Khmer" },
  { value: "km_KH", label: "Khmer (Kambodscha)" },
  { value: "kn", label: "Kannada" },
  { value: "kn_IN", label: "Kannada (Indien)" },
  { value: "ko", label: "Koreanisch" },
  { value: "ko_KP", label: "Koreanisch (Nordkorea)" },
  { value: "ko_KR", label: "Koreanisch (S\u00fcdkorea)" },
  { value: "ks", label: "Kaschmiri" },
  { value: "ks_IN", label: "Kaschmiri (Indien)" },
  { value: "kw", label: "Kornisch" },
  { value: "kw_GB", label: "Kornisch (Vereinigtes K\u00f6nigreich)" },
  { value: "ky", label: "Kirgisisch" },
  { value: "ky_KG", label: "Kirgisisch (Kirgisistan)" },
  { value: "lb", label: "Luxemburgisch" },
  { value: "lb_LU", label: "Luxemburgisch (Luxemburg)" },
  { value: "lg", label: "Ganda" },
  { value: "lg_UG", label: "Ganda (Uganda)" },
  { value: "ln", label: "Lingala" },
  { value: "ln_AO", label: "Lingala (Angola)" },
  { value: "ln_CD", label: "Lingala (Kongo-Kinshasa)" },
  { value: "ln_CF", label: "Lingala (Zentralafrikanische Republik)" },
  { value: "ln_CG", label: "Lingala (Kongo-Brazzaville)" },
  { value: "lo", label: "Laotisch" },
  { value: "lo_LA", label: "Laotisch (Laos)" },
  { value: "lt", label: "Litauisch" },
  { value: "lt_LT", label: "Litauisch (Litauen)" },
  { value: "lu", label: "Luba-Katanga" },
  { value: "lu_CD", label: "Luba-Katanga (Kongo-Kinshasa)" },
  { value: "lv", label: "Lettisch" },
  { value: "lv_LV", label: "Lettisch (Lettland)" },
  { value: "mg", label: "Madagassisch" },
  { value: "mg_MG", label: "Madagassisch (Madagaskar)" },
  { value: "mk", label: "Mazedonisch" },
  { value: "mk_MK", label: "Mazedonisch (Mazedonien)" },
  { value: "ml", label: "Malayalam" },
  { value: "ml_IN", label: "Malayalam (Indien)" },
  { value: "mn", label: "Mongolisch" },
  { value: "mn_MN", label: "Mongolisch (Mongolei)" },
  { value: "mr", label: "Marathi" },
  { value: "mr_IN", label: "Marathi (Indien)" },
  { value: "ms", label: "Malaiisch" },
  { value: "ms_BN", label: "Malaiisch (Brunei Darussalam)" },
  { value: "ms_MY", label: "Malaiisch (Malaysia)" },
  { value: "ms_SG", label: "Malaiisch (Singapur)" },
  { value: "mt", label: "Maltesisch" },
  { value: "mt_MT", label: "Maltesisch (Malta)" },
  { value: "my", label: "Birmanisch" },
  { value: "my_MM", label: "Birmanisch (Myanmar)" },
  { value: "nb", label: "Norwegisch Bokm\u00e5l" },
  { value: "nb_NO", label: "Norwegisch Bokm\u00e5l (Norwegen)" },
  {
    value: "nb_SJ",
    label: "Norwegisch Bokm\u00e5l (Spitzbergen und Jan Mayen)",
  },
  { value: "nd", label: "Nord-Ndebele" },
  { value: "nd_ZW", label: "Nord-Ndebele (Simbabwe)" },
  { value: "ne", label: "Nepalesisch" },
  { value: "ne_IN", label: "Nepalesisch (Indien)" },
  { value: "ne_NP", label: "Nepalesisch (Nepal)" },
  { value: "nl", label: "Niederl\u00e4ndisch" },
  { value: "nl_AW", label: "Niederl\u00e4ndisch (Aruba)" },
  { value: "nl_BE", label: "Niederl\u00e4ndisch (Belgien)" },
  {
    value: "nl_BQ",
    label: "Niederl\u00e4ndisch (Bonaire, Sint Eustatius und Saba)",
  },
  { value: "nl_CW", label: "Niederl\u00e4ndisch (Cura\u00e7ao)" },
  { value: "nl_NL", label: "Niederl\u00e4ndisch (Niederlande)" },
  { value: "nl_SR", label: "Niederl\u00e4ndisch (Suriname)" },
  { value: "nl_SX", label: "Niederl\u00e4ndisch (Sint Maarten)" },
  { value: "nn", label: "Norwegisch Nynorsk" },
  { value: "nn_NO", label: "Norwegisch Nynorsk (Norwegen)" },
  { value: "no", label: "Norwegisch" },
  { value: "no_NO", label: "Norwegisch (Norwegen)" },
  { value: "om", label: "Oromo" },
  { value: "om_ET", label: "Oromo (\u00c4thiopien)" },
  { value: "om_KE", label: "Oromo (Kenia)" },
  { value: "or", label: "Oriya" },
  { value: "or_IN", label: "Oriya (Indien)" },
  { value: "os", label: "Ossetisch" },
  { value: "os_GE", label: "Ossetisch (Georgien)" },
  { value: "os_RU", label: "Ossetisch (Russland)" },
  { value: "pa", label: "Punjabi" },
  { value: "pa_Arab", label: "Punjabi (Arabisch)" },
  { value: "pa_Arab_PK", label: "Punjabi (Arabisch, Pakistan)" },
  { value: "pa_Guru", label: "Punjabi (Gurmukhi)" },
  { value: "pa_Guru_IN", label: "Punjabi (Gurmukhi, Indien)" },
  { value: "pa_IN", label: "Punjabi (Indien)" },
  { value: "pa_PK", label: "Punjabi (Pakistan)" },
  { value: "pl", label: "Polnisch" },
  { value: "pl_PL", label: "Polnisch (Polen)" },
  { value: "ps", label: "Paschtu" },
  { value: "ps_AF", label: "Paschtu (Afghanistan)" },
  { value: "pt", label: "Portugiesisch" },
  { value: "pt_AO", label: "Portugiesisch (Angola)" },
  { value: "pt_BR", label: "Portugiesisch (Brasilien)" },
  { value: "pt_CH", label: "Portugiesisch (Schweiz)" },
  { value: "pt_CV", label: "Portugiesisch (Cabo Verde)" },
  { value: "pt_GQ", label: "Portugiesisch (\u00c4quatorialguinea)" },
  { value: "pt_GW", label: "Portugiesisch (Guinea-Bissau)" },
  { value: "pt_LU", label: "Portugiesisch (Luxemburg)" },
  {
    value: "pt_MO",
    label: "Portugiesisch (Sonderverwaltungsregion Macau)",
  },
  { value: "pt_MZ", label: "Portugiesisch (Mosambik)" },
  { value: "pt_PT", label: "Portugiesisch (Portugal)" },
  {
    value: "pt_ST",
    label: "Portugiesisch (S\u00e3o Tom\u00e9 und Pr\u00edncipe)",
  },
  { value: "pt_TL", label: "Portugiesisch (Timor-Leste)" },
  { value: "qu", label: "Quechua" },
  { value: "qu_BO", label: "Quechua (Bolivien)" },
  { value: "qu_EC", label: "Quechua (Ecuador)" },
  { value: "qu_PE", label: "Quechua (Peru)" },
  { value: "rm", label: "R\u00e4toromanisch" },
  { value: "rm_CH", label: "R\u00e4toromanisch (Schweiz)" },
  { value: "rn", label: "Rundi" },
  { value: "rn_BI", label: "Rundi (Burundi)" },
  { value: "ro", label: "Rum\u00e4nisch" },
  { value: "ro_MD", label: "Rum\u00e4nisch (Republik Moldau)" },
  { value: "ro_RO", label: "Rum\u00e4nisch (Rum\u00e4nien)" },
  { value: "ru", label: "Russisch" },
  { value: "ru_BY", label: "Russisch (Belarus)" },
  { value: "ru_KG", label: "Russisch (Kirgisistan)" },
  { value: "ru_KZ", label: "Russisch (Kasachstan)" },
  { value: "ru_MD", label: "Russisch (Republik Moldau)" },
  { value: "ru_RU", label: "Russisch (Russland)" },
  { value: "ru_UA", label: "Russisch (Ukraine)" },
  { value: "rw", label: "Kinyarwanda" },
  { value: "rw_RW", label: "Kinyarwanda (Ruanda)" },
  { value: "se", label: "Nordsamisch" },
  { value: "se_FI", label: "Nordsamisch (Finnland)" },
  { value: "se_NO", label: "Nordsamisch (Norwegen)" },
  { value: "se_SE", label: "Nordsamisch (Schweden)" },
  { value: "sg", label: "Sango" },
  { value: "sg_CF", label: "Sango (Zentralafrikanische Republik)" },
  { value: "sh", label: "Serbo-Kroatisch" },
  { value: "sh_BA", label: "Serbo-Kroatisch (Bosnien und Herzegowina)" },
  { value: "si", label: "Singhalesisch" },
  { value: "si_LK", label: "Singhalesisch (Sri Lanka)" },
  { value: "sk", label: "Slowakisch" },
  { value: "sk_SK", label: "Slowakisch (Slowakei)" },
  { value: "sl", label: "Slowenisch" },
  { value: "sl_SI", label: "Slowenisch (Slowenien)" },
  { value: "sn", label: "Shona" },
  { value: "sn_ZW", label: "Shona (Simbabwe)" },
  { value: "so", label: "Somali" },
  { value: "so_DJ", label: "Somali (Dschibuti)" },
  { value: "so_ET", label: "Somali (\u00c4thiopien)" },
  { value: "so_KE", label: "Somali (Kenia)" },
  { value: "so_SO", label: "Somali (Somalia)" },
  { value: "sq", label: "Albanisch" },
  { value: "sq_AL", label: "Albanisch (Albanien)" },
  { value: "sq_MK", label: "Albanisch (Mazedonien)" },
  { value: "sq_XK", label: "Albanisch (Kosovo)" },
  { value: "sr", label: "Serbisch" },
  { value: "sr_BA", label: "Serbisch (Bosnien und Herzegowina)" },
  { value: "sr_Cyrl", label: "Serbisch (Kyrillisch)" },
  {
    value: "sr_Cyrl_BA",
    label: "Serbisch (Kyrillisch, Bosnien und Herzegowina)",
  },
  { value: "sr_Cyrl_ME", label: "Serbisch (Kyrillisch, Montenegro)" },
  { value: "sr_Cyrl_RS", label: "Serbisch (Kyrillisch, Serbien)" },
  { value: "sr_Cyrl_XK", label: "Serbisch (Kyrillisch, Kosovo)" },
  { value: "sr_Latn", label: "Serbisch (Lateinisch)" },
  {
    value: "sr_Latn_BA",
    label: "Serbisch (Lateinisch, Bosnien und Herzegowina)",
  },
  { value: "sr_Latn_ME", label: "Serbisch (Lateinisch, Montenegro)" },
  { value: "sr_Latn_RS", label: "Serbisch (Lateinisch, Serbien)" },
  { value: "sr_Latn_XK", label: "Serbisch (Lateinisch, Kosovo)" },
  { value: "sr_ME", label: "Serbisch (Montenegro)" },
  { value: "sr_RS", label: "Serbisch (Serbien)" },
  { value: "sr_XK", label: "Serbisch (Kosovo)" },
  { value: "sv", label: "Schwedisch" },
  { value: "sv_AX", label: "Schwedisch (\u00c5landinseln)" },
  { value: "sv_FI", label: "Schwedisch (Finnland)" },
  { value: "sv_SE", label: "Schwedisch (Schweden)" },
  { value: "sw", label: "Suaheli" },
  { value: "sw_CD", label: "Suaheli (Kongo-Kinshasa)" },
  { value: "sw_KE", label: "Suaheli (Kenia)" },
  { value: "sw_TZ", label: "Suaheli (Tansania)" },
  { value: "sw_UG", label: "Suaheli (Uganda)" },
  { value: "ta", label: "Tamil" },
  { value: "ta_IN", label: "Tamil (Indien)" },
  { value: "ta_LK", label: "Tamil (Sri Lanka)" },
  { value: "ta_MY", label: "Tamil (Malaysia)" },
  { value: "ta_SG", label: "Tamil (Singapur)" },
  { value: "te", label: "Telugu" },
  { value: "te_IN", label: "Telugu (Indien)" },
  { value: "tg", label: "Tadschikisch" },
  { value: "tg_TJ", label: "Tadschikisch (Tadschikistan)" },
  { value: "th", label: "Thail\u00e4ndisch" },
  { value: "th_TH", label: "Thail\u00e4ndisch (Thailand)" },
  { value: "ti", label: "Tigrinya" },
  { value: "ti_ER", label: "Tigrinya (Eritrea)" },
  { value: "ti_ET", label: "Tigrinya (\u00c4thiopien)" },
  { value: "tl", label: "Tagalog" },
  { value: "tl_PH", label: "Tagalog (Philippinen)" },
  { value: "to", label: "Tongaisch" },
  { value: "to_TO", label: "Tongaisch (Tonga)" },
  { value: "tr", label: "T\u00fcrkisch" },
  { value: "tr_CY", label: "T\u00fcrkisch (Zypern)" },
  { value: "tr_TR", label: "T\u00fcrkisch (T\u00fcrkei)" },
  { value: "tt", label: "Tatarisch" },
  { value: "tt_RU", label: "Tatarisch (Russland)" },
  { value: "ug", label: "Uigurisch" },
  { value: "ug_CN", label: "Uigurisch (China)" },
  { value: "uk", label: "Ukrainisch" },
  { value: "uk_UA", label: "Ukrainisch (Ukraine)" },
  { value: "ur", label: "Urdu" },
  { value: "ur_IN", label: "Urdu (Indien)" },
  { value: "ur_PK", label: "Urdu (Pakistan)" },
  { value: "uz", label: "Usbekisch" },
  { value: "uz_AF", label: "Usbekisch (Afghanistan)" },
  { value: "uz_Arab", label: "Usbekisch (Arabisch)" },
  { value: "uz_Arab_AF", label: "Usbekisch (Arabisch, Afghanistan)" },
  { value: "uz_Cyrl", label: "Usbekisch (Kyrillisch)" },
  { value: "uz_Cyrl_UZ", label: "Usbekisch (Kyrillisch, Usbekistan)" },
  { value: "uz_Latn", label: "Usbekisch (Lateinisch)" },
  { value: "uz_Latn_UZ", label: "Usbekisch (Lateinisch, Usbekistan)" },
  { value: "uz_UZ", label: "Usbekisch (Usbekistan)" },
  { value: "vi", label: "Vietnamesisch" },
  { value: "vi_VN", label: "Vietnamesisch (Vietnam)" },
  { value: "wo", label: "Wolof" },
  { value: "wo_SN", label: "Wolof (Senegal)" },
  { value: "yi", label: "Jiddisch" },
  { value: "yo", label: "Yoruba" },
  { value: "yo_BJ", label: "Yoruba (Benin)" },
  { value: "yo_NG", label: "Yoruba (Nigeria)" },
  { value: "zh", label: "Chinesisch" },
  { value: "zh_CN", label: "Chinesisch (China)" },
  {
    value: "zh_HK",
    label: "Chinesisch (Sonderverwaltungsregion Hongkong)",
  },
  { value: "zh_Hans", label: "Chinesisch (Vereinfacht)" },
  { value: "zh_Hans_CN", label: "Chinesisch (Vereinfacht, China)" },
  {
    value: "zh_Hans_HK",
    label: "Chinesisch (Vereinfacht, Sonderverwaltungsregion Hongkong)",
  },
  {
    value: "zh_Hans_MO",
    label: "Chinesisch (Vereinfacht, Sonderverwaltungsregion Macau)",
  },
  { value: "zh_Hans_SG", label: "Chinesisch (Vereinfacht, Singapur)" },
  { value: "zh_Hant", label: "Chinesisch (Traditionell)" },
  {
    value: "zh_Hant_HK",
    label: "Chinesisch (Traditionell, Sonderverwaltungsregion Hongkong)",
  },
  {
    value: "zh_Hant_MO",
    label: "Chinesisch (Traditionell, Sonderverwaltungsregion Macau)",
  },
  { value: "zh_Hant_TW", label: "Chinesisch (Traditionell, Taiwan)" },
  { value: "zh_MO", label: "Chinesisch (Sonderverwaltungsregion Macau)" },
  { value: "zh_SG", label: "Chinesisch (Singapur)" },
  { value: "zh_TW", label: "Chinesisch (Taiwan)" },
  { value: "zu", label: "Zulu" },
  { value: "zu_ZA", label: "Zulu (S\u00fcdafrika)" },
];

export const regionData = [{ label: "", value: null }];

export const treeData = [
  {
    key: "first-level-node-1",
    label: "Node 1 at the first level",
    nodes: [
      {
        key: "second-level-node-1",
        label: "Node 1 at the second level",
        nodes: [
          {
            key: "third-level-node-1",
            label: "Last node of the branch",
            nodes: [],
          },
        ],
      },
    ],
  },
  {
    key: "first-level-node-2",
    label: "Node 2 at the first level",
    nodes: [
      {
        key: "second-level-node-1",
        label: "Node 1 at the second level",
        nodes: [],
      },
    ],
  },
];

export const stateOptions = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FM", label: "Federated States Of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VI", label: "Virgin Islands" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const companyIndustryData = [
  { value: "Agriculture", label: "Agriculture" },
  { value: "Apparel", label: "Apparel" },
  { value: "Banking", label: "Banking" },
  { value: "Biotechnology", label: "Biotechnology" },
  { value: "Chemicals", label: "Chemicals" },
  { value: "Communications", label: "Communications" },
  { value: "Construction", label: "Construction" },
  { value: "Education", label: "Education" },
  { value: "Electronics", label: "Electronics" },
  { value: "Energy", label: "Energy" },
  { value: "Engineering", label: "Engineering" },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Environmental", label: "Environmental" },
  { value: "Finance", label: "Finance" },
  { value: "Food & Beverage", label: "Food & Beverage" },
  { value: "Government", label: "Government" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Insurance", label: "Insurance" },
  { value: "Machinery", label: "Machinery" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Media", label: "Media" },
  { value: "Not for Profit", label: "Not for Profit" },
  { value: "Recreation", label: "Recreation" },
  { value: "Retail", label: "Retail" },
  { value: "Shipping", label: "Shipping" },
  { value: "Technology", label: "Technology" },
  { value: "Telecommunications", label: "Telecommunications" },
  { value: "Transportation", label: "Transportation" },
  { value: "Utilities", label: "Utilities" },
  { value: "Other", label: "Other" },
];

export const groupedOptions = [
  {
    label: "United States",
    options: [
      { label: "Alabama", value: "Alabama" },
      { label: "Alaska", value: "Alaska" },
      { label: "Arizona", value: "Arizona" },
      { label: "Arkansas", value: "Arkansas" },
      { label: "California", value: "California" },
      { label: "Colorado", value: "Colorado" },
      { label: "Connecticut", value: "Connecticut" },
      { label: "Delaware", value: "Delaware" },
      { label: "Florida", value: "Florida" },
      { label: "Georgia", value: "Georgia" },
      { label: "Hawaii", value: "Hawaii" },
      { label: "Idaho", value: "Idaho" },
      { label: "Illinois", value: "Illinois" },
      { label: "Indiana", value: "Indiana" },
      { label: "Iowa", value: "Iowa" },
      { label: "Kansas", value: "Kansas" },
      { label: "Kentucky", value: "Kentucky" },
      { label: "Louisiana", value: "Louisiana" },
      { label: "Maine", value: "Maine" },
      { label: "Maryland", value: "Maryland" },
      { label: "Massachusetts", value: "Massachusetts" },
      { label: "Michigan", value: "Michigan" },
      { label: "Minnesota", value: "Minnesota" },
      { label: "Mississippi", value: "Mississippi" },
      { label: "Missouri", value: "Missouri" },
      { label: "Montana", value: "Montana" },
      { label: "Nebraska", value: "Nebraska" },
      { label: "Nevada", value: "Nevada" },
      { label: "New Hampshire", value: "New Hampshire" },
      { label: "New Jersey", value: "New Jersey" },
      { label: "New Mexico", value: "New Mexico" },
      { label: "New York", value: "New York" },
      { label: "North Carolina", value: "North Carolina" },
      { label: "North Dakota", value: "North Dakota" },
      { label: "Ohio", value: "Ohio" },
      { label: "Oklahoma", value: "Oklahoma" },
      { label: "Oregon", value: "Oregon" },
      { label: "Pennsylvania", value: "Pennsylvania" },
      { label: "Rhode Island", value: "Rhode Island" },
      { label: "South Carolina", value: "South Carolina" },
      { label: "South Dakota", value: "South Dakota" },
      { label: "Tennessee", value: "Tennessee" },
      { label: "Texas", value: "Texas" },
      { label: "Utah", value: "Utah" },
      { label: "Vermont", value: "Vermont" },
      { label: "Virginia", value: "Virginia" },
      { label: "Washington", value: "Washington" },
      { label: "West Virginia", value: "West Virginia" },
      { label: "Wisconsin", value: "Wisconsin" },
      { label: "Wyoming", value: "Wyoming" },
      { label: "District of Columbia", value: "District of Columbia" },
      { label: "American Samoa", value: "American Samoa" },
      { label: "Guam", value: "Guam" },
      {
        label: "Northern Mariana Islands",
        value: "Northern Mariana Islands",
      },
      { label: "Puerto Rico", value: "Puerto Rico" },
      { label: "Virgin Islands", value: "Virgin Islands" },
      {
        label: "United States Minor Outlying Islands",
        value: "United States Minor Outlying Islands",
      },
      { label: "Armed Forces Europe", value: "Armed Forces Europe" },
      { label: "Armed Forces Americas", value: "Armed Forces Americas" },
      { label: "Armed Forces Pacific", value: "Armed Forces Pacific" },
    ],
  },
  {
    label: "Liberia",
    options: [
      { label: "Maryland", value: "Maryland" },
      { label: "Bomi", value: "Bomi" },
      { label: "Bong", value: "Bong" },
      { label: "Grand Basaa", value: "Grand Basaa" },
      { label: "Grand Cape Mount", value: "Grand Cape Mount" },
      { label: "Grand Gedeh", value: "Grand Gedeh" },
      { label: "Grand Kru", value: "Grand Kru" },
      { label: "Lofa", value: "Lofa" },
      { label: "Margibi", value: "Margibi" },
      { label: "Montserrado", value: "Montserrado" },
      { label: "Nimba", value: "Nimba" },
      { label: "Rivercess", value: "Rivercess" },
      { label: "Sinoe", value: "Sinoe" },
    ],
  },
  {
    label: "Bulgaria",
    options: [
      { label: "Montana", value: "Montana" },
      { label: "Blagoevgrad", value: "Blagoevgrad" },
      { label: "Burgas", value: "Burgas" },
      { label: "Dobric", value: "Dobric" },
      { label: "Gabrovo", value: "Gabrovo" },
      { label: "Haskovo", value: "Haskovo" },
      { label: "Jambol", value: "Jambol" },
      { label: "Kardzali", value: "Kardzali" },
      { label: "Kjstendil", value: "Kjstendil" },
      { label: "Lovec", value: "Lovec" },
      { label: "Pazardzik", value: "Pazardzik" },
      { label: "Pernik", value: "Pernik" },
      { label: "Pleven", value: "Pleven" },
      { label: "Plovdiv", value: "Plovdiv" },
      { label: "Razgrad", value: "Razgrad" },
      { label: "Ruse", value: "Ruse" },
      { label: "Silistra", value: "Silistra" },
      { label: "Sliven", value: "Sliven" },
      { label: "Smoljan", value: "Smoljan" },
      { label: "Sofia", value: "Sofia" },
      { label: "Stara Zagora", value: "Stara Zagora" },
      { label: "Sumen", value: "Sumen" },
      { label: "Targoviste", value: "Targoviste" },
      { label: "Varna", value: "Varna" },
      { label: "Veliko Tarnovo", value: "Veliko Tarnovo" },
      { label: "Vidin", value: "Vidin" },
      { label: "Vraca", value: "Vraca" },
    ],
  },
  {
    label: "Canada",
    options: [
      { label: "Alberta", value: "Alberta" },
      { label: "British Columbia", value: "British Columbia" },
      { label: "Manitoba", value: "Manitoba" },
      { label: "New Brunswick", value: "New Brunswick" },
      {
        label: "Newfoundland and Labrador",
        value: "Newfoundland and Labrador",
      },
      { label: "Northwest Territories", value: "Northwest Territories" },
      { label: "Nova Scotia", value: "Nova Scotia" },
      { label: "Nunavut", value: "Nunavut" },
      { label: "Ontario", value: "Ontario" },
      { label: "Prince Edward Island", value: "Prince Edward Island" },
      { label: "Quebec", value: "Quebec" },
      { label: "Saskatchewan", value: "Saskatchewan" },
      { label: "Yukon Territory", value: "Yukon Territory" },
    ],
  },
  {
    label: "India",
    options: [
      { label: "Maharashtra", value: "Maharashtra" },
      { label: "Karnataka", value: "Karnataka" },
      { label: "Andhra Pradesh", value: "Andhra Pradesh" },
      { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
      { label: "Assam", value: "Assam" },
      { label: "Bihar", value: "Bihar" },
      { label: "Chhattisgarh", value: "Chhattisgarh" },
      { label: "Goa", value: "Goa" },
      { label: "Gujarat", value: "Gujarat" },
      { label: "Haryana", value: "Haryana" },
      { label: "Himachal Pradesh", value: "Himachal Pradesh" },
      { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
      { label: "Jharkhand", value: "Jharkhand" },
      { label: "Kerala", value: "Kerala" },
      { label: "Madhya Pradesh", value: "Madhya Pradesh" },
      { label: "Manipur", value: "Manipur" },
      { label: "Meghalaya", value: "Meghalaya" },
      { label: "Mizoram", value: "Mizoram" },
      { label: "Nagaland", value: "Nagaland" },
      { label: "Orissa", value: "Orissa" },
      { label: "Punjab", value: "Punjab" },
      { label: "Rajasthan", value: "Rajasthan" },
      { label: "Sikkim", value: "Sikkim" },
      { label: "Tamil Nadu", value: "Tamil Nadu" },
      { label: "Telangana", value: "Telangana" },
      { label: "Tripura", value: "Tripura" },
      { label: "Uttaranchal", value: "Uttaranchal" },
      { label: "Uttar Pradesh", value: "Uttar Pradesh" },
      { label: "West Bengal", value: "West Bengal" },
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      { label: "Dadra and Nagar Haveli", value: "Dadra and Nagar Haveli" },
      { label: "Daman and Diu", value: "Daman and Diu" },
      { label: "Delhi", value: "Delhi" },
      { label: "Lakshadweep", value: "Lakshadweep" },
      { label: "Pondicherry", value: "Pondicherry" },
    ],
  },
  {
    label: "Poland",
    options: [
      { label: "mazowieckie", value: "mazowieckie" },
      { label: "pomorskie", value: "pomorskie" },
      { label: "dolnośląskie", value: "dolnośląskie" },
      { label: "kujawsko-pomorskie", value: "kujawsko-pomorskie" },
      { label: "lubelskie", value: "lubelskie" },
      { label: "lubuskie", value: "lubuskie" },
      { label: "łódzkie", value: "łódzkie" },
      { label: "małopolskie", value: "małopolskie" },
      { label: "opolskie", value: "opolskie" },
      { label: "podkarpackie", value: "podkarpackie" },
      { label: "podlaskie", value: "podlaskie" },
      { label: "śląskie", value: "śląskie" },
      { label: "świętokrzyskie", value: "świętokrzyskie" },
      { label: "warmińsko-mazurskie", value: "warmińsko-mazurskie" },
      { label: "wielkopolskie", value: "wielkopolskie" },
      { label: "zachodniopomorskie", value: "zachodniopomorskie" },
    ],
  },
  {
    label: "United Arab Emirates",
    options: [
      { label: "Abu Zaby", value: "Abu Zaby" },
      { label: "'Ajman", value: "'Ajman" },
      { label: "Al Fujayrah", value: "Al Fujayrah" },
      { label: "Ash Shariqah", value: "Ash Shariqah" },
      { label: "Dubayy", value: "Dubayy" },
      { label: "Ra's al Khaymah", value: "Ra's al Khaymah" },
      { label: "Umm al Qaywayn", value: "Umm al Qaywayn" },
    ],
  },
  {
    label: "Viet Nam",
    options: [
      { label: "Dac Lac", value: "Dac Lac" },
      { label: "An Giang", value: "An Giang" },
      { label: "Ba Ria - Vung Tau", value: "Ba Ria - Vung Tau" },
      { label: "Bac Can", value: "Bac Can" },
      { label: "Bac Giang", value: "Bac Giang" },
      { label: "Bac Lieu", value: "Bac Lieu" },
      { label: "Bac Ninh", value: "Bac Ninh" },
      { label: "Ben Tre", value: "Ben Tre" },
      { label: "Binh Dinh", value: "Binh Dinh" },
      { label: "Binh Duong", value: "Binh Duong" },
      { label: "Binh Phuoc", value: "Binh Phuoc" },
      { label: "Binh Thuan", value: "Binh Thuan" },
      { label: "Ca Mau", value: "Ca Mau" },
      { label: "Can Tho", value: "Can Tho" },
      { label: "Cao Bang", value: "Cao Bang" },
      { label: "Da Nang, thanh pho", value: "Da Nang, thanh pho" },
      { label: "Dong Nai", value: "Dong Nai" },
      { label: "Dong Thap", value: "Dong Thap" },
      { label: "Gia Lai", value: "Gia Lai" },
      { label: "Ha Giang", value: "Ha Giang" },
      { label: "Ha Nam", value: "Ha Nam" },
      { label: "Ha Noi, thu do", value: "Ha Noi, thu do" },
      { label: "Ha Tay", value: "Ha Tay" },
      { label: "Ha Tinh", value: "Ha Tinh" },
      { label: "Hai Duong", value: "Hai Duong" },
      { label: "Hai Phong, thanh pho", value: "Hai Phong, thanh pho" },
      { label: "Hoa Binh", value: "Hoa Binh" },
      {
        label: "Ho Chi Minh, thanh pho [Sai Gon]",
        value: "Ho Chi Minh, thanh pho [Sai Gon]",
      },
      { label: "Hung Yen", value: "Hung Yen" },
      { label: "Khanh Hoa", value: "Khanh Hoa" },
      { label: "Kien Giang", value: "Kien Giang" },
      { label: "Kon Tum", value: "Kon Tum" },
      { label: "Lai Chau", value: "Lai Chau" },
      { label: "Lam Dong", value: "Lam Dong" },
      { label: "Lang Son", value: "Lang Son" },
      { label: "Lao Cai", value: "Lao Cai" },
      { label: "Long An", value: "Long An" },
      { label: "Nam Dinh", value: "Nam Dinh" },
      { label: "Nghe An", value: "Nghe An" },
      { label: "Ninh Binh", value: "Ninh Binh" },
      { label: "Ninh Thuan", value: "Ninh Thuan" },
      { label: "Phu Tho", value: "Phu Tho" },
      { label: "Phu Yen", value: "Phu Yen" },
      { label: "Quang Binh", value: "Quang Binh" },
      { label: "Quang Nam", value: "Quang Nam" },
      { label: "Quang Ngai", value: "Quang Ngai" },
      { label: "Quang Ninh", value: "Quang Ninh" },
      { label: "Quang Tri", value: "Quang Tri" },
      { label: "Soc Trang", value: "Soc Trang" },
      { label: "Son La", value: "Son La" },
      { label: "Tay Ninh", value: "Tay Ninh" },
      { label: "Thai Binh", value: "Thai Binh" },
      { label: "Thai Nguyen", value: "Thai Nguyen" },
      { label: "Thanh Hoa", value: "Thanh Hoa" },
      { label: "Thua Thien-Hue", value: "Thua Thien-Hue" },
      { label: "Tien Giang", value: "Tien Giang" },
      { label: "Tra Vinh", value: "Tra Vinh" },
      { label: "Tuyen Quang", value: "Tuyen Quang" },
      { label: "Vinh Long", value: "Vinh Long" },
      { label: "Vinh Phuc", value: "Vinh Phuc" },
      { label: "Yen Bai", value: "Yen Bai" },
    ],
  },
  {
    label: "Afghanistan",
    options: [
      { label: "Badakhshan", value: "Badakhshan" },
      { label: "Badghis", value: "Badghis" },
      { label: "Baghlan", value: "Baghlan" },
      { label: "Balkh", value: "Balkh" },
      { label: "Bamian", value: "Bamian" },
      { label: "Farah", value: "Farah" },
      { label: "Faryab", value: "Faryab" },
      { label: "Ghazni", value: "Ghazni" },
      { label: "Ghowr", value: "Ghowr" },
      { label: "Helmand", value: "Helmand" },
      { label: "Herat", value: "Herat" },
      { label: "Jowzjan", value: "Jowzjan" },
      { label: "Kabul", value: "Kabul" },
      { label: "Kandahar", value: "Kandahar" },
      { label: "Kapisa", value: "Kapisa" },
      { label: "Khowst", value: "Khowst" },
      { label: "Konar", value: "Konar" },
      { label: "Kondoz", value: "Kondoz" },
      { label: "Laghman", value: "Laghman" },
      { label: "Lowgar", value: "Lowgar" },
      { label: "Nangrahar", value: "Nangrahar" },
      { label: "Nimruz", value: "Nimruz" },
      { label: "Nurestan", value: "Nurestan" },
      { label: "Oruzgan", value: "Oruzgan" },
      { label: "Paktia", value: "Paktia" },
      { label: "Paktika", value: "Paktika" },
      { label: "Parwan", value: "Parwan" },
      { label: "Samangan", value: "Samangan" },
      { label: "Sar-e Pol", value: "Sar-e Pol" },
      { label: "Takhar", value: "Takhar" },
      { label: "Wardak", value: "Wardak" },
      { label: "Zabol", value: "Zabol" },
    ],
  },
  {
    label: "Albania",
    options: [
      { label: "Berat", value: "Berat" },
      { label: "Bulqizë", value: "Bulqizë" },
      { label: "Delvinë", value: "Delvinë" },
      { label: "Devoll", value: "Devoll" },
      { label: "Dibër", value: "Dibër" },
      { label: "Durrsës", value: "Durrsës" },
      { label: "Elbasan", value: "Elbasan" },
      { label: "Fier", value: "Fier" },
      { label: "Gramsh", value: "Gramsh" },
      { label: "Gjirokastër", value: "Gjirokastër" },
      { label: "Has", value: "Has" },
      { label: "Kavajë", value: "Kavajë" },
      { label: "Kolonjë", value: "Kolonjë" },
      { label: "Korcë", value: "Korcë" },
      { label: "Krujë", value: "Krujë" },
      { label: "Kuçovë", value: "Kuçovë" },
      { label: "Kukës", value: "Kukës" },
      { label: "Kurbin", value: "Kurbin" },
      { label: "Lezhë", value: "Lezhë" },
      { label: "Librazhd", value: "Librazhd" },
      { label: "Lushnjë", value: "Lushnjë" },
      { label: "Malësi e Madhe", value: "Malësi e Madhe" },
      { label: "Mallakastër", value: "Mallakastër" },
      { label: "Mat", value: "Mat" },
      { label: "Mirditë", value: "Mirditë" },
      { label: "Peqin", value: "Peqin" },
      { label: "Përmet", value: "Përmet" },
      { label: "Pogradec", value: "Pogradec" },
      { label: "Pukë", value: "Pukë" },
      { label: "Sarandë", value: "Sarandë" },
      { label: "Skrapar", value: "Skrapar" },
      { label: "Shkodër", value: "Shkodër" },
      { label: "Tepelenë", value: "Tepelenë" },
      { label: "Tiranë", value: "Tiranë" },
      { label: "Tropojë", value: "Tropojë" },
      { label: "Vlorë", value: "Vlorë" },
    ],
  },
  {
    label: "Armenia",
    options: [
      { label: "Erevan", value: "Erevan" },
      { label: "Aragacotn", value: "Aragacotn" },
      { label: "Ararat", value: "Ararat" },
      { label: "Armavir", value: "Armavir" },
      { label: "Gegarkunik'", value: "Gegarkunik'" },
      { label: "Kotayk'", value: "Kotayk'" },
      { label: "Lory", value: "Lory" },
      { label: "Sirak", value: "Sirak" },
      { label: "Syunik'", value: "Syunik'" },
      { label: "Tavus", value: "Tavus" },
      { label: "Vayoc Jor", value: "Vayoc Jor" },
    ],
  },
  {
    label: "Angola",
    options: [
      { label: "Bengo", value: "Bengo" },
      { label: "Benguela", value: "Benguela" },
      { label: "Bie", value: "Bie" },
      { label: "Cabinda", value: "Cabinda" },
      { label: "Cuando-Cubango", value: "Cuando-Cubango" },
      { label: "Cuanza Norte", value: "Cuanza Norte" },
      { label: "Cuanza Sul", value: "Cuanza Sul" },
      { label: "Cunene", value: "Cunene" },
      { label: "Huambo", value: "Huambo" },
      { label: "Huila", value: "Huila" },
      { label: "Luanda", value: "Luanda" },
      { label: "Lunda Norte", value: "Lunda Norte" },
      { label: "Lunda Sul", value: "Lunda Sul" },
      { label: "Malange", value: "Malange" },
      { label: "Moxico", value: "Moxico" },
      { label: "Namibe", value: "Namibe" },
      { label: "Uige", value: "Uige" },
      { label: "Zaire", value: "Zaire" },
    ],
  },
  {
    label: "Argentina",
    options: [
      { label: "Capital federal", value: "Capital federal" },
      { label: "Buenos Aires", value: "Buenos Aires" },
      { label: "Catamarca", value: "Catamarca" },
      { label: "Cordoba", value: "Cordoba" },
      { label: "Corrientes", value: "Corrientes" },
      { label: "Chaco", value: "Chaco" },
      { label: "Chubut", value: "Chubut" },
      { label: "Entre Rios", value: "Entre Rios" },
      { label: "Formosa", value: "Formosa" },
      { label: "Jujuy", value: "Jujuy" },
      { label: "La Pampa", value: "La Pampa" },
      { label: "Mendoza", value: "Mendoza" },
      { label: "Misiones", value: "Misiones" },
      { label: "Neuquen", value: "Neuquen" },
      { label: "Rio Negro", value: "Rio Negro" },
      { label: "Salta", value: "Salta" },
      { label: "San Juan", value: "San Juan" },
      { label: "San Luis", value: "San Luis" },
      { label: "Santa Cruz", value: "Santa Cruz" },
      { label: "Santa Fe", value: "Santa Fe" },
      { label: "Santiago del Estero", value: "Santiago del Estero" },
      { label: "Tierra del Fuego", value: "Tierra del Fuego" },
      { label: "Tucuman", value: "Tucuman" },
    ],
  },
  {
    label: "Österreich",
    options: [
      { label: "Burgenland", value: "Burgenland" },
      { label: "Kärnten", value: "Kärnten" },
      { label: "Niederösterreich", value: "Niederösterreich" },
      { label: "Oberösterreich", value: "Oberösterreich" },
      { label: "Salzburg", value: "Salzburg" },
      { label: "Steiermark", value: "Steiermark" },
      { label: "Tirol", value: "Tirol" },
      { label: "Vorarlberg", value: "Vorarlberg" },
      { label: "Wien", value: "Wien" },
    ],
  },
  {
    label: "Antarctica",
    options: [
      {
        label: "Australian Antarctic Territory",
        value: "Australian Antarctic Territory",
      },
    ],
  },
  {
    label: "Australia",
    options: [
      {
        label: "Australian Capital Territory",
        value: "Australian Capital Territory",
      },
      { label: "Northern Territory", value: "Northern Territory" },
      { label: "New South Wales", value: "New South Wales" },
      { label: "Queensland", value: "Queensland" },
      { label: "South Australia", value: "South Australia" },
      { label: "Tasmania", value: "Tasmania" },
      { label: "Victoria", value: "Victoria" },
      { label: "Western Australia", value: "Western Australia" },
    ],
  },
  {
    label: "Azerbaijan",
    options: [
      { label: "Naxcivan", value: "Naxcivan" },
      { label: "Ali Bayramli", value: "Ali Bayramli" },
      { label: "Baki", value: "Baki" },
      { label: "Ganca", value: "Ganca" },
      { label: "Lankaran", value: "Lankaran" },
      { label: "Mingacevir", value: "Mingacevir" },
      { label: "Naftalan", value: "Naftalan" },
      { label: "Saki", value: "Saki" },
      { label: "Sumqayit", value: "Sumqayit" },
      { label: "Susa", value: "Susa" },
      { label: "Xankandi", value: "Xankandi" },
      { label: "Yevlax", value: "Yevlax" },
      { label: "Abseron", value: "Abseron" },
      { label: "Agcabadi", value: "Agcabadi" },
      { label: "Agdam", value: "Agdam" },
      { label: "Agdas", value: "Agdas" },
      { label: "Agstafa", value: "Agstafa" },
      { label: "Agsu", value: "Agsu" },
      { label: "Astara", value: "Astara" },
      { label: "Babak", value: "Babak" },
      { label: "Balakan", value: "Balakan" },
      { label: "Barda", value: "Barda" },
      { label: "Beylagan", value: "Beylagan" },
      { label: "Bilasuvar", value: "Bilasuvar" },
      { label: "Cabrayll", value: "Cabrayll" },
      { label: "Calilabad", value: "Calilabad" },
      { label: "Culfa", value: "Culfa" },
      { label: "Daskasan", value: "Daskasan" },
      { label: "Davaci", value: "Davaci" },
      { label: "Fuzuli", value: "Fuzuli" },
      { label: "Gadabay", value: "Gadabay" },
      { label: "Goranboy", value: "Goranboy" },
      { label: "Goycay", value: "Goycay" },
      { label: "Haciqabul", value: "Haciqabul" },
      { label: "Imisli", value: "Imisli" },
      { label: "Ismayilli", value: "Ismayilli" },
      { label: "Kalbacar", value: "Kalbacar" },
      { label: "Kurdamir", value: "Kurdamir" },
      { label: "Lacin", value: "Lacin" },
      { label: "Lerik", value: "Lerik" },
      { label: "Masalli", value: "Masalli" },
      { label: "Neftcala", value: "Neftcala" },
      { label: "Oguz", value: "Oguz" },
      { label: "Ordubad", value: "Ordubad" },
      { label: "Qabala", value: "Qabala" },
      { label: "Qax", value: "Qax" },
      { label: "Qazax", value: "Qazax" },
      { label: "Qobustan", value: "Qobustan" },
      { label: "Quba", value: "Quba" },
      { label: "Qubadli", value: "Qubadli" },
      { label: "Qusar", value: "Qusar" },
      { label: "Saatli", value: "Saatli" },
      { label: "Sabirabad", value: "Sabirabad" },
      { label: "Sadarak", value: "Sadarak" },
      { label: "Sahbuz", value: "Sahbuz" },
      { label: "Salyan", value: "Salyan" },
      { label: "Samaxi", value: "Samaxi" },
      { label: "Samkir", value: "Samkir" },
      { label: "Samux", value: "Samux" },
      { label: "Sarur", value: "Sarur" },
      { label: "Siyazan", value: "Siyazan" },
      { label: "Tartar", value: "Tartar" },
      { label: "Tovuz", value: "Tovuz" },
      { label: "Ucar", value: "Ucar" },
      { label: "Xacmaz", value: "Xacmaz" },
      { label: "Xanlar", value: "Xanlar" },
      { label: "Xizi", value: "Xizi" },
      { label: "Xocali", value: "Xocali" },
      { label: "Xocavand", value: "Xocavand" },
      { label: "Yardimli", value: "Yardimli" },
      { label: "Zangilan", value: "Zangilan" },
      { label: "Zaqatala", value: "Zaqatala" },
      { label: "Zardab", value: "Zardab" },
    ],
  },
  {
    label: "Bosnia and Herzegovina",
    options: [
      {
        label: "Federacija Bosna i Hercegovina",
        value: "Federacija Bosna i Hercegovina",
      },
      { label: "Republika Srpska", value: "Republika Srpska" },
    ],
  },
  {
    label: "Bangladesh",
    options: [
      { label: "Bagerhat zila", value: "Bagerhat zila" },
      { label: "Bandarban zila", value: "Bandarban zila" },
      { label: "Barguna zila", value: "Barguna zila" },
      { label: "Barisal zila", value: "Barisal zila" },
      { label: "Bhola zila", value: "Bhola zila" },
      { label: "Bogra zila", value: "Bogra zila" },
      { label: "Brahmanbaria zila", value: "Brahmanbaria zila" },
      { label: "Chandpur zila", value: "Chandpur zila" },
      { label: "Chittagong zila", value: "Chittagong zila" },
      { label: "Chuadanga zila", value: "Chuadanga zila" },
      { label: "Comilla zila", value: "Comilla zila" },
      { label: "Cox's Bazar zila", value: "Cox's Bazar zila" },
      { label: "Dhaka zila", value: "Dhaka zila" },
      { label: "Dinajpur zila", value: "Dinajpur zila" },
      { label: "Faridpur zila", value: "Faridpur zila" },
      { label: "Feni zila", value: "Feni zila" },
      { label: "Gaibandha zila", value: "Gaibandha zila" },
      { label: "Gazipur zila", value: "Gazipur zila" },
      { label: "Gopalganj zila", value: "Gopalganj zila" },
      { label: "Habiganj zila", value: "Habiganj zila" },
      { label: "Jaipurhat zila", value: "Jaipurhat zila" },
      { label: "Jamalpur zila", value: "Jamalpur zila" },
      { label: "Jessore zila", value: "Jessore zila" },
      { label: "Jhalakati zila", value: "Jhalakati zila" },
      { label: "Jhenaidah zila", value: "Jhenaidah zila" },
      { label: "Khagrachari zila", value: "Khagrachari zila" },
      { label: "Khulna zila", value: "Khulna zila" },
      { label: "Kishorganj zila", value: "Kishorganj zila" },
      { label: "Kurigram zila", value: "Kurigram zila" },
      { label: "Kushtia zila", value: "Kushtia zila" },
      { label: "Lakshmipur zila", value: "Lakshmipur zila" },
      { label: "Lalmonirhat zila", value: "Lalmonirhat zila" },
      { label: "Madaripur zila", value: "Madaripur zila" },
      { label: "Magura zila", value: "Magura zila" },
      { label: "Manikganj zila", value: "Manikganj zila" },
      { label: "Meherpur zila", value: "Meherpur zila" },
      { label: "Moulvibazar zila", value: "Moulvibazar zila" },
      { label: "Munshiganj zila", value: "Munshiganj zila" },
      { label: "Mymensingh zila", value: "Mymensingh zila" },
      { label: "Naogaon zila", value: "Naogaon zila" },
      { label: "Narail zila", value: "Narail zila" },
      { label: "Narayanganj zila", value: "Narayanganj zila" },
      { label: "Narsingdi zila", value: "Narsingdi zila" },
      { label: "Natore zila", value: "Natore zila" },
      { label: "Nawabganj zila", value: "Nawabganj zila" },
      { label: "Netrakona zila", value: "Netrakona zila" },
      { label: "Nilphamari zila", value: "Nilphamari zila" },
      { label: "Noakhali zila", value: "Noakhali zila" },
      { label: "Pabna zila", value: "Pabna zila" },
      { label: "Panchagarh zila", value: "Panchagarh zila" },
      { label: "Patuakhali zila", value: "Patuakhali zila" },
      { label: "Pirojpur zila", value: "Pirojpur zila" },
      { label: "Rajbari zila", value: "Rajbari zila" },
      { label: "Rajshahi zila", value: "Rajshahi zila" },
      { label: "Rangamati zila", value: "Rangamati zila" },
      { label: "Rangpur zila", value: "Rangpur zila" },
      { label: "Satkhira zila", value: "Satkhira zila" },
      { label: "Shariatpur zila", value: "Shariatpur zila" },
      { label: "Sherpur zila", value: "Sherpur zila" },
      { label: "Sirajganj zila", value: "Sirajganj zila" },
      { label: "Sunamganj zila", value: "Sunamganj zila" },
      { label: "Sylhet zila", value: "Sylhet zila" },
      { label: "Tangail zila", value: "Tangail zila" },
      { label: "Thakurgaon zila", value: "Thakurgaon zila" },
    ],
  },
  {
    label: "Belgium",
    options: [
      { label: "Antwerpen", value: "Antwerpen" },
      { label: "Brabant Wallon", value: "Brabant Wallon" },
      { label: "Hainaut", value: "Hainaut" },
      { label: "Liege", value: "Liege" },
      { label: "Luxembourg", value: "Luxembourg" },
      { label: "Namur", value: "Namur" },
      { label: "Oost-Vlaanderen", value: "Oost-Vlaanderen" },
      { label: "Vlaams-Brabant", value: "Vlaams-Brabant" },
      { label: "West-Vlaanderen", value: "West-Vlaanderen" },
      { label: "Brussels", value: "Brussels" },
    ],
  },
  {
    label: "Netherlands",
    options: [
      { label: "Limburg", value: "Limburg" },
      { label: "Drente", value: "Drente" },
      { label: "Flevoland", value: "Flevoland" },
      { label: "Friesland", value: "Friesland" },
      { label: "Gelderland", value: "Gelderland" },
      { label: "Groningen", value: "Groningen" },
      { label: "Noord-Brabant", value: "Noord-Brabant" },
      { label: "Noord-Holland", value: "Noord-Holland" },
      { label: "Overijssel", value: "Overijssel" },
      { label: "Utrecht", value: "Utrecht" },
      { label: "Zuid-Holland", value: "Zuid-Holland" },
      { label: "Zeeland", value: "Zeeland" },
    ],
  },
  {
    label: "Burkina Faso",
    options: [
      { label: "Bale", value: "Bale" },
      { label: "Bam", value: "Bam" },
      { label: "Banwa", value: "Banwa" },
      { label: "Bazega", value: "Bazega" },
      { label: "Bougouriba", value: "Bougouriba" },
      { label: "Boulgou", value: "Boulgou" },
      { label: "Boulkiemde", value: "Boulkiemde" },
      { label: "Comoe", value: "Comoe" },
      { label: "Ganzourgou", value: "Ganzourgou" },
      { label: "Gnagna", value: "Gnagna" },
      { label: "Gourma", value: "Gourma" },
      { label: "Houet", value: "Houet" },
      { label: "Ioba", value: "Ioba" },
      { label: "Kadiogo", value: "Kadiogo" },
      { label: "Kenedougou", value: "Kenedougou" },
      { label: "Komondjari", value: "Komondjari" },
      { label: "Kompienga", value: "Kompienga" },
      { label: "Kossi", value: "Kossi" },
      { label: "Koulpulogo", value: "Koulpulogo" },
      { label: "Kouritenga", value: "Kouritenga" },
      { label: "Kourweogo", value: "Kourweogo" },
      { label: "Leraba", value: "Leraba" },
      { label: "Loroum", value: "Loroum" },
      { label: "Mouhoun", value: "Mouhoun" },
      { label: "Nahouri", value: "Nahouri" },
      { label: "Namentenga", value: "Namentenga" },
      { label: "Nayala", value: "Nayala" },
      { label: "Noumbiel", value: "Noumbiel" },
      { label: "Oubritenga", value: "Oubritenga" },
      { label: "Oudalan", value: "Oudalan" },
      { label: "Passore", value: "Passore" },
      { label: "Poni", value: "Poni" },
      { label: "Sanguie", value: "Sanguie" },
      { label: "Sanmatenga", value: "Sanmatenga" },
      { label: "Seno", value: "Seno" },
      { label: "Siasili", value: "Siasili" },
      { label: "Soum", value: "Soum" },
      { label: "Sourou", value: "Sourou" },
      { label: "Tapoa", value: "Tapoa" },
      { label: "Tui", value: "Tui" },
      { label: "Yagha", value: "Yagha" },
      { label: "Yatenga", value: "Yatenga" },
      { label: "Ziro", value: "Ziro" },
      { label: "Zondoma", value: "Zondoma" },
      { label: "Zoundweogo", value: "Zoundweogo" },
    ],
  },
  {
    label: "Bahrain",
    options: [
      { label: "Al Hadd", value: "Al Hadd" },
      { label: "Al Manamah", value: "Al Manamah" },
      {
        label: "Al Mintaqah al Gharbiyah",
        value: "Al Mintaqah al Gharbiyah",
      },
      { label: "Al Mintagah al Wusta", value: "Al Mintagah al Wusta" },
      {
        label: "Al Mintaqah ash Shamaliyah",
        value: "Al Mintaqah ash Shamaliyah",
      },
      { label: "Al Muharraq", value: "Al Muharraq" },
      { label: "Ar Rifa", value: "Ar Rifa" },
      { label: "Jidd Hafs", value: "Jidd Hafs" },
      { label: "Madluat Jamad", value: "Madluat Jamad" },
      { label: "Madluat Isa", value: "Madluat Isa" },
      { label: "Mintaqat Juzur tawar", value: "Mintaqat Juzur tawar" },
      { label: "Sitrah", value: "Sitrah" },
      {
        label: "Al Manāmah (Al ‘Āşimah)",
        value: "Al Manāmah (Al ‘Āşimah)",
      },
      { label: "Al Janūbīyah", value: "Al Janūbīyah" },
      { label: "Al Wusţá", value: "Al Wusţá" },
      { label: "Ash Shamālīyah", value: "Ash Shamālīyah" },
    ],
  },
  {
    label: "Burundi",
    options: [
      { label: "Bubanza", value: "Bubanza" },
      { label: "Bujumbura", value: "Bujumbura" },
      { label: "Bururi", value: "Bururi" },
      { label: "Cankuzo", value: "Cankuzo" },
      { label: "Cibitoke", value: "Cibitoke" },
      { label: "Gitega", value: "Gitega" },
      { label: "Karuzi", value: "Karuzi" },
      { label: "Kayanza", value: "Kayanza" },
      { label: "Makamba", value: "Makamba" },
      { label: "Muramvya", value: "Muramvya" },
      { label: "Mwaro", value: "Mwaro" },
      { label: "Ngozi", value: "Ngozi" },
      { label: "Rutana", value: "Rutana" },
      { label: "Ruyigi", value: "Ruyigi" },
    ],
  },
  {
    label: "Benin",
    options: [
      { label: "Alibori", value: "Alibori" },
      { label: "Atakora", value: "Atakora" },
      { label: "Atlantique", value: "Atlantique" },
      { label: "Borgou", value: "Borgou" },
      { label: "Collines", value: "Collines" },
      { label: "Donga", value: "Donga" },
      { label: "Kouffo", value: "Kouffo" },
      { label: "Littoral", value: "Littoral" },
      { label: "Mono", value: "Mono" },
      { label: "Oueme", value: "Oueme" },
      { label: "Plateau", value: "Plateau" },
      { label: "Zou", value: "Zou" },
    ],
  },
  {
    label: "Brunei Darussalam",
    options: [
      { label: "Belait", value: "Belait" },
      { label: "Brunei-Muara", value: "Brunei-Muara" },
      { label: "Temburong", value: "Temburong" },
      { label: "Tutong", value: "Tutong" },
    ],
  },
  {
    label: "Bolivia",
    options: [
      { label: "Cochabamba", value: "Cochabamba" },
      { label: "Chuquisaca", value: "Chuquisaca" },
      { label: "El Beni", value: "El Beni" },
      { label: "La Paz", value: "La Paz" },
      { label: "Oruro", value: "Oruro" },
      { label: "Pando", value: "Pando" },
      { label: "Potosi", value: "Potosi" },
      { label: "Tarija", value: "Tarija" },
    ],
  },
  {
    label: "Brazil",
    options: [
      { label: "Acre", value: "Acre" },
      { label: "Alagoas", value: "Alagoas" },
      { label: "Amazonas", value: "Amazonas" },
      { label: "Amapa", value: "Amapa" },
      { label: "Bahia", value: "Bahia" },
      { label: "Ceara", value: "Ceara" },
      { label: "Distrito Federal", value: "Distrito Federal" },
      { label: "Espirito Santo", value: "Espirito Santo" },
      { label: "Goias", value: "Goias" },
      { label: "Maranhao", value: "Maranhao" },
      { label: "Minas Gerais", value: "Minas Gerais" },
      { label: "Mato Grosso do Sul", value: "Mato Grosso do Sul" },
      { label: "Mato Grosso", value: "Mato Grosso" },
      { label: "Para", value: "Para" },
      { label: "Paraiba", value: "Paraiba" },
      { label: "Pernambuco", value: "Pernambuco" },
      { label: "Piaui", value: "Piaui" },
      { label: "Parana", value: "Parana" },
      { label: "Rio de Janeiro", value: "Rio de Janeiro" },
      { label: "Rio Grande do Norte", value: "Rio Grande do Norte" },
      { label: "Rondonia", value: "Rondonia" },
      { label: "Roraima", value: "Roraima" },
      { label: "Rio Grande do Sul", value: "Rio Grande do Sul" },
      { label: "Santa Catarina", value: "Santa Catarina" },
      { label: "Sergipe", value: "Sergipe" },
      { label: "Sao Paulo", value: "Sao Paulo" },
      { label: "Tocantins", value: "Tocantins" },
    ],
  },
  {
    label: "Mexico",
    options: [
      { label: "Aguascalientes", value: "Aguascalientes" },
      { label: "Baja California", value: "Baja California" },
      { label: "Baja California Sur", value: "Baja California Sur" },
      { label: "Campeche", value: "Campeche" },
      { label: "Ciudad de México", value: "Ciudad de México" },
      { label: "Coahuila", value: "Coahuila" },
      { label: "Colima", value: "Colima" },
      { label: "Chiapas", value: "Chiapas" },
      { label: "Chihuahua", value: "Chihuahua" },
      { label: "Durango", value: "Durango" },
      { label: "Guanajuato", value: "Guanajuato" },
      { label: "Guerrero", value: "Guerrero" },
      { label: "Hidalgo", value: "Hidalgo" },
      { label: "Jalisco", value: "Jalisco" },
      { label: "Estado de México", value: "Estado de México" },
      { label: "Michoacán", value: "Michoacán" },
      { label: "Morelos", value: "Morelos" },
      { label: "Nayarit", value: "Nayarit" },
      { label: "Nuevo León", value: "Nuevo León" },
      { label: "Oaxaca", value: "Oaxaca" },
      { label: "Puebla", value: "Puebla" },
      { label: "Querétaro", value: "Querétaro" },
      { label: "Quintana Roo", value: "Quintana Roo" },
      { label: "San Luis Potosí", value: "San Luis Potosí" },
      { label: "Sinaloa", value: "Sinaloa" },
      { label: "Sonora", value: "Sonora" },
      { label: "Tabasco", value: "Tabasco" },
      { label: "Tamaulipas", value: "Tamaulipas" },
      { label: "Tlaxcala", value: "Tlaxcala" },
      { label: "Veracruz", value: "Veracruz" },
      { label: "Yucatán", value: "Yucatán" },
      { label: "Zacatecas", value: "Zacatecas" },
    ],
  },
  {
    label: "Bahamas",
    options: [
      {
        label: "Acklins and Crooked Islands",
        value: "Acklins and Crooked Islands",
      },
      { label: "Bimini", value: "Bimini" },
      { label: "Cat Island", value: "Cat Island" },
      { label: "Exuma", value: "Exuma" },
      { label: "Freeport", value: "Freeport" },
      { label: "Fresh Creek", value: "Fresh Creek" },
      { label: "Governor's Harbour", value: "Governor's Harbour" },
      { label: "Green Turtle Cay", value: "Green Turtle Cay" },
      { label: "Harbour Island", value: "Harbour Island" },
      { label: "High Rock", value: "High Rock" },
      { label: "Inagua", value: "Inagua" },
      { label: "Kemps Bay", value: "Kemps Bay" },
      { label: "Long Island", value: "Long Island" },
      { label: "Marsh Harbour", value: "Marsh Harbour" },
      { label: "Mayaguana", value: "Mayaguana" },
      { label: "New Providence", value: "New Providence" },
      {
        label: "Nicholls Town and Berry Islands",
        value: "Nicholls Town and Berry Islands",
      },
      { label: "Ragged Island", value: "Ragged Island" },
      { label: "Rock Sound", value: "Rock Sound" },
      { label: "Sandy Point", value: "Sandy Point" },
      {
        label: "San Salvador and Rum Cay",
        value: "San Salvador and Rum Cay",
      },
    ],
  },
  {
    label: "Bhutan",
    options: [
      { label: "Bumthang", value: "Bumthang" },
      { label: "Chhukha", value: "Chhukha" },
      { label: "Dagana", value: "Dagana" },
      { label: "Gasa", value: "Gasa" },
      { label: "Ha", value: "Ha" },
      { label: "Lhuentse", value: "Lhuentse" },
      { label: "Monggar", value: "Monggar" },
      { label: "Paro", value: "Paro" },
      { label: "Pemagatshel", value: "Pemagatshel" },
      { label: "Punakha", value: "Punakha" },
      { label: "Samdrup Jongkha", value: "Samdrup Jongkha" },
      { label: "Samtee", value: "Samtee" },
      { label: "Sarpang", value: "Sarpang" },
      { label: "Thimphu", value: "Thimphu" },
      { label: "Trashigang", value: "Trashigang" },
      { label: "Trashi Yangtse", value: "Trashi Yangtse" },
      { label: "Trongsa", value: "Trongsa" },
      { label: "Tsirang", value: "Tsirang" },
      { label: "Wangdue Phodrang", value: "Wangdue Phodrang" },
      { label: "Zhemgang", value: "Zhemgang" },
    ],
  },
  {
    label: "Botswana",
    options: [
      { label: "Central", value: "Central" },
      { label: "Ghanzi", value: "Ghanzi" },
      { label: "Kgalagadi", value: "Kgalagadi" },
      { label: "Kgatleng", value: "Kgatleng" },
      { label: "Kweneng", value: "Kweneng" },
      { label: "Ngamiland", value: "Ngamiland" },
      { label: "North-East", value: "North-East" },
      { label: "North-West", value: "North-West" },
      { label: "South-East", value: "South-East" },
    ],
  },
  {
    label: "Hong Kong",
    options: [
      { label: "Southern", value: "Southern" },
      { label: "North", value: "North" },
      { label: "Eastern", value: "Eastern" },
      { label: "Central and Western", value: "Central and Western" },
      { label: "Wan Chai", value: "Wan Chai" },
      { label: "Kowloon City", value: "Kowloon City" },
      { label: "Kwun Tong", value: "Kwun Tong" },
      { label: "Sham Shui Po", value: "Sham Shui Po" },
      { label: "Wong Tai Sin", value: "Wong Tai Sin" },
      { label: "Yau Tsim Mong", value: "Yau Tsim Mong" },
      { label: "Islands", value: "Islands" },
      { label: "Kwai Tsing", value: "Kwai Tsing" },
      { label: "Sai Kung", value: "Sai Kung" },
      { label: "Sha Tin", value: "Sha Tin" },
      { label: "Tai Po", value: "Tai Po" },
      { label: "Tsuen Wan", value: "Tsuen Wan" },
      { label: "Tuen Mun", value: "Tuen Mun" },
      { label: "Yuen Long", value: "Yuen Long" },
    ],
  },
  {
    label: "Belarus",
    options: [
      { label: "Brèsckaja voblasc'", value: "Brèsckaja voblasc'" },
      { label: "Homel'skaja voblasc'", value: "Homel'skaja voblasc'" },
      { label: "Hrodzenskaja voblasc'", value: "Hrodzenskaja voblasc'" },
      { label: "Mahilëuskaja voblasc'", value: "Mahilëuskaja voblasc'" },
      { label: "Minskaja voblasc'", value: "Minskaja voblasc'" },
      { label: "Vicebskaja voblasc'", value: "Vicebskaja voblasc'" },
    ],
  },
  {
    label: "Belize",
    options: [
      { label: "Belize", value: "Belize" },
      { label: "Cayo", value: "Cayo" },
      { label: "Corozal", value: "Corozal" },
      { label: "Orange Walk", value: "Orange Walk" },
      { label: "Stann Creek", value: "Stann Creek" },
      { label: "Toledo", value: "Toledo" },
    ],
  },
  {
    label: "Congo, The Democratic Republic of the",
    options: [
      { label: "Kinshasa", value: "Kinshasa" },
      { label: "Bandundu", value: "Bandundu" },
      { label: "Bas-Congo", value: "Bas-Congo" },
      { label: "Equateur", value: "Equateur" },
      { label: "Haut-Congo", value: "Haut-Congo" },
      { label: "Kasai-Occidental", value: "Kasai-Occidental" },
      { label: "Kasai-Oriental", value: "Kasai-Oriental" },
      { label: "Katanga", value: "Katanga" },
      { label: "Maniema", value: "Maniema" },
      { label: "Nord-Kivu", value: "Nord-Kivu" },
      { label: "Orientale", value: "Orientale" },
      { label: "Sud-Kivu", value: "Sud-Kivu" },
    ],
  },
  {
    label: "Central African Republic",
    options: [
      { label: "Bangui", value: "Bangui" },
      { label: "Bamingui-Bangoran", value: "Bamingui-Bangoran" },
      { label: "Basse-Kotto", value: "Basse-Kotto" },
      { label: "Haute-Kotto", value: "Haute-Kotto" },
      { label: "Haut-Mbomou", value: "Haut-Mbomou" },
      { label: "Kemo", value: "Kemo" },
      { label: "Lobaye", value: "Lobaye" },
      { label: "Mambere-Kadei", value: "Mambere-Kadei" },
      { label: "Mbomou", value: "Mbomou" },
      { label: "Nana-Grebizi", value: "Nana-Grebizi" },
      { label: "Nana-Mambere", value: "Nana-Mambere" },
      { label: "Ombella-Mpoko", value: "Ombella-Mpoko" },
      { label: "Ouaka", value: "Ouaka" },
      { label: "Ouham", value: "Ouham" },
      { label: "Ouham-Pende", value: "Ouham-Pende" },
      { label: "Sangha-Mbaere", value: "Sangha-Mbaere" },
      { label: "Vakaga", value: "Vakaga" },
    ],
  },
  {
    label: "Congo",
    options: [
      { label: "Brazzaville", value: "Brazzaville" },
      { label: "Bouenza", value: "Bouenza" },
      { label: "Cuvette", value: "Cuvette" },
      { label: "Cuvette-Ouest", value: "Cuvette-Ouest" },
      { label: "Kouilou", value: "Kouilou" },
      { label: "Lekoumou", value: "Lekoumou" },
      { label: "Likouala", value: "Likouala" },
      { label: "Niari", value: "Niari" },
      { label: "Plateaux", value: "Plateaux" },
      { label: "Pool", value: "Pool" },
      { label: "Sangha", value: "Sangha" },
    ],
  },
  {
    label: "Schweiz",
    options: [
      { label: "Aargau", value: "Aargau" },
      { label: "Appenzell Innerrhoden", value: "Appenzell Innerrhoden" },
      { label: "Appenzell Ausserrhoden", value: "Appenzell Ausserrhoden" },
      { label: "Bern", value: "Bern" },
      { label: "Basel-Landschaft", value: "Basel-Landschaft" },
      { label: "Basel-Stadt", value: "Basel-Stadt" },
      { label: "Fribourg", value: "Fribourg" },
      { label: "Genève", value: "Genève" },
      { label: "Glarus", value: "Glarus" },
      { label: "Graubünden", value: "Graubünden" },
      { label: "Luzern", value: "Luzern" },
      { label: "Neuchatel", value: "Neuchatel" },
      { label: "Nidwalden", value: "Nidwalden" },
      { label: "Obwalden", value: "Obwalden" },
      { label: "Sankt Gallen", value: "Sankt Gallen" },
      { label: "Schaffhausen", value: "Schaffhausen" },
      { label: "Solothurn", value: "Solothurn" },
      { label: "Schwyz", value: "Schwyz" },
      { label: "Thurgau", value: "Thurgau" },
      { label: "Ticino", value: "Ticino" },
      { label: "Uri", value: "Uri" },
      { label: "Vaud", value: "Vaud" },
      { label: "Valais", value: "Valais" },
      { label: "Zug", value: "Zug" },
      { label: "Zürich", value: "Zürich" },
    ],
  },
  {
    label: "France",
    options: [
      { label: "Auvergne-Rhône-Alpes", value: "Auvergne-Rhône-Alpes" },
      {
        label: "Bourgogne-Franche-Comté",
        value: "Bourgogne-Franche-Comté",
      },
      { label: "Bretagne", value: "Bretagne" },
      { label: "Centre-Val de Loire", value: "Centre-Val de Loire" },
      { label: "Corse", value: "Corse" },
      { label: "Grand Est", value: "Grand Est" },
      { label: "Hauts-de-France", value: "Hauts-de-France" },
      { label: "Île-de-France", value: "Île-de-France" },
      { label: "Normandie", value: "Normandie" },
      { label: "Nouvelle-Aquitaine", value: "Nouvelle-Aquitaine" },
      { label: "Occitanie", value: "Occitanie" },
      { label: "Pays de la Loire", value: "Pays de la Loire" },
      {
        label: "Provence-Alpes-Côte d'Azur",
        value: "Provence-Alpes-Côte d'Azur",
      },
      { label: "Jura", value: "Jura" },
      { label: "Ain", value: "Ain" },
      { label: "Aisne", value: "Aisne" },
      { label: "Allier", value: "Allier" },
      {
        label: "Alpes-de-Haute-Provence",
        value: "Alpes-de-Haute-Provence",
      },
      { label: "Alpes-Maritimes", value: "Alpes-Maritimes" },
      { label: "Ardèche", value: "Ardèche" },
      { label: "Ardennes", value: "Ardennes" },
      { label: "Ariège", value: "Ariège" },
      { label: "Aube", value: "Aube" },
      { label: "Aude", value: "Aude" },
      { label: "Aveyron", value: "Aveyron" },
      { label: "Bas-Rhin", value: "Bas-Rhin" },
      { label: "Bouches-du-Rhône", value: "Bouches-du-Rhône" },
      { label: "Calvados", value: "Calvados" },
      { label: "Cantal", value: "Cantal" },
      { label: "Charente", value: "Charente" },
      { label: "Charente-Maritime", value: "Charente-Maritime" },
      { label: "Cher", value: "Cher" },
      { label: "Corrèze", value: "Corrèze" },
      { label: "Corse-du-Sud", value: "Corse-du-Sud" },
      { label: "Côte-d'Or", value: "Côte-d'Or" },
      { label: "Côtes-d'Armor", value: "Côtes-d'Armor" },
      { label: "Creuse", value: "Creuse" },
      { label: "Deux-Sèvres", value: "Deux-Sèvres" },
      { label: "Dordogne", value: "Dordogne" },
      { label: "Doubs", value: "Doubs" },
      { label: "Drôme", value: "Drôme" },
      { label: "Essonne", value: "Essonne" },
      { label: "Eure", value: "Eure" },
      { label: "Eure-et-Loir", value: "Eure-et-Loir" },
      { label: "Finistère", value: "Finistère" },
      { label: "Gard", value: "Gard" },
      { label: "Gers", value: "Gers" },
      { label: "Gironde", value: "Gironde" },
      { label: "Haut-Rhin", value: "Haut-Rhin" },
      { label: "Haute-Corse", value: "Haute-Corse" },
      { label: "Haute-Garonne", value: "Haute-Garonne" },
      { label: "Haute-Loire", value: "Haute-Loire" },
      { label: "Haute-Saône", value: "Haute-Saône" },
      { label: "Haute-Savoie", value: "Haute-Savoie" },
      { label: "Haute-Vienne", value: "Haute-Vienne" },
      { label: "Hautes-Alpes", value: "Hautes-Alpes" },
      { label: "Hautes-Pyrénées", value: "Hautes-Pyrénées" },
      { label: "Hauts-de-Seine", value: "Hauts-de-Seine" },
      { label: "Hérault", value: "Hérault" },
      { label: "Indre", value: "Indre" },
      { label: "Ille-et-Vilaine", value: "Ille-et-Vilaine" },
      { label: "Indre-et-Loire", value: "Indre-et-Loire" },
      { label: "Isère", value: "Isère" },
      { label: "Landes", value: "Landes" },
      { label: "Loir-et-Cher", value: "Loir-et-Cher" },
      { label: "Loire", value: "Loire" },
      { label: "Loire-Atlantique", value: "Loire-Atlantique" },
      { label: "Loiret", value: "Loiret" },
      { label: "Lot", value: "Lot" },
      { label: "Lot-et-Garonne", value: "Lot-et-Garonne" },
      { label: "Lozère", value: "Lozère" },
      { label: "Maine-et-Loire", value: "Maine-et-Loire" },
      { label: "Manche", value: "Manche" },
      { label: "Marne", value: "Marne" },
      { label: "Mayenne", value: "Mayenne" },
      { label: "Meurthe-et-Moselle", value: "Meurthe-et-Moselle" },
      { label: "Meuse", value: "Meuse" },
      { label: "Morbihan", value: "Morbihan" },
      { label: "Moselle", value: "Moselle" },
      { label: "Nièvre", value: "Nièvre" },
      { label: "Oise", value: "Oise" },
      { label: "Orne", value: "Orne" },
      { label: "Paris", value: "Paris" },
      { label: "Pas-de-Calais", value: "Pas-de-Calais" },
      { label: "Puy-de-Dôme", value: "Puy-de-Dôme" },
      { label: "Pyrénées-Atlantiques", value: "Pyrénées-Atlantiques" },
      { label: "Pyrénées-Orientales", value: "Pyrénées-Orientales" },
      { label: "Réunion", value: "Réunion" },
      { label: "Rhône", value: "Rhône" },
      { label: "Saône-et-Loire", value: "Saône-et-Loire" },
      { label: "Sarthe", value: "Sarthe" },
      { label: "Savoie", value: "Savoie" },
      { label: "Seine-et-Marne", value: "Seine-et-Marne" },
      { label: "Seine-Maritime", value: "Seine-Maritime" },
      { label: "Seine-Saint-Denis", value: "Seine-Saint-Denis" },
      { label: "Somme", value: "Somme" },
      { label: "Tarn", value: "Tarn" },
      { label: "Tarn-et-Garonne", value: "Tarn-et-Garonne" },
      { label: "Val d'Oise", value: "Val d'Oise" },
      { label: "Territoire de Belfort", value: "Territoire de Belfort" },
      { label: "Val-de-Marne", value: "Val-de-Marne" },
      { label: "Var", value: "Var" },
      { label: "Vaucluse", value: "Vaucluse" },
      { label: "Vendée", value: "Vendée" },
      { label: "Vienne", value: "Vienne" },
      { label: "Vosges", value: "Vosges" },
      { label: "Yonne", value: "Yonne" },
      { label: "Yvelines", value: "Yvelines" },
    ],
  },
  {
    label: "Côte d'Ivoire",
    options: [
      { label: "18 Montagnes", value: "18 Montagnes" },
      { label: "Agnebi", value: "Agnebi" },
      { label: "Bas-Sassandra", value: "Bas-Sassandra" },
      { label: "Denguele", value: "Denguele" },
      { label: "Haut-Sassandra", value: "Haut-Sassandra" },
      { label: "Lacs", value: "Lacs" },
      { label: "Lagunes", value: "Lagunes" },
      { label: "Marahoue", value: "Marahoue" },
      { label: "Moyen-Comoe", value: "Moyen-Comoe" },
      { label: "Nzi-Comoe", value: "Nzi-Comoe" },
      { label: "Savanes", value: "Savanes" },
      { label: "Sud-Bandama", value: "Sud-Bandama" },
      { label: "Sud-Comoe", value: "Sud-Comoe" },
      { label: "Vallee du Bandama", value: "Vallee du Bandama" },
      { label: "Worodouqou", value: "Worodouqou" },
      { label: "Zanzan", value: "Zanzan" },
    ],
  },
  {
    label: "Chile",
    options: [
      {
        label: "Aisen del General Carlos Ibanez del Campo",
        value: "Aisen del General Carlos Ibanez del Campo",
      },
      { label: "Antofagasta", value: "Antofagasta" },
      { label: "Araucania", value: "Araucania" },
      { label: "Atacama", value: "Atacama" },
      { label: "Bio-Bio", value: "Bio-Bio" },
      { label: "Coquimbo", value: "Coquimbo" },
      {
        label: "Libertador General Bernardo O'Higgins",
        value: "Libertador General Bernardo O'Higgins",
      },
      { label: "Los Lagos", value: "Los Lagos" },
      { label: "Magallanes", value: "Magallanes" },
      { label: "Maule", value: "Maule" },
      {
        label: "Region Metropolitana de Santiago",
        value: "Region Metropolitana de Santiago",
      },
      { label: "Tarapaca", value: "Tarapaca" },
      { label: "Valparaiso", value: "Valparaiso" },
    ],
  },
  {
    label: "Cameroon",
    options: [
      { label: "Adamaoua", value: "Adamaoua" },
      { label: "East", value: "East" },
      { label: "Far North", value: "Far North" },
      { label: "South", value: "South" },
      { label: "South-West", value: "South-West" },
      { label: "West", value: "West" },
    ],
  },
  {
    label: "Haiti",
    options: [
      { label: "Centre", value: "Centre" },
      { label: "Nord", value: "Nord" },
      { label: "Grande-Anse", value: "Grande-Anse" },
      { label: "Nord-Est", value: "Nord-Est" },
      { label: "Nord-Ouest", value: "Nord-Ouest" },
      { label: "Ouest", value: "Ouest" },
      { label: "Sud", value: "Sud" },
      { label: "Sud-Est", value: "Sud-Est" },
      { label: "Artibonite", value: "Artibonite" },
      { label: "Nippes", value: "Nippes" },
    ],
  },
  {
    label: "China",
    options: [
      { label: "Beijing", value: "Beijing" },
      { label: "Chongqing", value: "Chongqing" },
      { label: "Shanghai", value: "Shanghai" },
      { label: "Tianjin", value: "Tianjin" },
      { label: "Anhui", value: "Anhui" },
      { label: "Fujian", value: "Fujian" },
      { label: "Gansu", value: "Gansu" },
      { label: "Guangdong", value: "Guangdong" },
      { label: "Guizhou", value: "Guizhou" },
      { label: "Hainan", value: "Hainan" },
      { label: "Hebei", value: "Hebei" },
      { label: "Heilongjiang", value: "Heilongjiang" },
      { label: "Henan", value: "Henan" },
      { label: "Hubei", value: "Hubei" },
      { label: "Hunan", value: "Hunan" },
      { label: "Jiangsu", value: "Jiangsu" },
      { label: "Jiangxi", value: "Jiangxi" },
      { label: "Jilin", value: "Jilin" },
      { label: "Liaoning", value: "Liaoning" },
      { label: "Qinghai", value: "Qinghai" },
      { label: "Shaanxi", value: "Shaanxi" },
      { label: "Shandong", value: "Shandong" },
      { label: "Shanxi", value: "Shanxi" },
      { label: "Sichuan", value: "Sichuan" },
      { label: "Taiwan", value: "Taiwan" },
      { label: "Yunnan", value: "Yunnan" },
      { label: "Zhejiang", value: "Zhejiang" },
      { label: "Guangxi", value: "Guangxi" },
      { label: "Neia Mongol (mn)", value: "Neia Mongol (mn)" },
      { label: "Xinjiang", value: "Xinjiang" },
      { label: "Xizang", value: "Xizang" },
      { label: "Hong Kong", value: "Hong Kong" },
      { label: "Macau", value: "Macau" },
    ],
  },
  {
    label: "Colombia",
    options: [
      {
        label: "Distrito Capital de Bogotá",
        value: "Distrito Capital de Bogotá",
      },
      { label: "Amazonea", value: "Amazonea" },
      { label: "Antioquia", value: "Antioquia" },
      { label: "Arauca", value: "Arauca" },
      { label: "Atlántico", value: "Atlántico" },
      { label: "Bolívar", value: "Bolívar" },
      { label: "Boyacá", value: "Boyacá" },
      { label: "Caldea", value: "Caldea" },
      { label: "Caquetá", value: "Caquetá" },
      { label: "Casanare", value: "Casanare" },
      { label: "Cauca", value: "Cauca" },
      { label: "Cesar", value: "Cesar" },
      { label: "Córdoba", value: "Córdoba" },
      { label: "Cundinamarca", value: "Cundinamarca" },
      { label: "Chocó", value: "Chocó" },
      { label: "Guainía", value: "Guainía" },
      { label: "Guaviare", value: "Guaviare" },
      { label: "La Guajira", value: "La Guajira" },
      { label: "Magdalena", value: "Magdalena" },
      { label: "Meta", value: "Meta" },
      { label: "Nariño", value: "Nariño" },
      { label: "Norte de Santander", value: "Norte de Santander" },
      { label: "Putumayo", value: "Putumayo" },
      { label: "Quindio", value: "Quindio" },
      { label: "Risaralda", value: "Risaralda" },
      {
        label: "San Andrés, Providencia y Santa Catalina",
        value: "San Andrés, Providencia y Santa Catalina",
      },
      { label: "Santander", value: "Santander" },
      { label: "Sucre", value: "Sucre" },
      { label: "Tolima", value: "Tolima" },
      { label: "Valle del Cauca", value: "Valle del Cauca" },
      { label: "Vaupés", value: "Vaupés" },
      { label: "Vichada", value: "Vichada" },
    ],
  },
  {
    label: "Costa Rica",
    options: [
      { label: "Alajuela", value: "Alajuela" },
      { label: "Cartago", value: "Cartago" },
      { label: "Guanacaste", value: "Guanacaste" },
      { label: "Heredia", value: "Heredia" },
      { label: "Limon", value: "Limon" },
      { label: "Puntarenas", value: "Puntarenas" },
      { label: "San Jose", value: "San Jose" },
    ],
  },
  {
    label: "Cuba",
    options: [
      { label: "Camagey", value: "Camagey" },
      { label: "Ciego de `vila", value: "Ciego de `vila" },
      { label: "Cienfuegos", value: "Cienfuegos" },
      { label: "Ciudad de La Habana", value: "Ciudad de La Habana" },
      { label: "Granma", value: "Granma" },
      { label: "Guantanamo", value: "Guantanamo" },
      { label: "Holquin", value: "Holquin" },
      { label: "La Habana", value: "La Habana" },
      { label: "Las Tunas", value: "Las Tunas" },
      { label: "Matanzas", value: "Matanzas" },
      { label: "Pinar del Rio", value: "Pinar del Rio" },
      { label: "Sancti Spiritus", value: "Sancti Spiritus" },
      { label: "Santiago de Cuba", value: "Santiago de Cuba" },
      { label: "Villa Clara", value: "Villa Clara" },
      { label: "Isla de la Juventud", value: "Isla de la Juventud" },
      { label: "Pinar del Roo", value: "Pinar del Roo" },
      { label: "Ciego de Avila", value: "Ciego de Avila" },
      { label: "Camagoey", value: "Camagoey" },
      { label: "Holgun", value: "Holgun" },
      { label: "Sancti Spritus", value: "Sancti Spritus" },
      {
        label: "Municipio Especial Isla de la Juventud",
        value: "Municipio Especial Isla de la Juventud",
      },
    ],
  },
  {
    label: "Cape Verde",
    options: [
      { label: "Boa Vista", value: "Boa Vista" },
      { label: "Brava", value: "Brava" },
      { label: "Calheta de Sao Miguel", value: "Calheta de Sao Miguel" },
      { label: "Fogo", value: "Fogo" },
      { label: "Maio", value: "Maio" },
      { label: "Mosteiros", value: "Mosteiros" },
      { label: "Paul", value: "Paul" },
      { label: "Porto Novo", value: "Porto Novo" },
      { label: "Praia", value: "Praia" },
      { label: "Ribeira Grande", value: "Ribeira Grande" },
      { label: "Sal", value: "Sal" },
      { label: "Sao Domingos", value: "Sao Domingos" },
      { label: "Sao Filipe", value: "Sao Filipe" },
      { label: "Sao Nicolau", value: "Sao Nicolau" },
      { label: "Sao Vicente", value: "Sao Vicente" },
      { label: "Tarrafal", value: "Tarrafal" },
    ],
  },
  {
    label: "Cyprus",
    options: [
      { label: "Ammochostos Magusa", value: "Ammochostos Magusa" },
      { label: "Keryneia", value: "Keryneia" },
      { label: "Larnaka", value: "Larnaka" },
      { label: "Lefkosia", value: "Lefkosia" },
      { label: "Lemesos", value: "Lemesos" },
      { label: "Pafos", value: "Pafos" },
    ],
  },
  {
    label: "Czech Republic",
    options: [
      { label: "Jihočeský kraj", value: "Jihočeský kraj" },
      { label: "Jihomoravský kraj", value: "Jihomoravský kraj" },
      { label: "Karlovarský kraj", value: "Karlovarský kraj" },
      { label: "Královéhradecký kraj", value: "Královéhradecký kraj" },
      { label: "Liberecký kraj", value: "Liberecký kraj" },
      { label: "Moravskoslezský kraj", value: "Moravskoslezský kraj" },
      { label: "Olomoucký kraj", value: "Olomoucký kraj" },
      { label: "Pardubický kraj", value: "Pardubický kraj" },
      { label: "Plzeňský kraj", value: "Plzeňský kraj" },
      { label: "Praha, hlavní město", value: "Praha, hlavní město" },
      { label: "Středočeský kraj", value: "Středočeský kraj" },
      { label: "Ústecký kraj", value: "Ústecký kraj" },
      { label: "Vysočina", value: "Vysočina" },
      { label: "Zlínský kraj", value: "Zlínský kraj" },
    ],
  },
  {
    label: "Deutschland",
    options: [
      { label: "Baden-Württemberg", value: "Baden-Württemberg" },
      { label: "Bayern", value: "Bayern" },
      { label: "Bremen", value: "Bremen" },
      { label: "Hamburg", value: "Hamburg" },
      { label: "Hessen", value: "Hessen" },
      { label: "Niedersachsen", value: "Niedersachsen" },
      { label: "Nordrhein-Westfalen", value: "Nordrhein-Westfalen" },
      { label: "Rheinland-Pfalz", value: "Rheinland-Pfalz" },
      { label: "Saarland", value: "Saarland" },
      { label: "Schleswig-Holstein", value: "Schleswig-Holstein" },
      { label: "Berlin", value: "Berlin" },
      { label: "Brandenburg", value: "Brandenburg" },
      { label: "Mecklenburg-Vorpommern", value: "Mecklenburg-Vorpommern" },
      { label: "Sachsen", value: "Sachsen" },
      { label: "Sachsen-Anhalt", value: "Sachsen-Anhalt" },
      { label: "Thueringen", value: "Thueringen" },
    ],
  },
  {
    label: "Djibouti",
    options: [
      { label: "Ali Sabiah", value: "Ali Sabiah" },
      { label: "Dikhil", value: "Dikhil" },
      { label: "Djibouti", value: "Djibouti" },
      { label: "Obock", value: "Obock" },
      { label: "Tadjoura", value: "Tadjoura" },
    ],
  },
  {
    label: "Denmark",
    options: [
      { label: "Frederiksberg", value: "Frederiksberg" },
      { label: "Copenhagen City", value: "Copenhagen City" },
      { label: "Copenhagen", value: "Copenhagen" },
      { label: "Frederiksborg", value: "Frederiksborg" },
      { label: "Roskilde", value: "Roskilde" },
      { label: "Vestsjælland", value: "Vestsjælland" },
      { label: "Storstrøm", value: "Storstrøm" },
      { label: "Bornholm", value: "Bornholm" },
      { label: "Fyn", value: "Fyn" },
      { label: "South Jutland", value: "South Jutland" },
      { label: "Ribe", value: "Ribe" },
      { label: "Vejle", value: "Vejle" },
      { label: "Ringkjøbing", value: "Ringkjøbing" },
      { label: "Århus", value: "Århus" },
      { label: "Viborg", value: "Viborg" },
      { label: "North Jutland", value: "North Jutland" },
    ],
  },
  {
    label: "Dominican Republic",
    options: [
      {
        label: "Distrito Nacional (Santo Domingo)",
        value: "Distrito Nacional (Santo Domingo)",
      },
      { label: "Azua", value: "Azua" },
      { label: "Bahoruco", value: "Bahoruco" },
      { label: "Barahona", value: "Barahona" },
      { label: "Dajabón", value: "Dajabón" },
      { label: "Duarte", value: "Duarte" },
      { label: "El Seybo [El Seibo]", value: "El Seybo [El Seibo]" },
      { label: "Espaillat", value: "Espaillat" },
      { label: "Hato Mayor", value: "Hato Mayor" },
      { label: "Independencia", value: "Independencia" },
      { label: "La Altagracia", value: "La Altagracia" },
      {
        label: "La Estrelleta [Elias Pina]",
        value: "La Estrelleta [Elias Pina]",
      },
      { label: "La Romana", value: "La Romana" },
      { label: "La Vega", value: "La Vega" },
      {
        label: "Maroia Trinidad Sánchez",
        value: "Maroia Trinidad Sánchez",
      },
      { label: "Monseñor Nouel", value: "Monseñor Nouel" },
      { label: "Monte Cristi", value: "Monte Cristi" },
      { label: "Monte Plata", value: "Monte Plata" },
      { label: "Pedernales", value: "Pedernales" },
      { label: "Peravia", value: "Peravia" },
      { label: "Puerto Plata", value: "Puerto Plata" },
      { label: "Salcedo", value: "Salcedo" },
      { label: "Samaná", value: "Samaná" },
      { label: "San Cristóbal", value: "San Cristóbal" },
      { label: "San Pedro de Macorís", value: "San Pedro de Macorís" },
      { label: "Sánchez Ramírez", value: "Sánchez Ramírez" },
      { label: "Santiago", value: "Santiago" },
      { label: "Santiago Rodríguez", value: "Santiago Rodríguez" },
      { label: "Valverde", value: "Valverde" },
    ],
  },
  {
    label: "Algeria",
    options: [
      { label: "Adrar", value: "Adrar" },
      { label: "Ain Defla", value: "Ain Defla" },
      { label: "Ain Tmouchent", value: "Ain Tmouchent" },
      { label: "Alger", value: "Alger" },
      { label: "Annaba", value: "Annaba" },
      { label: "Batna", value: "Batna" },
      { label: "Bechar", value: "Bechar" },
      { label: "Bejaia", value: "Bejaia" },
      { label: "Biskra", value: "Biskra" },
      { label: "Blida", value: "Blida" },
      { label: "Bordj Bou Arreridj", value: "Bordj Bou Arreridj" },
      { label: "Bouira", value: "Bouira" },
      { label: "Boumerdes", value: "Boumerdes" },
      { label: "Chlef", value: "Chlef" },
      { label: "Constantine", value: "Constantine" },
      { label: "Djelfa", value: "Djelfa" },
      { label: "El Bayadh", value: "El Bayadh" },
      { label: "El Oued", value: "El Oued" },
      { label: "El Tarf", value: "El Tarf" },
      { label: "Ghardaia", value: "Ghardaia" },
      { label: "Guelma", value: "Guelma" },
      { label: "Illizi", value: "Illizi" },
      { label: "Jijel", value: "Jijel" },
      { label: "Khenchela", value: "Khenchela" },
      { label: "Laghouat", value: "Laghouat" },
      { label: "Mascara", value: "Mascara" },
      { label: "Medea", value: "Medea" },
      { label: "Mila", value: "Mila" },
      { label: "Mostaganem", value: "Mostaganem" },
      { label: "Msila", value: "Msila" },
      { label: "Naama", value: "Naama" },
      { label: "Oran", value: "Oran" },
      { label: "Ouargla", value: "Ouargla" },
      { label: "Oum el Bouaghi", value: "Oum el Bouaghi" },
      { label: "Relizane", value: "Relizane" },
      { label: "Saida", value: "Saida" },
      { label: "Setif", value: "Setif" },
      { label: "Sidi Bel Abbes", value: "Sidi Bel Abbes" },
      { label: "Skikda", value: "Skikda" },
      { label: "Souk Ahras", value: "Souk Ahras" },
      { label: "Tamanghasset", value: "Tamanghasset" },
      { label: "Tebessa", value: "Tebessa" },
      { label: "Tiaret", value: "Tiaret" },
      { label: "Tindouf", value: "Tindouf" },
      { label: "Tipaza", value: "Tipaza" },
      { label: "Tissemsilt", value: "Tissemsilt" },
      { label: "Tizi Ouzou", value: "Tizi Ouzou" },
      { label: "Tlemcen", value: "Tlemcen" },
    ],
  },
  {
    label: "Ecuador",
    options: [
      { label: "Azuay", value: "Azuay" },
      { label: "Bolivar", value: "Bolivar" },
      { label: "Canar", value: "Canar" },
      { label: "Carchi", value: "Carchi" },
      { label: "Cotopaxi", value: "Cotopaxi" },
      { label: "Chimborazo", value: "Chimborazo" },
      { label: "El Oro", value: "El Oro" },
      { label: "Esmeraldas", value: "Esmeraldas" },
      { label: "Galapagos", value: "Galapagos" },
      { label: "Guayas", value: "Guayas" },
      { label: "Imbabura", value: "Imbabura" },
      { label: "Loja", value: "Loja" },
      { label: "Los Rios", value: "Los Rios" },
      { label: "Manabi", value: "Manabi" },
      { label: "Morona-Santiago", value: "Morona-Santiago" },
      { label: "Napo", value: "Napo" },
      { label: "Orellana", value: "Orellana" },
      { label: "Pastaza", value: "Pastaza" },
      { label: "Pichincha", value: "Pichincha" },
      { label: "Sucumbios", value: "Sucumbios" },
      { label: "Tungurahua", value: "Tungurahua" },
      { label: "Zamora-Chinchipe", value: "Zamora-Chinchipe" },
    ],
  },
  {
    label: "Estonia",
    options: [
      { label: "Harjumsa", value: "Harjumsa" },
      { label: "Hitumea", value: "Hitumea" },
      { label: "Ida-Virumsa", value: "Ida-Virumsa" },
      { label: "Jogevamsa", value: "Jogevamsa" },
      { label: "Jarvamsa", value: "Jarvamsa" },
      { label: "Lasnemsa", value: "Lasnemsa" },
      { label: "Laane-Virumaa", value: "Laane-Virumaa" },
      { label: "Polvamea", value: "Polvamea" },
      { label: "Parnumsa", value: "Parnumsa" },
      { label: "Raplamsa", value: "Raplamsa" },
      { label: "Saaremsa", value: "Saaremsa" },
      { label: "Tartumsa", value: "Tartumsa" },
      { label: "Valgamaa", value: "Valgamaa" },
      { label: "Viljandimsa", value: "Viljandimsa" },
      { label: "Vorumaa", value: "Vorumaa" },
    ],
  },
  {
    label: "Egypt",
    options: [
      { label: "Ad Daqahllyah", value: "Ad Daqahllyah" },
      { label: "Al Bahr al Ahmar", value: "Al Bahr al Ahmar" },
      { label: "Al Buhayrah", value: "Al Buhayrah" },
      { label: "Al Fayym", value: "Al Fayym" },
      { label: "Al Gharbiyah", value: "Al Gharbiyah" },
      { label: "Al Iskandarlyah", value: "Al Iskandarlyah" },
      { label: "Al Isma illyah", value: "Al Isma illyah" },
      { label: "Al Jizah", value: "Al Jizah" },
      { label: "Al Minuflyah", value: "Al Minuflyah" },
      { label: "Al Minya", value: "Al Minya" },
      { label: "Al Qahirah", value: "Al Qahirah" },
      { label: "Al Qalyublyah", value: "Al Qalyublyah" },
      { label: "Al Wadi al Jadid", value: "Al Wadi al Jadid" },
      { label: "Ash Sharqiyah", value: "Ash Sharqiyah" },
      { label: "As Suways", value: "As Suways" },
      { label: "Aswan", value: "Aswan" },
      { label: "Asyut", value: "Asyut" },
      { label: "Bani Suwayf", value: "Bani Suwayf" },
      { label: "Bur Sa'id", value: "Bur Sa'id" },
      { label: "Dumyat", value: "Dumyat" },
      { label: "Janub Sina'", value: "Janub Sina'" },
      { label: "Kafr ash Shaykh", value: "Kafr ash Shaykh" },
      { label: "Matruh", value: "Matruh" },
      { label: "Qina", value: "Qina" },
      { label: "Shamal Sina'", value: "Shamal Sina'" },
      { label: "Suhaj", value: "Suhaj" },
    ],
  },
  {
    label: "Eritrea",
    options: [
      { label: "Anseba", value: "Anseba" },
      { label: "Debub", value: "Debub" },
      {
        label: "Debubawi Keyih Bahri [Debub-Keih-Bahri]",
        value: "Debubawi Keyih Bahri [Debub-Keih-Bahri]",
      },
      { label: "Gash-Barka", value: "Gash-Barka" },
      { label: "Maakel [Maekel]", value: "Maakel [Maekel]" },
      {
        label: "Semenawi Keyih Bahri [Semien-Keih-Bahri]",
        value: "Semenawi Keyih Bahri [Semien-Keih-Bahri]",
      },
    ],
  },
  {
    label: "Spain",
    options: [
      { label: "Álava", value: "Álava" },
      { label: "Albacete", value: "Albacete" },
      { label: "Alicante", value: "Alicante" },
      { label: "Almería", value: "Almería" },
      { label: "Asturias", value: "Asturias" },
      { label: "Ávila", value: "Ávila" },
      { label: "Badajoz", value: "Badajoz" },
      { label: "Baleares", value: "Baleares" },
      { label: "Barcelona", value: "Barcelona" },
      { label: "Burgos", value: "Burgos" },
      { label: "Cáceres", value: "Cáceres" },
      { label: "Cádiz", value: "Cádiz" },
      { label: "Cantabria", value: "Cantabria" },
      { label: "Castellón", value: "Castellón" },
      { label: "Ciudad Real", value: "Ciudad Real" },
      { label: "Cuenca", value: "Cuenca" },
      { label: "Cordóba", value: "Cordóba" },
      { label: "Girona [Gerona]", value: "Girona [Gerona]" },
      { label: "Granada", value: "Granada" },
      { label: "Guadalajara", value: "Guadalajara" },
      { label: "Guipúzcoa", value: "Guipúzcoa" },
      { label: "Huelva", value: "Huelva" },
      { label: "Huesca", value: "Huesca" },
      { label: "Jaén", value: "Jaén" },
      { label: "La Coruña", value: "La Coruña" },
      { label: "La Rioja", value: "La Rioja" },
      { label: "Las Palmas", value: "Las Palmas" },
      { label: "León", value: "León" },
      { label: "Lleida [Lérida]", value: "Lleida [Lérida]" },
      { label: "Lugo", value: "Lugo" },
      { label: "Madrid", value: "Madrid" },
      { label: "Málaga", value: "Málaga" },
      { label: "Marbella", value: "Marbella" },
      { label: "Murcia", value: "Murcia" },
      { label: "Navarra", value: "Navarra" },
      { label: "Ourense", value: "Ourense" },
      { label: "Palencia", value: "Palencia" },
      { label: "Pontevedra", value: "Pontevedra" },
      { label: "Salamanca", value: "Salamanca" },
      { label: "Santa Cruz de Tenerife", value: "Santa Cruz de Tenerife" },
      { label: "Segovia", value: "Segovia" },
      { label: "Sevilla", value: "Sevilla" },
      { label: "Soria", value: "Soria" },
      { label: "Tarragona", value: "Tarragona" },
      { label: "Teruel", value: "Teruel" },
      { label: "Valencia", value: "Valencia" },
      { label: "Valladolid", value: "Valladolid" },
      { label: "Vizcaya", value: "Vizcaya" },
      { label: "Zamora", value: "Zamora" },
      { label: "Zaragoza", value: "Zaragoza" },
      { label: "Ceuta", value: "Ceuta" },
      { label: "Melilla", value: "Melilla" },
    ],
  },
  {
    label: "Ethiopia",
    options: [
      { label: "Addis Ababa", value: "Addis Ababa" },
      { label: "Dire Dawa", value: "Dire Dawa" },
      { label: "Afar", value: "Afar" },
      { label: "Amara", value: "Amara" },
      { label: "Benshangul-Gumaz", value: "Benshangul-Gumaz" },
      { label: "Gambela Peoples", value: "Gambela Peoples" },
      { label: "Harari People", value: "Harari People" },
      { label: "Oromia", value: "Oromia" },
      { label: "Somali", value: "Somali" },
      {
        label: "Southern Nations, Nationalities and Peoples",
        value: "Southern Nations, Nationalities and Peoples",
      },
      { label: "Tigrai", value: "Tigrai" },
    ],
  },
  {
    label: "Fiji",
    options: [
      { label: "Northern", value: "Northern" },
      { label: "Western", value: "Western" },
      { label: "Rotuma", value: "Rotuma" },
    ],
  },
  {
    label: "Micronesia, Federated States of",
    options: [
      { label: "Chuuk", value: "Chuuk" },
      { label: "Kosrae", value: "Kosrae" },
      { label: "Pohnpei", value: "Pohnpei" },
      { label: "Yap", value: "Yap" },
    ],
  },
  {
    label: "Ghana",
    options: [
      { label: "Ashanti", value: "Ashanti" },
      { label: "Brong-Ahafo", value: "Brong-Ahafo" },
      { label: "Greater Accra", value: "Greater Accra" },
      { label: "Upper East", value: "Upper East" },
      { label: "Upper West", value: "Upper West" },
      { label: "Volta", value: "Volta" },
    ],
  },
  {
    label: "United Kingdom",
    options: [
      { label: "Aberdeen City", value: "Aberdeen City" },
      { label: "Aberdeenshire", value: "Aberdeenshire" },
      { label: "Angus", value: "Angus" },
      { label: "Co Antrim", value: "Co Antrim" },
      { label: "Argyll and Bute", value: "Argyll and Bute" },
      { label: "Co Armagh", value: "Co Armagh" },
      { label: "Bedfordshire", value: "Bedfordshire" },
      { label: "Gwent", value: "Gwent" },
      { label: "Bristol, City of", value: "Bristol, City of" },
      { label: "Buckinghamshire", value: "Buckinghamshire" },
      { label: "Cambridgeshire", value: "Cambridgeshire" },
      { label: "Cheshire", value: "Cheshire" },
      { label: "Clackmannanshire", value: "Clackmannanshire" },
      { label: "Cornwall", value: "Cornwall" },
      { label: "Cumbria", value: "Cumbria" },
      { label: "Derbyshire", value: "Derbyshire" },
      { label: "Co Londonderry", value: "Co Londonderry" },
      { label: "Devon", value: "Devon" },
      { label: "Dorset", value: "Dorset" },
      { label: "Co Down", value: "Co Down" },
      { label: "Dumfries and Galloway", value: "Dumfries and Galloway" },
      { label: "Dundee City", value: "Dundee City" },
      { label: "County Durham", value: "County Durham" },
      { label: "East Ayrshire", value: "East Ayrshire" },
      { label: "East Dunbartonshire", value: "East Dunbartonshire" },
      { label: "East Lothian", value: "East Lothian" },
      { label: "East Renfrewshire", value: "East Renfrewshire" },
      {
        label: "East Riding of Yorkshire",
        value: "East Riding of Yorkshire",
      },
      { label: "East Sussex", value: "East Sussex" },
      { label: "Edinburgh, City of", value: "Edinburgh, City of" },
      { label: "Na h-Eileanan Siar", value: "Na h-Eileanan Siar" },
      { label: "Essex", value: "Essex" },
      { label: "Falkirk", value: "Falkirk" },
      { label: "Co Fermanagh", value: "Co Fermanagh" },
      { label: "Fife", value: "Fife" },
      { label: "Glasgow City", value: "Glasgow City" },
      { label: "Gloucestershire", value: "Gloucestershire" },
      { label: "Gwynedd", value: "Gwynedd" },
      { label: "Hampshire", value: "Hampshire" },
      { label: "Herefordshire", value: "Herefordshire" },
      { label: "Hertfordshire", value: "Hertfordshire" },
      { label: "Highland", value: "Highland" },
      { label: "Inverclyde", value: "Inverclyde" },
      { label: "Isle of Wight", value: "Isle of Wight" },
      { label: "Kent", value: "Kent" },
      { label: "Lancashire", value: "Lancashire" },
      { label: "Leicestershire", value: "Leicestershire" },
      { label: "Lincolnshire", value: "Lincolnshire" },
      { label: "Midlothian", value: "Midlothian" },
      { label: "Moray", value: "Moray" },
      { label: "Norfolk", value: "Norfolk" },
      { label: "North Ayrshire", value: "North Ayrshire" },
      { label: "North Lanarkshire", value: "North Lanarkshire" },
      { label: "North Yorkshire", value: "North Yorkshire" },
      { label: "Northamptonshire", value: "Northamptonshire" },
      { label: "Northumberland", value: "Northumberland" },
      { label: "Nottinghamshire", value: "Nottinghamshire" },
      { label: "Oldham", value: "Oldham" },
      { label: "Omagh", value: "Omagh" },
      { label: "Orkney Islands", value: "Orkney Islands" },
      { label: "Oxfordshire", value: "Oxfordshire" },
      { label: "Perth and Kinross", value: "Perth and Kinross" },
      { label: "Powys", value: "Powys" },
      { label: "Renfrewshire", value: "Renfrewshire" },
      { label: "Rutland", value: "Rutland" },
      { label: "Scottish Borders", value: "Scottish Borders" },
      { label: "Shetland Islands", value: "Shetland Islands" },
      { label: "Shropshire", value: "Shropshire" },
      { label: "Somerset", value: "Somerset" },
      { label: "South Ayrshire", value: "South Ayrshire" },
      { label: "South Gloucestershire", value: "South Gloucestershire" },
      { label: "South Lanarkshire", value: "South Lanarkshire" },
      { label: "Staffordshire", value: "Staffordshire" },
      { label: "Stirling", value: "Stirling" },
      { label: "Suffolk", value: "Suffolk" },
      { label: "Surrey", value: "Surrey" },
      { label: "Mid Glamorgan", value: "Mid Glamorgan" },
      { label: "Warwickshire", value: "Warwickshire" },
      { label: "West Dunbartonshire", value: "West Dunbartonshire" },
      { label: "West Lothian", value: "West Lothian" },
      { label: "West Sussex", value: "West Sussex" },
      { label: "Wiltshire", value: "Wiltshire" },
      { label: "Worcestershire", value: "Worcestershire" },
      { label: "Tyne and Wear", value: "Tyne and Wear" },
      { label: "Greater Manchester", value: "Greater Manchester" },
      { label: "Co Tyrone", value: "Co Tyrone" },
      { label: "West Yorkshire", value: "West Yorkshire" },
      { label: "South Yorkshire", value: "South Yorkshire" },
      { label: "Merseyside", value: "Merseyside" },
      { label: "Berkshire", value: "Berkshire" },
      { label: "West Midlands", value: "West Midlands" },
      { label: "West Glamorgan", value: "West Glamorgan" },
      { label: "Greater London", value: "Greater London" },
      { label: "Clwyd", value: "Clwyd" },
      { label: "Dyfed", value: "Dyfed" },
      { label: "South Glamorgan", value: "South Glamorgan" },
    ],
  },
  {
    label: "Gambia",
    options: [
      { label: "Banjul", value: "Banjul" },
      { label: "Lower River", value: "Lower River" },
      { label: "MacCarthy Island", value: "MacCarthy Island" },
      { label: "North Bank", value: "North Bank" },
      { label: "Upper River", value: "Upper River" },
    ],
  },
  {
    label: "Guinea",
    options: [
      { label: "Beyla", value: "Beyla" },
      { label: "Boffa", value: "Boffa" },
      { label: "Boke", value: "Boke" },
      { label: "Coyah", value: "Coyah" },
      { label: "Dabola", value: "Dabola" },
      { label: "Dalaba", value: "Dalaba" },
      { label: "Dinguiraye", value: "Dinguiraye" },
      { label: "Dubreka", value: "Dubreka" },
      { label: "Faranah", value: "Faranah" },
      { label: "Forecariah", value: "Forecariah" },
      { label: "Fria", value: "Fria" },
      { label: "Gaoual", value: "Gaoual" },
      { label: "Guekedou", value: "Guekedou" },
      { label: "Kankan", value: "Kankan" },
      { label: "Kerouane", value: "Kerouane" },
      { label: "Kindia", value: "Kindia" },
      { label: "Kissidougou", value: "Kissidougou" },
      { label: "Koubia", value: "Koubia" },
      { label: "Koundara", value: "Koundara" },
      { label: "Kouroussa", value: "Kouroussa" },
      { label: "Labe", value: "Labe" },
      { label: "Lelouma", value: "Lelouma" },
      { label: "Lola", value: "Lola" },
      { label: "Macenta", value: "Macenta" },
      { label: "Mali", value: "Mali" },
      { label: "Mamou", value: "Mamou" },
      { label: "Mandiana", value: "Mandiana" },
      { label: "Nzerekore", value: "Nzerekore" },
      { label: "Pita", value: "Pita" },
      { label: "Siguiri", value: "Siguiri" },
      { label: "Telimele", value: "Telimele" },
      { label: "Tougue", value: "Tougue" },
      { label: "Yomou", value: "Yomou" },
    ],
  },
  {
    label: "Equatorial Guinea",
    options: [
      { label: "Region Continental", value: "Region Continental" },
      { label: "Region Insular", value: "Region Insular" },
      { label: "Annobon", value: "Annobon" },
      { label: "Bioko Norte", value: "Bioko Norte" },
      { label: "Bioko Sur", value: "Bioko Sur" },
      { label: "Centro Sur", value: "Centro Sur" },
      { label: "Kie-Ntem", value: "Kie-Ntem" },
      { label: "Litoral", value: "Litoral" },
      { label: "Wele-Nzas", value: "Wele-Nzas" },
    ],
  },
  {
    label: "Greece",
    options: [
      { label: "Achaïa", value: "Achaïa" },
      { label: "Aitolia-Akarnania", value: "Aitolia-Akarnania" },
      { label: "Argolis", value: "Argolis" },
      { label: "Arkadia", value: "Arkadia" },
      { label: "Arta", value: "Arta" },
      { label: "Attiki", value: "Attiki" },
      { label: "Chalkidiki", value: "Chalkidiki" },
      { label: "Chania", value: "Chania" },
      { label: "Chios", value: "Chios" },
      { label: "Dodekanisos", value: "Dodekanisos" },
      { label: "Drama", value: "Drama" },
      { label: "Evros", value: "Evros" },
      { label: "Evrytania", value: "Evrytania" },
      { label: "Evvoia", value: "Evvoia" },
      { label: "Florina", value: "Florina" },
      { label: "Fokis", value: "Fokis" },
      { label: "Fthiotis", value: "Fthiotis" },
      { label: "Grevena", value: "Grevena" },
      { label: "Ileia", value: "Ileia" },
      { label: "Imathia", value: "Imathia" },
      { label: "Ioannina", value: "Ioannina" },
      { label: "Irakleion", value: "Irakleion" },
      { label: "Karditsa", value: "Karditsa" },
      { label: "Kastoria", value: "Kastoria" },
      { label: "Kavalla", value: "Kavalla" },
      { label: "Kefallinia", value: "Kefallinia" },
      { label: "Kerkyra", value: "Kerkyra" },
      { label: "Kilkis", value: "Kilkis" },
      { label: "Korinthia", value: "Korinthia" },
      { label: "Kozani", value: "Kozani" },
      { label: "Kyklades", value: "Kyklades" },
      { label: "Lakonia", value: "Lakonia" },
      { label: "Larisa", value: "Larisa" },
      { label: "Lasithion", value: "Lasithion" },
      { label: "Lefkas", value: "Lefkas" },
      { label: "Lesvos", value: "Lesvos" },
      { label: "Magnisia", value: "Magnisia" },
      { label: "Messinia", value: "Messinia" },
      { label: "Pella", value: "Pella" },
      { label: "Preveza", value: "Preveza" },
      { label: "Rethymnon", value: "Rethymnon" },
      { label: "Rodopi", value: "Rodopi" },
      { label: "Samos", value: "Samos" },
      { label: "Serrai", value: "Serrai" },
      { label: "Thesprotia", value: "Thesprotia" },
      { label: "Thessaloniki", value: "Thessaloniki" },
      { label: "Trikala", value: "Trikala" },
      { label: "Voiotia", value: "Voiotia" },
      { label: "Xanthi", value: "Xanthi" },
      { label: "Zakynthos", value: "Zakynthos" },
      { label: "Agio Oros", value: "Agio Oros" },
    ],
  },
  {
    label: "Guatemala",
    options: [
      { label: "Alta Verapez", value: "Alta Verapez" },
      { label: "Baja Verapez", value: "Baja Verapez" },
      { label: "Chimaltenango", value: "Chimaltenango" },
      { label: "Chiquimula", value: "Chiquimula" },
      { label: "El Progreso", value: "El Progreso" },
      { label: "Escuintla", value: "Escuintla" },
      { label: "Guatemala", value: "Guatemala" },
      { label: "Huehuetenango", value: "Huehuetenango" },
      { label: "Izabal", value: "Izabal" },
      { label: "Jalapa", value: "Jalapa" },
      { label: "Jutiapa", value: "Jutiapa" },
      { label: "Peten", value: "Peten" },
      { label: "Quetzaltenango", value: "Quetzaltenango" },
      { label: "Quiche", value: "Quiche" },
      { label: "Reta.thuleu", value: "Reta.thuleu" },
      { label: "Sacatepequez", value: "Sacatepequez" },
      { label: "San Marcos", value: "San Marcos" },
      { label: "Santa Rosa", value: "Santa Rosa" },
      { label: "Solol6", value: "Solol6" },
      { label: "Suchitepequez", value: "Suchitepequez" },
      { label: "Totonicapan", value: "Totonicapan" },
      { label: "Zacapa", value: "Zacapa" },
    ],
  },
  {
    label: "Guinea-Bissau",
    options: [
      { label: "Bissau", value: "Bissau" },
      { label: "Bafata", value: "Bafata" },
      { label: "Biombo", value: "Biombo" },
      { label: "Bolama", value: "Bolama" },
      { label: "Cacheu", value: "Cacheu" },
      { label: "Gabu", value: "Gabu" },
      { label: "Oio", value: "Oio" },
      { label: "Quloara", value: "Quloara" },
      { label: "Tombali S", value: "Tombali S" },
    ],
  },
  {
    label: "Guyana",
    options: [
      { label: "Barima-Waini", value: "Barima-Waini" },
      { label: "Cuyuni-Mazaruni", value: "Cuyuni-Mazaruni" },
      { label: "Demerara-Mahaica", value: "Demerara-Mahaica" },
      { label: "East Berbice-Corentyne", value: "East Berbice-Corentyne" },
      {
        label: "Essequibo Islands-West Demerara",
        value: "Essequibo Islands-West Demerara",
      },
      { label: "Mahaica-Berbice", value: "Mahaica-Berbice" },
      { label: "Pomeroon-Supenaam", value: "Pomeroon-Supenaam" },
      { label: "Potaro-Siparuni", value: "Potaro-Siparuni" },
      { label: "Upper Demerara-Berbice", value: "Upper Demerara-Berbice" },
      {
        label: "Upper Takutu-Upper Essequibo",
        value: "Upper Takutu-Upper Essequibo",
      },
    ],
  },
  {
    label: "Honduras",
    options: [
      { label: "Atlantida", value: "Atlantida" },
      { label: "Colon", value: "Colon" },
      { label: "Comayagua", value: "Comayagua" },
      { label: "Copan", value: "Copan" },
      { label: "Cortes", value: "Cortes" },
      { label: "Choluteca", value: "Choluteca" },
      { label: "El Paraiso", value: "El Paraiso" },
      { label: "Francisco Morazan", value: "Francisco Morazan" },
      { label: "Gracias a Dios", value: "Gracias a Dios" },
      { label: "Intibuca", value: "Intibuca" },
      { label: "Islas de la Bahia", value: "Islas de la Bahia" },
      { label: "Lempira", value: "Lempira" },
      { label: "Ocotepeque", value: "Ocotepeque" },
      { label: "Olancho", value: "Olancho" },
      { label: "Santa Barbara", value: "Santa Barbara" },
      { label: "Valle", value: "Valle" },
      { label: "Yoro", value: "Yoro" },
    ],
  },
  {
    label: "Croatia",
    options: [
      {
        label: "Bjelovarsko-bilogorska zupanija",
        value: "Bjelovarsko-bilogorska zupanija",
      },
      {
        label: "Brodsko-posavska zupanija",
        value: "Brodsko-posavska zupanija",
      },
      {
        label: "Dubrovacko-neretvanska zupanija",
        value: "Dubrovacko-neretvanska zupanija",
      },
      { label: "Istarska zupanija", value: "Istarska zupanija" },
      { label: "Karlovacka zupanija", value: "Karlovacka zupanija" },
      {
        label: "Koprivnickco-krizevacka zupanija",
        value: "Koprivnickco-krizevacka zupanija",
      },
      {
        label: "Krapinako-zagorska zupanija",
        value: "Krapinako-zagorska zupanija",
      },
      { label: "Licko-senjska zupanija", value: "Licko-senjska zupanija" },
      { label: "Medimurska zupanija", value: "Medimurska zupanija" },
      {
        label: "Osjecko-baranjska zupanija",
        value: "Osjecko-baranjska zupanija",
      },
      {
        label: "Pozesko-slavonska zupanija",
        value: "Pozesko-slavonska zupanija",
      },
      {
        label: "Primorsko-goranska zupanija",
        value: "Primorsko-goranska zupanija",
      },
      {
        label: "Sisacko-moelavacka Iupanija",
        value: "Sisacko-moelavacka Iupanija",
      },
      {
        label: "Splitako-dalmatinska zupanija",
        value: "Splitako-dalmatinska zupanija",
      },
      {
        label: "Sibenako-kninska zupanija",
        value: "Sibenako-kninska zupanija",
      },
      { label: "Varaidinska zupanija", value: "Varaidinska zupanija" },
      {
        label: "VirovitiEko-podravska zupanija",
        value: "VirovitiEko-podravska zupanija",
      },
      {
        label: "VuRovarako-srijemska zupanija",
        value: "VuRovarako-srijemska zupanija",
      },
      { label: "Zadaraka", value: "Zadaraka" },
      { label: "Zagrebacka zupanija", value: "Zagrebacka zupanija" },
    ],
  },
  {
    label: "Hungary",
    options: [
      { label: "Budapest", value: "Budapest" },
      { label: "Bács-Kiskun", value: "Bács-Kiskun" },
      { label: "Baranya", value: "Baranya" },
      { label: "Békés", value: "Békés" },
      { label: "Borsod-Abaúj-Zemplén", value: "Borsod-Abaúj-Zemplén" },
      { label: "Csongrád", value: "Csongrád" },
      { label: "Fejér", value: "Fejér" },
      { label: "Győr-Moson-Sopron", value: "Győr-Moson-Sopron" },
      { label: "Hajdu-Bihar", value: "Hajdu-Bihar" },
      { label: "Heves", value: "Heves" },
      { label: "Jász-Nagykun-Szolnok", value: "Jász-Nagykun-Szolnok" },
      { label: "Komárom-Esztergom", value: "Komárom-Esztergom" },
      { label: "Nográd", value: "Nográd" },
      { label: "Pest", value: "Pest" },
      { label: "Somogy", value: "Somogy" },
      { label: "Szabolcs-Szatmár-Bereg", value: "Szabolcs-Szatmár-Bereg" },
      { label: "Tolna", value: "Tolna" },
      { label: "Vas", value: "Vas" },
      { label: "Veszprém", value: "Veszprém" },
      { label: "Zala", value: "Zala" },
      { label: "Békéscsaba", value: "Békéscsaba" },
      { label: "Debrecen", value: "Debrecen" },
      { label: "Dunaújváros", value: "Dunaújváros" },
      { label: "Eger", value: "Eger" },
      { label: "Győr", value: "Győr" },
      { label: "Hódmezővásárhely", value: "Hódmezővásárhely" },
      { label: "Kaposvár", value: "Kaposvár" },
      { label: "Kecskemét", value: "Kecskemét" },
      { label: "Miskolc", value: "Miskolc" },
      { label: "Nagykanizsa", value: "Nagykanizsa" },
      { label: "Nyiregyháza", value: "Nyiregyháza" },
      { label: "Pécs", value: "Pécs" },
      { label: "Salgótarján", value: "Salgótarján" },
      { label: "Sopron", value: "Sopron" },
      { label: "Szeged", value: "Szeged" },
      { label: "Székesfehérvár", value: "Székesfehérvár" },
      { label: "Szekszárd", value: "Szekszárd" },
      { label: "Szolnok", value: "Szolnok" },
      { label: "Szombathely", value: "Szombathely" },
      { label: "Tatabánya", value: "Tatabánya" },
      { label: "Zalaegerszeg", value: "Zalaegerszeg" },
    ],
  },
  {
    label: "Indonesia",
    options: [
      { label: "Bali", value: "Bali" },
      { label: "Bangka Belitung", value: "Bangka Belitung" },
      { label: "Banten", value: "Banten" },
      { label: "Bengkulu", value: "Bengkulu" },
      { label: "Gorontalo", value: "Gorontalo" },
      { label: "Irian Jaya", value: "Irian Jaya" },
      { label: "Jambi", value: "Jambi" },
      { label: "Jawa Barat", value: "Jawa Barat" },
      { label: "Jawa Tengah", value: "Jawa Tengah" },
      { label: "Jawa Timur", value: "Jawa Timur" },
      { label: "Kalimantan Barat", value: "Kalimantan Barat" },
      { label: "Kalimantan Timur", value: "Kalimantan Timur" },
      { label: "Kalimantan Selatan", value: "Kalimantan Selatan" },
      { label: "Kepulauan Riau", value: "Kepulauan Riau" },
      { label: "Lampung", value: "Lampung" },
      { label: "Maluku", value: "Maluku" },
      { label: "Maluku Utara", value: "Maluku Utara" },
      { label: "Nusa Tenggara Barat", value: "Nusa Tenggara Barat" },
      { label: "Nusa Tenggara Timur", value: "Nusa Tenggara Timur" },
      { label: "Papua", value: "Papua" },
      { label: "Riau", value: "Riau" },
      { label: "Sulawesi Selatan", value: "Sulawesi Selatan" },
      { label: "Sulawesi Tengah", value: "Sulawesi Tengah" },
      { label: "Sulawesi Tenggara", value: "Sulawesi Tenggara" },
      { label: "Sulawesi Utara", value: "Sulawesi Utara" },
      { label: "Sumatra Barat", value: "Sumatra Barat" },
      { label: "Sumatra Selatan", value: "Sumatra Selatan" },
      { label: "Sumatera Utara", value: "Sumatera Utara" },
      { label: "Jakarta Raya", value: "Jakarta Raya" },
      { label: "Aceh", value: "Aceh" },
      { label: "Yogyakarta", value: "Yogyakarta" },
    ],
  },
  {
    label: "Ireland",
    options: [
      { label: "Cork", value: "Cork" },
      { label: "Clare", value: "Clare" },
      { label: "Cavan", value: "Cavan" },
      { label: "Carlow", value: "Carlow" },
      { label: "Dublin", value: "Dublin" },
      { label: "Donegal", value: "Donegal" },
      { label: "Galway", value: "Galway" },
      { label: "Kildare", value: "Kildare" },
      { label: "Kilkenny", value: "Kilkenny" },
      { label: "Kerry", value: "Kerry" },
      { label: "Longford", value: "Longford" },
      { label: "Louth", value: "Louth" },
      { label: "Limerick", value: "Limerick" },
      { label: "Leitrim", value: "Leitrim" },
      { label: "Laois", value: "Laois" },
      { label: "Meath", value: "Meath" },
      { label: "Monaghan", value: "Monaghan" },
      { label: "Mayo", value: "Mayo" },
      { label: "Offaly", value: "Offaly" },
      { label: "Roscommon", value: "Roscommon" },
      { label: "Sligo", value: "Sligo" },
      { label: "Tipperary", value: "Tipperary" },
      { label: "Waterford", value: "Waterford" },
      { label: "Westmeath", value: "Westmeath" },
      { label: "Wicklow", value: "Wicklow" },
      { label: "Wexford", value: "Wexford" },
    ],
  },
  {
    label: "Israel",
    options: [
      { label: "HaDarom", value: "HaDarom" },
      { label: "HaMerkaz", value: "HaMerkaz" },
      { label: "HaZafon", value: "HaZafon" },
      { label: "Haifa", value: "Haifa" },
      { label: "Tel-Aviv", value: "Tel-Aviv" },
    ],
  },
  {
    label: "Palestinian Territory, Occupied",
    options: [
      { label: "Jerusalem", value: "Jerusalem" },
      { label: "Gaza", value: "Gaza" },
      { label: "Jenin", value: "Jenin" },
      { label: "Tubas", value: "Tubas" },
      { label: "Tulkarm", value: "Tulkarm" },
      { label: "Nablus", value: "Nablus" },
      { label: "Qalqilya", value: "Qalqilya" },
      { label: "Salfit", value: "Salfit" },
      { label: "Ramallah and Al-Bireh", value: "Ramallah and Al-Bireh" },
      { label: "Jericho", value: "Jericho" },
      { label: "Bethlehem", value: "Bethlehem" },
      { label: "Hebron", value: "Hebron" },
      { label: "North Gaza", value: "North Gaza" },
      { label: "Deir el-Balah", value: "Deir el-Balah" },
      { label: "Khan Yunis", value: "Khan Yunis" },
      { label: "Rafah", value: "Rafah" },
    ],
  },
  {
    label: "Iraq",
    options: [
      { label: "Al Anbar", value: "Al Anbar" },
      { label: "Al Ba, rah", value: "Al Ba, rah" },
      { label: "Al Muthanna", value: "Al Muthanna" },
      { label: "Al Qadisiyah", value: "Al Qadisiyah" },
      { label: "An Najef", value: "An Najef" },
      { label: "Arbil", value: "Arbil" },
      { label: "As Sulaymaniyah", value: "As Sulaymaniyah" },
      { label: "At Ta'mim", value: "At Ta'mim" },
      { label: "Babil", value: "Babil" },
      { label: "Baghdad", value: "Baghdad" },
      { label: "Dahuk", value: "Dahuk" },
      { label: "Dhi Qar", value: "Dhi Qar" },
      { label: "Diyala", value: "Diyala" },
      { label: "Karbala'", value: "Karbala'" },
      { label: "Maysan", value: "Maysan" },
      { label: "Ninawa", value: "Ninawa" },
      { label: "Salah ad Din", value: "Salah ad Din" },
      { label: "Wasit", value: "Wasit" },
    ],
  },
  {
    label: "Iran, Islamic Republic of",
    options: [
      { label: "Ardabil", value: "Ardabil" },
      { label: "Azarbayjan-e Gharbi", value: "Azarbayjan-e Gharbi" },
      { label: "Azarbayjan-e Sharqi", value: "Azarbayjan-e Sharqi" },
      { label: "Bushehr", value: "Bushehr" },
      {
        label: "Chahar Mahall va Bakhtiari",
        value: "Chahar Mahall va Bakhtiari",
      },
      { label: "Esfahan", value: "Esfahan" },
      { label: "Fars", value: "Fars" },
      { label: "Gilan", value: "Gilan" },
      { label: "Golestan", value: "Golestan" },
      { label: "Hamadan", value: "Hamadan" },
      { label: "Hormozgan", value: "Hormozgan" },
      { label: "Iiam", value: "Iiam" },
      { label: "Kerman", value: "Kerman" },
      { label: "Kermanshah", value: "Kermanshah" },
      { label: "Khorasan", value: "Khorasan" },
      { label: "Khuzestan", value: "Khuzestan" },
      {
        label: "Kohjiluyeh va Buyer Ahmad",
        value: "Kohjiluyeh va Buyer Ahmad",
      },
      { label: "Kordestan", value: "Kordestan" },
      { label: "Lorestan", value: "Lorestan" },
      { label: "Markazi", value: "Markazi" },
      { label: "Mazandaran", value: "Mazandaran" },
      { label: "Qazvin", value: "Qazvin" },
      { label: "Qom", value: "Qom" },
      { label: "Semnan", value: "Semnan" },
      { label: "Sistan va Baluchestan", value: "Sistan va Baluchestan" },
      { label: "Tehran", value: "Tehran" },
      { label: "Yazd", value: "Yazd" },
      { label: "Zanjan", value: "Zanjan" },
    ],
  },
  {
    label: "Iceland",
    options: [
      { label: "Austurland", value: "Austurland" },
      {
        label: "Hofuoborgarsvaeoi utan Reykjavikur",
        value: "Hofuoborgarsvaeoi utan Reykjavikur",
      },
      { label: "Norourland eystra", value: "Norourland eystra" },
      { label: "Norourland vestra", value: "Norourland vestra" },
      { label: "Reykjavik", value: "Reykjavik" },
      { label: "Suourland", value: "Suourland" },
      { label: "Suournes", value: "Suournes" },
      { label: "Vestfirolr", value: "Vestfirolr" },
      { label: "Vesturland", value: "Vesturland" },
    ],
  },
  {
    label: "Italy",
    options: [
      { label: "Agrigento", value: "Agrigento" },
      { label: "Alessandria", value: "Alessandria" },
      { label: "Ancona", value: "Ancona" },
      { label: "Aosta", value: "Aosta" },
      { label: "Arezzo", value: "Arezzo" },
      { label: "Ascoli Piceno", value: "Ascoli Piceno" },
      { label: "Asti", value: "Asti" },
      { label: "Avellino", value: "Avellino" },
      { label: "Bari", value: "Bari" },
      { label: "Belluno", value: "Belluno" },
      { label: "Benevento", value: "Benevento" },
      { label: "Bergamo", value: "Bergamo" },
      { label: "Biella", value: "Biella" },
      { label: "Bologna", value: "Bologna" },
      { label: "Bolzano", value: "Bolzano" },
      { label: "Brescia", value: "Brescia" },
      { label: "Brindisi", value: "Brindisi" },
      { label: "Cagliari", value: "Cagliari" },
      { label: "Caltanissetta", value: "Caltanissetta" },
      { label: "Campobasso", value: "Campobasso" },
      { label: "Caserta", value: "Caserta" },
      { label: "Catania", value: "Catania" },
      { label: "Catanzaro", value: "Catanzaro" },
      { label: "Chieti", value: "Chieti" },
      { label: "Como", value: "Como" },
      { label: "Cosenza", value: "Cosenza" },
      { label: "Cremona", value: "Cremona" },
      { label: "Crotone", value: "Crotone" },
      { label: "Cuneo", value: "Cuneo" },
      { label: "Enna", value: "Enna" },
      { label: "Ferrara", value: "Ferrara" },
      { label: "Firenze", value: "Firenze" },
      { label: "Foggia", value: "Foggia" },
      { label: "Forlì-Cesena", value: "Forlì-Cesena" },
      { label: "Frosinone", value: "Frosinone" },
      { label: "Genova", value: "Genova" },
      { label: "Gorizia", value: "Gorizia" },
      { label: "Grosseto", value: "Grosseto" },
      { label: "Imperia", value: "Imperia" },
      { label: "Isernia", value: "Isernia" },
      { label: "L'Aquila", value: "L'Aquila" },
      { label: "La Spezia", value: "La Spezia" },
      { label: "Latina", value: "Latina" },
      { label: "Lecce", value: "Lecce" },
      { label: "Lecco", value: "Lecco" },
      { label: "Livorno", value: "Livorno" },
      { label: "Lodi", value: "Lodi" },
      { label: "Lucca", value: "Lucca" },
      { label: "Macerata", value: "Macerata" },
      { label: "Mantova", value: "Mantova" },
      { label: "Massa-Carrara", value: "Massa-Carrara" },
      { label: "Matera", value: "Matera" },
      { label: "Messina", value: "Messina" },
      { label: "Milano", value: "Milano" },
      { label: "Modena", value: "Modena" },
      { label: "Napoli", value: "Napoli" },
      { label: "Novara", value: "Novara" },
      { label: "Nuoro", value: "Nuoro" },
      { label: "Oristano", value: "Oristano" },
      { label: "Padova", value: "Padova" },
      { label: "Palermo", value: "Palermo" },
      { label: "Parma", value: "Parma" },
      { label: "Pavia", value: "Pavia" },
      { label: "Perugia", value: "Perugia" },
      { label: "Pesaro e Urbino", value: "Pesaro e Urbino" },
      { label: "Pescara", value: "Pescara" },
      { label: "Piacenza", value: "Piacenza" },
      { label: "Pisa", value: "Pisa" },
      { label: "Pistoia", value: "Pistoia" },
      { label: "Pordenone", value: "Pordenone" },
      { label: "Potenza", value: "Potenza" },
      { label: "Prato", value: "Prato" },
      { label: "Ragusa", value: "Ragusa" },
      { label: "Ravenna", value: "Ravenna" },
      { label: "Reggio Calabria", value: "Reggio Calabria" },
      { label: "Reggio Emilia", value: "Reggio Emilia" },
      { label: "Rieti", value: "Rieti" },
      { label: "Rimini", value: "Rimini" },
      { label: "Roma", value: "Roma" },
      { label: "Rovigo", value: "Rovigo" },
      { label: "Salerno", value: "Salerno" },
      { label: "Sassari", value: "Sassari" },
      { label: "Savona", value: "Savona" },
      { label: "Siena", value: "Siena" },
      { label: "Siracusa", value: "Siracusa" },
      { label: "Sondrio", value: "Sondrio" },
      { label: "Taranto", value: "Taranto" },
      { label: "Teramo", value: "Teramo" },
      { label: "Terni", value: "Terni" },
      { label: "Torino", value: "Torino" },
      { label: "Trapani", value: "Trapani" },
      { label: "Trento", value: "Trento" },
      { label: "Treviso", value: "Treviso" },
      { label: "Trieste", value: "Trieste" },
      { label: "Udine", value: "Udine" },
      { label: "Varese", value: "Varese" },
      { label: "Venezia", value: "Venezia" },
      { label: "Verbano-Cusio-Ossola", value: "Verbano-Cusio-Ossola" },
      { label: "Vercelli", value: "Vercelli" },
      { label: "Verona", value: "Verona" },
      { label: "Vibo Valentia", value: "Vibo Valentia" },
      { label: "Vicenza", value: "Vicenza" },
      { label: "Viterbo", value: "Viterbo" },
      { label: "Carbonia-Iglesias", value: "Carbonia-Iglesias" },
      { label: "Olbia-Tempio", value: "Olbia-Tempio" },
      { label: "Medio Campidano", value: "Medio Campidano" },
      { label: "Ogliastra", value: "Ogliastra" },
      { label: "Barletta-Andria-Trani", value: "Barletta-Andria-Trani" },
      { label: "Fermo", value: "Fermo" },
      { label: "Monza e Brianza", value: "Monza e Brianza" },
    ],
  },
  {
    label: "Japan",
    options: [
      { label: "Aichi", value: "Aichi" },
      { label: "Akita", value: "Akita" },
      { label: "Aomori", value: "Aomori" },
      { label: "Chiba", value: "Chiba" },
      { label: "Ehime", value: "Ehime" },
      { label: "Fukui", value: "Fukui" },
      { label: "Fukuoka", value: "Fukuoka" },
      { label: "Fukusima", value: "Fukusima" },
      { label: "Gifu", value: "Gifu" },
      { label: "Gunma", value: "Gunma" },
      { label: "Hiroshima", value: "Hiroshima" },
      { label: "Hokkaido", value: "Hokkaido" },
      { label: "Hyogo", value: "Hyogo" },
      { label: "Ibaraki", value: "Ibaraki" },
      { label: "Ishikawa", value: "Ishikawa" },
      { label: "Iwate", value: "Iwate" },
      { label: "Kagawa", value: "Kagawa" },
      { label: "Kagoshima", value: "Kagoshima" },
      { label: "Kanagawa", value: "Kanagawa" },
      { label: "Kochi", value: "Kochi" },
      { label: "Kumamoto", value: "Kumamoto" },
      { label: "Kyoto", value: "Kyoto" },
      { label: "Mie", value: "Mie" },
      { label: "Miyagi", value: "Miyagi" },
      { label: "Miyazaki", value: "Miyazaki" },
      { label: "Nagano", value: "Nagano" },
      { label: "Nagasaki", value: "Nagasaki" },
      { label: "Nara", value: "Nara" },
      { label: "Niigata", value: "Niigata" },
      { label: "Oita", value: "Oita" },
      { label: "Okayama", value: "Okayama" },
      { label: "Okinawa", value: "Okinawa" },
      { label: "Osaka", value: "Osaka" },
      { label: "Saga", value: "Saga" },
      { label: "Saitama", value: "Saitama" },
      { label: "Shiga", value: "Shiga" },
      { label: "Shimane", value: "Shimane" },
      { label: "Shizuoka", value: "Shizuoka" },
      { label: "Tochigi", value: "Tochigi" },
      { label: "Tokushima", value: "Tokushima" },
      { label: "Tokyo", value: "Tokyo" },
      { label: "Tottori", value: "Tottori" },
      { label: "Toyama", value: "Toyama" },
      { label: "Wakayama", value: "Wakayama" },
      { label: "Yamagata", value: "Yamagata" },
      { label: "Yamaguchi", value: "Yamaguchi" },
      { label: "Yamanashi", value: "Yamanashi" },
    ],
  },
  {
    label: "Jamaica",
    options: [
      { label: "Clarendon", value: "Clarendon" },
      { label: "Hanover", value: "Hanover" },
      { label: "Kingston", value: "Kingston" },
      { label: "Portland", value: "Portland" },
      { label: "Saint Andrew", value: "Saint Andrew" },
      { label: "Saint Ann", value: "Saint Ann" },
      { label: "Saint Catherine", value: "Saint Catherine" },
      { label: "Saint Elizabeth", value: "Saint Elizabeth" },
      { label: "Saint James", value: "Saint James" },
      { label: "Saint Mary", value: "Saint Mary" },
      { label: "Saint Thomas", value: "Saint Thomas" },
      { label: "Trelawny", value: "Trelawny" },
      { label: "Westmoreland", value: "Westmoreland" },
      { label: "Manchester", value: "Manchester" },
    ],
  },
  {
    label: "Jordan",
    options: [
      { label: "Ajln", value: "Ajln" },
      { label: "Al 'Aqaba", value: "Al 'Aqaba" },
      { label: "Al Balqa'", value: "Al Balqa'" },
      { label: "Al Karak", value: "Al Karak" },
      { label: "Al Mafraq", value: "Al Mafraq" },
      { label: "Amman", value: "Amman" },
      { label: "At Tafilah", value: "At Tafilah" },
      { label: "Az Zarga", value: "Az Zarga" },
      { label: "Irbid", value: "Irbid" },
      { label: "Jarash", value: "Jarash" },
      { label: "Ma'an", value: "Ma'an" },
      { label: "Madaba", value: "Madaba" },
    ],
  },
  {
    label: "Kenya",
    options: [
      { label: "Nairobi Municipality", value: "Nairobi Municipality" },
      { label: "Coast", value: "Coast" },
      {
        label: "North-Eastern Kaskazini Mashariki",
        value: "North-Eastern Kaskazini Mashariki",
      },
      { label: "Rift Valley", value: "Rift Valley" },
      { label: "Western Magharibi", value: "Western Magharibi" },
    ],
  },
  {
    label: "Kyrgyzstan",
    options: [
      { label: "Bishkek", value: "Bishkek" },
      { label: "Batken", value: "Batken" },
      { label: "Chu", value: "Chu" },
      { label: "Jalal-Abad", value: "Jalal-Abad" },
      { label: "Naryn", value: "Naryn" },
      { label: "Osh", value: "Osh" },
      { label: "Talas", value: "Talas" },
      { label: "Ysyk-Kol", value: "Ysyk-Kol" },
    ],
  },
  {
    label: "Cambodia",
    options: [
      { label: "Krong Kaeb", value: "Krong Kaeb" },
      { label: "Krong Pailin", value: "Krong Pailin" },
      { label: "Xrong Preah Sihanouk", value: "Xrong Preah Sihanouk" },
      { label: "Phnom Penh", value: "Phnom Penh" },
      { label: "Baat Dambang", value: "Baat Dambang" },
      { label: "Banteay Mean Chey", value: "Banteay Mean Chey" },
      { label: "Rampong Chaam", value: "Rampong Chaam" },
      { label: "Kampong Chhnang", value: "Kampong Chhnang" },
      { label: "Kampong Spueu", value: "Kampong Spueu" },
      { label: "Kampong Thum", value: "Kampong Thum" },
      { label: "Kampot", value: "Kampot" },
      { label: "Kandaal", value: "Kandaal" },
      { label: "Kach Kong", value: "Kach Kong" },
      { label: "Krachoh", value: "Krachoh" },
      { label: "Mondol Kiri", value: "Mondol Kiri" },
      { label: "Otdar Mean Chey", value: "Otdar Mean Chey" },
      { label: "Pousaat", value: "Pousaat" },
      { label: "Preah Vihear", value: "Preah Vihear" },
      { label: "Prey Veaeng", value: "Prey Veaeng" },
      { label: "Rotanak Kiri", value: "Rotanak Kiri" },
      { label: "Siem Reab", value: "Siem Reab" },
      { label: "Stueng Traeng", value: "Stueng Traeng" },
      { label: "Svaay Rieng", value: "Svaay Rieng" },
      { label: "Taakaev", value: "Taakaev" },
    ],
  },
  {
    label: "Kiribati",
    options: [
      { label: "Gilbert Islands", value: "Gilbert Islands" },
      { label: "Line Islands", value: "Line Islands" },
      { label: "Phoenix Islands", value: "Phoenix Islands" },
    ],
  },
  {
    label: "Comoros",
    options: [
      { label: "Anjouan Ndzouani", value: "Anjouan Ndzouani" },
      { label: "Grande Comore Ngazidja", value: "Grande Comore Ngazidja" },
      { label: "Moheli Moili", value: "Moheli Moili" },
    ],
  },
  {
    label: "Korea, Democratic People's Republic of",
    options: [
      { label: "Kaesong-si", value: "Kaesong-si" },
      { label: "Nampo-si", value: "Nampo-si" },
      { label: "Pyongyang-ai", value: "Pyongyang-ai" },
      { label: "Chagang-do", value: "Chagang-do" },
      { label: "Hamgyongbuk-do", value: "Hamgyongbuk-do" },
      { label: "Hamgyongnam-do", value: "Hamgyongnam-do" },
      { label: "Hwanghaebuk-do", value: "Hwanghaebuk-do" },
      { label: "Hwanghaenam-do", value: "Hwanghaenam-do" },
      { label: "Kangwon-do", value: "Kangwon-do" },
      { label: "Pyonganbuk-do", value: "Pyonganbuk-do" },
      { label: "Pyongannam-do", value: "Pyongannam-do" },
      { label: "Yanggang-do", value: "Yanggang-do" },
      { label: "Najin Sonbong-si", value: "Najin Sonbong-si" },
    ],
  },
  {
    label: "Korea, Republic of",
    options: [
      { label: "Seoul Teugbyeolsi", value: "Seoul Teugbyeolsi" },
      { label: "Busan Gwang'yeogsi", value: "Busan Gwang'yeogsi" },
      { label: "Daegu Gwang'yeogsi", value: "Daegu Gwang'yeogsi" },
      { label: "Daejeon Gwang'yeogsi", value: "Daejeon Gwang'yeogsi" },
      { label: "Gwangju Gwang'yeogsi", value: "Gwangju Gwang'yeogsi" },
      { label: "Incheon Gwang'yeogsi", value: "Incheon Gwang'yeogsi" },
      { label: "Ulsan Gwang'yeogsi", value: "Ulsan Gwang'yeogsi" },
      { label: "Chungcheongbugdo", value: "Chungcheongbugdo" },
      { label: "Chungcheongnamdo", value: "Chungcheongnamdo" },
      { label: "Gang'weondo", value: "Gang'weondo" },
      { label: "Gyeonggido", value: "Gyeonggido" },
      { label: "Gyeongsangbugdo", value: "Gyeongsangbugdo" },
      { label: "Gyeongsangnamdo", value: "Gyeongsangnamdo" },
      { label: "Jejudo", value: "Jejudo" },
      { label: "Jeonrabugdo", value: "Jeonrabugdo" },
      { label: "Jeonranamdo", value: "Jeonranamdo" },
    ],
  },
  {
    label: "Kuwait",
    options: [
      { label: "Al Ahmadi", value: "Al Ahmadi" },
      { label: "Al Farwanlyah", value: "Al Farwanlyah" },
      { label: "Al Jahrah", value: "Al Jahrah" },
      { label: "Al Kuwayt", value: "Al Kuwayt" },
      { label: "Hawalli", value: "Hawalli" },
    ],
  },
  {
    label: "Kazakhstan",
    options: [
      { label: "Almaty", value: "Almaty" },
      { label: "Astana", value: "Astana" },
      { label: "Almaty oblysy", value: "Almaty oblysy" },
      { label: "Aqmola oblysy", value: "Aqmola oblysy" },
      { label: "Aqtobe oblysy", value: "Aqtobe oblysy" },
      { label: "Atyrau oblyfiy", value: "Atyrau oblyfiy" },
      { label: "Batys Quzaqstan oblysy", value: "Batys Quzaqstan oblysy" },
      { label: "Mangghystau oblysy", value: "Mangghystau oblysy" },
      {
        label: "Ongtustik Quzaqstan oblysy",
        value: "Ongtustik Quzaqstan oblysy",
      },
      { label: "Pavlodar oblysy", value: "Pavlodar oblysy" },
      { label: "Qaraghandy oblysy", value: "Qaraghandy oblysy" },
      { label: "Qostanay oblysy", value: "Qostanay oblysy" },
      { label: "Qyzylorda oblysy", value: "Qyzylorda oblysy" },
      {
        label: "Shyghys Quzaqstan oblysy",
        value: "Shyghys Quzaqstan oblysy",
      },
      {
        label: "Soltustik Quzaqstan oblysy",
        value: "Soltustik Quzaqstan oblysy",
      },
      {
        label: "Zhambyl oblysy Zhambylskaya oblast'",
        value: "Zhambyl oblysy Zhambylskaya oblast'",
      },
    ],
  },
  {
    label: "Lao People's Democratic Republic",
    options: [
      { label: "Vientiane", value: "Vientiane" },
      { label: "Attapu", value: "Attapu" },
      { label: "Bokeo", value: "Bokeo" },
      { label: "Bolikhamxai", value: "Bolikhamxai" },
      { label: "Champasak", value: "Champasak" },
      { label: "Houaphan", value: "Houaphan" },
      { label: "Khammouan", value: "Khammouan" },
      { label: "Louang Namtha", value: "Louang Namtha" },
      { label: "Louangphabang", value: "Louangphabang" },
      { label: "Oudomxai", value: "Oudomxai" },
      { label: "Phongsali", value: "Phongsali" },
      { label: "Salavan", value: "Salavan" },
      { label: "Savannakhet", value: "Savannakhet" },
      { label: "Xaignabouli", value: "Xaignabouli" },
      { label: "Xiasomboun", value: "Xiasomboun" },
      { label: "Xekong", value: "Xekong" },
      { label: "Xiangkhoang", value: "Xiangkhoang" },
    ],
  },
  {
    label: "Lebanon",
    options: [
      { label: "Beirout", value: "Beirout" },
      { label: "El Begsa", value: "El Begsa" },
      { label: "Jabal Loubnane", value: "Jabal Loubnane" },
      { label: "Loubnane ech Chemali", value: "Loubnane ech Chemali" },
      { label: "Loubnane ej Jnoubi", value: "Loubnane ej Jnoubi" },
      { label: "Nabatiye", value: "Nabatiye" },
    ],
  },
  {
    label: "Sri Lanka",
    options: [
      { label: "Ampara", value: "Ampara" },
      { label: "Anuradhapura", value: "Anuradhapura" },
      { label: "Badulla", value: "Badulla" },
      { label: "Batticaloa", value: "Batticaloa" },
      { label: "Colombo", value: "Colombo" },
      { label: "Galle", value: "Galle" },
      { label: "Gampaha", value: "Gampaha" },
      { label: "Hambantota", value: "Hambantota" },
      { label: "Jaffna", value: "Jaffna" },
      { label: "Kalutara", value: "Kalutara" },
      { label: "Kandy", value: "Kandy" },
      { label: "Kegalla", value: "Kegalla" },
      { label: "Kilinochchi", value: "Kilinochchi" },
      { label: "Kurunegala", value: "Kurunegala" },
      { label: "Mannar", value: "Mannar" },
      { label: "Matale", value: "Matale" },
      { label: "Matara", value: "Matara" },
      { label: "Monaragala", value: "Monaragala" },
      { label: "Mullaittivu", value: "Mullaittivu" },
      { label: "Nuwara Eliya", value: "Nuwara Eliya" },
      { label: "Polonnaruwa", value: "Polonnaruwa" },
      { label: "Puttalum", value: "Puttalum" },
      { label: "Ratnapura", value: "Ratnapura" },
      { label: "Trincomalee", value: "Trincomalee" },
      { label: "VavunLya", value: "VavunLya" },
    ],
  },
  {
    label: "Lesotho",
    options: [
      { label: "Berea", value: "Berea" },
      { label: "Butha-Buthe", value: "Butha-Buthe" },
      { label: "Leribe", value: "Leribe" },
      { label: "Mafeteng", value: "Mafeteng" },
      { label: "Maseru", value: "Maseru" },
      { label: "Mohale's Hoek", value: "Mohale's Hoek" },
      { label: "Mokhotlong", value: "Mokhotlong" },
      { label: "Qacha's Nek", value: "Qacha's Nek" },
      { label: "Quthing", value: "Quthing" },
      { label: "Thaba-Tseka", value: "Thaba-Tseka" },
    ],
  },
  {
    label: "Lithuania",
    options: [
      { label: "Alytaus Apskritis", value: "Alytaus Apskritis" },
      { label: "Kauno Apskritis", value: "Kauno Apskritis" },
      { label: "Klaipedos Apskritis", value: "Klaipedos Apskritis" },
      { label: "Marijampoles Apskritis", value: "Marijampoles Apskritis" },
      { label: "Panevezio Apskritis", value: "Panevezio Apskritis" },
      { label: "Sisuliu Apskritis", value: "Sisuliu Apskritis" },
      { label: "Taurages Apskritis", value: "Taurages Apskritis" },
      { label: "Telsiu Apskritis", value: "Telsiu Apskritis" },
      { label: "Utenos Apskritis", value: "Utenos Apskritis" },
      { label: "Vilniaus Apskritis", value: "Vilniaus Apskritis" },
    ],
  },
  {
    label: "Luxembourg",
    options: [
      { label: "Diekirch", value: "Diekirch" },
      { label: "GreveNmacher", value: "GreveNmacher" },
    ],
  },
  {
    label: "Latvia",
    options: [
      { label: "Aizkraukles Apripkis", value: "Aizkraukles Apripkis" },
      { label: "Alkanes Apripkis", value: "Alkanes Apripkis" },
      { label: "Balvu Apripkis", value: "Balvu Apripkis" },
      { label: "Bauskas Apripkis", value: "Bauskas Apripkis" },
      { label: "Cesu Aprikis", value: "Cesu Aprikis" },
      { label: "Daugavpile Apripkis", value: "Daugavpile Apripkis" },
      { label: "Dobeles Apripkis", value: "Dobeles Apripkis" },
      { label: "Gulbenes Aprlpkis", value: "Gulbenes Aprlpkis" },
      { label: "Jelgavas Apripkis", value: "Jelgavas Apripkis" },
      { label: "Jekabpils Apripkis", value: "Jekabpils Apripkis" },
      { label: "Kraslavas Apripkis", value: "Kraslavas Apripkis" },
      { label: "Kuldlgas Apripkis", value: "Kuldlgas Apripkis" },
      { label: "Limbazu Apripkis", value: "Limbazu Apripkis" },
      { label: "Liepajas Apripkis", value: "Liepajas Apripkis" },
      { label: "Ludzas Apripkis", value: "Ludzas Apripkis" },
      { label: "Madonas Apripkis", value: "Madonas Apripkis" },
      { label: "Ogres Apripkis", value: "Ogres Apripkis" },
      { label: "Preilu Apripkis", value: "Preilu Apripkis" },
      { label: "Rezaknes Apripkis", value: "Rezaknes Apripkis" },
      { label: "Rigas Apripkis", value: "Rigas Apripkis" },
      { label: "Saldus Apripkis", value: "Saldus Apripkis" },
      { label: "Talsu Apripkis", value: "Talsu Apripkis" },
      { label: "Tukuma Apriplcis", value: "Tukuma Apriplcis" },
      { label: "Valkas Apripkis", value: "Valkas Apripkis" },
      { label: "Valmieras Apripkis", value: "Valmieras Apripkis" },
      { label: "Ventspils Apripkis", value: "Ventspils Apripkis" },
      { label: "Daugavpils", value: "Daugavpils" },
      { label: "Jelgava", value: "Jelgava" },
      { label: "Jurmala", value: "Jurmala" },
      { label: "Liepaja", value: "Liepaja" },
      { label: "Rezekne", value: "Rezekne" },
      { label: "Riga", value: "Riga" },
      { label: "Ventspils", value: "Ventspils" },
    ],
  },
  {
    label: "Libyan Arab Jamahiriya",
    options: [
      { label: "Ajdābiyā", value: "Ajdābiyā" },
      { label: "Al Buţnān", value: "Al Buţnān" },
      { label: "Al Hizām al Akhdar", value: "Al Hizām al Akhdar" },
      { label: "Al Jabal al Akhdar", value: "Al Jabal al Akhdar" },
      { label: "Al Jifārah", value: "Al Jifārah" },
      { label: "Al Jufrah", value: "Al Jufrah" },
      { label: "Al Kufrah", value: "Al Kufrah" },
      { label: "Al Marj", value: "Al Marj" },
      { label: "Al Marqab", value: "Al Marqab" },
      { label: "Al Qaţrūn", value: "Al Qaţrūn" },
      { label: "Al Qubbah", value: "Al Qubbah" },
      { label: "Al Wāhah", value: "Al Wāhah" },
      { label: "An Nuqaţ al Khams", value: "An Nuqaţ al Khams" },
      { label: "Ash Shāţi'", value: "Ash Shāţi'" },
      { label: "Az Zāwiyah", value: "Az Zāwiyah" },
      { label: "Banghāzī", value: "Banghāzī" },
      { label: "Banī Walīd", value: "Banī Walīd" },
      { label: "Darnah", value: "Darnah" },
      { label: "Ghadāmis", value: "Ghadāmis" },
      { label: "Gharyān", value: "Gharyān" },
      { label: "Ghāt", value: "Ghāt" },
      { label: "Jaghbūb", value: "Jaghbūb" },
      { label: "Mişrātah", value: "Mişrātah" },
      { label: "Mizdah", value: "Mizdah" },
      { label: "Murzuq", value: "Murzuq" },
      { label: "Nālūt", value: "Nālūt" },
      { label: "Sabhā", value: "Sabhā" },
      { label: "Şabrātah Şurmān", value: "Şabrātah Şurmān" },
      { label: "Surt", value: "Surt" },
      {
        label: "Tājūrā' wa an Nawāhī al Arbāh",
        value: "Tājūrā' wa an Nawāhī al Arbāh",
      },
      { label: "Ţarābulus", value: "Ţarābulus" },
      { label: "Tarhūnah-Masallātah", value: "Tarhūnah-Masallātah" },
      { label: "Wādī al hayāt", value: "Wādī al hayāt" },
      { label: "Yafran-Jādū", value: "Yafran-Jādū" },
    ],
  },
  {
    label: "Morocco",
    options: [
      { label: "Agadir", value: "Agadir" },
      { label: "Aït Baha", value: "Aït Baha" },
      { label: "Aït Melloul", value: "Aït Melloul" },
      { label: "Al Haouz", value: "Al Haouz" },
      { label: "Al Hoceïma", value: "Al Hoceïma" },
      { label: "Assa-Zag", value: "Assa-Zag" },
      { label: "Azilal", value: "Azilal" },
      { label: "Beni Mellal", value: "Beni Mellal" },
      { label: "Ben Sllmane", value: "Ben Sllmane" },
      { label: "Berkane", value: "Berkane" },
      { label: "Boujdour", value: "Boujdour" },
      { label: "Boulemane", value: "Boulemane" },
      {
        label: "Casablanca  [Dar el Beïda]",
        value: "Casablanca  [Dar el Beïda]",
      },
      { label: "Chefchaouene", value: "Chefchaouene" },
      { label: "Chichaoua", value: "Chichaoua" },
      { label: "El Hajeb", value: "El Hajeb" },
      { label: "El Jadida", value: "El Jadida" },
      { label: "Errachidia", value: "Errachidia" },
      { label: "Essaouira", value: "Essaouira" },
      { label: "Es Smara", value: "Es Smara" },
      { label: "Fès", value: "Fès" },
      { label: "Figuig", value: "Figuig" },
      { label: "Guelmim", value: "Guelmim" },
      { label: "Ifrane", value: "Ifrane" },
      { label: "Jerada", value: "Jerada" },
      { label: "Kelaat Sraghna", value: "Kelaat Sraghna" },
      { label: "Kénitra", value: "Kénitra" },
      { label: "Khemisaet", value: "Khemisaet" },
      { label: "Khenifra", value: "Khenifra" },
      { label: "Khouribga", value: "Khouribga" },
      { label: "Laâyoune (EH)", value: "Laâyoune (EH)" },
      { label: "Larache", value: "Larache" },
      { label: "Marrakech", value: "Marrakech" },
      { label: "Meknsès", value: "Meknsès" },
      { label: "Nador", value: "Nador" },
      { label: "Ouarzazate", value: "Ouarzazate" },
      { label: "Oued ed Dahab (EH)", value: "Oued ed Dahab (EH)" },
      { label: "Oujda", value: "Oujda" },
      { label: "Rabat-Salé", value: "Rabat-Salé" },
      { label: "Safi", value: "Safi" },
      { label: "Sefrou", value: "Sefrou" },
      { label: "Settat", value: "Settat" },
      { label: "Sidl Kacem", value: "Sidl Kacem" },
      { label: "Tanger", value: "Tanger" },
      { label: "Tan-Tan", value: "Tan-Tan" },
      { label: "Taounate", value: "Taounate" },
      { label: "Taroudannt", value: "Taroudannt" },
      { label: "Tata", value: "Tata" },
      { label: "Taza", value: "Taza" },
      { label: "Tétouan", value: "Tétouan" },
      { label: "Tiznit", value: "Tiznit" },
    ],
  },
  {
    label: "Moldova",
    options: [
      {
        label: "Gagauzia, Unitate Teritoriala Autonoma",
        value: "Gagauzia, Unitate Teritoriala Autonoma",
      },
      { label: "Chisinau", value: "Chisinau" },
      {
        label: "Stinga Nistrului, unitatea teritoriala din",
        value: "Stinga Nistrului, unitatea teritoriala din",
      },
      { label: "Balti", value: "Balti" },
      { label: "Cahul", value: "Cahul" },
      { label: "Edinet", value: "Edinet" },
      { label: "Lapusna", value: "Lapusna" },
      { label: "Orhei", value: "Orhei" },
      { label: "Soroca", value: "Soroca" },
      { label: "Taraclia", value: "Taraclia" },
      { label: "Tighina [Bender]", value: "Tighina [Bender]" },
      { label: "Ungheni", value: "Ungheni" },
    ],
  },
  {
    label: "Madagascar",
    options: [
      { label: "Antananarivo", value: "Antananarivo" },
      { label: "Antsiranana", value: "Antsiranana" },
      { label: "Fianarantsoa", value: "Fianarantsoa" },
      { label: "Mahajanga", value: "Mahajanga" },
      { label: "Toamasina", value: "Toamasina" },
      { label: "Toliara", value: "Toliara" },
    ],
  },
  {
    label: "Marshall Islands",
    options: [
      { label: "Ailinglapalap", value: "Ailinglapalap" },
      { label: "Ailuk", value: "Ailuk" },
      { label: "Arno", value: "Arno" },
      { label: "Aur", value: "Aur" },
      { label: "Ebon", value: "Ebon" },
      { label: "Eniwetok", value: "Eniwetok" },
      { label: "Jaluit", value: "Jaluit" },
      { label: "Kili", value: "Kili" },
      { label: "Kwajalein", value: "Kwajalein" },
      { label: "Lae", value: "Lae" },
      { label: "Lib", value: "Lib" },
      { label: "Likiep", value: "Likiep" },
      { label: "Majuro", value: "Majuro" },
      { label: "Maloelap", value: "Maloelap" },
      { label: "Mejit", value: "Mejit" },
      { label: "Mili", value: "Mili" },
      { label: "Namorik", value: "Namorik" },
      { label: "Namu", value: "Namu" },
      { label: "Rongelap", value: "Rongelap" },
      { label: "Ujae", value: "Ujae" },
      { label: "Ujelang", value: "Ujelang" },
      { label: "Utirik", value: "Utirik" },
      { label: "Wotho", value: "Wotho" },
      { label: "Wotje", value: "Wotje" },
    ],
  },
  {
    label: "Mali",
    options: [
      { label: "Bamako", value: "Bamako" },
      { label: "Gao", value: "Gao" },
      { label: "Kayes", value: "Kayes" },
      { label: "Kidal", value: "Kidal" },
      { label: "Xoulikoro", value: "Xoulikoro" },
      { label: "Mopti", value: "Mopti" },
      { label: "S69ou", value: "S69ou" },
      { label: "Sikasso", value: "Sikasso" },
      { label: "Tombouctou", value: "Tombouctou" },
    ],
  },
  {
    label: "Myanmar",
    options: [
      { label: "Ayeyarwady", value: "Ayeyarwady" },
      { label: "Bago", value: "Bago" },
      { label: "Magway", value: "Magway" },
      { label: "Mandalay", value: "Mandalay" },
      { label: "Sagaing", value: "Sagaing" },
      { label: "Tanintharyi", value: "Tanintharyi" },
      { label: "Yangon", value: "Yangon" },
      { label: "Chin", value: "Chin" },
      { label: "Kachin", value: "Kachin" },
      { label: "Kayah", value: "Kayah" },
      { label: "Kayin", value: "Kayin" },
      { label: "Mon", value: "Mon" },
      { label: "Rakhine", value: "Rakhine" },
      { label: "Shan", value: "Shan" },
    ],
  },
  {
    label: "Mongolia",
    options: [
      { label: "Ulaanbaatar", value: "Ulaanbaatar" },
      { label: "Arhangay", value: "Arhangay" },
      { label: "Bayanhongor", value: "Bayanhongor" },
      { label: "Bayan-Olgiy", value: "Bayan-Olgiy" },
      { label: "Bulgan", value: "Bulgan" },
      { label: "Darhan uul", value: "Darhan uul" },
      { label: "Dornod", value: "Dornod" },
      { label: "Dornogov", value: "Dornogov" },
      { label: "DundgovL", value: "DundgovL" },
      { label: "Dzavhan", value: "Dzavhan" },
      { label: "Govi-Altay", value: "Govi-Altay" },
      { label: "Govi-Smber", value: "Govi-Smber" },
      { label: "Hentiy", value: "Hentiy" },
      { label: "Hovd", value: "Hovd" },
      { label: "Hovsgol", value: "Hovsgol" },
      { label: "Omnogovi", value: "Omnogovi" },
      { label: "Orhon", value: "Orhon" },
      { label: "Ovorhangay", value: "Ovorhangay" },
      { label: "Selenge", value: "Selenge" },
      { label: "Shbaatar", value: "Shbaatar" },
      { label: "Tov", value: "Tov" },
      { label: "Uvs", value: "Uvs" },
    ],
  },
  {
    label: "Mauritania",
    options: [
      { label: "Nouakchott", value: "Nouakchott" },
      { label: "Assaba", value: "Assaba" },
      { label: "Brakna", value: "Brakna" },
      { label: "Dakhlet Nouadhibou", value: "Dakhlet Nouadhibou" },
      { label: "Gorgol", value: "Gorgol" },
      { label: "Guidimaka", value: "Guidimaka" },
      { label: "Hodh ech Chargui", value: "Hodh ech Chargui" },
      { label: "Hodh el Charbi", value: "Hodh el Charbi" },
      { label: "Inchiri", value: "Inchiri" },
      { label: "Tagant", value: "Tagant" },
      { label: "Tiris Zemmour", value: "Tiris Zemmour" },
      { label: "Trarza", value: "Trarza" },
    ],
  },
  {
    label: "Mauritius",
    options: [
      { label: "Beau Bassin-Rose Hill", value: "Beau Bassin-Rose Hill" },
      { label: "Curepipe", value: "Curepipe" },
      { label: "Port Louis", value: "Port Louis" },
      { label: "Quatre Bornes", value: "Quatre Bornes" },
      { label: "Vacosa-Phoenix", value: "Vacosa-Phoenix" },
      { label: "Black River", value: "Black River" },
      { label: "Flacq", value: "Flacq" },
      { label: "Grand Port", value: "Grand Port" },
      { label: "Moka", value: "Moka" },
      { label: "Pamplemousses", value: "Pamplemousses" },
      { label: "Plaines Wilhems", value: "Plaines Wilhems" },
      { label: "Riviere du Rempart", value: "Riviere du Rempart" },
      { label: "Savanne", value: "Savanne" },
      { label: "Agalega Islands", value: "Agalega Islands" },
      {
        label: "Cargados Carajos Shoals",
        value: "Cargados Carajos Shoals",
      },
      { label: "Rodrigues Island", value: "Rodrigues Island" },
    ],
  },
  {
    label: "Maldives",
    options: [
      { label: "Male", value: "Male" },
      { label: "Alif", value: "Alif" },
      { label: "Baa", value: "Baa" },
      { label: "Dhaalu", value: "Dhaalu" },
      { label: "Faafu", value: "Faafu" },
      { label: "Gaaf Alif", value: "Gaaf Alif" },
      { label: "Gaefu Dhaalu", value: "Gaefu Dhaalu" },
      { label: "Gnaviyani", value: "Gnaviyani" },
      { label: "Haa Alif", value: "Haa Alif" },
      { label: "Haa Dhaalu", value: "Haa Dhaalu" },
      { label: "Kaafu", value: "Kaafu" },
      { label: "Laamu", value: "Laamu" },
      { label: "Lhaviyani", value: "Lhaviyani" },
      { label: "Meemu", value: "Meemu" },
      { label: "Noonu", value: "Noonu" },
      { label: "Raa", value: "Raa" },
      { label: "Seenu", value: "Seenu" },
      { label: "Shaviyani", value: "Shaviyani" },
      { label: "Thaa", value: "Thaa" },
      { label: "Vaavu", value: "Vaavu" },
    ],
  },
  {
    label: "Malawi",
    options: [
      { label: "Balaka", value: "Balaka" },
      { label: "Blantyre", value: "Blantyre" },
      { label: "Chikwawa", value: "Chikwawa" },
      { label: "Chiradzulu", value: "Chiradzulu" },
      { label: "Chitipa", value: "Chitipa" },
      { label: "Dedza", value: "Dedza" },
      { label: "Dowa", value: "Dowa" },
      { label: "Karonga", value: "Karonga" },
      { label: "Kasungu", value: "Kasungu" },
      { label: "Likoma Island", value: "Likoma Island" },
      { label: "Lilongwe", value: "Lilongwe" },
      { label: "Machinga", value: "Machinga" },
      { label: "Mangochi", value: "Mangochi" },
      { label: "Mchinji", value: "Mchinji" },
      { label: "Mulanje", value: "Mulanje" },
      { label: "Mwanza", value: "Mwanza" },
      { label: "Mzimba", value: "Mzimba" },
      { label: "Nkhata Bay", value: "Nkhata Bay" },
      { label: "Nkhotakota", value: "Nkhotakota" },
      { label: "Nsanje", value: "Nsanje" },
      { label: "Ntcheu", value: "Ntcheu" },
      { label: "Ntchisi", value: "Ntchisi" },
      { label: "Phalomba", value: "Phalomba" },
      { label: "Rumphi", value: "Rumphi" },
      { label: "Salima", value: "Salima" },
      { label: "Thyolo", value: "Thyolo" },
      { label: "Zomba", value: "Zomba" },
    ],
  },
  {
    label: "Malaysia",
    options: [
      {
        label: "Wilayah Persekutuan Kuala Lumpur",
        value: "Wilayah Persekutuan Kuala Lumpur",
      },
      {
        label: "Wilayah Persekutuan Labuan",
        value: "Wilayah Persekutuan Labuan",
      },
      {
        label: "Wilayah Persekutuan Putrajaya",
        value: "Wilayah Persekutuan Putrajaya",
      },
      { label: "Johor", value: "Johor" },
      { label: "Kedah", value: "Kedah" },
      { label: "Kelantan", value: "Kelantan" },
      { label: "Melaka", value: "Melaka" },
      { label: "Negeri Sembilan", value: "Negeri Sembilan" },
      { label: "Pahang", value: "Pahang" },
      { label: "Perak", value: "Perak" },
      { label: "Perlis", value: "Perlis" },
      { label: "Pulau Pinang", value: "Pulau Pinang" },
      { label: "Sabah", value: "Sabah" },
      { label: "Sarawak", value: "Sarawak" },
      { label: "Selangor", value: "Selangor" },
      { label: "Terengganu", value: "Terengganu" },
    ],
  },
  {
    label: "Mozambique",
    options: [
      { label: "Maputo", value: "Maputo" },
      { label: "Cabo Delgado", value: "Cabo Delgado" },
      { label: "Inhambane", value: "Inhambane" },
      { label: "Manica", value: "Manica" },
      { label: "Numpula", value: "Numpula" },
      { label: "Niaaea", value: "Niaaea" },
      { label: "Sofala", value: "Sofala" },
      { label: "Tete", value: "Tete" },
      { label: "Zambezia", value: "Zambezia" },
    ],
  },
  {
    label: "Namibia",
    options: [
      { label: "Caprivi", value: "Caprivi" },
      { label: "Erongo", value: "Erongo" },
      { label: "Hardap", value: "Hardap" },
      { label: "Karas", value: "Karas" },
      { label: "Khomae", value: "Khomae" },
      { label: "Kunene", value: "Kunene" },
      { label: "Ohangwena", value: "Ohangwena" },
      { label: "Okavango", value: "Okavango" },
      { label: "Omaheke", value: "Omaheke" },
      { label: "Omusati", value: "Omusati" },
      { label: "Oshana", value: "Oshana" },
      { label: "Oshikoto", value: "Oshikoto" },
      { label: "Otjozondjupa", value: "Otjozondjupa" },
    ],
  },
  {
    label: "Niger",
    options: [
      { label: "Niamey", value: "Niamey" },
      { label: "Agadez", value: "Agadez" },
      { label: "Diffa", value: "Diffa" },
      { label: "Dosso", value: "Dosso" },
      { label: "Maradi", value: "Maradi" },
      { label: "Tahoua", value: "Tahoua" },
      { label: "Tillaberi", value: "Tillaberi" },
      { label: "Zinder", value: "Zinder" },
    ],
  },
  {
    label: "Nigeria",
    options: [
      {
        label: "Abuja Capital Territory",
        value: "Abuja Capital Territory",
      },
      { label: "Abia", value: "Abia" },
      { label: "Adamawa", value: "Adamawa" },
      { label: "Akwa Ibom", value: "Akwa Ibom" },
      { label: "Anambra", value: "Anambra" },
      { label: "Bauchi", value: "Bauchi" },
      { label: "Bayelsa", value: "Bayelsa" },
      { label: "Benue", value: "Benue" },
      { label: "Borno", value: "Borno" },
      { label: "Cross River", value: "Cross River" },
      { label: "Delta", value: "Delta" },
      { label: "Ebonyi", value: "Ebonyi" },
      { label: "Edo", value: "Edo" },
      { label: "Ekiti", value: "Ekiti" },
      { label: "Enugu", value: "Enugu" },
      { label: "Gombe", value: "Gombe" },
      { label: "Imo", value: "Imo" },
      { label: "Jigawa", value: "Jigawa" },
      { label: "Kaduna", value: "Kaduna" },
      { label: "Kano", value: "Kano" },
      { label: "Katsina", value: "Katsina" },
      { label: "Kebbi", value: "Kebbi" },
      { label: "Kogi", value: "Kogi" },
      { label: "Kwara", value: "Kwara" },
      { label: "Lagos", value: "Lagos" },
      { label: "Nassarawa", value: "Nassarawa" },
      { label: "Niger", value: "Niger" },
      { label: "Ogun", value: "Ogun" },
      { label: "Ondo", value: "Ondo" },
      { label: "Osun", value: "Osun" },
      { label: "Oyo", value: "Oyo" },
      { label: "Rivers", value: "Rivers" },
      { label: "Sokoto", value: "Sokoto" },
      { label: "Taraba", value: "Taraba" },
      { label: "Yobe", value: "Yobe" },
      { label: "Zamfara", value: "Zamfara" },
    ],
  },
  {
    label: "Nicaragua",
    options: [
      { label: "Boaco", value: "Boaco" },
      { label: "Carazo", value: "Carazo" },
      { label: "Chinandega", value: "Chinandega" },
      { label: "Chontales", value: "Chontales" },
      { label: "Esteli", value: "Esteli" },
      { label: "Jinotega", value: "Jinotega" },
      { label: "Leon", value: "Leon" },
      { label: "Madriz", value: "Madriz" },
      { label: "Managua", value: "Managua" },
      { label: "Masaya", value: "Masaya" },
      { label: "Matagalpa", value: "Matagalpa" },
      { label: "Nueva Segovia", value: "Nueva Segovia" },
      { label: "Rio San Juan", value: "Rio San Juan" },
      { label: "Rivas", value: "Rivas" },
      { label: "Atlantico Norte", value: "Atlantico Norte" },
      { label: "Atlantico Sur", value: "Atlantico Sur" },
    ],
  },
  {
    label: "Norway",
    options: [
      { label: "Akershus", value: "Akershus" },
      { label: "Aust-Agder", value: "Aust-Agder" },
      { label: "Buskerud", value: "Buskerud" },
      { label: "Finumark", value: "Finumark" },
      { label: "Hedmark", value: "Hedmark" },
      { label: "Hordaland", value: "Hordaland" },
      { label: "Mire og Romsdal", value: "Mire og Romsdal" },
      { label: "Nordland", value: "Nordland" },
      { label: "Nord-Trindelag", value: "Nord-Trindelag" },
      { label: "Oppland", value: "Oppland" },
      { label: "Oslo", value: "Oslo" },
      { label: "Rogaland", value: "Rogaland" },
      { label: "Sogn og Fjordane", value: "Sogn og Fjordane" },
      { label: "Sir-Trindelag", value: "Sir-Trindelag" },
      { label: "Telemark", value: "Telemark" },
      { label: "Troms", value: "Troms" },
      { label: "Vest-Agder", value: "Vest-Agder" },
      { label: "Vestfold", value: "Vestfold" },
      { label: "Ostfold", value: "Ostfold" },
      { label: "Jan Mayen", value: "Jan Mayen" },
      { label: "Svalbard", value: "Svalbard" },
    ],
  },
  {
    label: "New Zealand",
    options: [
      { label: "Auckland", value: "Auckland" },
      { label: "Bay of Plenty", value: "Bay of Plenty" },
      { label: "Canterbury", value: "Canterbury" },
      { label: "Gisborne", value: "Gisborne" },
      { label: "Hawkes Bay", value: "Hawkes Bay" },
      { label: "Manawatu-Wanganui", value: "Manawatu-Wanganui" },
      { label: "Marlborough", value: "Marlborough" },
      { label: "Nelson", value: "Nelson" },
      { label: "Northland", value: "Northland" },
      { label: "Otago", value: "Otago" },
      { label: "Southland", value: "Southland" },
      { label: "Taranaki", value: "Taranaki" },
      { label: "Tasman", value: "Tasman" },
      { label: "waikato", value: "waikato" },
      { label: "Wellington", value: "Wellington" },
      { label: "West Coast", value: "West Coast" },
    ],
  },
  {
    label: "Oman",
    options: [
      { label: "Ad Dakhillyah", value: "Ad Dakhillyah" },
      { label: "Al Batinah", value: "Al Batinah" },
      { label: "Al Janblyah", value: "Al Janblyah" },
      { label: "Al Wusta", value: "Al Wusta" },
      { label: "Ash Sharqlyah", value: "Ash Sharqlyah" },
      { label: "Az Zahirah", value: "Az Zahirah" },
      { label: "Masqat", value: "Masqat" },
      { label: "Musandam", value: "Musandam" },
    ],
  },
  {
    label: "Panama",
    options: [
      { label: "Bocas del Toro", value: "Bocas del Toro" },
      { label: "Cocle", value: "Cocle" },
      { label: "Chiriqui", value: "Chiriqui" },
      { label: "Darien", value: "Darien" },
      { label: "Herrera", value: "Herrera" },
      { label: "Loa Santoa", value: "Loa Santoa" },
      { label: "Panama", value: "Panama" },
      { label: "Veraguas", value: "Veraguas" },
      { label: "Comarca de San Blas", value: "Comarca de San Blas" },
    ],
  },
  {
    label: "Peru",
    options: [
      { label: "El Callao", value: "El Callao" },
      { label: "Ancash", value: "Ancash" },
      { label: "Apurimac", value: "Apurimac" },
      { label: "Arequipa", value: "Arequipa" },
      { label: "Ayacucho", value: "Ayacucho" },
      { label: "Cajamarca", value: "Cajamarca" },
      { label: "Cuzco", value: "Cuzco" },
      { label: "Huancavelica", value: "Huancavelica" },
      { label: "Huanuco", value: "Huanuco" },
      { label: "Ica", value: "Ica" },
      { label: "Junin", value: "Junin" },
      { label: "La Libertad", value: "La Libertad" },
      { label: "Lambayeque", value: "Lambayeque" },
      { label: "Lima", value: "Lima" },
      { label: "Loreto", value: "Loreto" },
      { label: "Madre de Dios", value: "Madre de Dios" },
      { label: "Moquegua", value: "Moquegua" },
      { label: "Pasco", value: "Pasco" },
      { label: "Piura", value: "Piura" },
      { label: "Puno", value: "Puno" },
      { label: "San Martin", value: "San Martin" },
      { label: "Tacna", value: "Tacna" },
      { label: "Tumbes", value: "Tumbes" },
      { label: "Ucayali", value: "Ucayali" },
    ],
  },
  {
    label: "Papua New Guinea",
    options: [
      {
        label: "National Capital District (Port Moresby)",
        value: "National Capital District (Port Moresby)",
      },
      { label: "Chimbu", value: "Chimbu" },
      { label: "Eastern Highlands", value: "Eastern Highlands" },
      { label: "East New Britain", value: "East New Britain" },
      { label: "East Sepik", value: "East Sepik" },
      { label: "Enga", value: "Enga" },
      { label: "Gulf", value: "Gulf" },
      { label: "Madang", value: "Madang" },
      { label: "Manus", value: "Manus" },
      { label: "Milne Bay", value: "Milne Bay" },
      { label: "Morobe", value: "Morobe" },
      { label: "New Ireland", value: "New Ireland" },
      { label: "North Solomons", value: "North Solomons" },
      { label: "Santaun", value: "Santaun" },
      { label: "Southern Highlands", value: "Southern Highlands" },
      { label: "Western Highlands", value: "Western Highlands" },
      { label: "West New Britain", value: "West New Britain" },
    ],
  },
  {
    label: "Philippines",
    options: [
      { label: "Abra", value: "Abra" },
      { label: "Agusan del Norte", value: "Agusan del Norte" },
      { label: "Agusan del Sur", value: "Agusan del Sur" },
      { label: "Aklan", value: "Aklan" },
      { label: "Albay", value: "Albay" },
      { label: "Antique", value: "Antique" },
      { label: "Apayao", value: "Apayao" },
      { label: "Aurora", value: "Aurora" },
      { label: "Basilan", value: "Basilan" },
      { label: "Batasn", value: "Batasn" },
      { label: "Batanes", value: "Batanes" },
      { label: "Batangas", value: "Batangas" },
      { label: "Benguet", value: "Benguet" },
      { label: "Biliran", value: "Biliran" },
      { label: "Bohol", value: "Bohol" },
      { label: "Bukidnon", value: "Bukidnon" },
      { label: "Bulacan", value: "Bulacan" },
      { label: "Cagayan", value: "Cagayan" },
      { label: "Camarines Norte", value: "Camarines Norte" },
      { label: "Camarines Sur", value: "Camarines Sur" },
      { label: "Camiguin", value: "Camiguin" },
      { label: "Capiz", value: "Capiz" },
      { label: "Catanduanes", value: "Catanduanes" },
      { label: "Cavite", value: "Cavite" },
      { label: "Cebu", value: "Cebu" },
      { label: "Compostela Valley", value: "Compostela Valley" },
      { label: "Davao", value: "Davao" },
      { label: "Davao del Sur", value: "Davao del Sur" },
      { label: "Davao Oriental", value: "Davao Oriental" },
      { label: "Eastern Samar", value: "Eastern Samar" },
      { label: "Guimaras", value: "Guimaras" },
      { label: "Ifugao", value: "Ifugao" },
      { label: "Ilocos Norte", value: "Ilocos Norte" },
      { label: "Ilocos Sur", value: "Ilocos Sur" },
      { label: "Iloilo", value: "Iloilo" },
      { label: "Isabela", value: "Isabela" },
      { label: "Kalinga-Apayso", value: "Kalinga-Apayso" },
      { label: "Laguna", value: "Laguna" },
      { label: "Lanao del Norte", value: "Lanao del Norte" },
      { label: "Lanao del Sur", value: "Lanao del Sur" },
      { label: "La Union", value: "La Union" },
      { label: "Leyte", value: "Leyte" },
      { label: "Maguindanao", value: "Maguindanao" },
      { label: "Marinduque", value: "Marinduque" },
      { label: "Masbate", value: "Masbate" },
      { label: "Mindoro Occidental", value: "Mindoro Occidental" },
      { label: "Mindoro Oriental", value: "Mindoro Oriental" },
      { label: "Misamis Occidental", value: "Misamis Occidental" },
      { label: "Misamis Oriental", value: "Misamis Oriental" },
      { label: "Mountain Province", value: "Mountain Province" },
      { label: "Negroe Occidental", value: "Negroe Occidental" },
      { label: "Negros Oriental", value: "Negros Oriental" },
      { label: "North Cotabato", value: "North Cotabato" },
      { label: "Northern Samar", value: "Northern Samar" },
      { label: "Nueva Ecija", value: "Nueva Ecija" },
      { label: "Nueva Vizcaya", value: "Nueva Vizcaya" },
      { label: "Palawan", value: "Palawan" },
      { label: "Pampanga", value: "Pampanga" },
      { label: "Pangasinan", value: "Pangasinan" },
      { label: "Quezon", value: "Quezon" },
      { label: "Quirino", value: "Quirino" },
      { label: "Rizal", value: "Rizal" },
      { label: "Romblon", value: "Romblon" },
      { label: "Sarangani", value: "Sarangani" },
      { label: "Siquijor", value: "Siquijor" },
      { label: "Sorsogon", value: "Sorsogon" },
      { label: "South Cotabato", value: "South Cotabato" },
      { label: "Southern Leyte", value: "Southern Leyte" },
      { label: "Sultan Kudarat", value: "Sultan Kudarat" },
      { label: "Sulu", value: "Sulu" },
      { label: "Surigao del Norte", value: "Surigao del Norte" },
      { label: "Surigao del Sur", value: "Surigao del Sur" },
      { label: "Tarlac", value: "Tarlac" },
      { label: "Tawi-Tawi", value: "Tawi-Tawi" },
      { label: "Western Samar", value: "Western Samar" },
      { label: "Zambales", value: "Zambales" },
      { label: "Zamboanga del Norte", value: "Zamboanga del Norte" },
      { label: "Zamboanga del Sur", value: "Zamboanga del Sur" },
      { label: "Zamboanga Sibiguey", value: "Zamboanga Sibiguey" },
    ],
  },
  {
    label: "Pakistan",
    options: [
      { label: "Islamabad", value: "Islamabad" },
      { label: "Baluchistan (en)", value: "Baluchistan (en)" },
      { label: "North-West Frontier", value: "North-West Frontier" },
      { label: "Sind (en)", value: "Sind (en)" },
      {
        label: "Federally Administered Tribal Aresa",
        value: "Federally Administered Tribal Aresa",
      },
      { label: "Azad Rashmir", value: "Azad Rashmir" },
      { label: "Northern Areas", value: "Northern Areas" },
    ],
  },
  {
    label: "Portugal",
    options: [
      { label: "Aveiro", value: "Aveiro" },
      { label: "Beja", value: "Beja" },
      { label: "Braga", value: "Braga" },
      { label: "Braganca", value: "Braganca" },
      { label: "Castelo Branco", value: "Castelo Branco" },
      { label: "Colmbra", value: "Colmbra" },
      { label: "Ovora", value: "Ovora" },
      { label: "Faro", value: "Faro" },
      { label: "Guarda", value: "Guarda" },
      { label: "Leiria", value: "Leiria" },
      { label: "Lisboa", value: "Lisboa" },
      { label: "Portalegre", value: "Portalegre" },
      { label: "Porto", value: "Porto" },
      { label: "Santarem", value: "Santarem" },
      { label: "Setubal", value: "Setubal" },
      { label: "Viana do Castelo", value: "Viana do Castelo" },
      { label: "Vila Real", value: "Vila Real" },
      { label: "Viseu", value: "Viseu" },
      {
        label: "Regiao Autonoma dos Acores",
        value: "Regiao Autonoma dos Acores",
      },
      {
        label: "Regiao Autonoma da Madeira",
        value: "Regiao Autonoma da Madeira",
      },
    ],
  },
  {
    label: "Paraguay",
    options: [
      { label: "Asuncion", value: "Asuncion" },
      { label: "Alto Paraguay", value: "Alto Paraguay" },
      { label: "Alto Parana", value: "Alto Parana" },
      { label: "Amambay", value: "Amambay" },
      { label: "Boqueron", value: "Boqueron" },
      { label: "Caeguazu", value: "Caeguazu" },
      { label: "Caazapl", value: "Caazapl" },
      { label: "Canindeyu", value: "Canindeyu" },
      { label: "Concepcion", value: "Concepcion" },
      { label: "Cordillera", value: "Cordillera" },
      { label: "Guaira", value: "Guaira" },
      { label: "Itapua", value: "Itapua" },
      { label: "Miaiones", value: "Miaiones" },
      { label: "Neembucu", value: "Neembucu" },
      { label: "Paraguari", value: "Paraguari" },
      { label: "Presidente Hayes", value: "Presidente Hayes" },
      { label: "San Pedro", value: "San Pedro" },
    ],
  },
  {
    label: "Qatar",
    options: [
      { label: "Ad Dawhah", value: "Ad Dawhah" },
      { label: "Al Ghuwayriyah", value: "Al Ghuwayriyah" },
      { label: "Al Jumayliyah", value: "Al Jumayliyah" },
      { label: "Al Khawr", value: "Al Khawr" },
      { label: "Al Wakrah", value: "Al Wakrah" },
      { label: "Ar Rayyan", value: "Ar Rayyan" },
      { label: "Jariyan al Batnah", value: "Jariyan al Batnah" },
      { label: "Madinat ash Shamal", value: "Madinat ash Shamal" },
      { label: "Umm Salal", value: "Umm Salal" },
    ],
  },
  {
    label: "Romania",
    options: [
      { label: "Bucuresti", value: "Bucuresti" },
      { label: "Alba", value: "Alba" },
      { label: "Arad", value: "Arad" },
      { label: "Arges", value: "Arges" },
      { label: "Bacau", value: "Bacau" },
      { label: "Bihor", value: "Bihor" },
      { label: "Bistrita-Nasaud", value: "Bistrita-Nasaud" },
      { label: "Boto'ani", value: "Boto'ani" },
      { label: "Bra'ov", value: "Bra'ov" },
      { label: "Braila", value: "Braila" },
      { label: "Buzau", value: "Buzau" },
      { label: "Caras-Severin", value: "Caras-Severin" },
      { label: "Ca la ras'i", value: "Ca la ras'i" },
      { label: "Cluj", value: "Cluj" },
      { label: "Constant'a", value: "Constant'a" },
      { label: "Covasna", value: "Covasna" },
      { label: "Dambovit'a", value: "Dambovit'a" },
      { label: "Dolj", value: "Dolj" },
      { label: "Galat'i", value: "Galat'i" },
      { label: "Giurgiu", value: "Giurgiu" },
      { label: "Gorj", value: "Gorj" },
      { label: "Harghita", value: "Harghita" },
      { label: "Hunedoara", value: "Hunedoara" },
      { label: "Ialomit'a", value: "Ialomit'a" },
      { label: "Ias'i", value: "Ias'i" },
      { label: "Ilfov", value: "Ilfov" },
      { label: "Maramures", value: "Maramures" },
      { label: "Mehedint'i", value: "Mehedint'i" },
      { label: "Mures", value: "Mures" },
      { label: "Neamt", value: "Neamt" },
      { label: "Olt", value: "Olt" },
      { label: "Prahova", value: "Prahova" },
      { label: "Satu Mare", value: "Satu Mare" },
      { label: "Sa laj", value: "Sa laj" },
      { label: "Sibiu", value: "Sibiu" },
      { label: "Suceava", value: "Suceava" },
      { label: "Teleorman", value: "Teleorman" },
      { label: "Timis", value: "Timis" },
      { label: "Tulcea", value: "Tulcea" },
      { label: "Vaslui", value: "Vaslui" },
      { label: "Valcea", value: "Valcea" },
      { label: "Vrancea", value: "Vrancea" },
    ],
  },
  {
    label: "Russian Federation",
    options: [
      { label: "Adygeya, Respublika", value: "Adygeya, Respublika" },
      { label: "Altay, Respublika", value: "Altay, Respublika" },
      {
        label: "Bashkortostan, Respublika",
        value: "Bashkortostan, Respublika",
      },
      { label: "Buryatiya, Respublika", value: "Buryatiya, Respublika" },
      {
        label: "Chechenskaya Respublika",
        value: "Chechenskaya Respublika",
      },
      {
        label: "Chuvashskaya Respublika",
        value: "Chuvashskaya Respublika",
      },
      { label: "Dagestan, Respublika", value: "Dagestan, Respublika" },
      { label: "Ingushskaya Respublika", value: "Ingushskaya Respublika" },
      { label: "Kabardino-Balkarskaya", value: "Kabardino-Balkarskaya" },
      { label: "Kalmykiya, Respublika", value: "Kalmykiya, Respublika" },
      {
        label: "Karachayevo-Cherkesskaya Respublika",
        value: "Karachayevo-Cherkesskaya Respublika",
      },
      { label: "Kareliya, Respublika", value: "Kareliya, Respublika" },
      { label: "Khakasiya, Respublika", value: "Khakasiya, Respublika" },
      { label: "Komi, Respublika", value: "Komi, Respublika" },
      { label: "Mariy El, Respublika", value: "Mariy El, Respublika" },
      { label: "Mordoviya, Respublika", value: "Mordoviya, Respublika" },
      {
        label: "Sakha, Respublika [Yakutiya]",
        value: "Sakha, Respublika [Yakutiya]",
      },
      {
        label: "Severnaya Osetiya, Respublika",
        value: "Severnaya Osetiya, Respublika",
      },
      { label: "Tatarstan, Respublika", value: "Tatarstan, Respublika" },
      {
        label: "Tyva, Respublika [Tuva]",
        value: "Tyva, Respublika [Tuva]",
      },
      { label: "Udmurtskaya Respublika", value: "Udmurtskaya Respublika" },
      { label: "Altayskiy kray", value: "Altayskiy kray" },
      { label: "Khabarovskiy kray", value: "Khabarovskiy kray" },
      { label: "Krasnodarskiy kray", value: "Krasnodarskiy kray" },
      { label: "Krasnoyarskiy kray", value: "Krasnoyarskiy kray" },
      { label: "Primorskiy kray", value: "Primorskiy kray" },
      { label: "Stavropol'skiy kray", value: "Stavropol'skiy kray" },
      { label: "Amurskaya oblast'", value: "Amurskaya oblast'" },
      {
        label: "Arkhangel'skaya oblast'",
        value: "Arkhangel'skaya oblast'",
      },
      { label: "Astrakhanskaya oblast'", value: "Astrakhanskaya oblast'" },
      { label: "Belgorodskaya oblast'", value: "Belgorodskaya oblast'" },
      { label: "Bryanskaya oblast'", value: "Bryanskaya oblast'" },
      { label: "Chelyabinskaya oblast'", value: "Chelyabinskaya oblast'" },
      { label: "Chitinskaya oblast'", value: "Chitinskaya oblast'" },
      { label: "Irkutskaya oblast'", value: "Irkutskaya oblast'" },
      { label: "Ivanovskaya oblast'", value: "Ivanovskaya oblast'" },
      {
        label: "Kaliningradskaya oblast'",
        value: "Kaliningradskaya oblast'",
      },
      { label: "Kaluzhskaya oblast'", value: "Kaluzhskaya oblast'" },
      { label: "Kamchatskaya oblast'", value: "Kamchatskaya oblast'" },
      { label: "Kemerovskaya oblast'", value: "Kemerovskaya oblast'" },
      { label: "Kirovskaya oblast'", value: "Kirovskaya oblast'" },
      { label: "Kostromskaya oblast'", value: "Kostromskaya oblast'" },
      { label: "Kurganskaya oblast'", value: "Kurganskaya oblast'" },
      { label: "Kurskaya oblast'", value: "Kurskaya oblast'" },
      { label: "Leningradskaya oblast'", value: "Leningradskaya oblast'" },
      { label: "Lipetskaya oblast'", value: "Lipetskaya oblast'" },
      { label: "Magadanskaya oblast'", value: "Magadanskaya oblast'" },
      { label: "Moskovskaya oblast'", value: "Moskovskaya oblast'" },
      { label: "Murmanskaya oblast'", value: "Murmanskaya oblast'" },
      {
        label: "Nizhegorodskaya oblast'",
        value: "Nizhegorodskaya oblast'",
      },
      { label: "Novgorodskaya oblast'", value: "Novgorodskaya oblast'" },
      { label: "Novosibirskaya oblast'", value: "Novosibirskaya oblast'" },
      { label: "Omskaya oblast'", value: "Omskaya oblast'" },
      { label: "Orenburgskaya oblast'", value: "Orenburgskaya oblast'" },
      { label: "Orlovskaya oblast'", value: "Orlovskaya oblast'" },
      { label: "Penzenskaya oblast'", value: "Penzenskaya oblast'" },
      { label: "Permskaya oblast'", value: "Permskaya oblast'" },
      { label: "Pskovskaya oblast'", value: "Pskovskaya oblast'" },
      { label: "Rostovskaya oblast'", value: "Rostovskaya oblast'" },
      { label: "Ryazanskaya oblast'", value: "Ryazanskaya oblast'" },
      { label: "Sakhalinskaya oblast'", value: "Sakhalinskaya oblast'" },
      { label: "Samarskaya oblast'", value: "Samarskaya oblast'" },
      { label: "Saratovskaya oblast'", value: "Saratovskaya oblast'" },
      { label: "Smolenskaya oblast'", value: "Smolenskaya oblast'" },
      { label: "Sverdlovskaya oblast'", value: "Sverdlovskaya oblast'" },
      { label: "Tambovskaya oblast'", value: "Tambovskaya oblast'" },
      { label: "Tomskaya oblast'", value: "Tomskaya oblast'" },
      { label: "Tul'skaya oblast'", value: "Tul'skaya oblast'" },
      { label: "Tverskaya oblast'", value: "Tverskaya oblast'" },
      { label: "Tyumenskaya oblast'", value: "Tyumenskaya oblast'" },
      { label: "Ul'yanovskaya oblast'", value: "Ul'yanovskaya oblast'" },
      { label: "Vladimirskaya oblast'", value: "Vladimirskaya oblast'" },
      { label: "Volgogradskaya oblast'", value: "Volgogradskaya oblast'" },
      { label: "Vologodskaya oblast'", value: "Vologodskaya oblast'" },
      { label: "Voronezhskaya oblast'", value: "Voronezhskaya oblast'" },
      { label: "Yaroslavskaya oblast'", value: "Yaroslavskaya oblast'" },
      { label: "Moskva", value: "Moskva" },
      { label: "Sankt-Peterburg", value: "Sankt-Peterburg" },
      {
        label: "Yevreyskaya avtonomnaya oblast'",
        value: "Yevreyskaya avtonomnaya oblast'",
      },
      {
        label: "Aginskiy Buryatskiy avtonomnyy",
        value: "Aginskiy Buryatskiy avtonomnyy",
      },
      {
        label: "Chukotskiy avtonomnyy okrug",
        value: "Chukotskiy avtonomnyy okrug",
      },
      {
        label: "Evenkiyskiy avtonomnyy okrug",
        value: "Evenkiyskiy avtonomnyy okrug",
      },
      {
        label: "Khanty-Mansiyskiy avtonomnyy okrug",
        value: "Khanty-Mansiyskiy avtonomnyy okrug",
      },
      {
        label: "Komi-Permyatskiy avtonomnyy okrug",
        value: "Komi-Permyatskiy avtonomnyy okrug",
      },
      {
        label: "Koryakskiy avtonomnyy okrug",
        value: "Koryakskiy avtonomnyy okrug",
      },
      {
        label: "Nenetskiy avtonomnyy okrug",
        value: "Nenetskiy avtonomnyy okrug",
      },
      {
        label: "Taymyrskiy (Dolgano-Nenetskiy)",
        value: "Taymyrskiy (Dolgano-Nenetskiy)",
      },
      {
        label: "Ust'-Ordynskiy Buryatskiy",
        value: "Ust'-Ordynskiy Buryatskiy",
      },
      {
        label: "Yamalo-Nenetskiy avtonomnyy okrug",
        value: "Yamalo-Nenetskiy avtonomnyy okrug",
      },
    ],
  },
  {
    label: "Rwanda",
    options: [
      { label: "Butare", value: "Butare" },
      { label: "Byumba", value: "Byumba" },
      { label: "Cyangugu", value: "Cyangugu" },
      { label: "Gikongoro", value: "Gikongoro" },
      { label: "Gisenyi", value: "Gisenyi" },
      { label: "Gitarama", value: "Gitarama" },
      { label: "Kibungo", value: "Kibungo" },
      { label: "Kibuye", value: "Kibuye" },
      {
        label: "Kigali-Rural Kigali y' Icyaro",
        value: "Kigali-Rural Kigali y' Icyaro",
      },
      {
        label: "Kigali-Ville Kigali Ngari",
        value: "Kigali-Ville Kigali Ngari",
      },
      { label: "Mutara", value: "Mutara" },
      { label: "Ruhengeri", value: "Ruhengeri" },
    ],
  },
  {
    label: "Saudi Arabia",
    options: [
      { label: "Al Batah", value: "Al Batah" },
      {
        label: "Al H, udd ash Shamallyah",
        value: "Al H, udd ash Shamallyah",
      },
      { label: "Al Jawf", value: "Al Jawf" },
      { label: "Al Madinah", value: "Al Madinah" },
      { label: "Al Qasim", value: "Al Qasim" },
      { label: "Ar Riyad", value: "Ar Riyad" },
      { label: "Asir", value: "Asir" },
      { label: "Ha'il", value: "Ha'il" },
      { label: "Jlzan", value: "Jlzan" },
      { label: "Makkah", value: "Makkah" },
      { label: "Najran", value: "Najran" },
      { label: "Tabuk", value: "Tabuk" },
    ],
  },
  {
    label: "Solomon Islands",
    options: [
      {
        label: "Capital Territory (Honiara)",
        value: "Capital Territory (Honiara)",
      },
      { label: "Guadalcanal", value: "Guadalcanal" },
      { label: "Isabel", value: "Isabel" },
      { label: "Makira", value: "Makira" },
      { label: "Malaita", value: "Malaita" },
      { label: "Temotu", value: "Temotu" },
    ],
  },
  {
    label: "Sudan",
    options: [
      { label: "A'ali an Nil", value: "A'ali an Nil" },
      { label: "Al Bah al Ahmar", value: "Al Bah al Ahmar" },
      { label: "Al Buhayrat", value: "Al Buhayrat" },
      { label: "Al Jazirah", value: "Al Jazirah" },
      { label: "Al Khartum", value: "Al Khartum" },
      { label: "Al Qadarif", value: "Al Qadarif" },
      { label: "Al Wahdah", value: "Al Wahdah" },
      { label: "An Nil", value: "An Nil" },
      { label: "An Nil al Abyaq", value: "An Nil al Abyaq" },
      { label: "An Nil al Azraq", value: "An Nil al Azraq" },
      { label: "Ash Shamallyah", value: "Ash Shamallyah" },
      { label: "Bahr al Jabal", value: "Bahr al Jabal" },
      { label: "Gharb al Istiwa'iyah", value: "Gharb al Istiwa'iyah" },
      { label: "Gharb Ba~r al Ghazal", value: "Gharb Ba~r al Ghazal" },
      { label: "Gharb Darfur", value: "Gharb Darfur" },
      { label: "Gharb Kurdufan", value: "Gharb Kurdufan" },
      { label: "Janub Darfur", value: "Janub Darfur" },
      { label: "Janub Rurdufan", value: "Janub Rurdufan" },
      { label: "Jnqall", value: "Jnqall" },
      { label: "Kassala", value: "Kassala" },
      { label: "Shamal Batr al Ghazal", value: "Shamal Batr al Ghazal" },
      { label: "Shamal Darfur", value: "Shamal Darfur" },
      { label: "Shamal Kurdufan", value: "Shamal Kurdufan" },
      { label: "Sharq al Istiwa'iyah", value: "Sharq al Istiwa'iyah" },
      { label: "Sinnar", value: "Sinnar" },
      { label: "Warab", value: "Warab" },
    ],
  },
  {
    label: "Sweden",
    options: [
      { label: "Blekinge lan", value: "Blekinge lan" },
      { label: "Dalarnas lan", value: "Dalarnas lan" },
      { label: "Gotlands lan", value: "Gotlands lan" },
      { label: "Gavleborge lan", value: "Gavleborge lan" },
      { label: "Hallands lan", value: "Hallands lan" },
      { label: "Jamtlande lan", value: "Jamtlande lan" },
      { label: "Jonkopings lan", value: "Jonkopings lan" },
      { label: "Kalmar lan", value: "Kalmar lan" },
      { label: "Kronoberge lan", value: "Kronoberge lan" },
      { label: "Norrbottena lan", value: "Norrbottena lan" },
      { label: "Skane lan", value: "Skane lan" },
      { label: "Stockholms lan", value: "Stockholms lan" },
      { label: "Sodermanlands lan", value: "Sodermanlands lan" },
      { label: "Uppsala lan", value: "Uppsala lan" },
      { label: "Varmlanda lan", value: "Varmlanda lan" },
      { label: "Vasterbottens lan", value: "Vasterbottens lan" },
      { label: "Vasternorrlands lan", value: "Vasternorrlands lan" },
      { label: "Vastmanlanda lan", value: "Vastmanlanda lan" },
      { label: "Vastra Gotalands lan", value: "Vastra Gotalands lan" },
      { label: "Orebro lan", value: "Orebro lan" },
      { label: "Ostergotlands lan", value: "Ostergotlands lan" },
    ],
  },
  {
    label: "Saint Helena",
    options: [
      { label: "Saint Helena", value: "Saint Helena" },
      { label: "Ascension", value: "Ascension" },
      { label: "Tristan da Cunha", value: "Tristan da Cunha" },
    ],
  },
  {
    label: "Slovenia",
    options: [
      { label: "Ajdovscina", value: "Ajdovscina" },
      { label: "Beltinci", value: "Beltinci" },
      { label: "Benedikt", value: "Benedikt" },
      { label: "Bistrica ob Sotli", value: "Bistrica ob Sotli" },
      { label: "Bled", value: "Bled" },
      { label: "Bloke", value: "Bloke" },
      { label: "Bohinj", value: "Bohinj" },
      { label: "Borovnica", value: "Borovnica" },
      { label: "Bovec", value: "Bovec" },
      { label: "Braslovce", value: "Braslovce" },
      { label: "Brda", value: "Brda" },
      { label: "Brezovica", value: "Brezovica" },
      { label: "Brezica", value: "Brezica" },
      { label: "Cankova", value: "Cankova" },
      { label: "Celje", value: "Celje" },
      { label: "Cerklje na Gorenjskem", value: "Cerklje na Gorenjskem" },
      { label: "Cerknica", value: "Cerknica" },
      { label: "Cerkno", value: "Cerkno" },
      { label: "Cerkvenjak", value: "Cerkvenjak" },
      { label: "Crensovci", value: "Crensovci" },
      { label: "Crna na Koroskem", value: "Crna na Koroskem" },
      { label: "Crnomelj", value: "Crnomelj" },
      { label: "Destrnik", value: "Destrnik" },
      { label: "Divaca", value: "Divaca" },
      { label: "Dobje", value: "Dobje" },
      { label: "Dobrepolje", value: "Dobrepolje" },
      { label: "Dobrna", value: "Dobrna" },
      { label: "Dobrova-Polhov Gradec", value: "Dobrova-Polhov Gradec" },
      { label: "Dobrovnik", value: "Dobrovnik" },
      { label: "Dol pri Ljubljani", value: "Dol pri Ljubljani" },
      { label: "Dolenjske Toplice", value: "Dolenjske Toplice" },
      { label: "Domzale", value: "Domzale" },
      { label: "Dornava", value: "Dornava" },
      { label: "Dravograd", value: "Dravograd" },
      { label: "Duplek", value: "Duplek" },
      { label: "Gorenja vas-Poljane", value: "Gorenja vas-Poljane" },
      { label: "Gorsnica", value: "Gorsnica" },
      { label: "Gornja Radgona", value: "Gornja Radgona" },
      { label: "Gornji Grad", value: "Gornji Grad" },
      { label: "Gornji Petrovci", value: "Gornji Petrovci" },
      { label: "Grad", value: "Grad" },
      { label: "Grosuplje", value: "Grosuplje" },
      { label: "Hajdina", value: "Hajdina" },
      { label: "Hoce-Slivnica", value: "Hoce-Slivnica" },
      { label: "Hodos", value: "Hodos" },
      { label: "Jorjul", value: "Jorjul" },
      { label: "Hrastnik", value: "Hrastnik" },
      { label: "Hrpelje-Kozina", value: "Hrpelje-Kozina" },
      { label: "Idrija", value: "Idrija" },
      { label: "Ig", value: "Ig" },
      { label: "IIrska Bistrica", value: "IIrska Bistrica" },
      { label: "Ivancna Gorica", value: "Ivancna Gorica" },
      { label: "Izola", value: "Izola" },
      { label: "Jesenice", value: "Jesenice" },
      { label: "Jezersko", value: "Jezersko" },
      { label: "Jursinci", value: "Jursinci" },
      { label: "Kamnik", value: "Kamnik" },
      { label: "Kanal", value: "Kanal" },
      { label: "Kidricevo", value: "Kidricevo" },
      { label: "Kobarid", value: "Kobarid" },
      { label: "Kobilje", value: "Kobilje" },
      { label: "Jovevje", value: "Jovevje" },
      { label: "Komen", value: "Komen" },
      { label: "Komenda", value: "Komenda" },
      { label: "Koper", value: "Koper" },
      { label: "Kostel", value: "Kostel" },
      { label: "Kozje", value: "Kozje" },
      { label: "Kranj", value: "Kranj" },
      { label: "Kranjska Gora", value: "Kranjska Gora" },
      { label: "Krizevci", value: "Krizevci" },
      { label: "Krsko", value: "Krsko" },
      { label: "Kungota", value: "Kungota" },
      { label: "Kuzma", value: "Kuzma" },
      { label: "Lasko", value: "Lasko" },
      { label: "Lenart", value: "Lenart" },
      { label: "Lendava", value: "Lendava" },
      { label: "Litija", value: "Litija" },
      { label: "Ljubljana", value: "Ljubljana" },
      { label: "Ljubno", value: "Ljubno" },
      { label: "Ljutomer", value: "Ljutomer" },
      { label: "Logatec", value: "Logatec" },
      { label: "Loska dolina", value: "Loska dolina" },
      { label: "Loski Potok", value: "Loski Potok" },
      { label: "Lovrenc na Pohorju", value: "Lovrenc na Pohorju" },
      { label: "Luce", value: "Luce" },
      { label: "Lukovica", value: "Lukovica" },
      { label: "Majsperk", value: "Majsperk" },
      { label: "Maribor", value: "Maribor" },
      { label: "Markovci", value: "Markovci" },
      { label: "Medvode", value: "Medvode" },
      { label: "Menges", value: "Menges" },
      { label: "Metlika", value: "Metlika" },
      { label: "Mezica", value: "Mezica" },
      {
        label: "Miklavz na Dravskern polju",
        value: "Miklavz na Dravskern polju",
      },
      { label: "Miren-Kostanjevica", value: "Miren-Kostanjevica" },
      { label: "Mirna Pec", value: "Mirna Pec" },
      { label: "Mislinja", value: "Mislinja" },
      { label: "Moravce", value: "Moravce" },
      { label: "Moravske Toplice", value: "Moravske Toplice" },
      { label: "Mozirje", value: "Mozirje" },
      { label: "Murska Sobota", value: "Murska Sobota" },
      { label: "Muta", value: "Muta" },
      { label: "Naklo", value: "Naklo" },
      { label: "Nazarje", value: "Nazarje" },
      { label: "Nova Gorica", value: "Nova Gorica" },
      { label: "Nova mesto", value: "Nova mesto" },
      { label: "Sveta Ana", value: "Sveta Ana" },
      {
        label: "Sveti Andraz v Slovenskih goricah",
        value: "Sveti Andraz v Slovenskih goricah",
      },
      { label: "Sveti Jurij", value: "Sveti Jurij" },
      { label: "Salovci", value: "Salovci" },
      { label: "Sempeter-Vrtojba", value: "Sempeter-Vrtojba" },
      { label: "Sencur", value: "Sencur" },
      { label: "Sentilj", value: "Sentilj" },
      { label: "Sentjernej", value: "Sentjernej" },
      { label: "Sentjur pri Celju", value: "Sentjur pri Celju" },
      { label: "Skocjan", value: "Skocjan" },
      { label: "Skofja Loka", value: "Skofja Loka" },
      { label: "Skoftjica", value: "Skoftjica" },
      { label: "Smarje pri Jelsah", value: "Smarje pri Jelsah" },
      { label: "Smartno ob Paki", value: "Smartno ob Paki" },
      { label: "Smartno pri Litiji", value: "Smartno pri Litiji" },
      { label: "Sostanj", value: "Sostanj" },
      { label: "Store", value: "Store" },
      { label: "Tabor", value: "Tabor" },
      { label: "Tisina", value: "Tisina" },
      { label: "Tolmin", value: "Tolmin" },
      { label: "Trbovje", value: "Trbovje" },
      { label: "Trebnje", value: "Trebnje" },
      { label: "Trnovska vas", value: "Trnovska vas" },
      { label: "Trzic", value: "Trzic" },
      { label: "Trzin", value: "Trzin" },
      { label: "Turnisce", value: "Turnisce" },
      { label: "Velenje", value: "Velenje" },
      { label: "Velika Polana", value: "Velika Polana" },
      { label: "Velika Lasce", value: "Velika Lasce" },
      { label: "Verzej", value: "Verzej" },
      { label: "Videm", value: "Videm" },
      { label: "Vipava", value: "Vipava" },
      { label: "Vitanje", value: "Vitanje" },
      { label: "Vojnik", value: "Vojnik" },
      { label: "Vransko", value: "Vransko" },
      { label: "Vrhnika", value: "Vrhnika" },
      { label: "Vuzenica", value: "Vuzenica" },
      { label: "Zagorje ob Savi", value: "Zagorje ob Savi" },
      { label: "Zavrc", value: "Zavrc" },
      { label: "Zrece", value: "Zrece" },
      { label: "Zalec", value: "Zalec" },
      { label: "Zelezniki", value: "Zelezniki" },
      { label: "Zetale", value: "Zetale" },
      { label: "Ziri", value: "Ziri" },
      { label: "Zirovnica", value: "Zirovnica" },
      { label: "Zuzemberk", value: "Zuzemberk" },
    ],
  },
  {
    label: "Slovakia",
    options: [
      { label: "Banskobystrický kraj", value: "Banskobystrický kraj" },
      { label: "Bratislavský kraj", value: "Bratislavský kraj" },
      { label: "Košický kraj", value: "Košický kraj" },
      { label: "Nitriansky kraj", value: "Nitriansky kraj" },
      { label: "Prešovský kraj", value: "Prešovský kraj" },
      { label: "Trenčiansky kraj", value: "Trenčiansky kraj" },
      { label: "Trnavský kraj", value: "Trnavský kraj" },
      { label: "Žilinský kraj", value: "Žilinský kraj" },
    ],
  },
  {
    label: "Sierra Leone",
    options: [{ label: "Western Area (Freetown)", value: "Western Area (Freetown)" }],
  },
  {
    label: "Senegal",
    options: [
      { label: "Dakar", value: "Dakar" },
      { label: "Diourbel", value: "Diourbel" },
      { label: "Fatick", value: "Fatick" },
      { label: "Kaolack", value: "Kaolack" },
      { label: "Kolda", value: "Kolda" },
      { label: "Louga", value: "Louga" },
      { label: "Matam", value: "Matam" },
      { label: "Saint-Louis", value: "Saint-Louis" },
      { label: "Tambacounda", value: "Tambacounda" },
      { label: "Thies", value: "Thies" },
      { label: "Ziguinchor", value: "Ziguinchor" },
    ],
  },
  {
    label: "Somalia",
    options: [
      { label: "Awdal", value: "Awdal" },
      { label: "Bakool", value: "Bakool" },
      { label: "Banaadir", value: "Banaadir" },
      { label: "Bay", value: "Bay" },
      { label: "Galguduud", value: "Galguduud" },
      { label: "Gedo", value: "Gedo" },
      { label: "Hiirsan", value: "Hiirsan" },
      { label: "Jubbada Dhexe", value: "Jubbada Dhexe" },
      { label: "Jubbada Hoose", value: "Jubbada Hoose" },
      { label: "Mudug", value: "Mudug" },
      { label: "Nugaal", value: "Nugaal" },
      { label: "Saneag", value: "Saneag" },
      { label: "Shabeellaha Dhexe", value: "Shabeellaha Dhexe" },
      { label: "Shabeellaha Hoose", value: "Shabeellaha Hoose" },
      { label: "Sool", value: "Sool" },
      { label: "Togdheer", value: "Togdheer" },
      { label: "Woqooyi Galbeed", value: "Woqooyi Galbeed" },
    ],
  },
  {
    label: "Suriname",
    options: [
      { label: "Brokopondo", value: "Brokopondo" },
      { label: "Commewijne", value: "Commewijne" },
      { label: "Coronie", value: "Coronie" },
      { label: "Marowijne", value: "Marowijne" },
      { label: "Nickerie", value: "Nickerie" },
      { label: "Paramaribo", value: "Paramaribo" },
      { label: "Saramacca", value: "Saramacca" },
      { label: "Sipaliwini", value: "Sipaliwini" },
      { label: "Wanica", value: "Wanica" },
    ],
  },
  {
    label: "Sao Tome and Principe",
    options: [
      { label: "Principe", value: "Principe" },
      { label: "Sao Tome", value: "Sao Tome" },
    ],
  },
  {
    label: "El Salvador",
    options: [
      { label: "Ahuachapan", value: "Ahuachapan" },
      { label: "Cabanas", value: "Cabanas" },
      { label: "Cuscatlan", value: "Cuscatlan" },
      { label: "Chalatenango", value: "Chalatenango" },
      { label: "Morazan", value: "Morazan" },
      { label: "San Miguel", value: "San Miguel" },
      { label: "San Salvador", value: "San Salvador" },
      { label: "Santa Ana", value: "Santa Ana" },
      { label: "San Vicente", value: "San Vicente" },
      { label: "Sonsonate", value: "Sonsonate" },
      { label: "Usulutan", value: "Usulutan" },
    ],
  },
  {
    label: "Syrian Arab Republic",
    options: [
      { label: "Al Hasakah", value: "Al Hasakah" },
      { label: "Al Ladhiqiyah", value: "Al Ladhiqiyah" },
      { label: "Al Qunaytirah", value: "Al Qunaytirah" },
      { label: "Ar Raqqah", value: "Ar Raqqah" },
      { label: "As Suwayda'", value: "As Suwayda'" },
      { label: "Dar'a", value: "Dar'a" },
      { label: "Dayr az Zawr", value: "Dayr az Zawr" },
      { label: "Dimashq", value: "Dimashq" },
      { label: "Halab", value: "Halab" },
      { label: "Hamah", value: "Hamah" },
      { label: "Jim'", value: "Jim'" },
      { label: "Idlib", value: "Idlib" },
      { label: "Rif Dimashq", value: "Rif Dimashq" },
      { label: "Tarts", value: "Tarts" },
    ],
  },
  {
    label: "Swaziland",
    options: [
      { label: "Hhohho", value: "Hhohho" },
      { label: "Lubombo", value: "Lubombo" },
      { label: "Manzini", value: "Manzini" },
      { label: "Shiselweni", value: "Shiselweni" },
    ],
  },
  {
    label: "Chad",
    options: [
      { label: "Batha", value: "Batha" },
      { label: "Biltine", value: "Biltine" },
      { label: "Borkou-Ennedi-Tibesti", value: "Borkou-Ennedi-Tibesti" },
      { label: "Chari-Baguirmi", value: "Chari-Baguirmi" },
      { label: "Guera", value: "Guera" },
      { label: "Kanem", value: "Kanem" },
      { label: "Lac", value: "Lac" },
      { label: "Logone-Occidental", value: "Logone-Occidental" },
      { label: "Logone-Oriental", value: "Logone-Oriental" },
      { label: "Mayo-Kebbi", value: "Mayo-Kebbi" },
      { label: "Moyen-Chari", value: "Moyen-Chari" },
      { label: "Ouaddai", value: "Ouaddai" },
      { label: "Salamat", value: "Salamat" },
      { label: "Tandjile", value: "Tandjile" },
    ],
  },
  {
    label: "Togo",
    options: [
      { label: "Kara", value: "Kara" },
      { label: "Maritime (Region)", value: "Maritime (Region)" },
      { label: "Savannes", value: "Savannes" },
    ],
  },
  {
    label: "Thailand",
    options: [
      {
        label: "Krung Thep Maha Nakhon Bangkok",
        value: "Krung Thep Maha Nakhon Bangkok",
      },
      { label: "Phatthaya", value: "Phatthaya" },
      { label: "Amnat Charoen", value: "Amnat Charoen" },
      { label: "Ang Thong", value: "Ang Thong" },
      { label: "Buri Ram", value: "Buri Ram" },
      { label: "Chachoengsao", value: "Chachoengsao" },
      { label: "Chai Nat", value: "Chai Nat" },
      { label: "Chaiyaphum", value: "Chaiyaphum" },
      { label: "Chanthaburi", value: "Chanthaburi" },
      { label: "Chiang Mai", value: "Chiang Mai" },
      { label: "Chiang Rai", value: "Chiang Rai" },
      { label: "Chon Buri", value: "Chon Buri" },
      { label: "Chumphon", value: "Chumphon" },
      { label: "Kalasin", value: "Kalasin" },
      { label: "Kamphasng Phet", value: "Kamphasng Phet" },
      { label: "Kanchanaburi", value: "Kanchanaburi" },
      { label: "Khon Kaen", value: "Khon Kaen" },
      { label: "Krabi", value: "Krabi" },
      { label: "Lampang", value: "Lampang" },
      { label: "Lamphun", value: "Lamphun" },
      { label: "Loei", value: "Loei" },
      { label: "Lop Buri", value: "Lop Buri" },
      { label: "Mae Hong Son", value: "Mae Hong Son" },
      { label: "Maha Sarakham", value: "Maha Sarakham" },
      { label: "Mukdahan", value: "Mukdahan" },
      { label: "Nakhon Nayok", value: "Nakhon Nayok" },
      { label: "Nakhon Pathom", value: "Nakhon Pathom" },
      { label: "Nakhon Phanom", value: "Nakhon Phanom" },
      { label: "Nakhon Ratchasima", value: "Nakhon Ratchasima" },
      { label: "Nakhon Sawan", value: "Nakhon Sawan" },
      { label: "Nakhon Si Thammarat", value: "Nakhon Si Thammarat" },
      { label: "Nan", value: "Nan" },
      { label: "Narathiwat", value: "Narathiwat" },
      { label: "Nong Bua Lam Phu", value: "Nong Bua Lam Phu" },
      { label: "Nong Khai", value: "Nong Khai" },
      { label: "Nonthaburi", value: "Nonthaburi" },
      { label: "Pathum Thani", value: "Pathum Thani" },
      { label: "Pattani", value: "Pattani" },
      { label: "Phangnga", value: "Phangnga" },
      { label: "Phatthalung", value: "Phatthalung" },
      { label: "Phayao", value: "Phayao" },
      { label: "Phetchabun", value: "Phetchabun" },
      { label: "Phetchaburi", value: "Phetchaburi" },
      { label: "Phichit", value: "Phichit" },
      { label: "Phitsanulok", value: "Phitsanulok" },
      { label: "Phrae", value: "Phrae" },
      {
        label: "Phra Nakhon Si Ayutthaya",
        value: "Phra Nakhon Si Ayutthaya",
      },
      { label: "Phaket", value: "Phaket" },
      { label: "Prachin Buri", value: "Prachin Buri" },
      { label: "Prachuap Khiri Khan", value: "Prachuap Khiri Khan" },
      { label: "Ranong", value: "Ranong" },
      { label: "Ratchaburi", value: "Ratchaburi" },
      { label: "Rayong", value: "Rayong" },
      { label: "Roi Et", value: "Roi Et" },
      { label: "Sa Kaeo", value: "Sa Kaeo" },
      { label: "Sakon Nakhon", value: "Sakon Nakhon" },
      { label: "Samut Prakan", value: "Samut Prakan" },
      { label: "Samut Sakhon", value: "Samut Sakhon" },
      { label: "Samut Songkhram", value: "Samut Songkhram" },
      { label: "Saraburi", value: "Saraburi" },
      { label: "Satun", value: "Satun" },
      { label: "Sing Buri", value: "Sing Buri" },
      { label: "Si Sa Ket", value: "Si Sa Ket" },
      { label: "Songkhla", value: "Songkhla" },
      { label: "Sukhothai", value: "Sukhothai" },
      { label: "Suphan Buri", value: "Suphan Buri" },
      { label: "Surat Thani", value: "Surat Thani" },
      { label: "Surin", value: "Surin" },
      { label: "Tak", value: "Tak" },
      { label: "Trang", value: "Trang" },
      { label: "Trat", value: "Trat" },
      { label: "Ubon Ratchathani", value: "Ubon Ratchathani" },
      { label: "Udon Thani", value: "Udon Thani" },
      { label: "Uthai Thani", value: "Uthai Thani" },
      { label: "Uttaradit", value: "Uttaradit" },
      { label: "Yala", value: "Yala" },
      { label: "Yasothon", value: "Yasothon" },
    ],
  },
  {
    label: "Tajikistan",
    options: [
      { label: "Sughd", value: "Sughd" },
      { label: "Khatlon", value: "Khatlon" },
      { label: "Gorno-Badakhshan", value: "Gorno-Badakhshan" },
    ],
  },
  {
    label: "Turkmenistan",
    options: [
      { label: "Ahal", value: "Ahal" },
      { label: "Balkan", value: "Balkan" },
      { label: "Dasoguz", value: "Dasoguz" },
      { label: "Lebap", value: "Lebap" },
      { label: "Mary", value: "Mary" },
    ],
  },
  {
    label: "Tunisia",
    options: [
      { label: "Béja", value: "Béja" },
      { label: "Ben Arous", value: "Ben Arous" },
      { label: "Bizerte", value: "Bizerte" },
      { label: "Gabès", value: "Gabès" },
      { label: "Gafsa", value: "Gafsa" },
      { label: "Jendouba", value: "Jendouba" },
      { label: "Kairouan", value: "Kairouan" },
      { label: "Rasserine", value: "Rasserine" },
      { label: "Kebili", value: "Kebili" },
      { label: "L'Ariana", value: "L'Ariana" },
      { label: "Le Ref", value: "Le Ref" },
      { label: "Mahdia", value: "Mahdia" },
      { label: "La Manouba", value: "La Manouba" },
      { label: "Medenine", value: "Medenine" },
      { label: "Moneatir", value: "Moneatir" },
      { label: "Naboul", value: "Naboul" },
      { label: "Sfax", value: "Sfax" },
      { label: "Sidi Bouxid", value: "Sidi Bouxid" },
      { label: "Siliana", value: "Siliana" },
      { label: "Sousse", value: "Sousse" },
      { label: "Tataouine", value: "Tataouine" },
      { label: "Tozeur", value: "Tozeur" },
      { label: "Tunis", value: "Tunis" },
      { label: "Zaghouan", value: "Zaghouan" },
    ],
  },
  {
    label: "Turkey",
    options: [
      { label: "Adana", value: "Adana" },
      { label: "Ad yaman", value: "Ad yaman" },
      { label: "Afyon", value: "Afyon" },
      { label: "Ag r", value: "Ag r" },
      { label: "Aksaray", value: "Aksaray" },
      { label: "Amasya", value: "Amasya" },
      { label: "Ankara", value: "Ankara" },
      { label: "Antalya", value: "Antalya" },
      { label: "Ardahan", value: "Ardahan" },
      { label: "Artvin", value: "Artvin" },
      { label: "Aydin", value: "Aydin" },
      { label: "Bal kesir", value: "Bal kesir" },
      { label: "Bartin", value: "Bartin" },
      { label: "Batman", value: "Batman" },
      { label: "Bayburt", value: "Bayburt" },
      { label: "Bilecik", value: "Bilecik" },
      { label: "Bingol", value: "Bingol" },
      { label: "Bitlis", value: "Bitlis" },
      { label: "Bolu", value: "Bolu" },
      { label: "Burdur", value: "Burdur" },
      { label: "Bursa", value: "Bursa" },
      { label: "Canakkale", value: "Canakkale" },
      { label: "Cankir", value: "Cankir" },
      { label: "Corum", value: "Corum" },
      { label: "Denizli", value: "Denizli" },
      { label: "Diyarbakir", value: "Diyarbakir" },
      { label: "Duzce", value: "Duzce" },
      { label: "Edirne", value: "Edirne" },
      { label: "Elazig", value: "Elazig" },
      { label: "Erzincan", value: "Erzincan" },
      { label: "Erzurum", value: "Erzurum" },
      { label: "Eskis'ehir", value: "Eskis'ehir" },
      { label: "Gaziantep", value: "Gaziantep" },
      { label: "Giresun", value: "Giresun" },
      { label: "Gms'hane", value: "Gms'hane" },
      { label: "Hakkari", value: "Hakkari" },
      { label: "Hatay", value: "Hatay" },
      { label: "Igidir", value: "Igidir" },
      { label: "Isparta", value: "Isparta" },
      { label: "Icel", value: "Icel" },
      { label: "Istanbul", value: "Istanbul" },
      { label: "Izmir", value: "Izmir" },
      { label: "Kahramanmaras", value: "Kahramanmaras" },
      { label: "Karabk", value: "Karabk" },
      { label: "Karaman", value: "Karaman" },
      { label: "Kars", value: "Kars" },
      { label: "Kastamonu", value: "Kastamonu" },
      { label: "Kayseri", value: "Kayseri" },
      { label: "Kirikkale", value: "Kirikkale" },
      { label: "Kirklareli", value: "Kirklareli" },
      { label: "Kirs'ehir", value: "Kirs'ehir" },
      { label: "Kilis", value: "Kilis" },
      { label: "Kocaeli", value: "Kocaeli" },
      { label: "Konya", value: "Konya" },
      { label: "Ktahya", value: "Ktahya" },
      { label: "Malatya", value: "Malatya" },
      { label: "Manisa", value: "Manisa" },
      { label: "Mardin", value: "Mardin" },
      { label: "Mugila", value: "Mugila" },
      { label: "Mus", value: "Mus" },
      { label: "Nevs'ehir", value: "Nevs'ehir" },
      { label: "Nigide", value: "Nigide" },
      { label: "Ordu", value: "Ordu" },
      { label: "Osmaniye", value: "Osmaniye" },
      { label: "Rize", value: "Rize" },
      { label: "Sakarya", value: "Sakarya" },
      { label: "Samsun", value: "Samsun" },
      { label: "Siirt", value: "Siirt" },
      { label: "Sinop", value: "Sinop" },
      { label: "Sivas", value: "Sivas" },
      { label: "S'anliurfa", value: "S'anliurfa" },
      { label: "S'rnak", value: "S'rnak" },
      { label: "Tekirdag", value: "Tekirdag" },
      { label: "Tokat", value: "Tokat" },
      { label: "Trabzon", value: "Trabzon" },
      { label: "Tunceli", value: "Tunceli" },
      { label: "Us'ak", value: "Us'ak" },
      { label: "Van", value: "Van" },
      { label: "Yalova", value: "Yalova" },
      { label: "Yozgat", value: "Yozgat" },
      { label: "Zonguldak", value: "Zonguldak" },
    ],
  },
  {
    label: "Trinidad and Tobago",
    options: [
      {
        label: "Couva-Tabaquite-Talparo",
        value: "Couva-Tabaquite-Talparo",
      },
      { label: "Diego Martin", value: "Diego Martin" },
      { label: "Eastern Tobago", value: "Eastern Tobago" },
      { label: "Penal-Debe", value: "Penal-Debe" },
      { label: "Princes Town", value: "Princes Town" },
      { label: "Rio Claro-Mayaro", value: "Rio Claro-Mayaro" },
      { label: "Sangre Grande", value: "Sangre Grande" },
      { label: "San Juan-Laventille", value: "San Juan-Laventille" },
      { label: "Siparia", value: "Siparia" },
      { label: "Tunapuna-Piarco", value: "Tunapuna-Piarco" },
      { label: "Western Tobago", value: "Western Tobago" },
      { label: "Arima", value: "Arima" },
      { label: "Chaguanas", value: "Chaguanas" },
      { label: "Point Fortin", value: "Point Fortin" },
      { label: "Port of Spain", value: "Port of Spain" },
      { label: "San Fernando", value: "San Fernando" },
    ],
  },
  {
    label: "Timor-Leste",
    options: [
      { label: "Aileu", value: "Aileu" },
      { label: "Ainaro", value: "Ainaro" },
      { label: "Bacucau", value: "Bacucau" },
      { label: "Bobonaro", value: "Bobonaro" },
      { label: "Cova Lima", value: "Cova Lima" },
      { label: "Dili", value: "Dili" },
      { label: "Ermera", value: "Ermera" },
      { label: "Laulem", value: "Laulem" },
      { label: "Liquica", value: "Liquica" },
      { label: "Manatuto", value: "Manatuto" },
      { label: "Manafahi", value: "Manafahi" },
      { label: "Oecussi", value: "Oecussi" },
      { label: "Viqueque", value: "Viqueque" },
    ],
  },
  {
    label: "Taiwan",
    options: [
      { label: "Changhua", value: "Changhua" },
      { label: "Chiayi", value: "Chiayi" },
      { label: "Hsinchu", value: "Hsinchu" },
      { label: "Hualien", value: "Hualien" },
      { label: "Ilan", value: "Ilan" },
      { label: "Kaohsiung", value: "Kaohsiung" },
      { label: "Miaoli", value: "Miaoli" },
      { label: "Nantou", value: "Nantou" },
      { label: "Penghu", value: "Penghu" },
      { label: "Pingtung", value: "Pingtung" },
      { label: "Taichung", value: "Taichung" },
      { label: "Tainan", value: "Tainan" },
      { label: "Taipei", value: "Taipei" },
      { label: "Taitung", value: "Taitung" },
      { label: "Taoyuan", value: "Taoyuan" },
      { label: "Yunlin", value: "Yunlin" },
      { label: "Keelung", value: "Keelung" },
    ],
  },
  {
    label: "Tanzania, United Republic of",
    options: [
      { label: "Arusha", value: "Arusha" },
      { label: "Dar-es-Salaam", value: "Dar-es-Salaam" },
      { label: "Dodoma", value: "Dodoma" },
      { label: "Iringa", value: "Iringa" },
      { label: "Kagera", value: "Kagera" },
      { label: "Kaskazini Pemba", value: "Kaskazini Pemba" },
      { label: "Kaskazini Unguja", value: "Kaskazini Unguja" },
      { label: "Xigoma", value: "Xigoma" },
      { label: "Kilimanjaro", value: "Kilimanjaro" },
      { label: "Rusini Pemba", value: "Rusini Pemba" },
      { label: "Kusini Unguja", value: "Kusini Unguja" },
      { label: "Lindi", value: "Lindi" },
      { label: "Manyara", value: "Manyara" },
      { label: "Mara", value: "Mara" },
      { label: "Mbeya", value: "Mbeya" },
      { label: "Mjini Magharibi", value: "Mjini Magharibi" },
      { label: "Morogoro", value: "Morogoro" },
      { label: "Mtwara", value: "Mtwara" },
      { label: "Pwani", value: "Pwani" },
      { label: "Rukwa", value: "Rukwa" },
      { label: "Ruvuma", value: "Ruvuma" },
      { label: "Shinyanga", value: "Shinyanga" },
      { label: "Singida", value: "Singida" },
      { label: "Tabora", value: "Tabora" },
      { label: "Tanga", value: "Tanga" },
    ],
  },
  {
    label: "Ukraine",
    options: [
      { label: "Cherkas'ka Oblast'", value: "Cherkas'ka Oblast'" },
      { label: "Chernihivs'ka Oblast'", value: "Chernihivs'ka Oblast'" },
      { label: "Chernivets'ka Oblast'", value: "Chernivets'ka Oblast'" },
      {
        label: "Dnipropetrovs'ka Oblast'",
        value: "Dnipropetrovs'ka Oblast'",
      },
      { label: "Donets'ka Oblast'", value: "Donets'ka Oblast'" },
      {
        label: "Ivano-Frankivs'ka Oblast'",
        value: "Ivano-Frankivs'ka Oblast'",
      },
      { label: "Kharkivs'ka Oblast'", value: "Kharkivs'ka Oblast'" },
      { label: "Khersons'ka Oblast'", value: "Khersons'ka Oblast'" },
      { label: "Khmel'nyts'ka Oblast'", value: "Khmel'nyts'ka Oblast'" },
      { label: "Kirovohrads'ka Oblast'", value: "Kirovohrads'ka Oblast'" },
      { label: "Kyivs'ka Oblast'", value: "Kyivs'ka Oblast'" },
      { label: "Luhans'ka Oblast'", value: "Luhans'ka Oblast'" },
      { label: "L'vivs'ka Oblast'", value: "L'vivs'ka Oblast'" },
      { label: "Mykolaivs'ka Oblast'", value: "Mykolaivs'ka Oblast'" },
      { label: "Odes 'ka Oblast'", value: "Odes 'ka Oblast'" },
      { label: "Poltavs'ka Oblast'", value: "Poltavs'ka Oblast'" },
      { label: "Rivnens'ka Oblast'", value: "Rivnens'ka Oblast'" },
      { label: "Sums 'ka Oblast'", value: "Sums 'ka Oblast'" },
      { label: "Ternopil's'ka Oblast'", value: "Ternopil's'ka Oblast'" },
      { label: "Vinnyts'ka Oblast'", value: "Vinnyts'ka Oblast'" },
      { label: "Volyos'ka Oblast'", value: "Volyos'ka Oblast'" },
      { label: "Zakarpats'ka Oblast'", value: "Zakarpats'ka Oblast'" },
      { label: "Zaporiz'ka Oblast'", value: "Zaporiz'ka Oblast'" },
      { label: "Zhytomyrs'ka Oblast'", value: "Zhytomyrs'ka Oblast'" },
      { label: "Respublika Krym", value: "Respublika Krym" },
      { label: "Kyiv", value: "Kyiv" },
      { label: "Sevastopol", value: "Sevastopol" },
    ],
  },
  {
    label: "Uganda",
    options: [
      { label: "Adjumani", value: "Adjumani" },
      { label: "Apac", value: "Apac" },
      { label: "Arua", value: "Arua" },
      { label: "Bugiri", value: "Bugiri" },
      { label: "Bundibugyo", value: "Bundibugyo" },
      { label: "Bushenyi", value: "Bushenyi" },
      { label: "Busia", value: "Busia" },
      { label: "Gulu", value: "Gulu" },
      { label: "Hoima", value: "Hoima" },
      { label: "Iganga", value: "Iganga" },
      { label: "Jinja", value: "Jinja" },
      { label: "Kabale", value: "Kabale" },
      { label: "Kabarole", value: "Kabarole" },
      { label: "Kaberamaido", value: "Kaberamaido" },
      { label: "Kalangala", value: "Kalangala" },
      { label: "Kampala", value: "Kampala" },
      { label: "Kamuli", value: "Kamuli" },
      { label: "Kamwenge", value: "Kamwenge" },
      { label: "Kanungu", value: "Kanungu" },
      { label: "Kapchorwa", value: "Kapchorwa" },
      { label: "Kasese", value: "Kasese" },
      { label: "Katakwi", value: "Katakwi" },
      { label: "Kayunga", value: "Kayunga" },
      { label: "Kibaale", value: "Kibaale" },
      { label: "Kiboga", value: "Kiboga" },
      { label: "Kisoro", value: "Kisoro" },
      { label: "Kitgum", value: "Kitgum" },
      { label: "Kotido", value: "Kotido" },
      { label: "Kumi", value: "Kumi" },
      { label: "Kyenjojo", value: "Kyenjojo" },
      { label: "Lira", value: "Lira" },
      { label: "Luwero", value: "Luwero" },
      { label: "Masaka", value: "Masaka" },
      { label: "Masindi", value: "Masindi" },
      { label: "Mayuge", value: "Mayuge" },
      { label: "Mbale", value: "Mbale" },
      { label: "Mbarara", value: "Mbarara" },
      { label: "Moroto", value: "Moroto" },
      { label: "Moyo", value: "Moyo" },
      { label: "Mpigi", value: "Mpigi" },
      { label: "Mubende", value: "Mubende" },
      { label: "Mukono", value: "Mukono" },
      { label: "Nakapiripirit", value: "Nakapiripirit" },
      { label: "Nakasongola", value: "Nakasongola" },
      { label: "Nebbi", value: "Nebbi" },
      { label: "Ntungamo", value: "Ntungamo" },
      { label: "Pader", value: "Pader" },
      { label: "Pallisa", value: "Pallisa" },
      { label: "Rakai", value: "Rakai" },
      { label: "Rukungiri", value: "Rukungiri" },
      { label: "Sembabule", value: "Sembabule" },
      { label: "Sironko", value: "Sironko" },
      { label: "Soroti", value: "Soroti" },
      { label: "Tororo", value: "Tororo" },
      { label: "Wakiso", value: "Wakiso" },
      { label: "Yumbe", value: "Yumbe" },
    ],
  },
  {
    label: "United States Minor Outlying Islands",
    options: [
      { label: "Baker Island", value: "Baker Island" },
      { label: "Howland Island", value: "Howland Island" },
      { label: "Jarvis Island", value: "Jarvis Island" },
      { label: "Johnston Atoll", value: "Johnston Atoll" },
      { label: "Kingman Reef", value: "Kingman Reef" },
      { label: "Midway Islands", value: "Midway Islands" },
      { label: "Navassa Island", value: "Navassa Island" },
      { label: "Palmyra Atoll", value: "Palmyra Atoll" },
      { label: "Wake Ialand", value: "Wake Ialand" },
    ],
  },
  {
    label: "Uruguay",
    options: [
      { label: "Artigsa", value: "Artigsa" },
      { label: "Canelones", value: "Canelones" },
      { label: "Cerro Largo", value: "Cerro Largo" },
      { label: "Colonia", value: "Colonia" },
      { label: "Durazno", value: "Durazno" },
      { label: "Flores", value: "Flores" },
      { label: "Lavalleja", value: "Lavalleja" },
      { label: "Maldonado", value: "Maldonado" },
      { label: "Montevideo", value: "Montevideo" },
      { label: "Paysandu", value: "Paysandu" },
      { label: "Rivera", value: "Rivera" },
      { label: "Rocha", value: "Rocha" },
      { label: "Salto", value: "Salto" },
      { label: "Soriano", value: "Soriano" },
      { label: "Tacuarembo", value: "Tacuarembo" },
      { label: "Treinta y Tres", value: "Treinta y Tres" },
    ],
  },
  {
    label: "Uzbekistan",
    options: [
      { label: "Toshkent (city)", value: "Toshkent (city)" },
      {
        label: "Qoraqalpogiston Respublikasi",
        value: "Qoraqalpogiston Respublikasi",
      },
      { label: "Andijon", value: "Andijon" },
      { label: "Buxoro", value: "Buxoro" },
      { label: "Farg'ona", value: "Farg'ona" },
      { label: "Jizzax", value: "Jizzax" },
      { label: "Khorazm", value: "Khorazm" },
      { label: "Namangan", value: "Namangan" },
      { label: "Navoiy", value: "Navoiy" },
      { label: "Qashqadaryo", value: "Qashqadaryo" },
      { label: "Samarqand", value: "Samarqand" },
      { label: "Sirdaryo", value: "Sirdaryo" },
      { label: "Surxondaryo", value: "Surxondaryo" },
      { label: "Toshkent", value: "Toshkent" },
      { label: "Xorazm", value: "Xorazm" },
    ],
  },
  {
    label: "Venezuela",
    options: [
      { label: "Diatrito Federal", value: "Diatrito Federal" },
      { label: "Anzoategui", value: "Anzoategui" },
      { label: "Apure", value: "Apure" },
      { label: "Aragua", value: "Aragua" },
      { label: "Barinas", value: "Barinas" },
      { label: "Carabobo", value: "Carabobo" },
      { label: "Cojedes", value: "Cojedes" },
      { label: "Falcon", value: "Falcon" },
      { label: "Guarico", value: "Guarico" },
      { label: "Lara", value: "Lara" },
      { label: "Merida", value: "Merida" },
      { label: "Miranda", value: "Miranda" },
      { label: "Monagas", value: "Monagas" },
      { label: "Nueva Esparta", value: "Nueva Esparta" },
      { label: "Portuguesa", value: "Portuguesa" },
      { label: "Tachira", value: "Tachira" },
      { label: "Trujillo", value: "Trujillo" },
      { label: "Vargas", value: "Vargas" },
      { label: "Yaracuy", value: "Yaracuy" },
      { label: "Zulia", value: "Zulia" },
      { label: "Delta Amacuro", value: "Delta Amacuro" },
      { label: "Dependencias Federales", value: "Dependencias Federales" },
    ],
  },
  {
    label: "Vanuatu",
    options: [
      { label: "Malampa", value: "Malampa" },
      { label: "Penama", value: "Penama" },
      { label: "Sanma", value: "Sanma" },
      { label: "Shefa", value: "Shefa" },
      { label: "Tafea", value: "Tafea" },
      { label: "Torba", value: "Torba" },
    ],
  },
  {
    label: "Samoa",
    options: [
      { label: "A'ana", value: "A'ana" },
      { label: "Aiga-i-le-Tai", value: "Aiga-i-le-Tai" },
      { label: "Atua", value: "Atua" },
      { label: "Fa'aaaleleaga", value: "Fa'aaaleleaga" },
      { label: "Gaga'emauga", value: "Gaga'emauga" },
      { label: "Gagaifomauga", value: "Gagaifomauga" },
      { label: "Palauli", value: "Palauli" },
      { label: "Satupa'itea", value: "Satupa'itea" },
      { label: "Tuamasaga", value: "Tuamasaga" },
      { label: "Va'a-o-Fonoti", value: "Va'a-o-Fonoti" },
      { label: "Vaisigano", value: "Vaisigano" },
    ],
  },
  {
    label: "Serbia and Montenegro",
    options: [
      { label: "Crna Gora", value: "Crna Gora" },
      { label: "Srbija", value: "Srbija" },
      { label: "Kosovo-Metohija", value: "Kosovo-Metohija" },
      { label: "Vojvodina", value: "Vojvodina" },
    ],
  },
  {
    label: "Yemen",
    options: [
      { label: "Abyan", value: "Abyan" },
      { label: "Adan", value: "Adan" },
      { label: "Ad Dali", value: "Ad Dali" },
      { label: "Al Bayda'", value: "Al Bayda'" },
      { label: "Al Hudaydah", value: "Al Hudaydah" },
      { label: "Al Mahrah", value: "Al Mahrah" },
      { label: "Al Mahwit", value: "Al Mahwit" },
      { label: "Amran", value: "Amran" },
      { label: "Dhamar", value: "Dhamar" },
      { label: "Hadramawt", value: "Hadramawt" },
      { label: "Hajjah", value: "Hajjah" },
      { label: "Ibb", value: "Ibb" },
      { label: "Lahij", value: "Lahij" },
      { label: "Ma'rib", value: "Ma'rib" },
      { label: "Sa'dah", value: "Sa'dah" },
      { label: "San'a'", value: "San'a'" },
      { label: "Shabwah", value: "Shabwah" },
      { label: "Ta'izz", value: "Ta'izz" },
    ],
  },
  {
    label: "South Africa",
    options: [
      { label: "Eastern Cape", value: "Eastern Cape" },
      { label: "Free State", value: "Free State" },
      { label: "Gauteng", value: "Gauteng" },
      { label: "Kwazulu-Natal", value: "Kwazulu-Natal" },
      { label: "Mpumalanga", value: "Mpumalanga" },
      { label: "Northern Cape", value: "Northern Cape" },
      { label: "Limpopo", value: "Limpopo" },
      { label: "Western Cape", value: "Western Cape" },
      { label: "North West", value: "North West" },
    ],
  },
  {
    label: "Zambia",
    options: [
      { label: "Copperbelt", value: "Copperbelt" },
      { label: "Luapula", value: "Luapula" },
      { label: "Lusaka", value: "Lusaka" },
      { label: "North-Western", value: "North-Western" },
    ],
  },
  {
    label: "Zimbabwe",
    options: [
      { label: "Bulawayo", value: "Bulawayo" },
      { label: "Harare", value: "Harare" },
      { label: "Manicaland", value: "Manicaland" },
      { label: "Mashonaland Central", value: "Mashonaland Central" },
      { label: "Mashonaland East", value: "Mashonaland East" },
      { label: "Mashonaland West", value: "Mashonaland West" },
      { label: "Masvingo", value: "Masvingo" },
      { label: "Matabeleland North", value: "Matabeleland North" },
      { label: "Matabeleland South", value: "Matabeleland South" },
      { label: "Midlands", value: "Midlands" },
    ],
  },
  {
    label: "Finland",
    options: [
      { label: "South Karelia", value: "South Karelia" },
      { label: "South Ostrobothnia", value: "South Ostrobothnia" },
      { label: "Etelä-Savo", value: "Etelä-Savo" },
      { label: "Häme", value: "Häme" },
      { label: "Itä-Uusimaa", value: "Itä-Uusimaa" },
      { label: "Kainuu", value: "Kainuu" },
      { label: "Central Ostrobothnia", value: "Central Ostrobothnia" },
      { label: "Central Finland", value: "Central Finland" },
      { label: "Kymenlaakso", value: "Kymenlaakso" },
      { label: "Lapland", value: "Lapland" },
      { label: "Tampere Region", value: "Tampere Region" },
      { label: "Ostrobothnia", value: "Ostrobothnia" },
      { label: "North Karelia", value: "North Karelia" },
      { label: "Nothern Ostrobothnia", value: "Nothern Ostrobothnia" },
      { label: "Northern Savo", value: "Northern Savo" },
      { label: "Päijät-Häme", value: "Päijät-Häme" },
      { label: "Satakunta", value: "Satakunta" },
      { label: "Uusimaa", value: "Uusimaa" },
      { label: "South-West Finland", value: "South-West Finland" },
      { label: "Åland", value: "Åland" },
    ],
  },
  {
    label: "Netherlands Antilles",
    options: [
      { label: "Bonaire", value: "Bonaire" },
      { label: "Curaçao", value: "Curaçao" },
      { label: "Saba", value: "Saba" },
      { label: "St. Eustatius", value: "St. Eustatius" },
      { label: "St. Maarten", value: "St. Maarten" },
    ],
  },
];
