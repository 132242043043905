import React, { Fragment } from "react";
import Columns from "./Data/Columns";
import Filters from "./Data/Filters";
import GroupBy from "./Data/GroupBy";

function Data({
  modifiedData,
  setModifiedData,
  sortOptionsByIndex,
  filterOptionsBySearch,
  createHandleSelectAll,
  createHandleDeselectAll,
  handleSelectChange,
  createHandleSearchChange,
  aggregators,
  groupBy,
  filters,
  columns,
  id,
}) {

  const handleDelete = (propertyName, keyToDelete) => {
    setModifiedData((prevData) => {
      if (prevData[propertyName] && typeof prevData[propertyName] === "object") {
        const updatedObject = { ...prevData[propertyName] };
        delete updatedObject[keyToDelete];
        return {
          ...prevData,
          [propertyName]: updatedObject,
        };
      }
      return prevData;
    });
  };

  return (
    <Fragment>
      <Columns
        modifiedData={modifiedData}
        setModifiedData={setModifiedData}
        handleSelectChange={handleSelectChange}
        createHandleSearchChange={createHandleSearchChange}
        createHandleSelectAll={createHandleSelectAll}
        createHandleDeselectAll={createHandleDeselectAll}
        sortOptionsByIndex={sortOptionsByIndex}
        handleDelete={handleDelete}
        filterOptionsBySearch={filterOptionsBySearch}
        columns={columns}
      />

      <Filters
        modifiedData={modifiedData}
        setModifiedData={setModifiedData}
        handleDelete={handleDelete}
        filters={filters}
      />

       <GroupBy
        modifiedData={modifiedData}
        setModifiedData={setModifiedData}
        handleSelectChange={handleSelectChange}
        createHandleSearchChange={createHandleSearchChange}
        createHandleSelectAll={createHandleSelectAll}
        createHandleDeselectAll={createHandleDeselectAll}
        sortOptionsByIndex={sortOptionsByIndex}
        handleDelete={handleDelete}
        filterOptionsBySearch={filterOptionsBySearch}
        aggregators={aggregators}
        groupBy={groupBy}
        id={id}
      /> 
    </Fragment>
  );
}

export default Data;
