import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Select } from "../../../reusableComponents/Inputs";
import Button from "../../../reusableComponents/Buttons/Button";
import { useParams } from "react-router-dom";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import { t } from "i18next";
import { contactsApi, useAddCompanyToContactMutation } from "../../../../redux/api/contactsApi";
import { Error, Success } from "../../../reusableComponents/Alerts";

function ContactsToCompanyModal({ setShowAlert, setAlertMessage, setBackgroundToast, refetch }) {
  const [openCompanyToContactModal, setOpenCompanyToContactModal] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [cursor, setCursor] = useState("default");
  const { id } = useParams();

  const [triggerContactsQuery] = contactsApi.useLazyGetContactsQuery();
  const [addContactToCompany] = useAddCompanyToContactMutation();
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleAddContactToCompany = () => {
    const response = addContactToCompany({ contactId: contactId, companyId: id });
    if (!response.error) {
      setShowAlert(true);
      setAlertMessage(
        t("caymland.message.type.successfully", {
          type: t("caymland.lead.segments.contacts.added"),
        })
      );
      refetch();
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3000);
      setOpenCompanyToContactModal(false);
    } else {
      Error(t("caymland.message.error"));
    }
  };

  const retrieveContacts = (inputValue, bundle, includeGlobal = true) =>
    new Promise(async (resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        const query = {
          pageIndex: 0,
          pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
          orderByDir: "desc",
          orderBy: "last_active",
        };
        if (inputValue.length) {
          query.search = inputValue;
        }

        const response = await triggerContactsQuery(query);
        if (!response.error) {
          const contactsData = response?.data?.data;
          const contactsArray = contactsData?.map((contact) => {
            const firstname = contact?.fields?.all?.firstname || "";
            const lastname = contact?.fields?.all?.lastname || "";
            const email = contact?.fields?.all?.email || "";

            const label = `${firstname} ${lastname}`.trim();

            return {
              label: label ? `${label} | ${email}` : email,
              value: contact?.id,
            };
          });

          resolve(contactsArray);
        } else {
          reject();
        }
      }, 500);

      setDebounceTimeout(newTimeout);
    });

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: () => setOpenCompanyToContactModal(false),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <TbDeviceFloppy size={15} />,
      onClick: handleAddContactToCompany,
    },
  ];

  return (
    <>
      <span
        onMouseOver={() => setCursor("pointer")}
        onMouseOut={() => setCursor("default")}
        onClick={() => setOpenCompanyToContactModal(true)}
        style={{ cursor: cursor }}
      >
        <Tooltip title={t("caymland.contacts.link.company")} arrow>
          <FaPlus color="#686868" size={17} style={{ marginRight: "4px" }} />
        </Tooltip>
      </span>
      <Modal show={openCompanyToContactModal} onHide={() => setOpenCompanyToContactModal(false)} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              color: "#000",
              fontSize: "16px",
              fontWeight: "500",
              padding: "4px",
            }}
          >
            {t("caymland.lead.linked.form.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select options={retrieveContacts} name="link" onChange={(e) => setContactId(e.value)} value={contactId} />
        </Modal.Body>
        <Modal.Footer>
          <Button buttons={buttons} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContactsToCompanyModal;
