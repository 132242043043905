import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import Button from "../../reusableComponents/Buttons/Button";
import { useParams } from "react-router-dom";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import { t } from "i18next";
import StaticCompanySelect from "./StaticCompanySelect";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"; // Correct import
import * as yup from "yup";
import { useAddCompanyToContactMutation, useUpdateContactsMutation } from "../../../redux/api/contactsApi";

function LinkCompaniesToContactModal({ setShow, setMessage, replaceCompanies, companiesFields, setBackgroundToast }) {
  const [openCompanyToContactModal, setOpenCompanyToContactModal] = useState(false);
  const [cursor, setCursor] = useState("default");
  const { id } = useParams();
  const [addCompanyToContact] = useAddCompanyToContactMutation();
  const [updateContact] = useUpdateContactsMutation();

  const validationSchema = yup.object().shape({
    companies: yup.array().min(1, t("caymland.core.fieldrequired")).required(t("caymland.core.fieldrequired")),
  });

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (companiesFields) {
      const mergedData = {
        companies: companiesFields?.map((company) => ({ value: company.company_id, label: company.companyname })),
      };
      reset(mergedData);
    }
  }, [companiesFields, reset]);

  useEffect(() => {
    if (errors.companies) {
      setShow(true);
      setMessage(t("caymland.core.error.not.valid"));
      setBackgroundToast("2");
      setTimeout(() => {
        setMessage("");
        setShow(false);
        setBackgroundToast("1");
      }, 3000);
    }
  }, [errors, setShow, setMessage, setBackgroundToast]);

  const addCompanyToContactFunc = async (data) => {
    const dataToSend = data.companies.map((company) => company.value);
    try {
      const response = await updateContact({ id, companies: dataToSend });
      if (!response.errors) {
        setOpenCompanyToContactModal(false);
        replaceCompanies(response.data.contact.fields.companies);
        setShow(true);
        setMessage(t("caymland.lead.company.messsage"));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      } else {
        setShow(true);
        setMessage(response.error.data.errors[0].message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {}
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: () => {
        setOpenCompanyToContactModal(false);
        reset();
      },
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <TbDeviceFloppy size={15} />,
      onClick: handleSubmit(addCompanyToContactFunc),
    },
  ];

  return (
    <>
      <span
        onMouseOver={() => setCursor("pointer")}
        onMouseOut={() => setCursor("default")}
        onClick={() => setOpenCompanyToContactModal(true)}
        style={{ cursor: cursor }}
      >
        <Tooltip title={t("caymland.contacts.link.company")} arrow>
          <FaPlus color="#686868" size={17} style={{ marginRight: "4px" }} />
        </Tooltip>
      </span>
      <Modal
        show={openCompanyToContactModal}
        onHide={() => {
          setOpenCompanyToContactModal(false);
          reset();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              color: "#000",
              fontSize: "16px",
              fontWeight: "500",
              padding: "4px",
            }}
          >
            {t("caymland.company.linked.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StaticCompanySelect
            setValue={setValue}
            getValues={getValues}
            control={control}
            errors={errors && errors["companies"]}
            companiesFields={companiesFields}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button buttons={buttons} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LinkCompaniesToContactModal;
