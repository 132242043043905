import React, { useState, useEffect, Fragment, useRef, useMemo } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ViewFieldsForm from "./ViewFieldsForms";
import { useForm } from "react-hook-form";
import { useUpdateContactsMutation } from "../../../../redux/api/contactsApi";
import { AiOutlineSave } from "react-icons/ai";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { t } from "i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaRegTimesCircle } from "react-icons/fa";
import { BsFillTelephoneOutboundFill, BsLink45Deg, BsSendFill } from "react-icons/bs";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdOutlineExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";
import { useTheme } from "@mui/material/styles";
import { useGetGroupOptionsQuery } from "../../../../redux/api/fieldsApi";

const FieldsForm = ({ contactFields, data, setShow, setMessage, setPointsValue, setBackgroundToast }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [primaryColorTab, setPrimaryColorTab] = useState(0);
  const [groupedFields, setGroupedFields] = useState({});
  const [fieldDisplayLimits, setFieldDisplayLimits] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const { palette } = useTheme();

  const { data: groupTabs } = useGetGroupOptionsQuery();

  const {
    register,
    control,
    watch,
    reset,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    resolver: yupResolver(),
  });
  useEffect(() => {
    if (data) {
      const mergedData = {
        ...data.fields.all,
        stage: data?.stage?.id || null,
        tags: data?.tags?.map((tag) => tag?.id) || [],
        owner: data?.owner?.id,
      };
      reset(mergedData);
      setInitialValues(mergedData);
    }
  }, [data, reset]);

  useEffect(() => {
    if (contactFields) {
      function groupFieldsByTab(fields) {
        const grouped = {};
        fields.forEach((field) => {
          if (field.isPublished && field.isVisible) {
            const groupName = field.group || "other";
            if (!grouped[groupName]) {
              grouped[groupName] = [];
            }
            grouped[groupName].push(field);
          }
        });

        if (grouped["core"]) {
          const orderedGrouped = { core: grouped["core"] };
          Object.keys(grouped).forEach((groupName) => {
            if (groupName !== "core") {
              orderedGrouped[groupName] = grouped[groupName];
            }
          });
          return orderedGrouped;
        }

        return grouped;
      }

      const grouped = groupFieldsByTab(contactFields);
      setGroupedFields(grouped);

      // Initialize display limits for each tab
      const displayLimits = Object.keys(grouped).reduce((acc, groupName) => {
        acc[groupName] = 16;
        return acc;
      }, {});
      setFieldDisplayLimits(displayLimits);
    }
  }, [contactFields]);
  const formRef = useRef(); // Create a ref for the form

  const changeDynamicTab = (index, groupName) => {
    setPrimaryColorTab(index);
    setCurrentTab(index);
  };

  const [updateContact] = useUpdateContactsMutation(); // Initialize the mutation hook

  const updateFields = async () => {
    const payload = Object.keys(dirtyFields).reduce((acc, field) => {
      const value = watch(field);

      // Find the field definition in contactFields using the alias
      const fieldDefinition = contactFields.find((contactField) => contactField.alias === field);

      // Check if the field type is multiselect and handle accordingly
      if (fieldDefinition && fieldDefinition.type === "multiselect") {
        acc[field] = value === "" ? [] : value; // Empty array for empty string, otherwise use the value
      } else {
        acc[field] = value === "" ? "" : value; // Empty string for empty string, otherwise use the value
      }

      return acc;
    }, {});

    try {
      const response = await updateContact({
        id: data.id,
        ...payload,
      }).unwrap();

      if (!response.errors) {
        reset(response?.contact?.fields?.all);
        setPointsValue(response?.contact?.points);
        setShow(true);
        setMessage(t("caymland.core.setmessage.contact"));
        setBackgroundToast("1");
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      } else {
        setShow(true);
        setMessage(response.error.data.errors[0].message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
    } catch (error) {
      // Handle error
      console.error("Error updating contact:", error);
    }
  };

  const toggleFieldDisplay = (groupName) => {
    setFieldDisplayLimits({
      ...fieldDisplayLimits,
      [groupName]: fieldDisplayLimits[groupName] > 16 ? 16 : groupedFields[groupName].length,
    });
  };

  function calculateAge(birthday) {
    if (!birthday) return ""; // If no date is provided, return an empty string

    const birthDate = new Date(birthday);

    // Check if the provided birthday is a valid date
    if (isNaN(birthDate.getTime())) return "";

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  const isValidUrl = (string) => {
    try {
      new URL(string); // If it can be parsed as a URL, it's valid
      return true;
    } catch (_) {
      return false;
    }
  };
  const [flexWrap, setFlexWrap] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const tabsRef = useRef(null); // Ref for the tabs container
  useEffect(() => {
    const checkOverflow = () => {
      if (tabsRef.current) {
        setIsOverflowing(tabsRef.current.scrollWidth > tabsRef.current.clientWidth);
      }
    };

    const observer = new MutationObserver(() => {
      setTimeout(checkOverflow, 100); // Small delay to ensure DOM updates
    });

    if (tabsRef.current) {
      observer.observe(tabsRef.current, { childList: true, subtree: true });
    }

    checkOverflow(); // Initial check
    window.addEventListener("resize", checkOverflow); // Recheck on window resize

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [groupedFields, groupTabs]); // Re-run when groupedFields updates

  const sortedGroupTabs = useMemo(() => {
    if (!groupTabs) return [];
    return [...groupTabs].sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
  }, [groupTabs]);

  // Ensure groupedFields follows the same sorted order
  const sortedGroupedFields = useMemo(() => {
    if (!groupedFields) return {};
    const sortedKeys = Object.keys(groupedFields).sort((a, b) => {
      const tabA = sortedGroupTabs.find((tab) => tab.value === a);
      const tabB = sortedGroupTabs.find((tab) => tab.value === b);
      return (tabA?.label || a).localeCompare(tabB?.label || b, undefined, { numeric: true });
    });

    return sortedKeys.reduce((acc, key) => {
      acc[key] = groupedFields[key];
      return acc;
    }, {});
  }, [groupedFields, sortedGroupTabs]);

  // Update the active tab index correctly based on sortedGroupTabs

  return (
    <div ref={formRef} className="w-full ">
      <div className="flex items-center justify-between" style={{ borderBottom: "2px solid lightgrey" }}>
        <Nav className="nav-primary w-full d-flex justify-between tw-max-w-[92%]" style={{ border: "none" }} tabs>
          <div
            ref={tabsRef} // Attach the ref to the container
            className="flex"
            style={{ flexWrap: flexWrap ? "wrap" : "nowrap", overflow: "hidden" }}
          >
            {/* Ensure groupTabs is available before attempting to render */}
            {sortedGroupTabs.map((tab, index) => {
              const groupName = tab.value;
              if (!sortedGroupedFields[groupName]) return null; // Skip if no corresponding group

              return (
                <NavItem key={index} style={{ border: "none" }}>
                  <NavLink
                    href="#"
                    className={primaryColorTab === index ? "active" : ""}
                    onClick={() => changeDynamicTab(index)}
                    style={{
                      color: primaryColorTab === index ? "white" : palette.text.primary,
                      textTransform: "capitalize",
                      cursor: "pointer",
                      backgroundColor: primaryColorTab === index ? palette?.primary?.main : "",
                      flexWrap: "nowrap",
                      whiteSpace: "nowrap",
                    }}
                    title={tab.label}
                  >
                    {tab.label}
                  </NavLink>
                </NavItem>
              );
            })}
          </div>
        </Nav>

        {isOverflowing && (
          <div>
            <button onClick={() => setFlexWrap(!flexWrap)}>
              {!flexWrap ? <MdOutlineExpandMore size={30} /> : <MdExpandLess size={30} />}
            </button>
          </div>
        )}
      </div>
      <TabContent activeTab={currentTab} style={{ padding: "20px" }}>
        {sortedGroupTabs.map((tab, index) => {
          const groupName = tab.value;
          if (!sortedGroupedFields[groupName]) return null;

          return (
            <TabPane tabId={index} key={index}>
              {sortedGroupedFields[groupName].slice(0, fieldDisplayLimits[groupName] || 16).map((field, fieldIndex) => {
                if (field.alias === "company" || field.alias === "points") {
                  return null; // Skip fields that should not be rendered
                }

                return (
                  <Fragment key={fieldIndex}>
                    <div className="tw-flex gap-2 tw-items-center">
                      <div className="w-full tw-flex tw-flex-col">
                        {field.alias !== "points" ? (
                          <span style={{ fontSize: "13px", color: "#6C757C", marginTop: "10px" }}>{field.label}</span>
                        ) : null}
                        <ViewFieldsForm
                          field={field}
                          control={control}
                          setValue={setValue}
                          register={register}
                          watch={watch}
                        />
                      </div>
                      <div className="tw-mt-[30px]">
                        {field.type === "email" && watch(field.alias) && (
                          <span style={{ cursor: "pointer" }}>
                            <a href={`mailto:${watch(field.alias)}`} target="_blank" rel="noopener noreferrer">
                              <BsSendFill size={15} className="hover-scale " />
                            </a>
                          </span>
                        )}
                        {field.alias === "birthday" && watch(field.alias) && (
                          <span style={{ color: "#135f95", cursor: "help" }} title={t("caymland.core.time.years")}>
                            {t("caymland.lead.fields.age") + ": " + calculateAge(watch(field.alias))}
                          </span>
                        )}
                        {field.type === "tel" && watch(field.alias) && (
                          <span style={{ cursor: "pointer" }}>
                            <a href={`tel:${watch(field.alias)}`} target="_blank" rel="noopener noreferrer">
                              <BsFillTelephoneOutboundFill size={15} className="hover-scale" />
                            </a>
                          </span>
                        )}
                        {field.type === "url" && watch(field.alias) && (
                          <span style={{ cursor: "pointer" }}>
                            <a href={watch(field.alias)} target="_blank" rel="noopener noreferrer">
                              <FaExternalLinkAlt size={15} className="hover-scale " />
                            </a>
                          </span>
                        )}
                        {isValidUrl(watch(field.alias)) && field.type !== "url" && (
                          <span style={{ cursor: "pointer" }}>
                            <a href={watch(field.alias)} target="_blank" rel="noopener noreferrer">
                              <FaExternalLinkAlt size={15} className="hover-scale " />
                            </a>
                          </span>
                        )}
                      </div>
                      {dirtyFields[field.alias] && (
                        <div
                          className="d-flex items-center gap-5px"
                          style={{
                            alignSelf: "center",
                            marginTop: "30px",
                          }}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              const fieldName = field.alias;
                              if (!(fieldName in initialValues)) {
                                setValue(fieldName, undefined, { shouldDirty: true });
                              } else {
                                setValue(fieldName, initialValues[fieldName], {
                                  shouldDirty: true,
                                });
                              }
                            }}
                          >
                            <FaRegTimesCircle color="#f86b4f" size={18} title={t("caymland.core.form.cancel")} />
                          </div>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              updateFields(field.id);
                            }}
                          >
                            <AiOutlineSave color="#135F95" size={20} title={t("caymland.lead.field.save.tooltip")} />
                          </div>
                        </div>
                      )}
                    </div>
                  </Fragment>
                );
              })}
              <div className="d-flex justify-center items-center mt-10">
                {sortedGroupedFields[groupName].length > 16 && (
                  <button
                    className="result-component"
                    style={{ height: "20px", paddingLeft: "10px", paddingRight: "10px" }}
                    onClick={() => toggleFieldDisplay(groupName)}
                  >
                    {fieldDisplayLimits[groupName] > 16 ? (
                      <i className="d-flex justify-center items-center" style={{ fontSize: "12px" }}>
                        <KeyboardDoubleArrowUpIcon fontSize="14px" /> {t("caymland.core.show.less")}
                      </i>
                    ) : (
                      <i className="d-flex justify-center items-center" style={{ fontSize: "12px" }}>
                        <KeyboardDoubleArrowDownIcon fontSize="14px" /> {t("caymland.core.show.more")}
                      </i>
                    )}
                  </button>
                )}
              </div>
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};

export default FieldsForm;
