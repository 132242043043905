import React, { useEffect, useMemo, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { t } from "i18next";
import {
  useAddDocumentNoteMutation,
  useDownloadContractNoteDocumentMutation,
} from "../../../../../redux/api/contractsApi";
import CustomTable from "../../../contacts/CustomTable/CustomTable";
import { Controller, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";

const Documents = ({ control, noteData, updateNoteData, setValue, currentIndex }) => {
  const [tempFileName, setTempFileName] = useState();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `notes[${currentIndex}].documents`, // This should match the form field name
  });

  console.log(fields);

  const user = useSelector((state) => state.auth.user);

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
    orderBy: "uf.dateAdded",
  });

  const [documents, setDocuments] = useState([]);

  const [addDocumentNote] = useAddDocumentNoteMutation();

  useEffect(() => {
    if (noteData) {
      setDocuments(noteData?.documents);
    }
  }, [noteData]);

  const getUploadParams = async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await addDocumentNote(formData).unwrap();

      // Create new document object
      const newDocument = {
        id: response.fileId,
        fileName: response.fileName || response.fileId,
        size: response.size,
        owner: response.user?.name,
      };

      // Update state using a function to ensure previous state is maintained
      setDocuments((prevDocuments) => {
        const updatedDocuments = [...prevDocuments, newDocument];
        setValue(`notes[${currentIndex}].documents`, updatedDocuments);
        return updatedDocuments;
      });

      // Append to react-hook-form field array
      append(newDocument);

      return { meta: { uploadUrl: response } };
    } catch (error) {
      console.error("File upload failed:", error);
      return { meta: { uploadUrl: null } };
    }
  };

  const handleRemoveDocument = (fileId) => {
    // Filter out the removed document
    const updatedDocuments = documents.filter((doc) => doc.id !== fileId);

    console.log("Before remove:", documents);
    console.log("Removing fileId:", fileId);
    console.log("Updated Documents:", updatedDocuments);

    // Update the state
    setDocuments(updatedDocuments);

    // Update the form field value
    setValue(`notes[${currentIndex}].documents`, updatedDocuments);

    // Find index in useFieldArray fields and remove
    const index = fields.findIndex((field) => field.id === fileId);
    if (index !== -1) {
      remove(index);
    }
  };

  const formatSize = (sizeInBytes) => {
    if (!sizeInBytes) return "0 Bytes";

    const units = ["Bytes", "KB", "MB", "GB", "TB"];
    let index = 0;

    let size = sizeInBytes;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }

    return `${size.toFixed(2)} ${units[index]}`;
  };

  const [downloadFile] = useDownloadContractNoteDocumentMutation();

  const columns = useMemo(() => [
    {
      accessorKey: "fileName",
      accessorFn: (row) => {
        console.log(row);
        // Determine display name
        const displayName =
          row?.fileOriginalName?.length > 30
            ? row?.fileOriginalName.substring(0, 30) + "..."
            : row?.fileOriginalName || row?.fileName;

        // Function to handle file download
        const handleFileClick = async (e) => {
          e.preventDefault();
          try {
            const response = await downloadFile(row.fileName).unwrap();
            const blob = new Blob([response], { type: response.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = row.fileOriginalName || "downloaded_file";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Failed to download file", error);
          }
        };

        // Check if the row contains a valid file name
        const isDownloadable = row?.fileOriginalName && row?.fileName;

        return isDownloadable ? (
          <a href="#" onClick={handleFileClick}>
            {displayName}
          </a>
        ) : (
          <span style={{ color: "gray", cursor: "default" }}>{displayName}</span>
        );
      },
      header: t("caymland.core.name"),
      enableColumnActions: false,
    },
    {
      accessorKey: "size",
      accessorFn: (row) => formatSize(row?.size),
      header: t("caymland.focus.form.bar.size"),
      enableColumnActions: false,
    },
    {
      accessorKey: "added",
      accessorFn: (row) =>
        row.owner.firstName
          ? row?.owner?.firstName + " " + row?.owner?.lastName
          : user?.firstName + " " + user?.lastName,
      header: t("caymland.lead.segments.contacts.added"),
      enableColumnActions: false,
    },
    {
      accessorKey: "actions",
      accessorFn: (row) => (
        <button
          onClick={(e) => {
            e.preventDefault(); // Prevent default behavior

            console.log("test");
            handleRemoveDocument(row.id); // Call the function only on click
          }}
          className="btn btn-sm btn-danger"
        >
          {t("caymland.core.remove")}
        </button>
      ),
      header: t("caymland.page.tracking.form.action"),
      enableColumnActions: false,
    },
  ]);

  return (
    <div>
      <Controller
        name="documents"
        control={control}
        render={({ field: { name, onChange, value } }) => (
          <Dropzone
            name={name}
            getUploadParams={getUploadParams}
            onChangeStatus={({ meta, file }, status) => {
              if (status === "done") {
                console.log("File uploaded:", file);
              }
            }}
            accept="*"
            showPreview={false}
            showUploadButton={false}
            inputContent={t("caymland.asset.drop.file.here")}
            multiple
            canRestart={false}
            styles={{
              dropzoneActive: { borderColor: "green" },
            }}
          />
        )}
      />

      <CustomTable
        data={documents}
        columns={columns}
        query={query}
        setQuery={() => {}}
        smallHeight
        actions={false}
        linkNavigate={null}
      />
    </div>
  );
};

export default Documents;
