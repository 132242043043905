import React from "react";
import CommentSection from "./CommentSection";
import InvoicesSection from "./InvoicesSection";

const Contract = ({ control, watch }) => {
  return (
    <div className="tw-p-4 tw-w-[50%] tw-flex-col tw-gap-[20px]">
      <InvoicesSection control={control} watch={watch} />
      <CommentSection control={control} />
    </div>
  );
};

export default Contract;
