import React, { useState } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import { v4 as uuidv4 } from "uuid"; // For generating unique IDs
import { TextArea } from "../../../reusableComponents/Inputs";
import Swal from "sweetalert2";
import { t } from "i18next";
import { useTheme } from "@mui/material/styles";

const CommentSection = ({ control }) => {
  const { palette } = useTheme();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "comments", // The name of the array in the form state
  });

  const comments = useWatch({ control, name: "comments" }); // Dynamically track changes in comments
  const [newComment, setNewComment] = useState("");
  const [editingCommentIndex, setEditingCommentIndex] = useState(null); // Track the comment being edited

  // Add a new comment
  const handleAddComment = () => {
    if (newComment.trim() === "") return; // Avoid empty comments
    append({
      id: uuidv4(),
      message: newComment,
      dateAdded: new Date().toISOString(),
      createdByUser: "Current User", // Replace with actual user
    });
    setNewComment("");
  };

  const handleCancelEdit = () => {
    setEditingCommentIndex(null); // Exit edit mode
  };

  return (
    <div className="tw-mt-[20px] tw-rounded-[6px] tw-w-full tw-h-[800px] tw-overflow-y-auto">
      {/* Comment Input Section */}
      <div className="tw-flex tw-gap-1 tw-mb-2">
        <input
          type="text"
          className="tw-border tw-border-gray-300 tw-px-2 tw-py-1 tw-rounded tw-w-full tw-text-sm focus:tw-outline-none focus:tw-border-[#135f95] focus:tw-ring-1 focus:tw-ring-[#135f95]"
          placeholder={t("caymland.contract.write.comment")}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <button
          onClick={handleAddComment}
          className="tw-bg-[#135f95] tw-text-white tw-px-3 tw-py-1 tw-rounded tw-text-sm"
          style={{ backgroundColor: palette?.primary?.main }}
        >
          {t("caymland.core.form.add")}
        </button>
      </div>

      {/* List of Comments */}
      {fields.length > 0 ? (
        <ul className="tw-space-y-2 tw-p-0">
          {fields.map((field, index) => (
            <li key={field.id} className="tw-border tw-p-2 tw-bg-[#f8f8f8] tw-rounded tw-flex tw-flex-col tw-gap-1">
              <div className="tw-flex tw-justify-between tw-items-center">
                <p className="tw-font-medium tw-text-sm">{comments?.[index]?.createdByUser || "Unknown User"}</p>
                <p className="tw-text-xs tw-text-gray-500">{new Date(comments?.[index]?.dateAdded).toLocaleString()}</p>
                <div className="tw-flex tw-gap-2">
                  <button
                    onClick={() => {
                      if (editingCommentIndex === index) {
                        handleCancelEdit(); // Exit edit mode
                      } else {
                        setEditingCommentIndex(index); // Enter edit mode
                      }
                    }}
                    className="tw-text-blue-500 tw-border tw-border-blue-500 tw-rounded tw-px-2 tw-py-1 tw-text-xs"
                  >
                    {editingCommentIndex === index ? t("caymland.core.form.save") : t("caymland.core.form.edit")}
                  </button>
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: t("caymland.badge_contact.form.confirmdelete"),
                        text: `${t("caymland.message.delete", { name: "comment" })}`,
                        icon: "question",
                        confirmButtonText: t("caymland.core.form.delete"),
                        cancelButtonText: t("caymland.core.form.cancel"),
                        showCancelButton: true,
                        customClass: {
                          confirmButton: "delete-component swal-custom",
                          cancelButton: "result-component swal-custom",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          console.log("Deleting comment...");
                          remove(index);
                        } else {
                          console.log("Deletion cancelled.");
                        }
                      });
                    }}
                    className="tw-text-red-500 tw-border tw-border-red-500 tw-rounded tw-px-2 tw-py-1 tw-text-xs"
                  >
                    {t("caymland.core.form.delete")}
                  </button>
                </div>
              </div>

              {/* Render Comment as View or Editable TextArea */}
              {editingCommentIndex === index ? (
                <Controller
                  name={`comments[${index}].message`}
                  control={control}
                  defaultValue={field.message}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      className="tw-border tw-rounded tw-w-full tw-px-1 tw-py-1 tw-text-sm"
                      onBlur={(e) => {
                        field.onChange(e.target.value); // Update the form state
                      }}
                    />
                  )}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: comments?.[index]?.message || "",
                  }}
                  className="tw-bg-white tw-px-2 tw-py-1 tw-rounded tw-text-sm"
                ></div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p className="tw-text-gray-500 tw-text-sm">{t("caymland.contract.no.comments")}</p>
      )}
    </div>
  );
};

export default CommentSection;
