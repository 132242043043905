import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../reusableComponents/Buttons/Button";
import "react-dropzone-uploader/dist/styles.css";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSend } from "react-icons/ai";
import { Text, TextArea } from "../Inputs";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import { t } from "i18next";

const CustomModal = ({ show, handleClose }) => {
  const [modifiedData, setModifiedData] = useState({
    "task[created_by_name]": "",
    "task[created_by_email]": "",
    "task[name]": "",
    "task[body]": "",
    submitted: "submitted",
    "task[assignee_id]": process.env.REACT_APP_SUPPORT_ASSIGNEE_ID,
  });
  const [attachmentCounter, setAttachmentCounter] = useState(1);

  const onFieldChange = (fieldName, value) => {
    setModifiedData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const onFileChange = (fileData) => {
    const attachmentKey = `attachments_${attachmentCounter}`;
    setModifiedData((prevData) => ({
      ...prevData,
      [attachmentKey]: fileData,
    }));
    setAttachmentCounter(attachmentCounter + 1);
  };

  const submitSupportTicket = () => {
    const endpoint =
      "https://proj.m-4.eu/public/api.php?path_info=projects/1/tasks/add&auth_api_token=1-IUCFjnxHD6xuWt05sttrCHaLV2aJFmRd8iIgv7OY&format=json";
    const data = new FormData();

    for (const key in modifiedData) {
      if (modifiedData.hasOwnProperty(key)) {
        data.append(key, modifiedData[key]);
      }
    }

    for (let i = 1; i <= attachmentCounter; i++) {
      const attachmentKey = `attachments_${i}`;
      const attachmentFile = modifiedData[attachmentKey];
      if (attachmentFile) {
        data.append(attachmentKey, attachmentFile);
      }
    }

    const config = {
      method: "post",
      url: endpoint,
      headers: {
        Cookie:
          "PHPSESSID=51omdfsb0em8tufduh7qsla2f5; bespoke_base_url=https%3A%2F%2Fproj.m-4.eu%2Fpublic%2Fassets%2F..%2Fbrand%2F",
      },
      timeout: 5000,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleClose,
    },
    {
      name: "submit",
      title: t("caymland.ticket.submit.request"),
      icon: <AiOutlineSend size={15} />,
      onClick: submitSupportTicket,
    },
  ];
  return (
    <Modal show={show} onHide={handleClose}   backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("caymland.core.supportticket")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text
          name="name"
          label={t("caymland.ticket.form.name")}
          value={modifiedData["task[created_by_name]"]}
          onChange={(e) => onFieldChange("task[created_by_name]", e.target.value)}
          required={true}
        />
        <Text
          name="email"
          label={t("caymland.ticket.form.email")}
          value={modifiedData["task[created_by_email]"]}
          onChange={(e) => onFieldChange("task[created_by_email]", e.target.value)}
          required={true}
        />
        <Text
          name="title"
          label={t("caymland.ticket.form.title")}
          value={modifiedData["task[name]"]}
          onChange={(e) => onFieldChange("task[name]", e.target.value)}
          required={true}
        />
        <TextArea
          name="comment"
          label={t("caymland.ticket.form.comment")}
          value={modifiedData["task[body]"] ?? ""}
          onChange={(data) => {
            setModifiedData((prev) => ({
              ...prev,
              "task[body]": data,
            }));
          }}
        />

        <p>{t("caymland.ticket.form.attachment")}</p>
        <form className="dropzone digits mt-10px" id="singleFileUpload" action="/upload.php">
          <div className="dz-message needsclick h-200-dropzone">
            <Dropzone
              maxFiles={5}
              multiple={true}
              canCancel={false}
              inputContent={t("caymland.ticket.form.file")}
              styles={{
                dropzoneActive: { borderColor: "green" },
              }}
              onChangeStatus={(file, status) => {
                if (status === "done") {
                  const fileData = file.file;
                  onFileChange(fileData);
                }
              }}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
