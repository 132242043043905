import React, { useMemo, useCallback } from "react";
import QueryBuilder from "react-querybuilder";
import "react-querybuilder/dist/query-builder.scss";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Select from "react-select";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import { IncomingTypeReusible } from "./IncomingTypeReusible";
import { FiltersFieldSelector } from "./FiltersFieldSelector";
import Button from "../../../../../reusableComponents/Buttons/Button";
import ValueEditor from "./ValueEditor";
import { t } from "i18next";

const QueryFiltersReusible = ({ query, setQuery, loading, fields }) => {
  console.log(fields);
  const CustomCombinatorSelector = (props) => {
    const { value, handleOnChange, disabled, options } = props;

    const combinatorOptions = useMemo(
      () =>
        options.map((option) => ({
          label: option.label || fields[0],
          value: option.name || fields[0],
        })),
      [options]
    );

    const currentValue = useMemo(
      () =>
        combinatorOptions.find(
          (option) => option.value === (query?.condition?.toLowerCase() || query?.combinator?.toLowerCase())
        ),
      [combinatorOptions, value]
    );

    const handleChange = useCallback(
      (selectedOption) => {
        if (handleOnChange && typeof handleOnChange === "function") {
          handleOnChange(selectedOption.value);
          setQuery((prevQuery) => ({ ...prevQuery, condition: selectedOption.value }));
        }
      },
      [handleOnChange]
    );

    return (
      <div style={{ width: "30%" }}>
        <Select
          name="combinator"
          // margin={true}
          options={combinatorOptions}
          isClearable={false}
          value={currentValue}
          onChange={handleChange}
          isDisabled={disabled}
          theme={selectTheme}
          styles={selectStyles}
        />
      </div>
    );
  };

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: "#135f95",
      primary: "#135f95",
    },
  });

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: "15px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.003)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        borderColor: "#5A5A5A",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "white",
    }),
    group: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: "10px 2px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#347AA6" : "white",
      color: state.isSelected ? "white" : "#333",
      padding: "10px 2px",
      fontSize: "15px",
      transition: "background-color 0.2s ease",
      paddingLeft: "20px",
      "&:active": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "#135f95",
      },
      "&:focus": {
        color: "white",
      },
    }),
  };

  const CustomActionButton = (props) => {
    const isRemoveButton = props.actionType === "remove";
    const buttonConfig = isRemoveButton
      ? {
          name: t(""),
          icon: <ClearOutlinedIcon />,
          onClick: (e) => remove(e, props.path),
        }
      : {
          name: props.label,
          title: props.label,
          onClick: (e) => props.handleOnClick(e),
        };

    return <Button buttons={[buttonConfig]} />;
  };

  const remove = (e, path) => {
    e.stopPropagation();
    let newRules = [...query.rules];

    if (path.length === 1) {
      newRules.splice(path[0], 1);
    } else {
      let currentLevel = newRules;
      for (let i = 0; i < path.length - 1; i++) {
        currentLevel = currentLevel[path[i]].rules;
      }
      currentLevel.splice(path[path.length - 1], 1);
    }

    const newQuery = { ...query, rules: newRules };
    setQuery(newQuery);
  };

  const sanitizeQuery = (query) => {
    const sanitizedRules = query.rules.filter((rule) => typeof rule === "object");
    return { ...query, rules: sanitizedRules };
  };

  return (
    <>
      <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
        <QueryBuilder
          fields={fields}
          query={query}
          controlClassnames={{ queryBuilder: "queryBuilder-branches", addGroup: "d-none" }}
          onQueryChange={(q) => {
            const sanitizedQuery = sanitizeQuery(q);
            setQuery(sanitizedQuery);
          }}
          independentCombinators={false}
          controlElements={{
            valueEditor: (props) => (
              <ValueEditor
                {...props}
                isDisabled={loading}
                loading={loading}
                fields={fields}
                selectStyles={selectStyles}
                selectTheme={selectTheme}
              />
            ),
            fieldSelector: (props) => (
              <FiltersFieldSelector fields={fields} {...props} isDisabled={loading} loading={loading} />
            ),
            operatorSelector: (props) => (
              <IncomingTypeReusible {...props} isDisabled={loading} loading={loading} fields={fields} />
            ),
            combinatorSelector: (props) => <CustomCombinatorSelector fields={fields} {...props} isDisabled={loading} />,
            addRuleAction: (props) => <CustomActionButton {...props} />,
            removeRuleAction: (props) => <CustomActionButton {...props} actionType="remove" />,
          }}
          enableDragAndDrop={!loading}
          autoSelectOperator={false}
          showCombinatorsBetweenRules={false}
        />
      </QueryBuilderDnD>
    </>
  );
};

export default QueryFiltersReusible;
