import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../reusableComponents/Header/Header";
import { BiAddToQueue } from "react-icons/bi";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { useTranslation } from "react-i18next";
import { UserSearchHelper } from "../../reusableComponents/HelperTexts/SearchHelper";
import {
  useDeletePurposeMutation,
  useGetPurposesQuery,
  useUpdatePurposeMutation,
} from "../../../redux/api/purposesApi";
import PurposeModal from "../Purposes/PurposeModal";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
function Purposes() {
  const name = "Purposes";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });
  const { data, isFetching, refetch } = useGetPurposesQuery(query);
  const [updatePurpose] = useUpdatePurposeMutation();
  const [deletePurpose] = useDeletePurposeMutation();
  const { t } = useTranslation();

  const handleTogglePublished = async (data) => {
    const updatedData = { ...data, isPublished: !data.isPublished };
    await updatePurpose(updatedData);
    await refetch();
  };

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    type: null,
    id: null,
  });

  const toggle = () => {
    setModalOptions({ isOpen: !modalOptions.isOpen });
  };
  const columns = [
    {
      accessorKey: "purpose",
      accessorFn: (row) => row.purpose,
      header: t("caymland.contact.export.purpose"),
      enableColumnActions: false,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      size: 300,
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          handleTogglePublished={handleTogglePublished}
          link="purposes"
          toggle={toggle}
          modal={setModalOptions}
          disableLink={true}
          permissionKey="lead:leads"
        />
      ),
    },
    {
      accessorKey: "123",

      header: "",
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "123",

      header: "",
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "Unit",
      accessorFn: (row) => t(`caymland.storage.purpose.valid.period.${row.unit}`),
      header: t("caymland.core.time.unit"),
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "123",

      header: "",
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "123",

      header: "",
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
    },

    {
      accessorKey: "validUntil",
      accessorFn: (row) => row.validPeriod,
      header: t("caymland.storage.purpose.valid.period"),
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
    },
  ];

  const actionButtons = ActionButtons({
    link: "purposes",
    refetch: refetch,
    openModal: setModalOptions,
    clone: false,
    deleteItem: deletePurpose,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "purposes",
    refetch: refetch,
  });

  return (
    <Fragment>
      <PurposeModal modalOptions={modalOptions} setModalOptions={setModalOptions} refetch={refetch} />
      <Header
        buttons={[
          {
            name: "addNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: toggle,
          },
        ]}
        title={t("Purposes")}
      />
      <CustomTable
        data={data ?? []}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        linkNavigate={null}
        toolbarActions={{
          search: true,
        }}
        isFetching={isFetching}
        searchHelperBody={<UserSearchHelper />}
      />
    </Fragment>
  );
}

export default Purposes;
