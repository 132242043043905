import axios from "axios";
import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import Button from "./Button";
import { Checkbox, Select } from "../../reusableComponents/Inputs";
import { Error, Success } from "../Alerts";
import { companiesApi, useMergeCompanyMutation } from "../../../redux/api/companiesApi";
import { contactsApi, useMergeContactsMutation } from "../../../redux/api/contactsApi";
import { ImCancelCircle } from "react-icons/im";
import { useTranslation } from "react-i18next";

function ModalForMerge({ handleClose, handleShow, show, name, company }) {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const idOfMergedContact = useRef();
  const idOfMergeCaller = useParams().id;

  const [triggerCompaniesQuery] = companiesApi.useLazyGetCompaniesQuery();
  const [triggerContactsQuery] = contactsApi.useLazyGetContactsQuery();
  const [mergeCompany] = useMergeCompanyMutation();
  const [mergeContacts] = useMergeContactsMutation();

  const [selectedOption, setSelectedOption] = useState(null);
  const [modifiedData, setModifiedData] = useState({
    isWinner: false,
    firstId: true,
    secondId: false,
  });

  const retrieveCompanies = (inputValue, bundle, includeGlobal = true) =>
    new Promise(async (resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        const query = {
          where: [
            {
              expr: "neq",
              col: "comp.id",
              val: idOfMergeCaller,
            },
          ],
        };
        if (inputValue.length) {
          query.search = inputValue;
        }

        const response = await triggerCompaniesQuery(query);
        if (!response.error) {
          const companiesData = response?.data?.data?.filter((company) => company.id != idOfMergeCaller);

          const companyArray = companiesData
            ?.filter((company) => company?.id !== idOfMergeCaller)
            ?.map((company) => ({
              label: company?.fields?.core?.companyname?.value,
              value: company?.id,
            }))
            ?.filter((entry) => entry.label && entry.value);

          setCompanies(companyArray);
          resolve(companyArray);
        } else {
          reject();
        }
      }, 500);

      setDebounceTimeout(newTimeout);
    });

  const retrieveContacts = (inputValue, bundle, includeGlobal = true) =>
    new Promise(async (resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        const query = {
          pageIndex: 0,
          pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
          orderByDir: "desc",
          orderBy: "last_active",
        };
        if (inputValue.length) {
          query.search = inputValue;
        }

        const response = await triggerContactsQuery(query);
        if (!response.error) {
          const contactsData = response?.data?.data?.filter((contact) => contact.id != idOfMergeCaller);
          console.log(contactsData);
          const contactsArray = contactsData?.map((contact) => {
            const firstname = contact?.fields?.all?.firstname || "";
            const lastname = contact?.fields?.all?.lastname || "";
            const email = contact?.fields?.all?.email || "";

            const label = `${firstname} ${lastname}`.trim();

            return {
              label: label ? `${label} | ${email}` : email,
              value: contact?.id,
            };
          });

          setContacts(contactsArray);

          resolve(contactsArray);
        } else {
          reject();
        }
      }, 500);

      setDebounceTimeout(newTimeout);
    });

  const mergeElement = async () => {
    const mainId = modifiedData.firstId ? idOfMergeCaller : idOfMergedContact.current;
    const secondId = modifiedData.firstId ? idOfMergedContact.current : idOfMergeCaller;

    if (company) {
      try {
        const response = await mergeCompany({ mainId, secondId }).unwrap();
        if (!response.error) {
          Success(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.merge.past.tense") })}!`);
          handleClose();
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (err) {}
    } else {
      try {
        const response = await mergeContacts({ mainId, secondId }).unwrap();
        if (!response.error) {
          Success(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.merge.past.tense") })}!`);
          handleClose();
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (err) {}
    }
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    idOfMergedContact.current = option?.value;
  };

  const handleInputChange = (e, type) => {
    const isCheckbox = type === "checkbox";
    const { name, value, checked } = e.target;

    setModifiedData((prev) => ({
      ...prev,
      isWinner: isCheckbox ? checked : value,
    }));
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: () => handleClose(),
    },
    {
      name: "Merge",
      title: t("caymland.lead.merge"),
      icon: <i style={{ fontSize: "15px" }} className="fa fa-user"></i>,
      onClick: () => mergeElement(),
    },
  ];

  return (
    <>
      <Modal show={show} onHide={handleClose} style={{}}   backdrop="static">
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
          closeButton
        >
          <Modal.Title
            style={{
              color: "#000",
              fontSize: "16px",
              fontWeight: "500",
              padding: "4px",
            }}
          >
            {t("caymland.lead.lead.header.merge", { name: name })}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1em",
              width: "100%",
            }}
          >
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Form.Group>
                <Form.Label style={{ marginLeft: "0.5em" }}>
                  {company ? t("caymland.lead.merge.company") : t("caymland.lead.merge.select")}
                  <span className="required"></span>
                </Form.Label>
                <Select
                  options={company ? retrieveCompanies : retrieveContacts}
                  onChange={handleChange}
                  value={selectedOption || ""}
                />
              </Form.Group>
            </Form>{" "}
          </div>
          {selectedOption && (
            <Checkbox
              name="isWinner"
              value={modifiedData.isWinner}
              onChange={(e) => handleInputChange(e, "checkbox")}
              label={t("caymland.lead.merge.is_manual_merge")}
            />
          )}

          {modifiedData.isWinner && (
            <div
              className="flex items-center justify-center"
              style={{ marginBottom: "25px", marginTop: "25px", width: "50%" }}
            >
              <div className="w-50 parent-100">
                <Button
                  buttons={[
                    {
                      name: "currentContact",
                      title: t("caymland.lead.merge.opened_contact"),
                      onClick: () => {
                        setModifiedData({
                          ...modifiedData,
                          firstId: true,
                          secondId: false,
                        });
                      },
                    },
                  ]}
                  variant={modifiedData?.firstId ? "contained" : "outlined"}
                  textFormat="capitalize"
                />
              </div>
              <div className="w-50 parent-100">
                <Button
                  buttons={[
                    {
                      name: "selectedContact",
                      title: t("caymland.lead.merge.selected_contact"),
                      onClick: () => {
                        setModifiedData({
                          ...modifiedData,
                          firstId: false,
                          secondId: true,
                        });
                      },
                    },
                  ]}
                  variant={modifiedData?.secondId ? "contained" : "outlined"}
                  textFormat="capitalize"
                />
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button buttons={buttons} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalForMerge;
