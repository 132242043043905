import React, { Fragment, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import md5 from "blueimp-md5";
import Header from "../../reusableComponents/Header/Header";
import { BiAddToQueue } from "react-icons/bi";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { useTranslation, Trans } from "react-i18next";
import { UserSearchHelper } from "../../reusableComponents/HelperTexts/SearchHelper";
import { useDeleteTypeMutation, useGetTypesQuery } from "../../../redux/api/contractsApi";
import CurrenciesModal from "./Forms/CurrenciesModal";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import TypesModal from "./Forms/TypesModal";

function Types() {
  const name = "Types";
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    type: null,
    id: null,
  });

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });
  const navigate = useNavigate();
  const { data, isFetching, refetch } = useGetTypesQuery(query);
  const [deleteItem] = useDeleteTypeMutation();
  const { t } = useTranslation();

  const toggle = () => {
    setModalOptions({ isOpen: !modalOptions.isOpen });
  };

  const columns = [
    {
      accessorKey: "type",
      accessorFn: (row) => row.type,
      header: t("caymland.core.name"),
      size: 150,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          link="purposes"
          toggle={toggle}
          modal={setModalOptions}
          disableLink={true}
        />
      ),
    },
  ];

  const actionButtons = ActionButtons({
    link: "currencies",
    refetch: refetch,
    openModal: setModalOptions,
    clone: false,
    deleteItem: deleteItem,
    permissionKey: "contract:contracts",
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "contracts/types",
    refetch: refetch,
    permissionKey: "contract:contracts",
  });

  return (
    <Fragment>
      <TypesModal modalOptions={modalOptions} setModalOptions={setModalOptions} refetch={refetch} />
      <Header
        buttons={[
          {
            name: "addNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: toggle,
          },
        ]}
        title={t("Types")}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        linkNavigate={null}
        toolbarActions={{
          search: true,
        }}
        isFetching={isFetching}
        searchHelperBody={<UserSearchHelper />}
      />
    </Fragment>
  );
}

export default Types;
