import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../reusableComponents/Buttons/Button";
import "react-dropzone-uploader/dist/styles.css";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { t } from "i18next";
import { IconButton, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import General from "./General";
import Documents from "./Documents";
import Other from "./Other";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";

const CustomModal = ({
  showModal,
  setShowModal,
  control,
  getValues,
  noteData = [],
  setNotes,
  setValue,
  reset,
  userOptions,
}) => {
  const [errors, setErrors] = useState();
  const [activeTab, setActiveTab] = useState("1");

  // useFieldArray for managing notes
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "notes",
    keyName: "id", // Use consistent key for IDs
  });

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (showModal.type === "edit" && showModal.id) {
      const noteToEdit = noteData?.find((note) => note.id === showModal.id);
      if (noteToEdit) {
        setValue("subject", noteToEdit?.subject || "");
        setValue("text", noteToEdit?.text || "");
        setValue("user", noteToEdit?.user?.id || "");
        setValue("dateTime", noteToEdit.dateTime || "");
        setValue("tags", noteToEdit.noteTags?.map((tag) => ({ label: tag.name, value: tag.name })) || []);
        setValue("isReminder", noteToEdit.isReminder || false);
        setValue("reminderDateTime", noteToEdit.reminderDateTime || "");
        setValue("documents", noteToEdit.docuemnts || []);
        setValue("action", noteToEdit.action || "");
        setValue("noteEvent", noteToEdit.noteEvent || {});
      }
    }
  }, [showModal, noteData, setValue]);

  const handleClose = () => {
    setShowModal({ isOpen: false, id: null, type: null });
    setValue("notes", noteData);
  };
  const handleSaveNote = () => {
    const note = getValues("notes");
    const currentIndex =
      showModal.type === "edit"
        ? noteData.findIndex((note) => note.id === showModal.id)
        : showModal.type === "add"
        ? noteData?.length
        : null;

    if (!note[currentIndex].subject || note[currentIndex].subject.trim() === "") {
      // Ensure `errors` follows the same structure as your form
      setErrors((prevErrors) => ({
        ...prevErrors,
        notes: {
          ...prevErrors?.notes,
          [currentIndex]: {
            ...prevErrors?.notes?.[currentIndex],
            subject: true, // Mark subject as having an error
          },
        },
      }));
      return;
    }

    const parentObject = getValues(); // Get the entire parent object
    const allNotes = parentObject.notes || []; // Ensure notes array is initialized

    if (showModal.type === "edit" && showModal.id !== null) {
      // Handle edit case
      const indexToEdit = allNotes.findIndex((note) => note.id === showModal.id);

      if (indexToEdit !== -1) {
        const updatedNote = {
          ...allNotes[indexToEdit],
          ...getValues(`notes[${indexToEdit}]`), // Merge with updated form values
        };

        const updatedNotes = allNotes.map((note, index) => (index === indexToEdit ? updatedNote : note));

        console.log(updatedNotes);

        setNotes(updatedNotes);
        update(updatedNotes);
        setTimeout(() => {
          setValue("notes", updatedNotes);
        }, 500);
      }
    } else if (showModal.type === "add") {
      // Handle add case
      const newNote = {
        subject: note[currentIndex].subject || "",
        text: note[currentIndex].text || "",
        user: note[currentIndex].user || "",
        dateTime: note[currentIndex].dateTime || "",
        noteTags: note[currentIndex].noteTags || [],
        isReminder: note[currentIndex].isReminder || false,
        reminderDateTime: note[currentIndex].reminderDateTime || "",
        action: note[currentIndex].action || "",
        noteEvent: note[currentIndex].noteEvent || null,
        documents: note[currentIndex].documents || [],
        id: crypto.randomUUID(),
        dateAdded: new Date().toISOString(),
      };
      // Append only the explicitly created new note
      const updatedNotes = [...noteData, newNote];

      setNotes(updatedNotes);
      setTimeout(() => {
        setValue("notes", updatedNotes);
      }, 500);
    }

    handleClose(); // Close the modal
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleClose,
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <AiOutlineSave size={15} />,
      onClick: handleSaveNote,
    },
  ];

  return (
    <>
      <Modal show={showModal.isOpen} onHide={handleClose} enforceFocus={false} size="lg" backdrop={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {showModal.type === "edit" ? t("caymland.lead.note.header.edit") : t("caymland.lead.add.note")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <Nav tabs className="tw-mb-5">
            <NavItem>
              <NavLink className={`${activeTab === "1" ? "active-bg" : ""}`} onClick={() => setActiveTab("1")}>
                {t("caymland.form.field.section.general")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`${activeTab === "2" ? "active-bg" : ""}`} onClick={() => setActiveTab("2")}>
                {t("caymalnd.contract.documents")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`${activeTab === "3" ? "active-bg" : ""}`} onClick={() => setActiveTab("3")}>
                {t("caymalnd.contract.event")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} style={{ padding: "14px" }}>
            <TabPane tabId="1">
              <General
                control={control}
                errors={errors}
                userOptions={userOptions}
                noteData={noteData?.find((note) => note.id === showModal.id)}
                currentIndex={
                  showModal.type === "edit"
                    ? noteData.findIndex((note) => note.id === showModal.id)
                    : showModal.type === "add"
                    ? noteData?.length
                    : null
                }
              />
            </TabPane>
            <TabPane tabId="2">
              <Documents
                control={control}
                noteData={noteData?.find((note) => note.id === showModal.id)}
                updateNoteData={setNotes} // Pass the function to update noteData
                setValue={setValue}
                currentIndex={
                  showModal.type === "edit"
                    ? noteData.findIndex((note) => note.id === showModal.id)
                    : showModal.type === "add"
                    ? noteData?.length
                    : null
                }
              />
            </TabPane>
            <TabPane tabId="3">
              <Other
                control={control}
                noteId={showModal.id}
                noteData={noteData}
                currentIndex={
                  showModal.type === "edit"
                    ? noteData.findIndex((note) => note.id === showModal.id)
                    : showModal.type === "add"
                    ? noteData?.length
                    : null
                }
              />
            </TabPane>
          </TabContent>
        </Modal.Body>
        <Modal.Footer>
          <Button buttons={buttons} />
        </Modal.Footer>
      </Modal>
      <Tooltip arrow title={t("caymland.lead.add.note")} onClick={() => setShowModal({ isOpen: true, type: "add" })}>
        <IconButton>
          <Add />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CustomModal;
