import React from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { DatePicker } from "../../../reusableComponents/Inputs";
import { t } from "i18next";

const InvoicesSection = ({ control, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "invoices", // The form state field name for invoices
  });

  // Watch the invoices array dynamically
  const invoices = useWatch({ control, name: "invoices" });

  // Calculate total price based on the watched invoices
  const totalPrice = (invoices || []).reduce((acc, invoice) => {
    const price = parseFloat(invoice?.price || 0);
    return acc + (price > 0 ? price : 0);
  }, 0);

  // Add a new invoice
  const handleAddInvoice = () => {
    append({
      id: crypto.randomUUID(),
      paymentDate: "",
      price: "",
      invoiceDeliveredDate: "",
      invoiceDelivered: 0, // 0 or 1 for status
    });
  };

  // Remove an invoice
  const handleRemoveInvoice = (index) => {
    remove(index);
  };

  return (
    <div className="flex flex-col">
      {/* Table Headers */}
      <div className="flex">
        <div
          className="tw-flex tw-items-center tw-justify-center tw-font-medium tw-capitalize tw-text-sm tw-px-2 tw-w-[5%] tw-h-[34px] tw-shadow tw-duration-150 tw-bg-secondary tw-border tw-rounded-tl-md tw-cursor-pointer"
          onClick={handleAddInvoice}
        >
          <AddCircleOutlineOutlinedIcon
            sx={{
              color: "darkgreen",
              fontSize: "16px",
            }}
          />
        </div>
        <div className="tw-w-[30%] tw-flex tw-items-center tw-justify-center tw-font-medium  tw-text-sm tw-px-2 tw-h-[34px] tw-shadow tw-duration-150 tw-bg-secondary tw-border">
          {t("caymland.contract.payment.date")}
        </div>
        <div className="tw-w-[20%] tw-flex tw-items-center tw-justify-center tw-font-medium  tw-text-sm tw-px-2 tw-h-[34px] tw-shadow tw-duration-150 tw-bg-secondary tw-border">
          {t("caymland.contract.price")}
        </div>
        <div className="tw-w-[30%] tw-flex tw-items-center tw-justify-center tw-font-medium  tw-text-sm tw-px-2 tw-h-[34px] tw-shadow tw-duration-150 tw-bg-secondary tw-border">
          {t("caymland.contract.delivered.date")}
        </div>
        <div className="tw-w-[15%] tw-flex tw-items-center tw-justify-center tw-font-medium  tw-text-sm tw-px-2 tw-h-[34px] tw-shadow tw-duration-150 tw-bg-secondary tw-border tw-rounded-tr-md">
          {t("caymland.contract.delivered")}
        </div>
      </div>

      {/* Table Rows */}
      <div className="flex flex-col">
        {fields.map((field, index) => (
          <div key={field.id} className="flex items-center">
            {/* Delete Row Button */}
            <div
              className="tw-flex tw-items-center tw-justify-center tw-w-[5%] tw-h-[34px] tw-border tw-bg-white tw-cursor-pointer"
              onClick={() => handleRemoveInvoice(index)}
            >
              <CloseOutlinedIcon
                sx={{
                  color: "red",
                  fontSize: "16px",
                }}
              />
            </div>

            {/* Payment Date */}
            <div className="tw-w-[30%] tw-h-[34px] tw-border tw-bg-white tw-flex tw-items-center">
              <Controller
                name={`invoices[${index}].paymentDate`}
                control={control}
                defaultValue={field.paymentDate}
                render={({ field: { name, onChange, value } }) => (
                  <DatePicker
                    name={name}
                    value={value}
                    onChange={onChange}
                    showTimeSelect
                    marginBottom={false}
                    viewEdit
                    isClearable={false}
                    required={true}
                    errors={errors && errors[name]}
                  />
                )}
              />
            </div>

            {/* Price */}
            <div className="tw-w-[20%] tw-h-[34px] tw-border tw-bg-white tw-flex tw-items-center">
              <Controller
                name={`invoices[${index}].price`}
                control={control}
                defaultValue={field.price}
                render={({ field }) => (
                  <input
                    type="number"
                    {...field}
                    className="tw-w-full tw-px-2 tw-py-1 tw-border-none tw-outline-none tw-text-sm tw-text-center"
                  />
                )}
              />
            </div>

            {/* Invoice Delivered Date */}
            <div className="tw-w-[30%] tw-h-[34px] tw-border tw-bg-white tw-flex tw-items-center">
              <Controller
                name={`invoices[${index}].invoiceDeliveredDate`}
                control={control}
                defaultValue={field.invoiceDeliveredDate}
                render={({ field: { name, onChange, value } }) => (
                  <DatePicker
                    name={name}
                    value={value}
                    onChange={onChange}
                    isClearable={false}
                    showTimeSelect
                    marginBottom={false}
                    viewEdit
                    required={true}
                    errors={errors && errors[name]}
                  />
                )}
              />
            </div>

            {/* Invoice Delivered Status */}
            <div className="tw-w-[15%] tw-h-[34px] tw-border tw-bg-white tw-flex tw-items-center">
              <Controller
                name={`invoices[${index}].invoiceDelivered`}
                control={control}
                defaultValue={field.invoiceDelivered}
                render={({ field }) => (
                  <select {...field} className="tw-w-full tw-px-2 tw-py-1 tw-border-none tw-outline-none tw-text-sm">
                    <option value={false}>{t("caymland.core.form.no")}</option>
                    <option value={true}>{t("caymland.core.form.yes")}</option>
                  </select>
                )}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Total Price Row */}
      <div className="flex items-center">
        {/* Empty cell for Add button */}
        <div className="tw-w-[5%] tw-h-[34px] tw-border tw-bg-secondary"></div>

        {/* Total Label */}
        <div className="tw-w-[30%] tw-h-[34px] tw-border tw-bg-secondary tw-flex tw-items-center tw-justify-center tw-font-medium">
          Total
        </div>

        {/* Total Price */}
        <div className="tw-w-[20%] tw-h-[34px] tw-border tw-bg-white tw-flex tw-items-center tw-justify-center tw-font-medium">
          {totalPrice.toFixed(2)}
        </div>

        {/* Empty cells for alignment */}
        <div className="tw-w-[30%] tw-h-[34px] tw-border tw-bg-secondary"></div>
        <div className="tw-w-[15%] tw-h-[34px] tw-border tw-bg-secondary"></div>
      </div>
    </div>
  );
};

export default InvoicesSection;
