import React, { useEffect, useState } from "react";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import NewCategoryPopUp from "../../../../reusableComponents/newCategoryPopUp/NewCategoryPopUp";
import { Text, Select, DatePicker, Checkbox, TextArea } from "../../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../../reusableComponents/retriveCategoriesByBundle";
import {
  categoriesApi,
  useAddCategoryMutation,
  useGetCategoriesOptionsQuery,
} from "../../../../../redux/api/categoriesApi";
import { t } from "i18next";
import ModalGenerator from "../../../../reusableComponents/ModalGenerator/ModalGenerator";
import { CategoryModalData } from "../../../../reusableComponents/ModalGenerator/ModalGeneratorData";

function Sidebar({ errors, setErrors, modifiedData, setModifiedData }) {
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "plugin:focus",
  });
  const [newItem, setNewItem] = useState();

  const [addCategory] = useAddCategoryMutation();

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === "create_new") {
      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
      }));
      return;
    }
    setModifiedData({ ...modifiedData, category: selectedOption });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "text") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
      const isUtmTags = Object.keys(modifiedData.utmTags).includes(name);
      isUtmTags &&
        setModifiedData({
          ...modifiedData,
          utmTags: {
            ...modifiedData.utmTags,
            [name]: value,
          },
        });
      !isUtmTags &&
        setModifiedData((prev) => ({
          ...prev,
          [name]: value,
        }));
    } else if (type === "checkbox") {
      setModifiedData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    }
  };

  return (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Text
        name="name"
        value={modifiedData?.name}
        onChange={(e) => handleChange(e)}
        label={t("caymland.core.name")}
        required={true}
        errors={errors?.name}
      />
      <Select
        name="category"
        label={t("caymland.page.tracking.form.category")}
        options={retrieveCategories}
        trigger={triggerCategories}
        bundle="plugin:focus"
        value={modifiedData?.category ?? null}
        onChange={(e) => handleSelectChange(e)}
        errors={errors?.category}
        isCreatable
        isClearable
      />
      {/* <Text
        name="website"
        value={modifiedData?.website}
        onChange={(e) => handleChange(e)}
        label="Website"
        tooltip={"Enter your website for Caymland to grab a screenshot and display an example."}
      /> */}
      <div className="tw-mb-[10px]">
        <Checkbox
          name="isPublished"
          value={modifiedData?.isPublished}
          onChange={(e) => handleChange(e)}
          label={t("caymland.core.form.published")}
        />
      </div>
      <DatePicker
        label={t("caymland.core.form.publishup")}
        name={"Publish at (date/time)"}
        value={modifiedData?.publishUp ? new Date(modifiedData?.publishUp) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishUp: e })}
        maxDate={modifiedData?.publishDown}
      />
      <DatePicker
        label={t("caymland.core.form.publishdown")}
        name={"Unpublish at (date/time)"}
        value={modifiedData?.publishDown ? new Date(modifiedData?.publishDown) : null}
        onChange={(e) => setModifiedData({ ...modifiedData, publishDown: e })}
        minDate={modifiedData?.publishUp}
      />
      <div className="small-editor">
        <TextArea
          label={t("caymland.core.description")}
          value={modifiedData?.description ?? ""}
          onChange={(data) => {
            setModifiedData((prev) => ({
              ...prev,
              description: data,
            }));
          }}
        />
      </div>
      <p className="mt-10">Google Analytics UTM tags</p>
      <Text
        name="utmSource"
        errors={errors.utmTags?.utmSource}
        value={modifiedData?.utmTags?.utmSource}
        onChange={(e) => handleChange(e)}
        label={t("caymland.email.campaign_source")}
      />
      <Text
        name="utmMedium"
        errors={errors.utmTags?.utmMedium}
        value={modifiedData?.utmTags?.utmMedium}
        onChange={(e) => handleChange(e)}
        label={t("caymland.email.campaign_medium")}
      />
      <Text
        name="utmCampaign"
        value={modifiedData?.utmTags?.utmCampaign}
        onChange={(e) => handleChange(e)}
        label={t("caymland.email.campaign_name")}
      />
      <Text
        name="utmContent"
        errors={errors.utmTags?.utmContent}
        value={modifiedData?.utmTags?.utmContent}
        onChange={(e) => handleChange(e)}
        label={t("caymland.email.campaign_content")}
      />
      {modifiedData?.execution && (
        <>
          <div className="w-full">
            <div style={{ marginBottom: "15px" }}>
              <p className="pb-0 mb-0 required">{t("caymland.core.form.interval")}</p>
            </div>
          </div>
          <p className="pb-0 mb-0 required">{t("caymland.core.form.time.execute")}</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label=""
              value={modifiedData?.timeExecution}
              onChange={(e) => setModifiedData({ ...modifiedData, timeExecution: e })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </>
      )}
    </>
  );
}

export default Sidebar;
