import React from "react";
import Modal from "react-bootstrap/Modal";

const SearchModal = ({ body, isOpen, setIsOpen }) => {
  return (
    <Modal show={isOpen} onHide={() => setIsOpen(!isOpen)}   backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title> Search Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
    </Modal>
  );
};

export default SearchModal;
