import React, { Fragment, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import md5 from "blueimp-md5";
import Header from "../../reusableComponents/Header/Header";
import { BiAddToQueue } from "react-icons/bi";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { useTranslation, Trans } from "react-i18next";
import { UserSearchHelper } from "../../reusableComponents/HelperTexts/SearchHelper";
import { useDeleteContractMutation, useGetContractsQuery } from "../../../redux/api/contractsApi";

function Contracts() {
  const name = "Contracts";
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: sessionStorage.getItem(`orderByDir${name}`) ? sessionStorage.getItem(`orderByDir${name}`) : "DESC",
    orderBy: sessionStorage.getItem(`orderBy${name}`) ? sessionStorage.getItem(`orderBy${name}`) : "id",
    name: name,
    where: [],
  });
  const { t } = useTranslation();
  const statusOptions = [
    { label: t("caymland.contract.status.1"), value: 1, color: "green" },
    { label: t("caymland.contract.status.2"), value: 2, color: "blue" },
    { label: t("caymland.contract.status.3"), value: 3, color: "gray" },
    { label: t("caymland.contract.status.4"), value: 4, color: "red" },
    { label: t("caymland.contract.status.5"), value: 5, color: "purple" },
  ];

  const navigate = useNavigate();
  const { data, isFetching, refetch } = useGetContractsQuery(query);
  const [deleteItem] = useDeleteContractMutation();

  const columns = [
    {
      accessorKey: "status",
      accessorFn: (row) => row.status,
      header: t("Status"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => {
        const statusValue = cell.row.original.status;
        const status = statusOptions.find((option) => option.value === statusValue);
        if (!status) return null;

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <span
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                backgroundColor: status.color,
              }}
            />
            <span>{status.label}</span>
          </div>
        );
      },
    },
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.email.subject"),
      size: 150,
      enableColumnActions: false,
    },

    {
      accessorKey: "type",
      accessorFn: (row) => row?.type?.type,
      header: t("caymlandb2b.opportunity.value_type"),
      size: 150,
      enableColumnActions: false,
    },
    // {
    //   accessorKey: "Total",
    //   accessorFn: (row) => row?.currency?.currency,
    //   header: t("Total"),
    //   size: 150,
    //   enableColumnActions: false,
    // },
    {
      accessorKey: "currency",
      accessorFn: (row) => row?.currency?.currency,
      header: t("caymland.showare.field.event.showare_event_currency"),
      size: 150,
      enableColumnActions: false,
    },

    {
      accessorKey: "dateValidFrom",
      accessorFn: (row) => row.dateValidFrom,
      header: t("caymland.contact.export.valid.from"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => moment(cell.row.original.dateValidFrom).format("DD.MM.YY HH:mm") || "",
    },
    {
      accessorKey: "dateValidTo",
      accessorFn: (row) => row.dateValidTo,
      header: t("caymland.contact.export.valid.until"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => moment(cell.row.original.dateValidTo).format("DD.MM.YY HH:mm") || "",
    },

    {
      accessorKey: "continueAutomatically",
      accessorFn: (row) => row.continueAutomatically,
      header: t("caymland.contract.continue.automatically"),
      size: 150,
      enableColumnActions: false,
      Cell: ({ cell }) => (cell.row.original.continueAutomatically ? "Yes" : "No"),
    },
  ];

  const actionButtons = ActionButtons({
    link: "contracts",
    refetch: refetch,
    clone: false,
    permissionKey: "contract:contracts",
    deleteItem: deleteItem,
  });

  const actionBatchButtons = ActionBatchButtons({
    link: "contracts",
    refetch: refetch,
    permissionKey: "contract:contracts",
  });

  return (
    <Fragment>
      <Header
        buttons={[
          {
            name: "addNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: () => navigate("/contracts/new"),
          },
        ]}
        title={t("Contracts")}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        linkNavigate="edit"
        toolbarActions={{
          search: true,
        }}
        isFetching={isFetching}
        searchHelperBody={<UserSearchHelper />}
      />
    </Fragment>
  );
}

export default Contracts;
