import React from "react";
import { Select, Text, Checkbox } from "../../../reusableComponents/Inputs";
import TimezoneSelect from "../../../reusableComponents/ReusableSelects/Timezone";
import { t } from "i18next";

const SidebarUser = ({ modifiedData, setModifiedData, handleChange, getTimezones, emailsData, errors }) => {
  const language = [
    { label: t("caymland.user.user.form.defaultlocale"), value: null },
    { label: t("caymland.core.english"), value: "en_US" },
    { label: t("caymland.core.german"), value: "de" },
  ];

  return (
    <>
      <div className="timezone-select-container tw-mb-[10px]">
        <TimezoneSelect
          label={t("caymland.lead.field.type.timezone")}
          // placeholder={t("caymland.user.user.form.defaulttimezone")}
          value={modifiedData?.timezone} // Set value based on modifiedData.timezone
          onChange={(selectedOption) => {
            setModifiedData((prev) => ({
              ...prev,
              timezone: selectedOption === null ? null : selectedOption?.value,
            }));
          }}
        />
      </div>

      <Select
        name="locale"
        label={t("caymland.core.language")}
        options={language}
        required={true}
        isClearable={false}
        value={language.find((l) => l.value === modifiedData.locale)}
        onChange={(selectedOption) => {
          setModifiedData((prev) => ({
            ...prev,
            locale: selectedOption === null ? null : selectedOption.value,
          }));
        }}
        errors={errors.locale}
      />

      <Text
        name="department"
        value={modifiedData.department}
        onChange={handleChange}
        label={t("caymland.user.field.department")}
      />

      <Text name="street" value={modifiedData.street} onChange={handleChange} label={t("caymland.user.field.street")} />
      <Text name="zip" value={modifiedData.zip} onChange={handleChange} label={t("caymland.user.field.zip")} />
      <Text name="city" value={modifiedData.city} onChange={handleChange} label={t("caymland.user.field.city")} />
      <Text name="phone1" value={modifiedData.phone1} onChange={handleChange} label={t("caymland.user.field.phone1")} />
      <Text name="phone2" value={modifiedData.phone2} onChange={handleChange} label={t("caymland.user.field.phone2")} />
      <Text name="fax" value={modifiedData.fax} onChange={handleChange} label={t("caymland.user.field.fax")} />
      <Select
        name="template"
        label={t("caymland.user.field.template")}
        placeholder={t("caymland.core.form.chooseone")}
        value={modifiedData.template}
        onChange={handleChange}
      />
      <Select
        name="defaultFolder"
        label={t("caymland.core.form.deafultfolder")}
        placeholder={t("caymland.core.form.chooseone")}
        value={modifiedData.defaultFolder}
        onChange={handleChange}
      />
      <Select
        name="defaultAddress"
        label={t("caymland.core.form.defaultaddress")}
        placeholder={t("caymland.core.form.chooseone")}
        options={emailsData ?? []}
        key={emailsData}
        value={(emailsData ?? []).find((e) => e.value === modifiedData.defaultAddress)}
        onChange={(e) => {
          setModifiedData((prev) => ({
            ...prev,
            defaultAddress: e === null ? null : e.value,
          }));
        }}
      />
      <Checkbox
        name="isPublished"
        value={modifiedData.isPublished}
        onChange={handleChange}
        label={t("caymland.core.form.published")}
      />
    </>
  );
};

export default SidebarUser;
