import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Details from "./addEditTrigger/Details";
import Sidebar from "./addEditTrigger/Sidebar";
import Events from "./addEditTrigger/Events";
import { Error, Success } from "../../../reusableComponents/Alerts";
import { AiOutlineSave } from "react-icons/ai";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import {
  useAddNewTriggerMutation,
  useGetTriggersAndPointTriggerQuery,
  useGetTriggersQuery,
  useUpdateTriggerMutation,
} from "../../../../redux/api/triggersApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import Swal from "sweetalert2";
import TitleHeader from "../../../reusableComponents/TitleHeader";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import { t } from "i18next";
import { useTheme } from "@mui/material/styles";


function AddTrigger() {
  const navigate = useNavigate();
  const location = useLocation();
  const { palette } = useTheme();
  const id = location?.state?.id || location?.state || useParams().id;
  const isClone = location.pathname.includes("clone");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [primarycolorTab, setPrimaryColorTab] = useState(1);
  const [events, setEvents] = useState([]);
  const [backgorundToast, setBackgroundToast] = useState("");

  const { data, isLoading, isFetching, refetch } = useGetTriggersAndPointTriggerQuery(id);
  const [addNewTrigger, { isLoading: isAddLoading }] = useAddNewTriggerMutation();
  const [updateTrigger, { isLoading: isEditLoading }] = useUpdateTriggerMutation();
  const isSaving = isEditLoading || isAddLoading;

  const [originalData, setOriginalData] = useState({});
  const [modifiedData, setModifiedData] = useState({
    isPublished: true,
    name: "",
    category: null,
    description: null,
    publishUp: null,
    publishDown: null,
    points: null,
    color: "#000000",
    events: [],
    triggerExistingLeads: false,
  });
  useEffect(() => {
    if (id && data?.trigger) {
      let updatedTrigger = { ...data?.trigger?.trigger };
      if (updatedTrigger.color && !updatedTrigger.color.startsWith("#")) {
        updatedTrigger.color = `#${updatedTrigger.color}`;
      }
      if (isClone) {
        updatedTrigger = { ...updatedTrigger, isPublished: false };
      }
      setModifiedData({
        ...updatedTrigger,
        category: updatedTrigger?.category
          ? {
              label: updatedTrigger?.category?.title,
              value: updatedTrigger?.category?.id,
            }
          : null,
      });
      setOriginalData({
        ...updatedTrigger,
        category: updatedTrigger?.category
          ? {
              label: updatedTrigger?.category?.title,
              value: updatedTrigger?.category?.id,
            }
          : null,
      });
    }
    const updatedTypes = { ...data?.pointTrigger };
    setEvents(updatedTypes);
  }, [id, data]);
  const checkIfFormIsModified = () => {
    return JSON.stringify(modifiedData) !== JSON.stringify(originalData);
  };
  const handleAddEditTriggers = async (params) => {
    if (modifiedData?.name.length < 1) {
      errors.name = true;
      setErrors({ ...errors });
    } else {
      delete errors.name;
      setErrors({ ...errors });
    }
    if (modifiedData.points === null || modifiedData?.points?.length < 1) {
      errors.points = true;
      setErrors({ ...errors });
    } else {
      delete errors.points;
      setErrors({ ...errors });
    }

    const hasErrors = Object.values(errors).some((value) => value === true);
    if (hasErrors) {
      setShow(true);
      setMessage(t("caymland.core.error.not.valid"));
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 3500);
    }

    if (Object.keys(errors).length === 0) {
      try {
        let response;

        if (modifiedData?.category) modifiedData.category = modifiedData.category.value;
        if (modifiedData?.color) modifiedData.color = modifiedData.color.split("#")[1];

        if (id && !isClone) {
          response = await updateTrigger({ id, ...modifiedData });
        } else {
          response = await addNewTrigger(modifiedData);
        }

        if (!response?.error) {
          Success(
            t("caymland.core.update.step.success"),
            t("caymland.message.type.successfully", {
              type: id ? t("caymland.core.type.edited") : t("caymland.dashboard.create.past.tense"),
            })
          ).then((result) => {
            if (result.isConfirmed && params === "back") {
              navigate("/triggers");
            } else {
              navigate(`/triggers/edit/${response?.data?.trigger?.id}`);
            }
          });
        } else if (modifiedData.points === null) {
          setShow(true);
          setMessage(t("caymland.core.error.not.valid"));
          setBackgroundToast("2");
          setTimeout(() => {
            setShow(false);
            setMessage("");
            setBackgroundToast("1");
          }, 3000);
        } else {
          Error(t("caymland.message.error"));
        }
      } catch (e) {
        Error(t("caymland.message.error"));
      }
    }
  };
  const handleCancel = () => {
    if (checkIfFormIsModified()) {
      Swal.fire({
        title: t("caymland.badge_contact.form.confirmdelete"),
        text: t("caymland.validation.message.changes"),
        icon: "question",
        confirmButtonText: t("caymland.core.form.yes"),
        cancelButtonText: t("caymland.core.form.cancel"),
        showCancelButton: true,
        customClass: {
          confirmButton: "swal-custom confirm-component",
          cancelButton: "swal-custom result-component",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate("/triggers");
        } else {
          return;
        }
      });
    } else {
      navigate("/triggers");
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose").replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleAddEditTriggers("back"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => handleAddEditTriggers(""),
    },
  ];

  return (
    <>
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <Header
          buttons={buttons}
          data={modifiedData}
          background={backgorundToast}
          // showIsPublished={true}
          loading={isSaving}
          showAlert={show}
          alertMessage={message}
          title={
            <TitleHeader
              name={t("caymland.point.trigger.header.index")}
              clone={isClone}
              alertMessage={message}
              showAlert={show}
              title={modifiedData?.name}
              loading={isLoading}
              id={id}
            />
          }
        />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne80"}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton applyPadding />
          ) : (
            <>
              <Nav className="nav-primary w-full flex" tabs>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => setPrimaryColorTab(1)}
                    style={{
                      color: primarycolorTab === 1 ? "white" : palette.text.primary,
                      textTransform: "capitalize",
                      cursor: "pointer",
                      backgroundColor: primarycolorTab === 1 ? palette?.primary?.main : "",
                    }}
                  >
                    {t("caymland.core.details")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => setPrimaryColorTab(2)}
                    style={{
                      color: primarycolorTab === 2 ? "white" : palette.text.primary,
                      textTransform: "capitalize",
                      cursor: "pointer",
                      backgroundColor: primarycolorTab === 2 ? palette?.primary?.main : "",
                    }}
                  >
                    {t("caymland.point.trigger.tab.events")}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={primarycolorTab}>
                <TabPane tabId={1}>
                  <div className="py-20 px-20">
                    <Details
                      modifiedData={modifiedData}
                      setModifiedData={setModifiedData}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  </div>
                </TabPane>
                <TabPane tabId={2}>
                  <div className="py-20 px-20">
                    <Events
                      modifiedData={modifiedData}
                      setModifiedData={setModifiedData}
                      loading={isFetching}
                      events={events}
                    />
                  </div>
                </TabPane>
              </TabContent>
            </>
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton />
          ) : (
            <Sidebar
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              errors={errors}
              setErrors={setErrors}
            />
          )
        }
      />
    </>
  );
}

export default AddTrigger;
