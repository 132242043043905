import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Sidebar from "./ViewFocusItem/Sidebar";
import Details from "./ViewFocusItem/Details";
import FocusStats from "./ViewFocusItem/FocusStats";
import ClickCounts from "./ViewFocusItem/ClickCounts";
import { FaTrashAlt } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import Header from "../../../reusableComponents/Header/Header";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import CustomizedAccordions from "../../../reusableComponents/Accordion/Accordion";
import Swal from "sweetalert2";
import {
  useDeleteFocusItemsMutation,
  useGetExtrasQuery,
  useGetFocusDataQuery,
} from "../../../../redux/api/focusItemsApi";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import Description from "../../../reusableComponents/Description/Description";
import { t } from "i18next";

function ViewFocusItem() {
  const [primarycolorTab, setprimarycolorTab] = useState(1);
  const [focus, setFocus] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isFetching, isLoading, error } = useGetFocusDataQuery(id);
  const [deleteFocusItems] = useDeleteFocusItemsMutation();
  const { data: extraDatas, refetch } = useGetExtrasQuery({
    id,
    dateFrom: localStorage.getItem("fromDate"),
    dateTo: localStorage.getItem("toDate"),
  });

  useEffect(() => {
    if (error) {
      navigate("/focus");
    }
  }, [data, error]);

  useEffect(() => {
    if (data === undefined) return;
    setFocus(data.focusData.focus);
    setExtraInfo(extraDatas);
  }, [id, data, extraDatas]);

  const handleDateChange = () => {
    setDateFrom(localStorage.getItem("fromDate"));
    setDateTo(localStorage.getItem("toDate"));
    const fromDate = localStorage.getItem("fromDate");
    const toDate = localStorage.getItem("toDate");
    refetch({
      id,
      dateFrom: fromDate,
      dateTo: toDate,
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: t("caymland.mailbox.message.delete.title"),
      text: t("caymland.message.delete", { name: focus?.name }),
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteFocusItems(focus?.id);
        if (!response?.error) {
          navigate("/focus");
        }
      }
    });
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/focus"),
    },
    {
      name: "edit",
      title: t("caymland.core.permissions.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => navigate(`/focus/edit/${id}`),
      childs: [
        {
          name: "delete",
          title: t("caymland.mailbox.list.action.deleteMessage"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => handleDelete(),
        },
      ],
    },
  ];

  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details setOpenDetails={setOpenDetails} openDetails={openDetails} focus={focus} />,
    },
  ];
  const accordionsDesc = [
    {
      name: t("caymland.core.description"),
      component: <Description data={focus} />,
    },
  ];

  return (
    <Fragment>
      {isLoading ? (
        <HeaderSkeleton isView={true} />
      ) : (
        <Header buttons={buttons} title={focus?.name} showIsPublished={true} data={focus} />
      )}
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton />
          ) : (
            <>
              <Card style={{ boxShadow: "unset" }}>
                <CardBody style={{ padding: "0" }}>
                  <FocusStats
                    extras={extraInfo}
                    setExtras={setExtraInfo}
                    handleDateChange={handleDateChange}
                    loading={isFetching}
                  />
                </CardBody>
              </Card>
              {extraInfo?.trackables?.length ? (
                <Card>
                  <CardBody>
                    <Nav className="nav-primary w-full" tabs>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 1 ? "active" : ""}
                          onClick={() => setprimarycolorTab(1)}
                          style={{
                            color: "#313131",
                            textTransform: "capitalize",
                          }}
                        >
                          {t("caymland.trackable.click_counts")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={primarycolorTab}>
                      <TabPane tabId={1} style={{ minHeight: "500px" }}>
                        <ClickCounts
                          extras={extraInfo}
                          setExtraInfo={setExtraInfo}
                          setDateFrom={setDateFrom}
                          setDateTo={setDateTo}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              ) : (
                ""
              )}
            </>
          )
        }
        childrenClassTwo={"wrapper-childTwoNoMargin"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton applyPadding={true} />
          ) : (
            <>
              <CustomizedAccordions accordions={accordions} />
              {focus && focus?.description ? <CustomizedAccordions accordions={accordionsDesc} isOpen={true} /> : null}

              <Sidebar extras={extraInfo} id={id} />
            </>
          )
        }
      />
    </Fragment>
  );
}

export default ViewFocusItem;
