import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { t } from "i18next";
import { useExportPrefContactMutation, useUpdatePreferencesMutation } from "../../../redux/api/contactsApi";
import { Select, Checkbox } from "../Inputs";
import Button from "./Button";
import { useLocation, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Categories from "../../main-menu-sidebar/contacts/TopBarComponents/Categories";
import { FaEnvelope, FaPaperPlane, FaSms, FaPhoneAlt } from "react-icons/fa";
import Purposes from "../../main-menu-sidebar/contacts/PrefCenter/Purposes";
import { Tooltip } from "@mui/material";
import { CgExport } from "react-icons/cg";
import { useTheme } from "@mui/material/styles";

const ModalForPreferences = ({
  modalOptions,
  setModalOptions,
  setShow,
  setMessage,
  data,
  setBackgroundToast,
  categoriesFields,
  replaceCategories,
  refetch,
  isFetching,
}) => {
  const closeModal = () => {
    setModalOptions(() => ({
      isOpen: false,
    }));
  };
  const categoriesRef = useRef(null);
  const { palette } = useTheme();

  const [primarycolorTab, setprimarycolorTab] = useState(1);
  const [prefCategories, setPrefCategories] = useState([]);

  const changeDynamicTab = (tabIndex) => {
    if (primarycolorTab !== tabIndex) {
      setprimarycolorTab(tabIndex);
    }
  };

  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;

  const defaultValues = React.useMemo(() => {
    const initialValues = {};
    const leadChannelsData = data?.channels || {};
    Object?.keys(leadChannelsData)?.forEach((channelKey) => {
      initialValues[`status_${channelKey}`] = leadChannelsData[channelKey]?.status;
    });
    return initialValues;
  }, [data]);

  const { control, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    Object.keys(defaultValues).forEach((field) => {
      const value = defaultValues[field];
      // Convert boolean values to string when setting the form defaults
      setValue(field, typeof value === "boolean" ? value.toString() : value);
    });
  }, [data, setValue, defaultValues]);
  const [updateChannels] = useUpdatePreferencesMutation();

  const stringToBoolean = (value) => {
    if (typeof value === "string") {
      return value.toLowerCase() === "true"; // Convert "true" to true, "false" to false
    }
    return value; // Return non-string values as is
  };

  const handleChannels = async (formData) => {
    const updatedChannels = Object.entries(formData)
      .filter(([key]) => key.startsWith("status_") || key.startsWith("reason_"))
      .reduce((acc, [key, value]) => {
        const channelKey = key.includes("status_") ? key.replace("status_", "") : key.replace("reason_", "");

        if (!acc[channelKey]) {
          acc[channelKey] = {};
        }

        if (key.startsWith("status_")) {
          acc[channelKey].status = stringToBoolean(value); // Apply conversion function here
        }
        if (key.startsWith("reason_")) {
          acc[channelKey].reason = isNaN(value) ? value : Number(value); // Convert all numeric-like strings to numbers
        }

        return acc;
      }, {});

    // Force the email channel to always have status true
    if (!updatedChannels.email) {
      updatedChannels.email = {};
    }
    updatedChannels.email.status = true;

    const purposesToSend = formData?.selectedPurpose?.map((x) => x.value) || [];

    // Remove 'data' and 'values' if they exist in updatedChannels
    delete updatedChannels.data;
    delete updatedChannels.values;

    // Construct the payload with the channels, ensuring data and values are not included
    const payload = {
      channels: { ...updatedChannels }, // Only include updated channels
      purposes: purposesToSend,
      categories: prefCategories?.map((x) => x.id),
    };

    try {
      const response = await updateChannels({ id: id, ...payload });
      if (!response.error) {
        setShow(true);
        setMessage(t("caymland.message.type.successfully", { type: "edited" }));
        setBackgroundToast("1");
        setTimeout(() => setShow(false), 3000);
        replaceCategories(prefCategories);
        refetch();
      } else {
        setShow(true);
        setMessage(t("caymland.message.type.error"));
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 3000);
      }
      closeModal();
    } catch (error) {
      console.error("Error updating preferences:", error);
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: closeModal,
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => {
        handleSubmit(handleChannels)(); // Save other fields
      },
    },
  ];

  const staticOptions = [
    { label: t("caymland.core.form.yes"), value: "true" },
    { label: t("caymland.core.form.no"), value: "false" },
  ];

  const getChannelIcon = (channelKey) => {
    switch (channelKey) {
      case "email":
        return <FaEnvelope size={20} style={{ marginRight: "8px" }} />;
      case "sms":
        return <FaSms size={20} style={{ marginRight: "8px" }} />;
      case "post":
        return <FaPaperPlane size={20} style={{ marginRight: "8px" }} />;
      case "viber":
        return <FaPhoneAlt size={20} style={{ marginRight: "8px" }} />;
      default:
        return null;
    }
  };

  const [exportPrefContact] = useExportPrefContactMutation();
  const exportButton = [
    {
      name: "",
      title: t("caymland.contact.export.contact"),
      margin: "0 0 0 auto",
      icon: (
        <Tooltip title={t("caymland.email.report.export")} arrow>
          <CgExport size={15} />
        </Tooltip>
      ),
      onClick: async () => {
        try {
          // Call the mutation to fetch the PDF as a Blob
          const response = await exportPrefContact(id).unwrap();

          // Create a Blob from the response (which is already in the correct format)
          const blob = new Blob([response], { type: "application/pdf" });

          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `exported_contact_${id}.pdf`; // PDF filename
          link.click();

          // Clean up by revoking the object URL
          window.URL.revokeObjectURL(link.href);
        } catch (error) {
          console.error("Error exporting contact: ", error);
        }
      },
    },
  ];
  return (
    <Modal show={modalOptions.isOpen} onHide={closeModal}   backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            padding: "4px",
            fontSize: "18px",
          }}
        >
          {t("caymland.lead.lead.header.contact.frequency")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Nav className="nav-primary w-full" tabs>
          <NavItem>
            <NavLink
              href="#"
              onClick={() => changeDynamicTab(1)}
              style={{
                color: primarycolorTab === 1 ? "white" : palette.text.primary,
                textTransform: "capitalize",
                cursor: "pointer",
                backgroundColor: primarycolorTab === 1 ? palette?.primary?.main : "",
              }}
            >
              {t("caymland.core.channels")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              onClick={() => changeDynamicTab(2)}
              style={{
                color: primarycolorTab === 2 ? "white" : palette.text.primary,
                textTransform: "capitalize",
                cursor: "pointer",
                backgroundColor: primarycolorTab === 2 ? palette?.primary?.main : "",
              }}
            >
              {t("caymland.lead.preferred.categories")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              onClick={() => changeDynamicTab(3)}
              style={{
                color: primarycolorTab === 3 ? "white" : palette.text.primary,
                textTransform: "capitalize",
                cursor: "pointer",
                backgroundColor: primarycolorTab === 3 ? palette?.primary?.main : "",
              }}
            >
              {t("caymland.contact.export.purposes")}
            </NavLink>
          </NavItem>
          <Button buttons={exportButton} height={30} margin={"0 0 auto 0"} />
        </Nav>
        <TabContent activeTab={primarycolorTab}>
          <TabPane tabId={1}>
            {/* Accessing data.channels.data to render the dynamic channels */}
            {data?.channels?.data &&
              Object.keys(data.channels.data).map((channelKey) => (
                <div
                  key={channelKey}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "2fr 6fr ",
                    padding: "1em 0",
                    marginTop: "1em",
                    border: "1px solid #ddd", // Adding a border to distinguish channels
                    borderRadius: "5px", // Rounded corners
                    backgroundColor: "#fafafa", // Light background to separate channels
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Subtle shadow for emphasis
                    alignItems: "center", // Vertical center alignment
                  }}
                >
                  {/* Display channel names with corresponding icons */}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {getChannelIcon(channelKey)} {/* Render the icon */}
                    {channelKey.toUpperCase()}
                  </span>

                  {/* Reason Select for email channel, otherwise status */}
                  {channelKey === "email" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensure it takes full height of grid
                        marginRight: "20px",
                      }}
                    >
                      <Controller
                        name={`reason_${channelKey}`}
                        control={control}
                        defaultValue={data.channels.data[channelKey]?.reason?.toString() ?? ""} // Convert initial value to string
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={data.channels.values.email.map((option) => ({
                              ...option,
                              value: option.value.toString(), // Ensure options are strings
                            }))}
                            onChange={(e) => field.onChange(e?.value ?? "")}
                            isClearable={false}
                            margin={"0px"}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                height: "100%", // Ensure Select box is vertically centered
                              }),
                            }}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensure it takes full height of grid
                        marginRight: "20px",
                      }}
                    >
                      <Controller
                        name={`status_${channelKey}`}
                        control={control}
                        defaultValue={data.channels.data[channelKey]?.status?.toString() ?? ""} // Convert initial value to string
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={staticOptions}
                            isClearable={false}
                            onChange={(e) => field.onChange(e?.value ?? "")}
                            margin={"0px"}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                height: "100%", // Ensure Select box is vertically centered
                              }),
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              ))}
          </TabPane>
          <TabPane tabId={2}>
            <Categories
              categoriesFields={categoriesFields}
              replaceCategories={replaceCategories}
              setMessage={setMessage}
              setShow={setShow}
              setBackgroundToast={setBackgroundToast}
              normal={false}
              onSave={categoriesRef} // Pass the ref to Categories
              setPrefCategories={setPrefCategories}
            />
          </TabPane>
          <TabPane tabId={3}>
            <Purposes
              control={control} // Form control for react-hook-form
              purposes={data?.purposes} // The list of purposes for the Select
              id={id}
              refetch={refetch}
              setValue={setValue}
              isFetching={isFetching}
            />
          </TabPane>
        </TabContent>
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalForPreferences;
