import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Label } from "./index";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const CustomDatePicker = ({
  name,
  value,
  onChange,
  label,
  tooltip,
  required = false,
  minDate,
  maxDate,
  showTimeSelect = false, // Assuming no time selection for 'dd.mm.yyyy' format
  isClearable = true,
  disabled = false,
  placeholderText,
  myBorder,
  noBorderRadius,
  marginBottom = true,
  viewEdit = false,
  extraStyle,
  timeFormat = "HH:mm",
  errors,
  datestring,
  isLoading = false,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const dateFormat = showTimeSelect ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";
  const refDatePicker = useRef(null);
  const debounceRef = useRef(null);

  // Function to format date as 'dd.mm.yyyy'
  const formatDate = (date) => {
    if (!date) return "";
    let d = new Date(date),
      day = "" + d.getDate(),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear();

    if (day.length < 2) day = "0" + day;
    if (month.length < 2) month = "0" + month;

    return [year, month, day].join("-");
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = formatDate(date);
      setInputValue(formattedDate);
      onChange(formattedDate);
    } else {
      setInputValue("");
      onChange("");
    }
  };

  const handleInputChange = (event) => {
    const input = event.target.value;
    setInputValue(input);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (!isNaN(Date.parse(input))) {
        // Parsing the input date to validate
        const parts = input.split(".");
        if (parts.length === 3) {
          const parsedDate = new Date(parts[2], parts[1] - 1, parts[0]);
          if (!isNaN(parsedDate)) {
            handleDateChange(parsedDate);
          }
        }
      } else {
        onChange(input);
      }
    }, 500); // Delay in milliseconds, adjust as necessary
  };

  const handleCalendarClick = () => {
    if (refDatePicker.current) {
      refDatePicker.current.setOpen(true);
    }
  };

  return datestring ? (
    <div className={`w-full ${marginBottom ? "mb-20px" : ""}`}>
      {label && <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />}
      <div style={{ position: "relative" }}>
        <input
          type="text"
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholderText}
          disabled={disabled}
          className={`form-control datepicker-placeholder hover-effect ${
            viewEdit ? "h-32 inherit font-13 noBorders" : "h-40 white font-15"
          } ${myBorder ? myBorder : ""} ${noBorderRadius ? "noBorderRadius" : "borderRadius"} ${extraStyle?.className}`}
          style={{ paddingLeft: "40px" }} // Ensure the input text starts after the icon
        />
        <CalendarTodayIcon
          onClick={handleCalendarClick}
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "gray",
            fontSize: "22px",
            cursor: "pointer",
          }}
        />
      </div>
      <DatePicker
        ref={refDatePicker}
        selected={
          inputValue && !isNaN(Date.parse(inputValue.split(".").reverse().join("-")))
            ? new Date(inputValue.split(".").reverse().join("-"))
            : null
        }
        onChange={handleDateChange}
        dateFormat="dd.MM.yyyy"
        showTimeSelect={showTimeSelect}
        minDate={minDate}
        maxDate={maxDate}
        customInput={<React.Fragment />} // Invisible, handling input through custom input field
        disabled={disabled}
        required={required}
        placeholderText={placeholderText}
      />
    </div>
  ) : (
    <div className={`w-full ${marginBottom ? "mb-20px" : ""}`}>
      {label && label.length && (
        <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />
      )}
      <DatePicker
        name={name}
        placeholderText={placeholderText}
        disabled={disabled}
        className={`form-control   datepicker-placeholder hover-effect focus:tw-shadow-none focus:tw-border-none focus:tw-text-transparent    ${
          viewEdit ? "h-32 inherit font-13 noBorders" : "h-40 white font-15"
        } ${myBorder ? myBorder : ""} ${noBorderRadius ? "noBorderRadius" : "borderRadius"} ${extraStyle?.className}`}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        minDate={minDate}
        maxDate={maxDate}
        showYearDropdown
        showMonthDropdown
        isClearable={isClearable}
        isLoading={isLoading}
        required={required}
        selected={value ? new Date(value) : null}
        onChange={(e) => onChange(e ? new Date(e) : null)}
        
      />
    </div>
  );
};

export default CustomDatePicker;
