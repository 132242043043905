import React, { useCallback, useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Label } from "./index";
import { useTheme } from '@mui/material/styles';

const CustomTimePicker = ({
  label,
  value,
  name,
  onChange,
  tooltip,
  required = false,
  disabled,
  marginBottom = true,
  viewEdit = false,
  width,
  errors
}) => {
  const [defaultValue, setDefaultValue] = useState(null);
    const { palette } = useTheme();
  

  useEffect(() => {
    if (value) {
      const formatter = new AdapterDayjs();
  
      if (typeof value === "string" && value.length === 5 && value.includes(":")) {
        const splitTime = value.split(":");
        const date = new Date();
  
        date.setHours(Number(splitTime[0]));
        date.setMinutes(Number(splitTime[1]));
  
        setDefaultValue(formatter.date(date));
      } 
      else if (typeof value === "string" && value.length > 5) {
        const parsedDate = formatter.date(value);
        setDefaultValue(parsedDate);
      } 
      else {
        console.log('Unsupported format:', value);
      }
    }
  }, [value]);

  const formatDate = (e) => {
    if (!e || e.$H === undefined || e.$m === undefined) return "";

    let { $H: hour, $m: minute } = e;

    if (hour < 10) {
      hour = "0" + hour;
    }

    if (minute < 10) {
      minute = "0" + minute;
    }

    return `${hour}:${minute}`;
  };

  return (
    <div className={`${!width && 'w-full', marginBottom ? "mb-20px" : ""}`}>
      {label && label.length && <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          // autoFocus={viewEdit}
          name={name}
          value={defaultValue}
          className={`w-full hover-effect ${disabled ? "jump-input disabled-cursor" : "jump-input"} ${
            viewEdit ? "h-32 inherit font-14 noBorders" : "h-40 white font-15"
          }`}
          ampm={false}
          ampmInClock={true}
          format={"HH:mm"}
          closeOnSelect={true}
          reduceAnimations={true}
          onChange={(e) => onChange(formatDate(e))}
          onBlur={() => onChange(formatDate(defaultValue))} 
          disabled={disabled}
          sx={{
            border: "0 !important",
            outline: "none !important",
            boxShadow: "none !important", // Remove box shadow
            "& .MuiInputBase-root": {
              color: palette?.text?.primary,
              height: viewEdit ? "32px" : "auto",
              fontSize: viewEdit ? "14px" : "auto",
              maxHeight: '40px',
              width: '112px !important',
              "&::placeholder": {
                color: palette?.text?.primary,
              },
            },
            "& .MuiFormControl-root":{
              width: '112px !important'
            },
            "& .MuiTextField-root":{
              width: '112px !important'
            },
            "& .css-vq78nm-MuiFormControl-root-MuiTextField-root":{
              width: '112px !important'
            },
            "& .MuiPickersPopper-root": {
              width: "160px",
            },
            "& .MuiList-root": {
              width: "80px",
            },
            "& .MuiMultiSectionDigitalClock-root": {
              width: "80px",
            },
            "& .css-1ed1o9w-MuiList-root-MuiMultiSectionDigitalClockSection-root": {
              width: "80px",
            },
            "& .css-15w7row-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "8.5px 12px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: viewEdit ? "0 !important" : "",
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CustomTimePicker;
