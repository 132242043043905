import React, { Fragment, useEffect, useState, useRef } from "react";
import { FormControl } from "react-bootstrap";
import { RiArrowLeftRightFill } from "react-icons/ri";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { t } from "i18next";

const parseResponseData = (response) => {
  const graphs = response?.graphList;
  const parseData = (data) => {
    const options = [];
    Object.entries(data).forEach(([value, label], index) => {
      options.push({
        label: label,
        value: value,
        index: index,
      });
    });
    return options;
  };
  return {
    graphList: parseData(graphs),
  };
};

function Graphs({
  graphs,
  modifiedData,
  setModifiedData,
  sortOptionsByIndex,
  filterOptionsBySearch,
  createHandleSelectAll,
  createHandleDeselectAll,
  handleSelectChange,
  createHandleSearchChange,
  id,
}) {
  const [leftGraphs, setLeftGraphs] = useState([]);
  const [rightGraphs, setRightGraphs] = useState([]);
  const [leftGraphSearchValue, setLeftGraphSearchValue] = useState("");
  const [rightGraphSearchValue, setRightGraphSearchValue] = useState("");

  const handleLeftGraphSearchChange = createHandleSearchChange(setLeftGraphSearchValue);
  const handleRightGraphSearchChange = createHandleSearchChange(setRightGraphSearchValue);

  const handleSelectAllGraphs = createHandleSelectAll(leftGraphs, rightGraphs, setLeftGraphs, setRightGraphs);
  const handleDeselectAllGraphs = createHandleDeselectAll(leftGraphs, rightGraphs, setLeftGraphs, setRightGraphs);

  const getDataGraphs = () => {
    if (modifiedData?.graphs?.length > 0) {
      const newRightGraphs = modifiedData.graphs
        .map((columnValue) => graphs.find((column) => column.value === columnValue))
        .filter(Boolean);
      const newLeftgraphs = graphs.filter((column) => !modifiedData.graphs.includes(column.value));
      setRightGraphs(newRightGraphs);
      setLeftGraphs(newLeftgraphs);
    }
  };

  useEffect(() => {
    setLeftGraphs(graphs);
    if (id) {
      getDataGraphs();
    }
  }, [graphs]);

  useEffect(() => {
    if (modifiedData?.graphs && rightGraphs !== null) {
      setModifiedData((prevModifiedData) => ({
        ...prevModifiedData,
        graphs: rightGraphs.map((g) => g.value) || [],
      }));
    }
  }, [rightGraphs]);

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    const isSettings = Object.keys(modifiedData.settings).includes(name);
    isSettings &&
      setModifiedData({
        ...modifiedData,
        settings: {
          ...modifiedData.settings,
          [name]: 1,
        },
      });
    !isSettings &&
      setModifiedData((prev) => ({
        ...prev,
        [name]: checked,
      }));
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedOptions = Array.from(rightGraphs);
    const [movedOption] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, movedOption);
    setRightGraphs(reorderedOptions);
  };

  return (
    <Fragment>
      <div class="switch-holder pb-10">
        <label className="toggle-label" for="showGraphsAboveTable" style={{ fontSize: "13px" }}>
          {t("caymland.report.report.form.display.graphs.above.table")}
        </label>
        <div class="switch-toggle">
          <input
            type="checkbox"
            name="showGraphsAboveTable"
            id="showGraphsAboveTable"
            checked={modifiedData?.settings?.showGraphsAboveTable}
            onChange={(e) => handleToggleChange(e)}
          />
          <label for="showGraphsAboveTable"></label>
        </div>
      </div>
      <div>
        <p className="mb-0">{t("caymland.report.report.form.graphs")}</p>
        <div className="flex">
          <div className="w-50" style={{ marginRight: "30px" }}>
            <div className="d-flex">
              <div className="w-50">
                <div className="flex flex-col gap-10 w-100">
                  <div class="input-container ic1 mb-10px">
                    <input
                      class="jump-input"
                      type="text"
                      placeholder={`${t("caymland.core.select")}...`}
                      value={leftGraphSearchValue}
                      onChange={handleLeftGraphSearchChange}
                    />
                  </div>
                </div>
                <FormControl
                  as="select"
                  multiple
                  value={sortOptionsByIndex(filterOptionsBySearch(leftGraphs, leftGraphSearchValue)).map(
                    (option) => option.value
                  )}
                  style={{ height: "200px", fontSize: "15px" }}
                >
                  {leftGraphs.length &&
                    sortOptionsByIndex(filterOptionsBySearch(leftGraphs, leftGraphSearchValue)).map((option) => (
                      <option
                        key={option.value}
                        onClick={() =>
                          handleSelectChange(leftGraphs, rightGraphs, setLeftGraphs, setRightGraphs)(option?.label)
                        }
                      >
                        {option.label}
                      </option>
                    ))}
                </FormControl>
                <div className="float-right">
                  <Link className="cursor-pointer" to="#" onClick={handleSelectAllGraphs}>
                    {t("caymland.core.select.all")}
                  </Link>
                </div>
              </div>
              <RiArrowLeftRightFill size={35} className="align-self-center" />
              <div className="w-50" style={{ marginLeft: "10px" }}>
                <div className="flex flex-col gap-10 w-100">
                  <div class="input-container ic1 mb-10px">
                    <input
                      class="jump-input"
                      type="text"
                      placeholder={`${t("caymland.core.select")}...`}
                      value={rightGraphSearchValue}
                      onChange={handleRightGraphSearchChange}
                    />
                  </div>
                </div>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <ul
                        {...provided.droppableProps}
                        multiple
                        ref={provided.innerRef}
                        value={
                          rightGraphs
                            ? filterOptionsBySearch(rightGraphs, rightGraphSearchValue)?.map((option) => option?.value)
                            : null
                        }
                        className="form-control"
                        style={{
                          height: "200px",
                          overflowY: "scroll",
                          textWrap: "nowrap",
                          fontSize: "15px",
                        }}
                      >
                        {filterOptionsBySearch(rightGraphs, rightGraphSearchValue)?.map((option, index) => (
                          <Draggable key={option?.value} draggableId={option?.value} index={index}>
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() =>
                                  handleSelectChange(
                                    leftGraphs,
                                    rightGraphs,
                                    setLeftGraphs,
                                    setRightGraphs
                                  )(option?.label)
                                }
                              >
                                {option?.label}
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                <Link className="cursor-pointer" to="#" onClick={handleDeselectAllGraphs}>
                  {t("caymland.core.deselect.all")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Graphs;
