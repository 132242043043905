import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useGetContractOptionsQuery } from "../../../../redux/api/contractsApi";
import { useGetUserSelectOptionsQuery } from "../../../../redux/api/usersApi";
import { Checkbox, DatePicker, Select, Text } from "../../../reusableComponents/Inputs";
import { contactsApi } from "../../../../redux/api/contactsApi";
import { t } from "i18next";
import { useSelector } from "react-redux";

const Sidebar = ({ control, errors, watch }) => {
  const { data: typeOptions } = useGetContractOptionsQuery({ type: "types" });
  const { data: currencyOptions } = useGetContractOptionsQuery({ type: "currencies" });
  const { data: checklistOptions } = useGetContractOptionsQuery({ type: "checklists" });
  const { data: userOptions } = useGetUserSelectOptionsQuery();
  const [contactsOptions, setContactsOptions] = useState([]);

  const user = useSelector((state) => state.auth.user);

  const [triggerContactsQuery] = contactsApi.useLazyGetContactsQuery();
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const statusOptions = [
    { label: t("caymland.contract.status.1"), value: 1, color: "green" },
    { label: t("caymland.contract.status.2"), value: 2, color: "blue" },
    { label: t("caymland.contract.status.3"), value: 3, color: "gray" },
    { label: t("caymland.contract.status.4"), value: 4, color: "red" },
    { label: t("caymland.contract.status.5"), value: 5, color: "purple" },
  ];

  console.log(watch());
  const retrieveContacts = (inputValue) =>
    new Promise(async (resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        const lead = watch("lead") ? [watch("lead")] : [];
        const selectedContacts = watch("contacts") || [];
        const selectedContactIds = [...lead, ...selectedContacts];

        // Base query
        const query = {
          pageIndex: 0,
          pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
          orderByDir: "desc",
          orderBy: "last_active",
        };

        // Add where clause only if there's no search input
        if (!inputValue.length && selectedContactIds.length > 0) {
          query.where = [
            {
              column: "id",
              expr: "in",
              value: selectedContactIds, // Ensure previously selected contacts remain in dropdown
            },
          ];
        } else {
          query.search = inputValue; // When searching, ignore where clause
        }

        const response = await triggerContactsQuery(query);
        if (!response.error) {
          const contactsData = response?.data?.data || [];

          // Convert API response to Select options
          const fetchedContacts = contactsData.map((contact) => {
            const firstname = contact?.fields?.all?.firstname || "";
            const lastname = contact?.fields?.all?.lastname || "";
            const label = `${firstname} ${lastname}`.trim();

            return {
              label,
              value: contact?.id,
            };
          });

          // Keep selected contacts that aren't in the search results
          const existingContacts = selectedContactIds
            .filter((id) => !fetchedContacts.some((c) => c.value === id))
            .map((id) => contactsOptions.find((c) => c.value === id))
            .filter(Boolean);

          // Merge existing selected contacts with fetched search results and remove duplicates
          const mergedContacts = [...existingContacts, ...fetchedContacts];

          // Ensure unique contacts using Map (removes duplicates)
          const uniqueContacts = Array.from(new Map(mergedContacts.map((item) => [item.value, item])).values());

          setContactsOptions(uniqueContacts);
          resolve(uniqueContacts);
        } else {
          reject();
        }
      }, 1000);

      setDebounceTimeout(newTimeout);
    });

  return (
    <div className="tw-w-[100%] tw-flex tw-flex-col tw-gap-4">
      {/* Text Input */}
      <Controller
        control={control}
        name={"name"}
        render={({ field: { name, onChange, value } }) => (
          <Text
            name={name}
            value={value}
            onChange={onChange}
            marginBottom
            label={"Name"}
            required={true}
            errors={errors && errors[name]}
          />
        )}
      />

      {/* Contract Partner Select */}

      <Controller
        control={control}
        name={"lead"}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={contactsOptions.find((item) => item.value == value) || value}
            onChange={onChange}
            margin
            options={retrieveContacts}
            label={t("caymland.contract.partner")}
            required={true}
            errors={errors && errors[name]}
          />
        )}
      />

      {/* Additional Leads */}
      <Controller
        control={control}
        name={"contacts"}
        render={({ field: { name, onChange, value } }) => {
          console.log(value);
          // Ensure unique values
          const uniqueValues = Array.from(new Set(value || []));
          console.log(uniqueValues);

          return (
            <Select
              name={name}
              value={contactsOptions.filter((item) => uniqueValues.includes(item.value)) || []}
              onChange={(selectedOptions) => {
                // Ensure unique selected IDs
                const selectedIds = Array.from(new Set(selectedOptions.map((option) => option.value)));
                onChange(selectedIds);
              }}
              margin
              options={retrieveContacts}
              isMulti
              label={t("caymland.contract.additional.leads")}
              required={true}
              errors={errors && errors[name]}
            />
          );
        }}
      />

      <Controller
        control={control}
        name={"sidebarUser"}
        render={({ field: { name, onChange, value } }) => {
          const selectedValue = value || user?.id; // Fallback to user.id if value is missing

          return (
            <Select
              name={name}
              label={t("caymlandb2b.opportunity.user")}
              value={userOptions?.find((item) => item.value == selectedValue) || selectedValue}
              onChange={onChange}
              margin
              isClearable={false}
              options={userOptions}
              errors={errors && errors[name]}
            />
          );
        }}
      />
      <Controller
        control={control}
        name={"type"}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={typeOptions?.find((item) => item.value == value) || value}
            onChange={onChange}
            margin
            options={typeOptions}
            label={t("caymland.contract.type")}
            errors={errors && errors[name]}
          />
        )}
      />

      <Controller
        control={control}
        name={"status"}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={value}
            onChange={onChange}
            margin
            options={statusOptions}
            label={t("caymland.contract.status")}
            required={true}
            errors={errors && errors[name]}
          />
        )}
      />
      <Controller
        control={control}
        name={"currency"}
        render={({ field: { name, onChange, value } }) => (
          <Select
            name={name}
            value={currencyOptions?.find((item) => item.value == value) || value}
            onChange={onChange}
            margin
            options={currencyOptions}
            label={t("caymland.contract.currency")}
            required={true}
            errors={errors && errors[name]}
          />
        )}
      />

      {/* Date Inputs */}
      <div className="tw-flex tw-items-center tw-gap-4">
        <Controller
          control={control}
          name={"dateValidFrom"}
          render={({ field: { name, onChange, value } }) => (
            <DatePicker
              name={name}
              value={value}
              onChange={onChange}
              marginBottom={false}
              label={t("caymland.contact.export.valid.from")}
              isClearable={false}
              // showTimeSelect
              errors={errors && errors[name]}
            />
          )}
        />
        <Controller
          control={control}
          name={"dateValidTo"}
          render={({ field: { name, onChange, value } }) => (
            <DatePicker
              name={name}
              value={value}
              onChange={onChange}
              marginBottom={false}
              label={t("caymland.contact.export.valid.until")}
              isClearable={false}
              // showTimeSelect
              errors={errors && errors[name]}
            />
          )}
        />
      </div>

      {/* Checkbox for Continue Automatically */}
      <Controller
        control={control}
        name={"continueAutomatically"}
        render={({ field: { name, onChange, value } }) => (
          <Checkbox
            name={name}
            value={value}
            onChange={onChange}
            marginBottom="0px"
            label={t("caymland.contract.continue.automatically")}
            errors={errors && errors[name]}
          />
        )}
      />

      {/* Dynamic Checklist Checkboxes */}
      {checklistOptions?.length > 0 && (
        <Controller
          control={control}
          name={"checklists"}
          render={({ field: { name, onChange, value } }) => (
            <div>
              <p className="tw-font-[13px] tw-mb-2 tw-text-[#6C757D]">{t()}</p>
              {checklistOptions?.map((option) => (
                <div key={option.value} className="checkbox-wrapper-46">
                  <input
                    type="checkbox"
                    id={`checklist-${option.value}`}
                    name={`${name}[${option.value}]`}
                    value={option.value}
                    checked={value?.includes(option.label)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const updatedValues = checked
                        ? [...(value || []), option.label]
                        : (value || []).filter((v) => v !== option.label);
                      onChange(updatedValues);
                    }}
                    style={{
                      width: "16px",
                      height: "16px",
                      cursor: "pointer",
                    }}
                    className="inp-cbx"
                  />
                  <label
                    className="cbx"
                    htmlFor={`checklist-${option.value}`}
                    style={{ margin: 0, fontSize: "16px", fontWeight: "500", color: "#495057" }}
                  >
                    <span>
                      <svg width="12px" height="10px" viewbox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </svg>
                    </span>
                    <span> {option.label}</span>
                  </label>
                </div>
              ))}
              {errors && errors[name] && <div className="text-danger">{errors[name].message}</div>}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default Sidebar;
