import { t } from "i18next";
import React from "react";
import { Modal } from "react-bootstrap";
import { ImCancelCircle } from "react-icons/im";
import { TbDeviceFloppy } from "react-icons/tb";
import Button from "../Buttons/Button";
import FieldGenerator from "./FieldGenerator";
import { useForm } from "react-hook-form";
import { Error, Success } from "../Alerts";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const ModalGenerator = ({ modalOptions, setModalOptions, content, addItem, setNewItem, type }) => {
  const createValidationSchema = (fields) => {
    let schemaFields = {};

    fields.forEach((field) => {
      switch (field.type) {
        case "text":
        case "color":
          schemaFields[field.alias] = field.isRequired
            ? Yup.string().required("This field is required").nullable()
            : Yup.string();
          break;
        case "boolean":
          schemaFields[field.alias] = Yup.boolean();
          break;
        // Add other field types and their validations here
        default:
          break;
      }
    });
    return Yup.object().shape(schemaFields);
  };

  const validationSchema = createValidationSchema(content?.fields || []);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const closeModal = () => {
    setModalOptions((prev) => ({
      ...prev,
      isOpen: false,
    }));
    reset();
  };
  const handleAddItem = async (data) => {
    const dataToSend = { ...data, bundle: modalOptions?.type || null };

    const response = await addItem(dataToSend);
    if (!response.error) {
      closeModal();
      if (type === "contacts") {
        setNewItem(response.data.category);
        // Set newItem for contacts
      } else {
        // Handle other types or a default case if needed
        setNewItem({
          label: response.data.category.title,
          value: response.data.category.id,
        });
      }
      // Success(
      //   t("caymland.core.update.step.success"),
      //   t("caymland.message.type.successfully", {
      //     type: t("caymland.dashboard.create.past.tense"),
      //   })
      // );
    } else {
      Error(response.error.data.errors[0].message);
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: closeModal,
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <TbDeviceFloppy size={15} />,
      onClick: handleSubmit(handleAddItem),
    },
  ];

  return (
    <div>
      {" "}
      <Modal show={modalOptions.isOpen} onHide={closeModal}   backdrop="static">
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
          closeButton
        >
          <Modal.Title
            style={{
              padding: "4px",
              fontSize: "18px",
            }}
          >
            {content?.name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FieldGenerator fields={content?.fields} control={control} errors={errors} />
        </Modal.Body>

        <Modal.Footer>
          <Button buttons={buttons} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalGenerator;
