import React, { Fragment, useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import Loading from "../reusableComponents/loading";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import AccountDetails from "./EditProfileForms/AccountDetails";

import { useNavigate } from "react-router-dom";
import { SlideAlert } from "../reusableComponents/Alerts";
import Swal from "sweetalert2";
import { isEqual } from "lodash";
import md5 from "blueimp-md5";
import Header from "../reusableComponents/Header/Header";
import { FaRegTimesCircle } from "react-icons/fa";
import Wrapper from "../reusableComponents/Wrapper/Wrapper";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateUserMutation, useUserSelfQuery } from "../../redux/api/usersApi";
import { setCredentials, setUpdatedUser } from "../../redux/features/authSlice";
import Title from "../reusableComponents/title";
import { FaCheckDouble } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

const EditProfile = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [newEditedUser, setNewEditedUser] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialUserData, setInitialUserData] = useState(null);
  const [changesSaved, setChangesSaved] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const [errors, setErrors] = useState({});
  const [formSubmited, setFormSubmitted] = useState(false);
  const [backgorundToast, setBackgroundToast] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    signature: "",
    password: "",
    confirmPassword: "",
    phone1: "",
    phone2: "",
    position: "",
    department: "",
    city: "",
    street: "",
    zip: "",
    fax: "",
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [editUser] = useUpdateUserMutation();

  const handleCancel = () => {
    if (changesSaved) {
      // Changes have been saved, navigate back without showing confirmation dialog
      navigate(-1);
      return;
    }

    // Changes have not been saved, show confirmation dialog
    const hasUnsavedChanges = !isEqual(newEditedUser, initialUserData);

    if (!hasUnsavedChanges) {
      navigate(-1);
      return;
    }

    Swal.fire({
      title: t("caymland.mailbox.message.delete.title"),
      text: t("caymland.validation.message.changes"),
      icon: "question",
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "swal-custom confirm-component",
        cancelButton: "swal-custom result-component",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        navigate(-1);
      }
    });
  };

  const getUser = async () => {
    setNewEditedUser(user);
    setInitialUserData(user);
  };
  useEffect(() => {
    getUser();
  }, []);
  console.log(newEditedUser);
  const editAccount = async () => {
    let newErrors = {};

    if (newEditedUser?.firstName?.length < 1) {
      newErrors.firstName = true;
    }
    if (newEditedUser?.lastName?.length < 1) {
      newErrors.lastName = true;
    }

    if (newEditedUser?.username?.length < 1) {
      newErrors.username = true;
    }
    if (newEditedUser?.plainPassword?.password?.length < 1) {
      newErrors.password = true;
    }
    if (newEditedUser?.email?.length < 1) {
      newErrors.email = true;
    }
    if (newEditedUser?.plainPassword?.confirm?.length < 1 || formData?.confirm === null) {
      newErrors.confirm = true;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (formData?.email && !emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
      setShow(true);
      setMessage(`Invalid email format format: exemple@exemple.com`);
      setBackgroundToast("2");
      setTimeout(() => {
        setShow(false);
        setMessage("");
        setBackgroundToast("1");
      }, 4000);
    }
    console.log(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShow(true);
      setMessage(t("caymland.core.error.not.valid"));
      setBackgroundToast("2");
      setTimeout(() => {
        setMessage("");
        setShow(false);
        setBackgroundToast("1");
      }, 3000);
      return;
    } else {
      setErrors({});
    }
    if (Object.keys(errors).length === 0) {
      try {
        const dataToSend = {
          ...newEditedUser,
          role: newEditedUser.role.id, // Assuming role object always exists and has an id
        };

        const response = await editUser({ id: newEditedUser.id, ...dataToSend });
        setFormSubmitted(true);
        console.log(response);
        if (!response.error) {
          const updatedUser = response.data.user;

          dispatch(setUpdatedUser(response.data.user));
          setShow(true);
          setMessage(`${newEditedUser.firstName} has been updated`);
          setBackgroundToast("1");
          setTimeout(() => {
            setMessage("");
            setShow(false);
          }, 3000);
          setChangesSaved(true);
        } else {
          setShow(true);
          setMessage(response.error.data.errors[0].message);
          setBackgroundToast("2");
          setTimeout(() => {
            setMessage("");
            setShow(false);
            setBackgroundToast("1");
          }, 3000);
        }
      } catch (error) {
        setShow(true);
        setMessage(t("caymland.core.error.not.valid"));
        setBackgroundToast("2");
        setTimeout(() => {
          setMessage("");
          setShow(false);
          setBackgroundToast("1");
        }, 3000);
      }
    }
  };

  function getGravatarURL(email, size = 80) {
    const defaultImageURL = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
    console.log(email);
    if (!email) {
      return defaultImageURL;
    }
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${encodeURIComponent(defaultImageURL)}`;
  }

  let imageURL = getGravatarURL(newEditedUser.email);
  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: handleCancel,
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: editAccount,
    },
  ];
  console.log(imageURL);
  return (
    <Fragment>
      <Header
        title={`${t("caymland.core.permissions.edit")} - ${newEditedUser.firstName} ${newEditedUser.lastName}`}
        buttons={buttons}
        showAlert={show}
        alertMessage={message}
        background={backgorundToast}
      />

      {/* <SlideAlert show={show} message={message} /> */}
      <Wrapper
        childrenClassOne={"wrapper-childTwo20"}
        childrenOne={
          <div className="custom-card" style={{ height: "auto" }}>
            <div className="custom-card-header">{t("caymland.core.profile.picture")}</div>
            <div className="custom-card-body">
              <img
                className="pro"
                alt=""
                src={imageURL}
                data-intro="This is Profile image"
                width="75%"
                style={{ display: "block", margin: "0 auto" }}
              />

              <div>
                <form className="dropzone digits" id="singleFileUpload" action="/upload.php">
                  <div className="dz-message needsclick"></div>
                </form>
              </div>
            </div>
          </div>
        }
        childrenClassTwo={"w-full"}
        childrenTwo={
          <Card>
            <CardBody style={{ padding: "0px" }}>
              <Nav className="nav-primary" tabs>
                <NavItem>
                  <NavLink
                    href="#"
                    className="active"
                    style={{
                      color: palette.text.primary,
                      textTransform: "capitalize",
                      cursor: "pointer",
                      backgroundColor: palette?.primary?.main,
                    }}
                  >
                    {t("caymland.user.account.header.details")}
                  </NavLink>
                </NavItem>
              </Nav>
              <AccountDetails
                newEditedUser={newEditedUser}
                setNewEditedUser={setNewEditedUser}
                errors={errors}
                setErrors={setErrors}
                formSubmited={formSubmited}
                formData={formData}
                setFormData={setFormData}
              />
            </CardBody>
          </Card>
        }
      />
    </Fragment>
  );
};

export default EditProfile;
