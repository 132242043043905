import React, { Fragment, useState } from "react";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import { t } from "i18next";
import { SlideAlert } from "../../../../reusableComponents/Alerts";

function Sidebar({ extras, id }) {
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const background = "1";
  const selectAllText = (e) => {
    e.target.select(); // Selects all text in textarea
    document.execCommand("copy"); // Copies the selected text to clipboard
    e.target.blur(); // Optional: removes focus from textarea after copying
    setShowAlert(true);
    setAlertMessage(t("Coppied to clipboard"));
    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 3500);
  };

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");

  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;

  return (
    <Fragment>
      <SlideAlert message={alertMessage} show={showAlert} setShowAlert={setShowAlert} background={background} />
      <div
        style={{
          padding: "20px",
          color: "#47535f",
        }}
      >
        <h4 style={{ fontSize: "14px" }}>{t("caymland.focus.install.header")}</h4>
        <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
          {t("caymland.focus.install.description")}
        </p>
        <textarea
          type="text"
          className="form-control cursor-disabled mt-10px"
          style={{ fontSize: "14px" }}
          title="Copy"
          readOnly={true} // Correct attribute is `readOnly`, not `readonly=""`
          // value={`<script src=&quot;//dev.m-4.ch/focus/${id}.js&quot; type=&quot;text/javascript&quot; charset=&quot;utf-8&quot; async=&quot;async&quot;></script>`}
          value={`<script src="//${api}/focus/${id}.js" type="text/javascript" charset="utf-8" async="async"></script>`}
          onClick={selectAllText} // Handles click to select all and copy
        />
      </div>
      <hr />
      <div className="card-body activity-scroll px-20">
        <p style={{ color: "#555", fontWeight: "bold" }}>{t("caymland.core.recent.activity")}</p>
        <div className="activity">
          {extras?.recentActivity?.map((activity, index) => (
            <div key={index} className="d-flex">
              <div
                className="flex-shrink-0 gradient-round gradient-line-1"
                style={{ marginRight: "15px", background: activity.action === "create" ? "limegreen" : "#e9e7ed" }}
              >
                {activity.action === "create" && <FaCheck />}
              </div>
              <div className="flex-grow-1">
                <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                  {activity.action === "create" ? "Created By" : "Updated By"} <strong>{activity.userName}</strong>
                </p>
                <small style={{ fontSize: "12px" }}>{moment(activity.dateAdded).format("DD.MM.YY HH:mm")}</small>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Sidebar;
