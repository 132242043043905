import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import FormatAlignCenterOutlinedIcon from "@mui/icons-material/FormatAlignCenterOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

const iconMapper = {
  DashboardOutlinedIcon,
  PeopleAltOutlinedIcon,
  BusinessOutlinedIcon,
  FilterAltOutlinedIcon,
  ViewInArOutlinedIcon,
  DisplaySettingsOutlinedIcon,
  GpsFixedOutlinedIcon,
  ChatBubbleOutlineOutlinedIcon,
  AssessmentOutlinedIcon,
  RouteOutlinedIcon,
  LocalPostOfficeOutlinedIcon,
  ArticleOutlinedIcon,
  EmailOutlinedIcon,
  FolderCopyOutlinedIcon,
  WebhookOutlinedIcon,
  FormatListBulletedOutlinedIcon,
  LocalOfferOutlinedIcon,
  SourceOutlinedIcon,
  ContactMailOutlinedIcon,
  HandshakeOutlinedIcon,
  FormatAlignCenterOutlinedIcon,
  ViewListOutlinedIcon,
  KeyboardArrowDownOutlinedIcon,
  KeyboardArrowRightOutlinedIcon,
};

export default iconMapper;
