import React, { useMemo, useState } from "react";
import { useSeeTicketsTabQuery } from "../../../../../redux/api/contactsApi";
import moment from "moment";
import CustomTable from "../../CustomTable/CustomTable";
import { FaExternalLinkAlt } from "react-icons/fa";
import { t } from "i18next";

const SeeTicket = ({ id }) => {
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderBy: "dOrderDate",
    orderByDir: "DESC",
  });
  const { data } = useSeeTicketsTabQuery({ id, query });

  const columns = useMemo(
    () => [
      {
        // Update this column definition
        accessorKey: "noOrderId",
        accessorFn: (row) => row.nOrderID,
        header: t("caymland.showare.field.order_id"),
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <div className="d-flex items-center gap" style={{ gap: "4px" }}>
            <span>{cell.row.original.nOrderID}</span>
            <a target="_blank" rel="noopener noreferrer" href={cell.row.original.nDetailsURL}>
              <FaExternalLinkAlt style={{ fontSize: "12px" }} />
            </a>
          </div>
        ),
      },
      {
        accessorKey: "statusName",
        accessorFn: (row) => row?.items[0]?.cOrderItemStatusName,
        header: t("caymland.showare.field.c_order_status_name"),
        enableColumnActions: false,
      },
      {
        accessorKey: "totalItems",
        accessorFn: (row) => row?.items?.length,
        header: t("caymland.showare.field.r_order_total_item"),
        enableColumnActions: false,
      },
      {
        // Update this column definition
        accessorKey: "orderTotal",
        accessorFn: (row) => `CHF ${row.rOrderTotal}`,
        header: t("caymland.showare.field.r_order_total"),
        enableColumnActions: false,
      },
      {
        accessorKey: "rOrderDiscountTotal",
        accessorFn: (row) => (row.rOrderDiscountTotal != null ? `CHF ${row.rOrderDiscountTotal}` : ""),
        header: t("caymland.showare.field.r_order_discount_total"),
        enableColumnActions: false,
      },
      {
        accessorKey: "timestamp",
        accessorFn: (row) => {
          // Use Moment.js to format the timestamp
          return moment(row?.dOrderDate).format("DD.MM.YY HH:mm");
        },
        header: t("caymland.showare.field.d_order_date"),
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );
  return (
    <div>
      <CustomTable
        data={data?.tickets}
        columns={columns}
        query={query}
        setQuery={setQuery}
        footer
        mediumHeight
        header
        actions={false}
        linkNavigate={null}
        showDetailPanel
        tickets
      />
    </div>
  );
};

export default SeeTicket;
