import * as React from "react";
import { ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon } from "@radix-ui/react-icons";

import { cn } from "../../../src/lib/utils";
import { buttonVariants } from "./button";

const Pagination = ({ className, ...props }) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn("tw-mx-auto tw-flex tw-w-full tw-justify-center", className)}
    {...props}
  />
);
Pagination.displayName = "Pagination";

const PaginationContent = React.forwardRef(({ className, ...props }, ref) => (
  <ul ref={ref} className={cn("tw-flex tw-flex-row tw-items-center tw-gap-1", className)} {...props} />
));
PaginationContent.displayName = "PaginationContent";

const PaginationItem = React.forwardRef(({ className, ...props }, ref) => (
  <li ref={ref} className={cn("tw-", className)} {...props} />
));
PaginationItem.displayName = "PaginationItem";

const PaginationLink = ({ className, isActive, size = "icon", ...props }) => (
  <a
    aria-current={isActive ? "page" : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? "outline" : "ghost",
        size,
      }),
      className
    )}
    {...props}
  />
);
PaginationLink.displayName = "PaginationLink";

const PaginationPrevious = ({ className, ...props }) => (
  <PaginationLink
    aria-label="Go to previous page"
    size="default"
    className={cn("tw-gap-1 tw-pl-2.5", className)}
    {...props}
  >
    <ChevronLeftIcon className="tw-h-4 tw-w-4 tw-text-gray-500 hover:tw-text-gray-800" />
    <span className="tw-text-gray-500  hover:tw-text-gray-800">Previous</span>
  </PaginationLink>
);
PaginationPrevious.displayName = "PaginationPrevious";

const PaginationNext = ({ className, ...props }) => (
  <PaginationLink
    aria-label="Go to next page"
    size="default"
    className={cn("tw-gap-1 tw-pr-2.5", className)}
    {...props}
  >
    <span className="tw-text-gray-500 hover:tw-text-gray-800">Next</span>
    <ChevronRightIcon className="tw-h-4 tw-w-4 tw-text-gray-500 hover:tw-text-gray-800" />
  </PaginationLink>
);
PaginationNext.displayName = "PaginationNext";

const PaginationEllipsis = ({ className, ...props }) => (
  <span aria-hidden className={cn("tw-flex tw-h-9 tw-w-9 tw-items-center tw-justify-center", className)} {...props}>
    <DotsHorizontalIcon className="tw-h-4 tw-w-4" />
    <span className="tw-sr-only">More pages</span>
  </span>
);
PaginationEllipsis.displayName = "PaginationEllipsis";

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
};
