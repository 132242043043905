import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetEmailPreviewHashQuery } from "../../../../redux/api/emailsApi";

function EmailPreviewHash() {
  const { id } = useParams();
  const [htmlContent, setHtmlContent] = useState("");
  const [documentTitle, setDocumentTitle] = useState("Loading..."); // Initial title message

  const { data, isLoading } = useGetEmailPreviewHashQuery(id, { skip: !id });

  useEffect(() => {
    if (id && data) {
      // Clean HTML content to hide specific <img> tags
      const cleanedHtml = cleanHtmlContent(data);
      setHtmlContent(cleanedHtml);

      // Extract document title from the cleaned HTML content
      const doc = new DOMParser().parseFromString(cleanedHtml, "text/html");
      const title = doc.querySelector("title")?.textContent || "Untitled"; // Fallback if no title found

      // Update the browser tab title
      setDocumentTitle(title);
    }
  }, [id, data]);

  useEffect(() => {
    // Set the document title on initial load
    document.title = documentTitle;
  }, [documentTitle]);

  // Function to clean HTML content (hide specific <img> tags)
  const cleanHtmlContent = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const imgElements = doc.querySelectorAll('img[src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="]');
    imgElements.forEach((img) => {
      img.style.display = "none"; // Hide the img element by setting display to none
    });
    return doc.documentElement.innerHTML;
  };

  return (
    <div
      style={{
        overflow: "auto", // Enables scrolling when content overflows
        maxHeight: "100vh", // Limits height to the viewport height
      }}
    >
      {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
    </div>
  );
}

export default EmailPreviewHash;
