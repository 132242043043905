import React, { useEffect, useState, useLayoutEffect } from "react";
import "./App.css";
import "react-dropzone-uploader/dist/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { customRoutes } from "./utils/CustomRoutes";
import { RoutesComponent } from "./utils/renderRoutes";
import { useSettingsQuery } from "../redux/api/settingsApi";
import i18n from "i18next";

const DEFAULT_LOCALE = "de";

const AppLayout = () => {
  const navigate = useNavigate(); // To handle navigation
  const token = useSelector((state) => state.auth.token);
  const { pathname } = useLocation();
  const { data, isLoading } = useSettingsQuery(localStorage.getItem("user") && undefined);

  const [translationsReady, setTranslationsReady] = useState(false);
  const translations = useSelector((state) => state?.settings?.data?.translations);
  const locale = useSelector((state) => state?.auth?.user?.locale || DEFAULT_LOCALE);

  useEffect(() => {
    // Function to dynamically load the script
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "//info.caymland.dev/focus/14.js";
      script.type = "text/javascript";
      script.charset = "utf-8";
      script.async = true;

      document.body.appendChild(script);

      script.onload = () => {
        console.log("Script loaded successfully!");
      };

      script.onerror = () => {
        console.error("Failed to load the script.");
      };
    };

    // Load the script immediately and then every 10 minutes
    loadScript();
    const interval = setInterval(() => {
      loadScript();
    }, 10 * 60 * 1000); // 10 minutes = 600,000 milliseconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (token) {
      if (!translations || Object.keys(translations).length === 0) {
        return;
      }
    }
    if (translations) {
      const resources = Object.keys(translations).reduce((acc, lang) => {
        acc[lang] = { translation: translations[lang] };
        return acc;
      }, {});

      Object.keys(resources).forEach((lang) => {
        if (!i18n.hasResourceBundle(lang, "translation")) {
          i18n.addResourceBundle(lang, "translation", resources[lang].translation, true, true);
        } else {
          i18n.addResourceBundle(lang, "translation", resources[lang].translation, true, true);
        }
      });
    }

    i18n.changeLanguage(locale).then(() => {
      setTranslationsReady(true);
    });
  }, [translations, locale, token]);

  useLayoutEffect(() => {
    const today = new Date().toISOString(); // Get today's date
    const storedFromDate = localStorage.getItem("fromDate");
    const storedToDate = localStorage.getItem("toDate");

    if (!storedFromDate || !storedToDate || new Date(storedFromDate).toDateString() !== new Date().toDateString()) {
      localStorage.setItem("fromDate", today);
      localStorage.setItem("toDate", today);

      sessionStorage.setItem("fromDate", today);
      sessionStorage.setItem("toDate", today);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("layout_version", "light-only");
    localStorage.setItem("primary_color", "#135f95");
    localStorage.setItem("secondary_color", "#00b1ea");
    localStorage.setItem("color", "");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!token) {
      sessionStorage.clear();
    }
  }, [token]);

  if (isLoading || !translationsReady) {
    return <></>;
  }

  return <RoutesComponent mainRoutes={customRoutes} token={token} />;
};

export default AppLayout;
