import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { t } from "i18next";
import Swal from "sweetalert2";
import Button from "../../../../reusableComponents/Buttons/Button";
import ThemeCards from "../addEmailContent/ThemeCards";

// Helper function to decode Base64 content
const decodeBase64 = (base64) => {
  try {
    return atob(base64);
  } catch (error) {
    console.error("Failed to decode Base64 content:", error);
    return base64;
  }
};

const CustomModal = ({ isOpenModal, setIsOpenModal, modifiedData, setModifiedData, themes, popup = false }) => {
  const [selectedTheme, setSelectedTheme] = useState();
  const [appliedTheme, setAppliedTheme] = useState(popup ? modifiedData?.json : modifiedData?.customJson);

  let idCounter = -1;
  const themesArray = Object.entries(themes).map(([key, value]) => ({
    name: value?.config?.name ?? key,
    id: value?.template?.id ?? idCounter--,
    json: decodeBase64(value?.template?.json || ""),
    html: decodeBase64(value?.template?.html || ""),
    thumbnail: value?.template?.thumbnail || "",
  }));

  const handleApply = () => {
    const themeToApply = themesArray.find((theme) => theme.id === selectedTheme);

    if (!themeToApply) return;

    if (
      (popup ? modifiedData?.json : modifiedData?.customJson) &&
      (popup ? modifiedData?.json : modifiedData?.customJson) !== appliedTheme
    ) {
      Swal.fire({
        title: t("caymland.mailbox.message.delete.title"),
        text: t("caymland.core.builder.theme_change_warning"),
        icon: "question",
        confirmButtonText: t("caymland.core.form.yes"),
        cancelButtonText: t("caymland.core.form.cancel"),
        showCancelButton: true,
        customClass: {
          confirmButton: "swal-custom confirm-component",
          cancelButton: "swal-custom result-component",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          applyTheme(themeToApply);
        }
      });
    } else {
      applyTheme(themeToApply);
    }
  };

  const applyTheme = (theme) => {
    setModifiedData((prev) => ({
      ...prev,
      ...(popup ? { json: theme.json } : { customJson: theme.json }),
      customHtml: theme.html, // Add HTML decoding here
    }));

    setIsOpenModal(false);
  };

  const handleThemeSelection = (theme) => {
    setSelectedTheme(theme.id);
    setAppliedTheme(theme.json);
  };

  return (
    <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)} size="xl"   backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t("caymland.core.filter.themes")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ThemeCards
          themesArray={themesArray}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          onThemeChange={handleThemeSelection}
          setAppliedTheme={setAppliedTheme}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          buttons={[
            { title: t("caymland.core.close"), onClick: () => setIsOpenModal(false) },
            { title: t("caymland.core.form.apply"), onClick: handleApply },
          ]}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
