import React, { useEffect, useRef, useState } from "react";
import ClassicEditor from "ckeditor5-custom-build";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { t } from "i18next";
import Label from "./Label";
import "./TextArea.css";
import { useGetBuilderConfigQuery } from "../../../redux/api//usersApi";
export default ({
  name,
  value,
  onChange,
  onReady,
  label,
  tooltip,
  required = false,
  showSmallEditor = true,
  showToolbar = true,
  errors,
  inserttoken = false,
}) => {
  const editorRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const dropdownInitializedRef = useRef(false); // New ref to track initialization
  const { data } = useGetBuilderConfigQuery("email");
  const [dropdownReady, setDropdownReady] = useState(false); // State to trigger second effect
  

  const handleEditorReady = (editor) => {
    editorRef.current = editor;
    if (inserttoken) {
      // Add custom dropdown to the toolbar
      const toolbarContainer = editor.ui.view.toolbar.element;

      // Create a container div for the dropdown
      const dropdownContainer = document.createElement("div");
      dropdownContainer.classList.add("ql-token");

      // Create the button that will show the icon
      const customButton = document.createElement("button");
      customButton.classList.add("ql-custom-token-button");
      customButton.title = "Insert Token"; // Add this line to set the tooltip
      customButton.innerHTML = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="18px" width="18px" xmlns="http://www.w3.org/2000/svg"><path d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"></path></svg>`;

      // Create the dropdown menu
      const dropdownMenu = document.createElement("div");
      dropdownMenuRef.current = dropdownMenu;
      dropdownMenu.classList.add("ql-custom-token-dropdown");

      // Toggle dropdown menu on button click
      customButton.addEventListener("click", () => {
        dropdownMenu.style.display = dropdownMenu.style.display === "none" ? "block" : "none";
      });

      // Hide dropdown if clicked outside
      document.addEventListener("click", (event) => {
        if (!dropdownContainer.contains(event.target)) {
          dropdownMenu.style.display = "none";
        }
      });

      // Append the button and dropdown menu to the container
      dropdownContainer.appendChild(customButton);
      dropdownContainer.appendChild(dropdownMenu);

      // Add the dropdown container to the toolbar
      toolbarContainer.appendChild(dropdownContainer);

      // Mark dropdown as initialized
      dropdownInitializedRef.current = true;
      setDropdownReady(true); // Trigger the second effect
    }
    if (onReady) {
      onReady(editor);
    }
  };

  useEffect(() => {
    if (dropdownReady && data && dropdownMenuRef.current) {
      const dropdownMenu = dropdownMenuRef.current;
      dropdownMenu.innerHTML = ""; // Clear previous content

      Object.keys(data?.mergeTags).forEach((key) => {
        const category = data.mergeTags[key];

        if (category.mergeTags && Array.isArray(category.mergeTags)) {
          category.mergeTags.forEach((tag) => {
            // Create the option element
            const option = document.createElement("div");
            option.classList.add("ql-custom-token-option");
            option.dataset.value = tag.value;
            option.dataset.name = tag.name;

            // Define the maximum length for tag.name
            const maxLength = 30; // Adjust this value as needed

            // Truncate tag.name if it exceeds maxLength
            let displayTagName = tag.name;
            if (tag.name.length > maxLength) {
              displayTagName = `${tag.name.substring(0, maxLength)}...`;
            }

            // Create two spans for tag name (left) and category name (right)
            const tagNameSpan = document.createElement("span");
            tagNameSpan.classList.add("ql-token-tag-name");
            tagNameSpan.innerText = displayTagName;

            const categoryNameSpan = document.createElement("span");
            categoryNameSpan.classList.add("ql-token-category-name");
            categoryNameSpan.innerText = category.name;

            // Append both spans to the option element
            option.appendChild(tagNameSpan);
            option.appendChild(categoryNameSpan);

            // Add click event listener
            option.addEventListener("click", () => {
              const editor = editorRef.current;
              if (editor) {
                editor.model.change((writer) => {
                  // Get the selection position and ensure it's valid
                  const selection = editor.model.document.selection;

                  if (selection.isCollapsed) {
                    const insertPosition = selection.getFirstPosition();

                    // Check if tag.value contains 'assetlink=' or 'pagelink='
                    if (tag.value.match(/assetlink=/i) || tag.value.match(/pagelink=/i)) {
                      // Insert the link as formatted text
                      const linkText = tag.name;
                      const linkHref = tag.value;

                      writer.insertText(linkText, { linkHref }, insertPosition);
                    } else {
                      // Insert the selected option into the editor as plain text
                      writer.insertText(tag.value, insertPosition);
                    }
                  } else {
                    console.error("Selection is not collapsed, can't insert at the correct position.");
                  }
                });

                // Hide the menu after selection
                dropdownMenu.style.display = "none";
              }
            });

            // Append the option to the custom dropdown
            dropdownMenu.appendChild(option);
          });
        } else {
          console.warn(`No mergeTags found for category: ${key}`);
        }
      });
    }
  }, [dropdownReady, data]); // Depend on both dropdownReady and data

  return (
    <div className="mb-20px w-full">
      {label && <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />}
      {/* <div className="hover-effect"> */}
      <CKEditor
        editor={ClassicEditor}
        data={value ?? ""}
        onReady={handleEditorReady}
        config={{
          placeholder: t("caymland.core.typesomething"),
          toolbar: showToolbar
            ? showSmallEditor
              ? ["undo", "redo", "bold", "italic", "underline"]
              : [
                  "undo",
                  "redo",
                  "sourceEditing",
                  "fullscreen",
                  "|",
                  "heading",
                  "|",
                  "fontfamily",
                  "fontsize",
                  "fontColor",
                  "fontBackgroundColor",
                  "|",
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "removeFormat",
                  "|",
                  "link",
                  // "imageUpload",
                  "mediaEmbed",
                  "|",
                  "alignment:left",
                  "alignment:center",
                  "alignment:right",
                  "alignment:justify",
                  "numberedList",
                  "bulletedList",
                  "|",
                  "insertTable",
                  "horizontalLine",
                  "|",
                ]
            : [],
          shouldNotGroupWhenFull: false,
        }}
        onChange={(event, editor) => {
          const data = editor?.getData();
          onChange(data !== "<p>&nbsp;</p>" ? data : null);
        }}
        onFocus={() => {
          const editableElement = editorRef.current.ui.view.editable.element;
          editableElement.classList.add("ck-editor-focused");
        }}
        onBlur={() => {
          const editableElement = editorRef.current.ui.view.editable.element;
          editableElement.classList.remove("ck-editor-focused");
        }}
      />
    </div>
    // </div>
  );
};
