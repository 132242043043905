import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Moment from "moment";
import { Card, CardBody } from "reactstrap";
import { BiLineChart } from "react-icons/bi";
import DateFromTo from "../../dashboard/defaultCompo/dateFromTo";
import SegmentUsages from "./SegmentUsages";
import TableViewSegment from "./TableViewSegment";
import SweetAlert from "sweetalert2";
import { Error, Success } from "../../reusableComponents/Alerts";
import { SlideAlert } from "../../reusableComponents/Alerts";
import { FaRegTimesCircle } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { TbRefresh } from "react-icons/tb";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import CustomizedAccordions from "../../reusableComponents/Accordion/Accordion";
import Details from "./Details";
import {
  useAddSegmentsStatisticsMutation,
  useDeleteSegmentMutation,
  useGetAddEditSegmentsQuery,
  useGetRebuildSegmentsMutation,
  useGetSegmentsExtrasQuery,
} from "../../../redux/api/segmentsApi";
import LineChart from "../../reusableComponents/ECharts/LineChart/LineEChart";
import DetailsSkeleton from "../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../reusableComponents/SidebarSkeleton";
import HeaderSkeleton from "../../reusableComponents/HeaderSkeleton";
import Description from "../../reusableComponents/Description/Description";
import { useTranslation } from "react-i18next";
import { useRunnerCommandMutation } from "../../../redux/api/contactsApi";

function ViewSegment(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const [contactsLength, setContactsLength] = useState(null);
  const [segment, setSegment] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [contactsStatisticsObject, setContactsStatisticsObject] = useState({
    page: 1,
    limit: 50,
    dateFrom: localStorage.getItem("fromDate")
      ? Moment(localStorage.getItem("fromDate")).format("DD-MM-YYYY")
      : Moment().format("DD-MM-YYYY"),
    dateTo: localStorage.getItem("toDate")
      ? Moment(localStorage.getItem("toDate")).format("DD-MM-YYYY")
      : Moment().format("DD-MM-YYYY"),
  });

  const [contactsInTime, setContactsInTime] = useState({});
  const [rebuildChangeValue, setRebuildChangeValue] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const id = location?.state?.id || location?.state || useParams().id;

  const [deleteSegments] = useDeleteSegmentMutation();
  const [rebuildSegment] = useGetRebuildSegmentsMutation();
  const { data, isFetching, isLoading } = useGetAddEditSegmentsQuery(id);

  const { data: getSegmentsExtras, refetch } = useGetSegmentsExtrasQuery({
    id,
    dateFrom: localStorage.getItem("fromDate"),
    dateTo: localStorage.getItem("toDate"),
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.segment) {
      setSegment(data?.segment?.list);
    } else if (!isFetching) {
      // If data is null or undefined and request is not loading
      navigate(`/segments`);
    }
  }, [data, isFetching, isLoading, navigate]);

  useEffect(() => {
    const f = async () => {
      setContactsInTime(getSegmentsExtras?.contactsInTime);
    };

    f();
  }, [getSegmentsExtras]);

  const formatDate = (date) => {
    return Moment(date).toISOString();
  };

  const saveFromTo = async () => {
    const defaultDate = Moment().toISOString();

    const getFormattedDate = (key) => {
      return localStorage.getItem(key) ? formatDate(localStorage.getItem(key)) : formatDate(defaultDate);
    };

    const fromDate = getFormattedDate("fromDate");
    const toDate = getFormattedDate("toDate");

    setContactsStatisticsObject(() => {
      return {
        page: 1,
        limit: 5,
        dateFrom: fromDate,
        dateTo: toDate,
      };
    });
  };

  const deleteSegment = async () => {
    return SweetAlert.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: `${t("caymland.message.delete", { name: segment?.name })}`,
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
      reverseButtons: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteSegments(id);
        console.log(response);
        if (!response.error) {
          Success(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.delete.past.tense") })}!`);
          navigate("/segments");
        } else {
          Error(response?.error?.data?.error[0]?.message || t("caymland.message.error"));
        }
      }
    });
  };
  const [runnerCommand] = useRunnerCommandMutation();
  const rebuild = async () => {
    const commandBody = {
      command: "caymland:segments:update",
      arguments: {
        "list-id": id,
      },
    };
    const response = await runnerCommand(commandBody);

    if (!response.errors) {
      setRebuildChangeValue(!rebuildChangeValue);
      setShowAlert(true);

      setAlertMessage(
        "Check whether the rebuild is finished at the main segments table or by reloading the current page"
      );
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
        navigate(`/segments/view/${id}`);
      }, 7000);
    }
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate("/segments"),
    },
    {
      name: "rebuild",
      title: t("plugin.extendee.cron.tester.rebuild.segment"),
      icon: <TbRefresh size={15} />,
      onClick: () => rebuild(),
    },
    {
      name: "edit",
      title: t("caymland.core.permissions.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => navigate(`/segments/edit/${id}`),
      childs: [
        {
          name: "delete",
          title: t("caymland.mailbox.list.action.deleteMessage"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => deleteSegment(),
        },
      ],
    },
  ];
  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details segment={segment} contactsLength={contactsLength} />,
    },
  ];
  const accordionsDesc = [
    {
      name: t("caymland.core.description"),
      component: <Description data={segment} />,
    },
  ];
  const Usages = [
    {
      name: t("caymland.lead.segments.usages"),
      component: <SegmentUsages />,
    },
  ];

  return (
    <Fragment>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />

      <>
        {isLoading ? (
          <HeaderSkeleton isView={true} viewMediumButton={true} />
        ) : (
          <Header buttons={buttons} title={segment?.name} showIsPublished={true} data={segment} loading={isFetching} />
        )}

        <Wrapper
          childrenClassOne={"wrapper-childOne"}
          childrenOne={
            isLoading ? (
              <DetailsSkeleton />
            ) : (
              <>
                <div className="cardsStatistics" style={{ display: "flex" }}>
                  <Card
                    style={{
                      boxShadow: "unset",
                      height: "auto",
                      width: "100%",
                    }}
                  >
                    <CardBody style={{ padding: "0" }}>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center " style={{ width: "30%" }}>
                          <BiLineChart size={18} />
                          {t("caymland.segment.stats")}
                        </div>

                        <div>
                          <DateFromTo cls="mid-full-width" saveFromTo={saveFromTo} loading={isFetching} />
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <LineChart
                          labelData={contactsInTime?.labels || []}
                          dataSeries={
                            contactsInTime?.datasets?.map((dataset, index) => ({
                              name: dataset.label,
                              data: dataset.data.map((value, i) => ({
                                value,
                                itemStyle: { color: dataset.backgroundColor },
                              })),
                            })) || []
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>

                <TableViewSegment
                  contactsLength={contactsLength}
                  setContactsLength={setContactsLength}
                  rebuild={rebuildChangeValue}
                />
              </>
            )
          }
          childrenClassTwo={"wrapper-childTwoNoMargin"}
          childrenTwo={
            isLoading ? (
              <SidebarSkeleton applyPadding={true} />
            ) : (
              <>
                <CustomizedAccordions accordions={accordions} />
                {segment && segment?.description ? (
                  <CustomizedAccordions accordions={accordionsDesc} isOpen={true} />
                ) : null}

                <CustomizedAccordions accordions={Usages} isOpen={true} />
              </>
            )
          }
        />
      </>
    </Fragment>
  );
}

export default ViewSegment;
