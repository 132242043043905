import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import moment from "moment";

export const smsApi = createApi({
  reducerPath: "smsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["SMS"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    getSmses: build.query({
      query: (q) =>
        `/api/smses?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["SMS"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    updateSmses: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/smses/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["SMS"],
    }),
    deleteSms: build.mutation({
      query: (id) => ({
        url: `/api/smses/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["SMS"],
    }),
    addNewSms: build.mutation({
      query: (body) => ({
        url: `api/smses/new`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["SMS"],
    }),
    getSms: build.query({
      query: (id) => `/api/smses/${id}`,
      providesTags: (id) => [{ type: "SMS", id }],
      lazy: true,
    }),
    getSMSExtras: build.query({
      query: ({ id, dateFrom, dateTo }) => ({
        url: `/api/sms/${id}/extras`,
        method: "POST",
        body: {
          dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
          dateTo: moment(dateTo).format("YYYY-MM-DD"),
        },
      }),
      providesTags: ["SMS"],
    }),
    sendExampleSms: build.mutation({
      query: ({ id, body }) => ({
        url: `api/sms/${id}/sendExample`,
        method: "POST",
        body,
      }),
    }),

    //FOLDERS
    //GET FOLDERS
    getFolderSMS: build.query({
      query: (q) => `/api/folders?limit=300&where[0][col]=source&where[0][expr]=eq&where[0][val]=sms`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderSMS: build.mutation({
      query: (body) => ({
        url: `/api/folders/new`,
        method: "POST",
        body,
      }),
    }),

    //EDIT FOLDER
    updateFolderSMS: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "SMS", id }],
    }),
    //DELETE FOLDER
    deleteFolderSMS: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, id) => [{ type: "SMS", id }],
    }),
    //MOVE SMS TO FOLDER
    updateSMSToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        // url: `api/folders/${id}/emails/${itemId}`,
        url: `api/folders/${id}/sms/${itemId}`,
        method: "PATCH",
      }),
    }),
    //REMOVE SMS TO FOLDER
    removeSMSToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/sms/${itemId}`,
        method: "DELETE",
      }),
    }),

    getSmsOptions: build.query({
      query: () => `/api/sms/select`,
      providesTags: ["SMS"],
    }),
  }),
});

export const {
  useGetSmsesQuery,
  useUpdateSmsesMutation,
  useDeleteSmsMutation,
  useAddNewSmsMutation,
  useGetSmsQuery,
  useGetSMSExtrasQuery,
  useSendExampleSmsMutation,
  //  FOLDERS
  useGetFolderSMSQuery,
  useAddFolderSMSMutation,
  useUpdateFolderSMSMutation,
  useDeleteFolderSMSMutation,
  useUpdateSMSToFoldersMutation,
  useRemoveSMSToFoldersMutation,
  useGetSmsOptionsQuery,
} = smsApi;
