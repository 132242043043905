import React from "react";
import Modal from "react-bootstrap/Modal";
import { t } from "i18next";
import { useGetSmsQuery } from "../../../../redux/api/smsApi";

export const SmsModal = ({ id, modalIsOpen, closeModal }) => {
  const { data: smsData } = useGetSmsQuery(id, { skip: !id });
  return (
    <>
      <Modal show={modalIsOpen} onHide={closeModal}   backdrop="static">
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
          closeButton
        >
          <Modal.Title
            style={{
              padding: "4px",
              fontSize: "18px",
            }}
          >
            {t("caymland.sms.send.preview.sms")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 d-flex justify-content-center">
            {smsData?.sms?.message ? <p>{smsData.sms.message}</p> : <p>{t("caymland.core.loading")}</p>}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
