import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { t } from "i18next";
import moment from "moment";

const styles = {
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tableRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid lightgray", // Add a separator between rows
  },
  tableHeader: {
    fontWeight: "bold",
    width: "50%",
    textAlign: "left",
    padding: "8px",
    background: "#f3f3f3",
    borderBottom: "2px solid gray",
  },
  tableHeaderField: {
    fontWeight: "bold",
    width: "50%",
    textAlign: "left",
    padding: "8px",
    background: "#f3f3f3",
    borderBottom: "2px solid gray",
  },
  tableCell: {
    width: "50%",
    textAlign: "left",
    padding: "8px",
    fontSize: "12px",
  },
};

const DynamicAccordion = ({ data }) => {
  const formatAmount = (amount) => {
    return amount
      ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") // Format with thousands separator
      : "0";
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
      }}
    >
      {data.map(
        (item) => (
          console.log(item),
          (
            <div
              key={item.id}
              style={{
                flex: "1 1 calc(25% - 16px)",
                minWidth: "250px",
                maxWidth: "calc(25% - 16px)",
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${item.id}-content`}
                  id={`panel-${item.id}-header`}
                >
                  <Typography>{item.product_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={styles.tableContainer}>
                    {/* Table Headers */}
                    <div style={styles.tableRow}>
                      <div style={styles.tableHeaderField}>{t("caymland.form.type.fields")}</div>
                      <div style={styles.tableHeader}>{t("caymland.green.table.details.value")}</div>
                    </div>

                    {/* Dynamically display key-value pairs */}
                    {Object.entries(item)
                      .filter(([key, value]) => value !== null && value !== undefined && value !== "") // Filter out empty values
                      .map(([key, value]) => (
                        <div key={key} style={styles.tableRow}>
                          <div style={styles.tableCell}>
                            <strong>{t(`caymland.transaction.column.${key}`)}</strong>
                          </div>
                          <div style={styles.tableCell}>
                            {key.toLowerCase().includes("amount")
                              ? `CHF ${formatAmount(value)}`
                              : key.toLowerCase().includes("date") && value
                              ? moment(value).format("DD.MM.YYYY HH:mm")
                              : typeof value === "object" && value !== null
                              ? JSON.stringify(value)
                              : value.toString()}
                          </div>
                        </div>
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )
        )
      )}
    </div>
  );
};

export default DynamicAccordion;
