import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
} from "material-react-table";
import { MRT_ToggleSelect } from "../CustomTable/MRT_ToggleSelect";
import { Box, ListItemIcon, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "../../reusableComponents/Buttons/Button";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import { Add, Delete, Edit, NoteAddOutlined } from "@mui/icons-material";
import CustomModal from "../BootsrapModal/CustomModal";
// import { useAlert } from "react-alert";
import Swal from "sweetalert2";
import { Alert } from "../Alerts";
import { MRT_ShowHideButtons } from "../CustomTable/MRT_ShowHideButtons";
import { BsEraserFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { MRT_CustomRowsActionFolder } from "../CustomTable/plugins/MRT_CustomActionsFolder";
import { MRT_CustomRowsAction } from "../CustomTable/plugins/MRT_CustomRowsAction";
import { MRT_CustomHeaderRowsAction } from "../CustomTable/plugins/MRT_CustomHeaderRowsAction";
import { FaTrashAlt } from "react-icons/fa";
import { MRT_GlobalSearch } from "../CustomTable/MRT_GlobalSearch";
import { useTranslation } from "react-i18next";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import SearchModal from "../HelperTexts/SearchModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { debounce } from "lodash";

const DragAndDropTable = ({
  data,
  columns,
  query,
  setQuery,
  actionButtons,
  folderData,
  addFolder,
  updateFolder,
  deleteFolder,
  body,
  handleSubmit,
  editingFolder,
  setEditingFolder,
  refetch,
  refetchTableData,
  updateCampaignsToFolder,
  reset,
  toolbarButtons,
  selectProps,
  actionBatchButtons,
  activeRowId,
  setActiveRowId,
  linkNavigate = "view",
  filteredData,
  isFetching,
  sort = true,
  rowDragging = false,
  reorderBetween = false,
  order,
  setOrder,
  handleReorder,
  searchHelperBody,
  searchHelper = true,
}) => {
  const { t } = useTranslation();
  const locale = useSelector((state) => state?.auth?.user?.locale);
  const tableLocalization = locale === "de" ? MRT_Localization_DE : MRT_Localization_EN;
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [draggingRow, setDraggingRow] = useState(null);
  const [clickedRow, setClickedRow] = useState(false);
  const [draggedRowId, setDraggedRowId] = useState(null);
  const [overRowId, setOverRowId] = useState(null);
  console.log(data);

  // const [globalFilter, setGlobalFilter] = useState('');
  // const [activeRowId, setActiveRowId] = useState(null);
  const tableFolderRef = useRef();
  const navigate = useNavigate();

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    type: "",
    data: {},
  });
  const [isOpen, setIsOpenSearchModal] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const openModal = (type, row) => {
    setModalOptions((prev) => ({
      ...prev,
      isOpen: true,
      type: type,
      data: row,
    }));
  };

  useEffect(() => {
    if (query.pageSize) {
      sessionStorage.setItem("pageSize", query.pageSize.toString());
    }
  }, [query.pageSize]);
  // Initialize state with values from local storage if they exist
  const initialOrderBy = sessionStorage.getItem(`orderBy${query.name}`) || query.orderBy;
  const initialOrderByDir = sessionStorage.getItem(`orderByDir${query.name}`) || query.orderByDir || "DESC";

  const [sorting, setSorting] = useState([{ id: initialOrderBy, desc: initialOrderByDir === "DESC" }]);

  useEffect(() => {
    if (query.orderBy || query.orderByDir) {
      // Construct keys with component name
      const orderByKey = `orderBy${query.name}`;
      const orderByDirKey = `orderByDir${query.name}`;
      const searchByKey = [`search${query.name}`];
      // Save to local storage
      sessionStorage.setItem(orderByKey, query?.orderBy?.toString());
      sessionStorage.setItem(orderByDirKey, query?.orderByDir?.toString());
      sessionStorage.setItem(searchByKey, query?.search?.toString() || "");
    }
  }, [query.orderBy, query.orderByDir, query.search, query.name]);

  const handleSortingChange = (newSorting) => {
    if (sorting?.length === 0) {
      setSorting([{ id: "id", desc: false }]);
    }

    const sort = newSorting[0];
    if (sorting?.length > 0 && sort?.id === sorting[0]?.id) {
      // If sorting the same column, toggle between asc and desc
      setSorting([{ id: sort?.id, desc: !sorting[0]?.desc }]);
    } else {
      // New column sorting or initial sorting
      setSorting(newSorting);
    }
  };

  useEffect(() => {
    if (rowDragging) {
      return;
    }
    if (sorting?.length > 0) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        orderByDir: sorting[0].desc ? "DESC" : "ASC",
        orderBy: sorting[0].id,
      }));
    } else {
      setQuery((prevQuery) => ({
        ...prevQuery,
        orderByDir: "DESC",
        orderBy: "id",
      }));
      setSorting([{ id: "id", desc: true }]);
    }
  }, [sorting, setQuery]);

  const closeModal = (type) => {
    setModalOptions((prev) => ({
      ...prev,
      isOpen: false,
      type: type,
      data: null,
    }));
  };
  useEffect(() => {
    if (data?.data) {
      setData1(data.data || data);
    }
  }, [data]);

  useEffect(() => {
    if (folderData?.data) {
      setData2(folderData.data);
    }
  }, [folderData, data]);

  useEffect(() => {
    if (!modalOptions?.isOpen) {
      setEditingFolder(undefined);
      reset();
    }
  }, [modalOptions?.isOpen, reset]);

  const totalDBRowCount = filteredData ? filteredData?.length : data?.total;

  //create SubRows with objects of parentId
  const itemMap = {};
  data2?.forEach((item) => {
    itemMap[item?.id] = { ...item, subRows: [] };
  });

  // Create a new array to store the transformed data
  const transformedData = [];

  data2.forEach((item) => {
    if (item?.parentId === null) {
      transformedData.push(itemMap[item.id]);
    } else {
      const parent = itemMap[item.parentId];
      parent?.subRows?.push(itemMap[item.id]);
    }
  });
  const handleDragEnter = (e, r, t) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e, r, t) => {
    e.preventDefault();
    e.stopPropagation();
    t.setHoveredRow(null);
  };
  const handleDragOver = (e, r, t) => {
    e.preventDefault();
    e.stopPropagation();
    t.setHoveredRow(r);
  };
  const handleDrop = (e, r, t) => {
    e.preventDefault();
    e.stopPropagation();
    t.setHoveredRow(null);
    handleMoveCampaign(r?.original?.id);
  };
  const buttons = [
    {
      icon: <Add />,
      tooltipTitle: t("caymland.core.add.folder"),
      onClick: () => openModal("add"),
    },
    ...(clickedRow
      ? [
          {
            tooltipTitle: t("caymland.core.reverse.table"),
            onClick: () => {
              setQuery({
                pageIndex: 0,
                pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
                orderByDir: "DESC",
                orderBy: "id",
                where: [
                  // {
                  //   expr: "isNull",
                  //   col: "folderId",
                  // },
                ],
              }),
                setClickedRow(false);
              setActiveRowId(null);
            },
            icon: <BsEraserFill size={20} />,
          },
        ]
      : []),
  ];
  //Add Folder
  const handleAddFolder = async (data) => {
    try {
      await addFolder(data).unwrap();

      setModalOptions((prev) => ({
        ...prev,
        isOpen: false,
        type: "",
        data: null,
      }));
      refetch();
    } catch (error) {}
  };
  //Edit Folder
  const handleEditFolder = async (data) => {
    const id = editingFolder?.id;
    try {
      const response = await updateFolder({
        id,
        ...data,
      }).unwrap();
      setModalOptions((prev) => ({
        ...prev,
        isOpen: false,
        type: "",
        data: null,
      }));
      refetch();
    } catch (error) {}
  };
  //Delete Folder
  const handleDeleteFolder = async (id) => {
    Alert(`${t("caymland.badge_contact.form.confirmdelete")}`, t("caymland.message.delete.folder")).then(
      async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteFolder(id);
            refetch();
          } catch (error) {}
        }
      }
    );
  };

  //Add SubFolder
  const handleAddSubFolder = async (data) => {
    const updatedData = { ...data }; // Make a copy of data
    updatedData.parentId = editingFolder.id;
    try {
      await addFolder(updatedData).unwrap();
      setModalOptions((prev) => ({
        ...prev,
        isOpen: false,
        type: "",
        data: null,
      }));
      refetch();
    } catch (error) {}
  };
  //Move Campaign to Folder/SubFolder
  const handleMoveCampaign = async (folderId) => {
    const itemId = draggingRow?.original?.id;
    try {
      await updateCampaignsToFolder({
        id: folderId,
        itemId,
      }).unwrap();
      refetchTableData();
      refetch(); // Refetch data to update the UI
    } catch (error) {}
  };

  const menuFolderButtons = [
    {
      name: "Add Subfolder",
      icon: <NoteAddOutlined style={{ fontSize: "18px" }} />,
      onClick: (row) => {
        setEditingFolder({
          id: row.original?.id,
          parentId: row.original?.parentId,
        });
        openModal("addsub", row.original);
      },
    },
    {
      name: `${t("caymland.core.form.edit")}`,
      icon: <Edit style={{ fontSize: "18px" }} />,
      onClick: (row) => {
        setEditingFolder({ id: row.original?.id, ...row?.original });
        openModal("edit", row.original);
      },
    },
    {
      name: `${t("caymland.core.form.delete")}`,
      icon: <FaTrashAlt size={16} color="#f86b4f" />,
      onClick: (row) => {
        handleDeleteFolder(row?.original.id);
      },
    },
  ];
  const [searchValue, setSearchValue] = useState(query.search || "");

  // Update searchValue state when initialSearch or query.search changes
  useEffect(() => {
    setSearchValue(query.search || "");
  }, [query.search]);

  const debouncedSearch = useMemo(() => debounce((search) => MRT_GlobalSearch(search, setQuery), 300), [setQuery]);
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value); // Update local state
    debouncedSearch(value); // Call debounced search function
  };

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name",
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: "right",
        sx: {
          paddingRight: 2,
          verticalAlign: "bottom",
        },
      },
      muiTableBodyCellProps: {
        align: "right",
        sx: {
          paddingRight: 2,
        },
      },
    },
  ]);

  // DRAG AND DROP DATA TABLE

  const updateOrderDuringDrag = (draggedOverRowId) => {
    if (!reorderBetween) {
      return;
    }
    if (!draggedRowId || draggedRowId === draggedOverRowId || overRowId === draggedOverRowId) return;

    setOverRowId(draggedOverRowId);

    let newOrder = [...order];
    let draggedIndex = newOrder.indexOf(draggedRowId);
    let draggedOverIndex = newOrder.indexOf(draggedOverRowId);

    if (draggedIndex < 0 || draggedOverIndex < 0) return;

    newOrder.splice(draggedIndex, 1);
    newOrder.splice(draggedOverIndex, 0, draggedRowId);

    setOrder(newOrder);
  };

  const onDragOver = (e, overId) => {
    if (!reorderBetween) {
      return;
    }
    e.preventDefault();
    updateOrderDuringDrag(overId);
  };

  const onDrop = (dropId) => {
    if (!reorderBetween) {
      return;
    }
    if (draggedRowId && dropId) {
      handleReorder(order);
    }
    setDraggedRowId(null);
  };

  const onDragStart = (rowId) => {
    setDraggedRowId(rowId);
  };

  // useEffect(() => {
  //   if (rowDragging) {
  //     return;
  //   }
  //   if (sorting.length > 0) {
  //     setQuery((prevQuery) => ({
  //       ...prevQuery,
  //       orderByDir: sorting[0].desc ? "desc" : "asc",
  //       orderBy: sorting[0].id,
  //       pageIndex: 0,
  //     }));
  //   } else {
  //     setQuery((prevQuery) => ({
  //       ...prevQuery,
  //       orderByDir: "asc",
  //       orderBy: query.orderBy,
  //       pageIndex: 0,
  //     }));
  //     setSorting([{ id: query.orderBy, desc: query.orderByDir === "asc" ? true : false }]);
  //   }
  // }, [sorting, setQuery]);

  const orderedData = useMemo(() => {
    if (!order?.length || !filteredData) {
      return filteredData || [];
    }

    const dataMap = new Map(filteredData?.map((item) => [item.id, item]));

    return order.map((id) => dataMap.get(id)).filter((item) => item);
  }, [order, filteredData]);

  console.log(filteredData);
  console.log(data1);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "auto", lg: "20% auto" },
        gap: "1rem",
        overflow: "auto",
      }}
    >
      <CustomModal
        body={body}
        show={modalOptions.isOpen}
        modalOptions={modalOptions}
        handleClose={closeModal}
        handleAddFolder={handleSubmit(handleAddFolder)}
        handleEditFolder={handleSubmit(handleEditFolder)}
        handleAddSubFolder={handleSubmit(handleAddSubFolder)}
      />
      {/* Folder Table */}
      <MaterialReactTable
        tableInstanceRef={tableFolderRef}
        localization={tableLocalization}
        data={transformedData}
        columns={folderColumns}
        showPaginationBottom={false}
        initialState={{
          density: "compact",
          expanded: true,
        }}
        muiLinearProgressProps={({ isTopToolbar }) => ({
          color: "secondary",
          sx: {
            display: isTopToolbar ? "block" : "none",
          },
        })}
        // state={{ showProgressBars: isFetching }}
        muiSearchTextFieldProps={{
          sx: { minWidth: "300px", height: "35px" },
          inputProps: { placeholder: t("caymland.core.multiselect.search") },
          variant: "outlined",
        }}
        defaultColumn={{
          maxSize: 20,
        }}
        // enableFilters={false}
        positionGlobalFilter="right"
        muiTableHeadCellProps={{
          sx: { paddingBottom: "5px", paddingTop: "5px" },
        }}
        muiBottomToolbarProps={{
          sx: {
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          },
        }}
        muiTableContainerProps={{
          sx: { height: "calc(100vh - 195px)", overflowY: "auto" },
        }}
        enableExpandAll
        renderRowActionMenuItems={({ closeMenu, row }) =>
          menuFolderButtons.map((e, i) => {
            const isDeleteButton = e.name === t("caymland.mailbox.list.action.deleteMessage");
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  closeMenu();
                  e.onClick(row);
                }}
                sx={{ m: 0, color: isDeleteButton ? "rgba(255, 0, 0, 0.7)" : undefined }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      e.name === t("caymland.mailbox.list.action.deleteMessage") ? "rgba(255, 0, 0, 0.7)" : undefined,
                  }}
                >
                  {e.icon}
                </ListItemIcon>
                {e.name}
              </MenuItem>
            );
          })
        }
        enableFullScreenToggle={false}
        enablePagination={false}
        enableExpanding
        enableColumnFilters={false}
        enableDensityToggle={false}
        enableTableFooter={false}
        enableBottomToolbar={false}
        muiTableBodyRowProps={({ row, table }) => ({
          onDrop: (e) => {
            handleDrop(e, row, table);
          },
          onDragOver: (e) => {
            handleDragOver(e, row, table);
          },
          onDragEnter: (e) => {
            handleDragEnter(e, row, table);
          },
          onDragLeave: (e) => {
            handleDragLeave(e, row, table);
          },
          onClick: () => {
            setClickedRow(true);
            setActiveRowId(row.original.id);

            setQuery({
              ...query,
              ...{
                pageIndex: 0,
                where: [
                  {
                    expr: "eq",
                    col: "folderId",
                    val: row?.original?.id,
                  },
                ],
              },
            });
          },
          sx: {
            cursor: "pointer",
            backgroundColor: activeRowId === row.original.id ? "#f2f2f2" : "transparent",
          },
        })}
        muiTablePaperProps={{
          sx: {
            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.1) !important",
          },
        }}
        renderToolbarInternalActions={({ table, row }) => (
          <>
            <MRT_ToggleGlobalFilterButton table={table} />
            {buttons.map((button, index) => (
              <Tooltip key={index} arrow title={button.tooltipTitle}>
                <IconButton onClick={button.onClick}>{button.icon}</IconButton>
              </Tooltip>
            ))}
          </>
        )}
      />

      {/* Data Table */}
      <MaterialReactTable
        data={rowDragging ? orderedData : (filteredData || data1) ?? []}
        localization={tableLocalization}
        enableRowOrdering={rowDragging}
        enableSorting={sort}
        columns={[
          ...columns,
          {
            accessorKey: "id",
            accessorFn: (row) => row.id,
            header: `${t("caymland.points.table.id")}`,
            size: 80,
            enableColumnActions: false,
            muiTableHeadCellProps: {
              align: "right",
              sx: {
                paddingRight: 2,
                verticalAlign: "bottom",
              },
            },
            muiTableBodyCellProps: {
              align: "right",
              sx: {
                paddingRight: 2,
              },
            },
          },
        ]}
        renderToolbarInternalActions={({ table }) => (
          <>
            {searchValue && (
              <span style={{ position: "absolute", left: "45px", top: "41px", color: "gray", fontSize: "10px" }}>
                {t("caymland.core.search.text")}
              </span>
            )}
            {searchHelper && (
              <>
                <SearchModal isOpen={isOpen} setIsOpen={setIsOpenSearchModal} body={searchHelperBody} />
                <Tooltip arrow title={"Search Helper"} style={{ position: "absolute", left: 0, top: "5px" }}>
                  <IconButton>
                    <HelpOutlineIcon onClick={() => setIsOpenSearchModal(true)} />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {/* <MRT_ToggleGlobalFilterButton table={table} /> */}
            {toolbarButtons?.map((item) => React.createElement(item.component, { table, ...item.props }))}
          </>
        )}
        enablePagination={true}
        muiLinearProgressProps={({ isTopToolbar }) => ({
          color: "secondary",
          sx: {
            display: isTopToolbar ? "block" : "none",
          },
        })}
        muiSearchTextFieldProps={{
          sx: { minWidth: "300px", height: "35px", marginLeft: searchHelper ? "35px" : 0 },
          variant: "outlined",
          value: searchValue, // Set the value of the input to searchValue
          onChange: (e) => setSearchValue(e.target.value), // Only update the local state
          onKeyDown: (e) => {
            if (e.key === "Enter") {
              MRT_GlobalSearch(searchValue, setQuery); // Trigger search on Enter
            }
          },
          InputProps: {
            endAdornment: (
              <IconButton
                disabled={searchValue.length === 0}
                onClick={() => {
                  setSearchValue(""); // Clear the input
                  MRT_GlobalSearch("", setQuery); // Clear the query state
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
          },
        }}
        manualPagination
        onSortingChange={handleSortingChange}
        manualSorting={true}
        enableColumnActions={false}
        onPaginationChange={setQuery}
        rowCount={totalDBRowCount}
        muiBottomToolbarProps={{
          sx: {
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            verticalAlign: "bottom",
          },
        }}
        muiTableContainerProps={{
          sx: {
            height: "calc(100vh - 195px)",
            overflowY: "auto",
          },
        }}
        initialState={{
          density: "compact",
        }}
        muiTablePaperProps={{
          sx: {
            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.1) !important",
          },
        }}
        onGlobalFilterChange={(search) => MRT_GlobalSearch(search, setQuery)}
        state={{
          pagination: query,
          showProgressBars: isFetching,
          sorting: sorting?.length > 0 ? [sorting[0]] : [],
          showGlobalFilter: true,
        }}
        enableColumnFilters={false}
        enableDensityToggle={false}
        enableTableFooter={false}
        enableBottomToolbar={true}
        enableStickyFooter={false}
        enableStickyHeader={true}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        positionGlobalFilter="left"
        enableMultiRowSelection
        renderCustomBatchActions={({ table, closeMenu }) => {
          let selectedRows = table.getSelectedRowModel().rows.map((e) => e.original.id);
          return actionBatchButtons?.map((e, i) => {
            const isDeleteButton = e.name === t("caymland.core.form.delete_selected");
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  closeMenu();
                  e.onClick(selectedRows, table);
                }}
                sx={{ m: 0, color: isDeleteButton ? "rgba(255, 0, 0, 0.7)" : undefined }}
              >
                <ListItemIcon
                  sx={{
                    color: e.name === t("caymland.core.form.delete_selected") ? "rgba(255, 0, 0, 0.7)" : undefined,
                  }}
                >
                  {e.icon}
                </ListItemIcon>
                {e.name}
              </MenuItem>
            );
          });
        }}
        renderRowActionMenuItems={({ row, closeMenu }) =>
          actionButtons?.map((e, i) => {
            const isExpired = dayjs().isAfter(dayjs(row?.original?.publishDown));
            const emailType = row?.original?.emailType === "list";
            const isPublished = row?.original?.isPublished;
            const showSendButton =
              e.name === t("caymland.email.send") &&
              isPublished &&
              emailType &&
              !isExpired &&
              (row.original.status === 0 || row.original.status === 2 || row.original.status === 3) &&
              row.original?.pendingCount > 0 &&
              !row.original.publishUp &&
              !row.original.publishDown;
            const showStopButton =
              e.name === t("caymland.email.stat.stop") &&
              isPublished &&
              emailType &&
              !isExpired &&
              row.original.status === 1;
            const showResumeButton =
              e.name === t("caymland.email.send") &&
              isPublished &&
              emailType &&
              !isExpired &&
              (row.original.status === 2 || row.original.status === 3) &&
              row.original?.pendingCount > 0;

            const showRunButton = e.name === t("caymland.cron.active") && row.original.isPublished;
            const showRemoveButton = e.name === t("caymland.core.remove") && row.original.folderId !== null;

            //Edit
            const isEditButton = e.name === t("caymland.core.permissions.edit");
            const buttonPermission = Array.isArray(e.permission) ? e.permission : [];
            const userId = user?.id;
            let showEditButton = false;

            if (isEditButton) {
              if (!e.permission || e.permission === null) {
                // Show the button if permission is undefined or null
                showEditButton = true;
              } else if (buttonPermission.includes("editown") && buttonPermission.includes("editother")) {
                showEditButton = true;
              } else if (buttonPermission.includes("editown")) {
                showEditButton = row.original.createdBy === userId;
              } else if (buttonPermission.includes("editother")) {
                showEditButton = row.original.createdBy !== userId;
              } else if (buttonPermission.includes("edit")) {
                showEditButton = true;
              } else if (buttonPermission.includes("full") || buttonPermission == "isAdmin") {
                showEditButton = true;
              }
            }
            //Delete
            const isDeleteButton = e.name === t("caymland.mailbox.list.action.deleteMessage");
            let showDeleteButton = false;

            if (isDeleteButton) {
              if (!e.permission || e.permission === null) {
                // Show the button if permission is undefined or null
                showDeleteButton = true;
              } else if (buttonPermission.includes("deleteown") && buttonPermission.includes("deleteother")) {
                showDeleteButton = true;
              } else if (buttonPermission.includes("deleteown")) {
                showDeleteButton = row.original.createdBy === userId;
              } else if (buttonPermission.includes("deleteother")) {
                showDeleteButton = row.original.createdBy !== userId;
              } else if (buttonPermission.includes("delete")) {
                showDeleteButton = true;
              } else if (buttonPermission.includes("full") || buttonPermission.includes("isAdmin")) {
                showDeleteButton = true;
              }
            }
            //Clone
            const isCloneButton = e.name === t("caymland.core.form.clone");
            let showCloneButton = false;

            if (isCloneButton) {
              if (!e.permission || e.permission === null) {
                // Show the button if permission is undefined or null
                showCloneButton = true;
              } else if (
                buttonPermission.includes("create") ||
                buttonPermission.includes("full") ||
                buttonPermission.includes("isAdmin")
              ) {
                showCloneButton = true;
              }
            }

            const isOtherButton = ![
              t("caymland.email.send"),
              t("caymland.email.stat.stop"),
              t("caymland.core.resume"),
              t("caymland.cron.active"),
              t("caymland.core.remove"),
              t("caymland.core.permissions.edit"),
              t("caymland.mailbox.list.action.deleteMessage"),
              t("caymland.core.form.clone"),
            ].includes(e.name);

            const shouldRender =
              showSendButton ||
              showStopButton ||
              showRunButton ||
              showRemoveButton ||
              isOtherButton ||
              showEditButton ||
              showDeleteButton ||
              showCloneButton;

            if (shouldRender) {
              return (
                <MenuItem
                  key={i}
                  onClick={() => {
                    closeMenu();
                    e.onClick(
                      e.name === t("caymland.mailbox.list.action.deleteMessage") ||
                        e.name === t("caymland.cron.active") ||
                        e.name === t("caymland.core.remove")
                        ? row.original
                        : row.original.id
                    );
                  }}
                  sx={{
                    m: 0,
                    color:
                      e.name === t("caymland.mailbox.list.action.deleteMessage") ? "rgba(255, 0, 0, 0.7)" : undefined,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        e.name === t("caymland.mailbox.list.action.deleteMessage") ? "rgba(255, 0, 0, 0.7)" : undefined,
                    }}
                  >
                    {e.icon}
                  </ListItemIcon>
                  {e.name}
                </MenuItem>
              );
            }
            return null;
          })
        }
        muiTableBodyRowProps={({ row }) => {
          return {
            onClick: () => navigate(`${linkNavigate}/${row?.original?.id}`),
            sx: {
              cursor: "pointer",
            },
            draggable: handleReorder ? true : false,
            onDragStart: () => onDragStart(row?.original?.id),
            onDrop: () => onDrop(row?.original?.id),
            onDragOver: (e) => {
              onDragOver(e, row?.original?.id);
            },
          };
        }}
        enableRowDragging
        onDraggingRowChange={setDraggingRow}
        stat={draggingRow}
        displayColumnDefOptions={{
          "mrt-row-drag": {
            size: 20,
            Header: () => {
              <></>;
            },
          },
          "mrt-row-select": {
            accessorKey: "mrt-row-action",
            Header: ({ table }) => <MRT_CustomHeaderRowsAction selectAll table={table} />,
            Cell: ({ cell, row, table }) => <MRT_CustomRowsAction cell={cell} row={row} table={table} />,
            size: 80,
            enableSorting: false,
          },
        }}
      />
    </Box>
  );
};

export default DragAndDropTable;
