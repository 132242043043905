import React, { Fragment, useState, useEffect } from "react";
import { FaHistory } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { SlideAlert } from "../Alerts";
import { Success } from "../../reusableComponents/Alerts";
import { FaRegTimesCircle } from "react-icons/fa";
import { Select as ReSelect } from "../../reusableComponents/Inputs";
import Header from "../Header/Header";
import { tagsApi } from "../../../redux/api/tagsApi";
import { usersApi } from "../../../redux/api/usersApi";
import { categoriesApi } from "../../../redux/api/categoriesApi";
import { segmentsApi } from "../../../redux/api/segmentsApi";
import { retrieveUsers } from "../retrieveUsers/retrieveUsersFunc";
import { useImportBackgroundCompanyMutation } from "../../../redux/api/companiesApi";
import { retrieveSegments } from "../retrieveSegments/retrieveSegmentsFunc";
import { retrieveCategories } from "../retriveCategoriesByBundle";
import { retrieveTags } from "../retrieveTags/retrieveTagsFunc";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRunnerCommandMutation } from "../../../redux/api/contactsApi";

const ImportedRender = ({ setUploaded, setSelectedFile, modifiedData, setModifiedData, fields }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [fieldEmpty, setFieldEmpty] = useState(false);

  //options for tags
  const [triggerTags] = tagsApi.useLazyGetTagsQuery();
  //options for users
  const [triggerUsers] = usersApi.useLazyGetUsersQuery();
  //options for categories
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesQuery();
  //options for segments
  const [triggerSegments] = segmentsApi.useLazyGetSegmentsQuery();

  const [importBackgroundCompany] = useImportBackgroundCompanyMutation();
  const { control, watch, handleSubmit, getValues } = useForm();
  const isContactsView = location.pathname.includes("/contacts");
  const isCompaniesView = location.pathname.includes("/companies");
  useEffect(() => {
    if (modifiedData?.properties?.headers.length > 0) {
      const updatedFields = {};
      console.log(updatedFields);

      modifiedData?.properties?.headers.forEach((header) => {
        const selectedOption = findSelectedOption(header);
        console.log(selectedOption);
        if (selectedOption) {
          updatedFields[selectedOption.value.toLowerCase()] = selectedOption.value;
        }
      });
      setModifiedData((prevData) => ({
        ...prevData,
        properties: {
          ...prevData.properties,
          fields: updatedFields,
        },
      }));
    }
  }, [fields]);

  console.log(watch().owner);

  const handelCancel = () => {
    setUploaded(false);
    setSelectedFile(null);

    setModifiedData({
      object: isCompaniesView ? "company" : "lead",
      dir: null,
      file: null,
      originalFile: null,
      properties: {
        parser: {
          delimiter: ";",
          enclosure: '"',
          escape: "\\",
          batchlimit: 100,
        },
        headers: [],
        defaults: {
          owner: watch()?.owner || null,
          list: watch()?.list || null,
          tags: watch()?.tags || [],
          categories: watch()?.categroies || [],
        },
        lines: null,
        fields: {},
      },
    });
  };
  const handleSelectChangeFields = (fieldName, selectedOptions) => {
    console.log(fieldName);

    // Replace spaces with underscores in the fieldName
    const formattedFieldName = fieldName.replace(/\s+/g, "_");

    let selectedValue;
    if (Array.isArray(selectedOptions)) {
      selectedValue = selectedOptions.map((option) => option.value);
    } else {
      selectedValue = selectedOptions ? selectedOptions.value : null;
    }

    // Update the modifiedData with the formattedFieldName
    setModifiedData((prevData) => ({
      ...prevData,
      properties: {
        ...prevData.properties,
        fields: {
          ...prevData.properties.fields,
          [formattedFieldName]: selectedValue, // Use the formatted field name with underscores
        },
      },
    }));
    setFieldEmpty(false);
  };

  const groupedData = fields?.reduce((acc, item) => {
    const groupKey = item.object || "Uncategorized";
    acc[groupKey] = acc[groupKey] || [];
    acc[groupKey].push(item);
    return acc;
  }, {});

  const selectOptions = Object.keys(groupedData).map((groupKey) => ({
    label: groupKey == "company" ? t("caymland.core.company") : t("caymland.lead.contact"),
    options: groupedData[groupKey].map((item) => ({
      value: item.alias,
      label: item.label,
    })),
  }));

  const findSelectedOption = (header) => {
    const selectedOptionGroup = selectOptions.find((optionGroup) =>
      optionGroup.options.some((option) => option.value === header || option.label === header)
    );

    return selectedOptionGroup
      ? selectedOptionGroup.options.find((option) => option.value === header || option.label === header)
      : null;
  };

  const [runnerCommand] = useRunnerCommandMutation();

  const handleImport = async (data) => {
    const { owner, list, tags, categories } = getValues(); // Retrieve all values at once

    console.log(owner);

    // Map categories to an array of `id`
    const mappedCategories = categories.map((category) => category.id);

    // Map tags to an array of `value`
    const mappedTags = tags.map((tag) => tag.value);

    // Directly modify modifiedData
    const updatedData = {
      ...modifiedData,
      properties: {
        ...modifiedData.properties,
        defaults: {
          owner: owner,
          list: list,
          tags: mappedTags, // Use mapped tags
          categories: mappedCategories, // Use mapped categories
        },
      },
    };

    // Validate if fields are empty
    if (Object.keys(updatedData?.properties?.fields)?.length === 0) {
      setFieldEmpty(true);
      return;
    }

    console.log(updatedData);

    // Perform import
    const response = await importBackgroundCompany(updatedData);
    if (!response.error) {
      const importId = response.data.import.id;

      // Prepare the command body
      const commandBody = {
        command: "caymland:import",
        arguments: {
          id: importId,
        },
      };

      await runnerCommand(commandBody);

      Success(t("caymland.lead.batch.import.created"), [], t("caymland.core.close"));
      setFieldEmpty(false);

      // Navigate based on view
      if (isContactsView) {
        navigate("/contacts/import");
      } else if (isCompaniesView) {
        navigate("/companies/import/history");
      }
    }

    setUploaded(false);
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handelCancel(),
    },
    {
      name: "Import in background",
      title: t("caymland.lead.import.in.background"),
      icon: <FaHistory size={15} />,
      onClick: handleSubmit(handleImport),
    },
  ];

  return (
    <Fragment>
      <SlideAlert show={show} message={message} setShowAlert={setShow} />
      <Header
        buttons={buttons}
        title={
          isContactsView
            ? `${t("caymland.lead.import")} ${t("caymland.lead.contact")}`
            : `${t("caymland.lead.import")} ${t("caymland.core.company")}`
        }
      />

      <form>
        <div className="panel panel-info">
          <div className="panel-heading">
            <div className="panel-title">
              {isContactsView ? t("caymland.lead.import.default.owner") : t("caymland.lead.import.default.owner")}
            </div>
          </div>
          {isContactsView ? (
            <div className="panel-body">
              <div className="d-flex flex-row w-full justify-between items-center gap-10">
                <Controller
                  control={control}
                  name="owner"
                  render={({ field: { name, onChange, value } }) => (
                    <ReSelect
                      name={name}
                      label={t("caymland.campaign.lead.owner")}
                      isClearable
                      className="w-full"
                      options={retrieveUsers}
                      trigger={triggerUsers}
                      value={value || null}
                      onChange={(selectedOption) => onChange(selectedOption?.value)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="list"
                  render={({ field: { name, onChange, value } }) => (
                    <ReSelect
                      name={name}
                      label={t("caymland.campaign.leadsource.lists")}
                      isClearable
                      className="w-full"
                      options={retrieveSegments}
                      trigger={triggerSegments}
                      value={value || null}
                      onChange={(selectedOption) => onChange(selectedOption?.value)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="categories"
                  render={({ field: { name, onChange, value } }) => (
                    <ReSelect
                      name={name}
                      label={t("caymland.category.header.index")}
                      isClearable
                      isMulti
                      className="w-full"
                      options={retrieveCategories}
                      trigger={triggerCategories}
                      labelField="title"
                      valueField="id"
                      value={value || []}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="tags"
                  render={({ field: { name, onChange, value } }) => (
                    <ReSelect
                      name={name}
                      label={t("caymland.campaign.lead.tags")}
                      isClearable
                      isMulti
                      className="w-full"
                      options={retrieveTags}
                      trigger={triggerTags}
                      value={value || []}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          ) : (
            <div className="panel-body">
              <div style={{ width: "30%" }}>
                <Controller
                  control={control}
                  name="owner"
                  render={({ field: { name, onChange, value } }) => (
                    <ReSelect
                      name={name}
                      label={t("caymland.lead.lead.events.owner")}
                      isClearable
                      className="w-full"
                      options={retrieveUsers}
                      trigger={triggerUsers}
                      value={value || null}
                      onChange={(selectedOption) => onChange(selectedOption?.value)}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <form>
        <div className="panel panel-info">
          <div className="panel-heading">
            <div className="panel-title">{t("caymland.lead.import.fields")}</div>
          </div>
          <div className="panel-body">
            <div className="row">
              {fieldEmpty && (
                <div class="has-error pa-10 col-12 text-center pb-3">
                  <div class="help-block">{t("caymland.core.import.match.field")}.</div>
                </div>
              )}
              {modifiedData?.properties?.headers?.map((header, index) => {
                // Format the header by replacing spaces with underscores and capitalizing the first letter
                const formattedHeader = header.replace(/\s+/g, "_");
                const capitalizedHeader = header.charAt(0).toUpperCase() + header.slice(1);

                // Use the formatted header to find the current selected option from the fields
                const selectedOptionValue = modifiedData.properties.fields[formattedHeader];

                // Find the corresponding option from selectOptions that matches the selectedOptionValue
                const matchingOption = selectOptions.find((optionGroup) =>
                  optionGroup.options.some((option) => option.value === selectedOptionValue)
                );

                // If a matching option group is found, find the correct option to display
                const selectedOption = matchingOption
                  ? matchingOption.options.find((option) => option.value === selectedOptionValue)
                  : null;

                return (
                  <div className="col-4" key={index}>
                    <div className="row">
                      <div className="form-group col-12">
                        <ReSelect
                          name={capitalizedHeader}
                          label={capitalizedHeader}
                          options={selectOptions}
                          value={selectedOption} // The selected option must match the format of the options
                          onChange={(selectedOptions) => handleSelectChangeFields(formattedHeader, selectedOptions)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default ImportedRender;
