import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../../reusableComponents/loading";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Sidebar from "./ViewForm/Sidebar";
import Details from "./ViewForm/Details";
import FormStats from "./ViewForm/FormStats";
import moment from "moment";
import Contacts from "./ViewForm/Contacts";
import Fields from "./ViewForm/Fields";
import Actions from "./ViewForm/Actions";
import Header from "../../../reusableComponents/Header/Header";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import { GoDatabase } from "react-icons/go";
import { BsFillCameraFill } from "react-icons/bs";
import Swal from "sweetalert2";
import Wrapper from "../../../reusableComponents/Wrapper/Wrapper";
import CustomizedAccordions from "../../../reusableComponents/Accordion/Accordion";
import { useDeleteFormMutation, useGetFormExtrasQuery, useGetViewFormQuery } from "../../../../redux/api/formsApi";
import SidebarSkeleton from "../../../reusableComponents/SidebarSkeleton";
import DetailsSkeleton from "../../../reusableComponents/DetailsSkeleton";
import HeaderSkeleton from "../../../reusableComponents/HeaderSkeleton";
import Description from "../../../reusableComponents/Description/Description";
import { t } from "i18next";
import { useTheme } from "@mui/material/styles";

function ViewForm() {
  const [primarycolorTab, setprimarycolorTab] = useState(4);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);
  const [applyLoading, setApplyLoading] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [manual, setManual] = useState();
  const [automatic, setAutomatic] = useState();
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
    orderBy: "id",
  });
  const { palette } = useTheme();

  const id = location?.state?.id || location?.state || useParams().id;

  const navigate = useNavigate();

  const changeDynamicTab = (index, tabTitle) => {
    setprimarycolorTab(index);
  };

  const { data, isFetching, isLoading } = useGetViewFormQuery(id);
  const [deleteForm] = useDeleteFormMutation();
  const {
    data: getExtras,
    refetch,
    error,
  } = useGetFormExtrasQuery({
    id,
    dateFrom: localStorage.getItem("fromDate"),
    dateTo: localStorage.getItem("toDate"),
    query,
  });

  useEffect(() => {
    if (data?.forms && id) {
      setManual(data.manuals?.automatic);
      setAutomatic(data.automatics?.automatic);
      setForm(data?.forms?.form);
      setExtraInfo(getExtras);
    }
  }, [id, data, getExtras, error]);

  // useEffect(() => {
  //   if (error) {
  //     navigate("/forms");
  //   }
  // }, [data, error]);

  const handleDateChange = () => {
    setDateFrom(localStorage.getItem("fromDate"));
    setDateTo(localStorage.getItem("toDate"));
    const fromDate = localStorage.getItem("fromDate");
    const toDate = localStorage.getItem("toDate");
    refetch({
      id,
      dateFrom: fromDate,
      dateTo: toDate,
    });
  };

  const handleDelete = () => {
    setLoading(true);
    Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.message.delete", { name: form.name }),
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteForm(form.id);
        if (response) {
          setLoading(false);
          navigate("/forms", { state: { deletedForm: true, name: form.name } });
        }
      }
    });
    setLoading(false);
  };

  const navBack = () => {
    navigate("/forms");
  };

  const editNavigate = () => {
    navigate(`/forms/edit/${id}`);
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navBack(),
    },
    {
      name: "edit",
      title: t("caymland.core.form.edit"),
      icon: <FaEdit size={15} />,
      onClick: () => editNavigate(),
      childs: [
        {
          name: "preview",
          title: t("caymland.core.preview"),
          icon: <BsFillCameraFill size={13} />,
          onClick: () => {
            const url = `/form/preview/${id}`;
            window.open(url, "_blank");
          },
        },
        {
          name: "results",
          title: t("caymland.form.form.results"),
          icon: <GoDatabase size={15} />,
          onClick: () => navigate(`/forms/results/${id}`),
        },
        {
          name: "delete",
          title: t("caymland.core.form.delete"),
          icon: <FaTrashAlt size={15} />,
          onClick: () => handleDelete(),
        },
      ],
    },
  ];
  const accordions = [
    {
      name: t("caymland.core.details"),
      component: <Details setOpenDetails={setOpenDetails} openDetails={openDetails} form={form} />,
    },
  ];
  const accordionsDesc = [
    {
      name: t("caymland.core.description"),
      component: <Description data={form} />,
    },
  ];

  const [iframe, setIframe] = useState("");

  const iframeString = data?.automatics?.automatic?.viaIframe;

  const decodeHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.documentElement.textContent;
  };

  useEffect(() => {
    if (iframeString) {
      const decodedIframe = decodeHtml(iframeString);
      const parser = new DOMParser();
      const doc = parser.parseFromString(decodedIframe, "text/html");
      const iframeElement = doc.querySelector("iframe");

      if (iframeElement) {
        // Get the current src value
        let src = iframeElement.getAttribute("src");

        // Check if src already has query parameters
        if (src) {
          const hasParams = src.includes("?");
          src += hasParams ? "&isApi=1" : "?isApi=1";

          // Update the src attribute with the new URL
          iframeElement.setAttribute("src", src);
        }

        // Modify the style attribute
        iframeElement.style.width = "50vw";
        iframeElement.style.height = "100vh";

        // Serialize the document back to HTML
        const updatedIframe = doc.body.innerHTML;
        setIframe(updatedIframe);
      }
    }
  }, [iframeString]);
  return (
    <Fragment>
      <>
        {isLoading ? (
          <HeaderSkeleton isView={true} />
        ) : (
          <Header
            buttons={buttons}
            title={form?.name}
            showIsPublished={true}
            isPublished={form?.isPublished}
            loading={loading}
            data={form}
          />
        )}
        <Wrapper
          childrenClassOne={"wrapper-childOne"}
          childrenOne={
            isLoading ? (
              <DetailsSkeleton />
            ) : (
              <>
                <div
                  className={`details-filters-container  ${openDetails ? "details-container" : "details-hidden"}`}
                ></div>
                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <FormStats
                      extras={extraInfo}
                      setExtras={setExtraInfo}
                      handleDateChange={handleDateChange}
                      loading={applyLoading}
                    />
                  </CardBody>
                </Card>
                <Card style={{ boxShadow: "unset", height: "auto" }}>
                  <CardBody style={{ padding: "0" }}>
                    <Nav className="nav-primary w-full" tabs>
                      <NavItem>
                        <NavLink
                          href="#"
                          onClick={() => changeDynamicTab(4)}
                          style={{
                            color: primarycolorTab === 4 ? "white" : palette.text.primary,
                            textTransform: "capitalize",
                            cursor: "pointer",
                            backgroundColor: primarycolorTab === 4 ? palette?.primary?.main : "",
                          }}
                        >
                          {t("caymland.core.preview")}
                        </NavLink>
                      </NavItem>

                      {extraInfo?.actions?.length ? (
                        <NavItem>
                          <NavLink
                            href="#"
                            onClick={() => changeDynamicTab(1)}
                            style={{
                              color: primarycolorTab === 1 ? "white" : palette.text.primary,
                              textTransform: "capitalize",
                              cursor: "pointer",
                              backgroundColor: primarycolorTab === 1 ? palette?.primary?.main : "",
                            }}
                          >
                            {t("caymland.core.actions")}
                          </NavLink>
                        </NavItem>
                      ) : null}
                      {extraInfo?.fields?.length ? (
                        <NavItem>
                          <NavLink
                            href="#"
                            onClick={() => changeDynamicTab(2)}
                            style={{
                              color: primarycolorTab === 2 ? "white" : palette.text.primary,
                              textTransform: "capitalize",
                              cursor: "pointer",
                              backgroundColor: primarycolorTab === 2 ? palette?.primary?.main : "",
                            }}
                          >
                            {t("caymland.form.tab.fields")}
                          </NavLink>
                        </NavItem>
                      ) : null}
                      <NavItem>
                        <NavLink
                          href="#"
                          className={primarycolorTab === 3 ? "active" : ""}
                          onClick={() => changeDynamicTab(3)}
                          style={{
                            color: primarycolorTab === 3 ? "white" : palette.text.primary,
                            textTransform: "capitalize",
                            cursor: "pointer",
                            backgroundColor: primarycolorTab === 3 ? palette?.primary?.main : "",
                          }}
                        >
                          {t("caymland.lead.leads")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={primarycolorTab}>
                      {form?.actions?.length ? (
                        <TabPane tabId={1}>
                          <Actions extras={form} />
                        </TabPane>
                      ) : null}
                      {form?.fields?.length ? (
                        <TabPane tabId={2}>
                          <Fields extras={form} />
                        </TabPane>
                      ) : null}
                      <TabPane tabId={3}>
                        <Contacts
                          extras={getExtras?.contacts}
                          setExtraInfo={setExtraInfo}
                          setDateFrom={setDateFrom}
                          setDateTo={setDateTo}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          data={data}
                          query={query}
                          setQuery={setQuery}
                        />
                      </TabPane>
                      <TabPane tabId={4}>
                        <div dangerouslySetInnerHTML={{ __html: iframe }} />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </>
            )
          }
          childrenClassTwo={"wrapper-childTwoNoMargin"}
          childrenTwo={
            isLoading ? (
              <SidebarSkeleton applyPadding />
            ) : (
              <>
                <CustomizedAccordions accordions={accordions} />
                {form && form?.description ? <CustomizedAccordions accordions={accordionsDesc} isOpen={true} /> : null}
                <Sidebar extras={extraInfo} manual={manual} automatic={automatic} cachedHtml={form?.cachedHtml} />
              </>
            )
          }
        />
      </>
    </Fragment>
  );
}

export default ViewForm;
