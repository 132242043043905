import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { t } from "i18next";
import { Text } from "../reusableComponents/Inputs";
import { SlideAlert } from "../reusableComponents/Alerts";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import Loader from "../common/loader";
import companyData from "../../assets/companies.json";

const SetNewPassword = () => {
  const [password, setPassword] = useState("");
  const [logo, setLogo] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [passwordTooShort, setPasswordTooShort] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [backgorundToast, setBackgroundToast] = useState("");
  const location = useLocation(); // Use useLocation hook to get location object
  const queryParams = new URLSearchParams(location.search); // Parse the query string
  const token = queryParams.get("token"); // Extract the token from the query string
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");
  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;
  const [loadingBackground, setLoadingBackground] = useState(true);

    useEffect(() => {
      const currentDomain = window.location.href;
      let company = companyData.companies.find((c) => currentDomain.includes(c.domain));
  
      if (!company && companyData.companies.length > 0) {
        company = companyData.companies[0];
      } 
  
      if (company) {
        try {
          const localLogo = require(`../../assets/logo/${company.logo.sidebarLogoPath}`);
          setLogo(localLogo);
        } catch (error) {
          setLogo(company.logo.url);
        }



        if (company?.loginBackground) {
          setBackgroundUrl(company.loginBackground);
          setLoadingBackground(false);
        } else {
          // Otherwise, fetch a new image
          fetchBackgroundImage();
  
          // Set an interval to refresh the background every hour
          const interval = setInterval(() => {
            fetchBackgroundImage();
          }, 60 * 60 * 1000); // 1 hour
  
          // Cleanup function to clear interval when component unmounts
          return () => clearInterval(interval);
        }


      } else {
        try {
          const localLogo = require(`../../assets/logo/sidebar_logo.png`);
          setLogo(localLogo);
        } catch (error) {
          setLogo(company.logo.url);
        }
      }
    }, []);

  const resetPassword = () => {
    if (password !== confirmPassword) {
      setWrongCredentials(true);
    } else if (password.length < 6) {
      setPasswordTooShort(true);
    } else {
      axios
        .post(`${api}/passwordresetconfirmV2`, {
          password: password,
          password_confirm: confirmPassword,
          token: token,
        })
        .then((response) => {
          console.log("Password reset successful");
          setWrongCredentials(false);
          setPasswordTooShort(false);
          if (response.status === 200) {
            setShow(true);
            setMessage(t("Password has been successfully reset."));
            setBackgroundToast("1");
            setTimeout(() => {
              setShow(false);
              setMessage("");
              navigate("/login");
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Password reset failed", error);
        });
    }
  };

  // const handlePasswordChange = (e) => {
  //   const newPassword = e.target.value;
  //   setPassword(newPassword);
  //   if (newPassword.length >= 6 && passwordTooShort) {
  //     setPasswordTooShort(false);
  //   }
  // };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);



  const fetchBackgroundImage = async () => {
    try {
      setLoadingBackground(true);

      const response = await axios.get(
        "https://api.unsplash.com/photos/random?query=nature&client_id=iOkMPl0_Ai2kMnxIbWO7t14QXE7sx_HSUa6YFG9VD00",
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "no-cors",
            "Referrer-Policy": "no-referrer",
          },
        }
      );
      const data = await response;
      console.log(data);

      // Extract the first landscape image URL
      const firstItem = data?.data?.batchrsp?.items?.[0];
      const landscapeImageUrl = firstItem ? JSON.parse(firstItem.item).ad.landscapeImage.asset : null;

      if (data) {
        // Save image and fetch time to localStorage
        localStorage.setItem("backgroundUrl", data.data.urls.raw);
        localStorage.setItem("backgroundFetchTime", Date.now());
        setBackgroundUrl(data.data.urls.raw);
      } else {
        console.error("No landscape image found in the API response.");
      }
    } catch (error) {
      console.error("Error fetching background image:", error);
    } finally {
      setLoadingBackground(false);
    }
  };

  

  // useEffect(() => {
  //   // Check if a background image already exists in localStorage
  //   const storedBackgroundUrl = localStorage.getItem("backgroundUrl");
  //   const backgroundFetchTime = localStorage.getItem("backgroundFetchTime");
  //   const currentTime = Date.now();

  //   // If the image exists and was fetched within the last hour, use it
  //   if (
  //     storedBackgroundUrl &&
  //     backgroundFetchTime &&
  //     currentTime - backgroundFetchTime < 60 * 60 * 1000 // 1 hour in milliseconds
  //   ) {
  //     setBackgroundUrl(storedBackgroundUrl);
  //   } else {
  //     // Otherwise, fetch a new image
  //     fetchBackgroundImage();
  //   }

  //   // Set an interval to refresh the background every hour
  //   const interval = setInterval(() => {
  //     fetchBackgroundImage();
  //   }, 60 * 60 * 1000); // 1 hour

  //   return () => clearInterval(interval); // Cleanup interval on unmount
  // }, []);

  return (
    <Fragment>
      <SlideAlert background={backgorundToast} message={message} show={show} />
      <div className="page-wrapper">
      <div
         className="auth-bg"
         style={{
           backgroundImage: loadingBackground ? (
             <Loader />
           ) : (
             `url(${backgroundUrl || "http://localhost:3000/static/media/auth-bg-1.8184142c45a47fd52a9c.jpg"})`
           ),
         }}
        >
          <div className="authentication-box">
            <div className="tw-flex justify-center text-center">
              {/* <img src="https://dev.m-4.ch/media/images/sidebar_logo.png" alt="" /> */}
              <img src={logo} alt="company logo"  style={{ maxWidth: "185px" }} />

            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className="text-center">
                  <h4>{t("Reset Password")}</h4>
                </div>

                <div>
                  <div style={{ marginTop: "20px", position: "relative" }}>
                    <Text
                      id="password"
                      className="jump-input"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      label={t("New Password")}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {/* Eye icon for New Password field */}
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "68%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        fontSize: "1.5em",
                      }}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>

                  <div style={{ marginTop: "20px", position: "relative" }}>
                    <Text
                      id="confirmPassword"
                      className="jump-input"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      label={t("Confirm Password")}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {/* Eye icon for Confirm Password field */}
                    <span
                      onClick={toggleConfirmPasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "68%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        fontSize: "1.5em",
                      }}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>

                {wrongCredentials && (
                  <div>
                    <p style={{ color: "red", marginBottom: "15px" }}>{t("Passwords don't match!")}</p>
                  </div>
                )}

                {passwordTooShort && (
                  <div>
                    <p style={{ color: "red", marginBottom: "15px" }}>{t("Password must be at least 6 characters!")}</p>
                  </div>
                )}

                <div className="forgotPassword">
                  <Link to="/login">{t("Back to Login")}</Link>
                </div>
                <div className="form-group form-row mt-3 mb-0 d-grid">
                  <button className="btn active-btn" onClick={resetPassword}>
                    {t("Reset Password")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SetNewPassword;
