import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form"; // Import useFieldArray
import CustomTable from "../../contacts/CustomTable/CustomTable";
import { t } from "i18next";
import moment from "moment";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import CustomModal from "./NoteComponents/CustomModal";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useGetUserSelectOptionsQuery } from "../../../../redux/api/usersApi";

const Note = ({ control, errors, watch, noteData, getValues, setValue, reset }) => {
  const { data: userOptions } = useGetUserSelectOptionsQuery();
  const [notes, setNotes] = useState();

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "ASC",
    orderBy: "dateTime",
  });

  const [showModal, setShowModal] = useState({
    isOpen: false,
    type: "add",
    id: null,
  });
  const [activeRowId, setActiveRowId] = useState();

  useEffect(() => {
    if (noteData) {
      setNotes(noteData);
    }
  }, [noteData]);

  const deleteNote = (id) => {
    // Find the index of the note to delete from the notes state
    const noteIndex = notes.findIndex((note) => note.id === id);

    if (noteIndex !== -1) {
      // Remove the note from the notes state
      const updatedNotes = notes.filter((note) => note.id !== id);
      setNotes(updatedNotes);

      // Use setValue to update only the notes field in the form
      setValue("notes", updatedNotes);
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "subject",
        accessorFn: (row) => row.subject,
        header: t("caymland.email.subject"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "user",
        accessorFn: (row) => {
          const firstName = row?.user?.firstName || "";
          const lastName = row?.user?.lastName || "";
          return firstName || lastName ? `${firstName} ${lastName}`.trim() : "";
        },
        header: t("caymland.lead.note.action.form.user"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "dateAdded",
        accessorFn: (row) => {
          return moment(row?.dateAdded).format("DD.MM.YY HH:mm");
        },
        header: t("caymland.showare.field.date_added"),
        enableColumnActions: false,
        size: 50,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      // {
      //   accessorKey: "notificationDate",
      //   accessorFn: (row) => {
      //     return moment(row?.noteAction?.dateTimeOfNotification).format("DD.MM.YY HH:mm");
      //   },
      //   header: t("caymland.lead.note.action.form.date"),
      //   enableColumnActions: false,
      //   size: 50,
      //   muiTableHeadCellProps: {
      //     align: "right",
      //     sx: {
      //       verticalAlign: "bottom",
      //       zIndex: 0,
      //     },
      //   },
      //   muiTableBodyCellProps: {
      //     align: "right",
      //   },
      // },
      {
        accessorKey: "documents",
        accessorFn: (row) => row?.documents?.length,
        header: <AttachmentIcon />,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    setActiveRowId(row.id);
  };

  const toolbarButtons = useMemo(() => [
    {
      name: "Add Note",
      component: CustomModal,
      props: {
        showModal: showModal,
        setValue: setValue,
        setShowModal: setShowModal,
        control: control,
        add: "add",
        noteId: activeRowId,
        setNotes: setNotes,
        noteData: notes,
        getValues: getValues,
        reset: reset,
        errors: errors,
        userOptions: userOptions,
      },
    },
  ]);

  const actionButtons = ActionButtons({
    link: "notes",
    clone: false,
    _delete: false,
    deleteItem: deleteNote, // Pass deleteNote function here
    onClickIDFunction: deleteNote,
    setDoNotContact: true,
    openModal: setShowModal,
  });

  return (
    <div className="tw-w-[60%]">
      <CustomTable
        data={notes}
        columns={columns}
        query={query}
        setQuery={setQuery}
        header
        actionButtons={actionButtons}
        onRowClick={handleRowClick}
        setIsOpen={setShowModal}
        linkNavigate="notes"
        toolbarButtons={toolbarButtons}
      />
    </div>
  );
};

export default Note;
