import React, { useMemo, useState } from "react";
import BarChart from "../../../../reusableComponents/ECharts/BarChart/BarChart";
import CustomTable from "../../CustomTable/CustomTable";
import { t } from "i18next";
import Timeline from "../../../../reusableComponents/timeline";

const Statistics = ({ statistics, data, setQuery, query }) => {
  const [activeRowId, setActiveRowId] = useState();

  const eventScheduledData = data?.events?.filter((event) => event.event === "campaign.event.scheduled") || [];
  const ColorComponent = (props) => {
    const color = props?.data?.color;

    console.log(color);

    return (
      <div className="flex items-center p-no-margin">
        <span
          className="p-10"
          style={{
            width: "5px",
            height: "5px",
            backgroundColor: color?.startsWith("#") ? color : `#${color}`,
            boxShadow: "0 0 10px rgb(0 0 0 / 20%)",
            borderRadius: "50%",
          }}
        ></span>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "color",
        accessorFn: (row) => row?.color,
        size: "unset",
        header: "",
        enableColumnActions: false,
        Cell: ({ cell }) => <ColorComponent data={cell?.row?.original} />,
      },

      {
        accessorKey: "categoryName",
        accessorFn: (row) => row.categoryName,
        header: t("caymland.points.table.cat_title"),
        enableColumnActions: false,
      },
      {
        accessorKey: "total",
        accessorFn: (row) => row?.total,
        header: t("caymland.core.count"),
        enableColumnActions: false,
      },
      {
        accessorKey: "id",
        accessorFn: (row) => row?.categoryId,
        header: t("caymland.points.table.id"),
        size: 80,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
          sx: {},
        },
      },
    ],
    []
  );

  // Calculate the sum of all 'total' values
  const totalSum = statistics?.reduce((sum, category) => sum + Number(category.total), 0) || 0;

  // Create a single dataSeries object with data array containing one entry per category
  function customRound(value) {
    const decimal = value - Math.floor(value);
    if (decimal >= 0.5) {
      return Math.ceil(value);
    } else {
      return Math.floor(value);
    }
  }

  const dataSeries = [
    {
      name: "Points",
      data:
        statistics?.map((category) => {
          const totalPercentage = totalSum > 0 ? customRound((Number(category.total) / totalSum) * 100) : 0;
          return {
            value: totalPercentage,
            itemStyle: {
              color: category?.color?.startsWith("#") ? category.color : `#${category.color}`,
            },
          };
        }) || [],
    },
  ];
  return (
    <div className="w-full d-flex flex-col gap-10">
      <div className="w-full">
        <BarChart
          labelData={statistics?.map((stat) => stat.categoryName) || []}
          dataSeries={dataSeries}
          dataZoom={false}
          percentage
        />
      </div>
      <div>
        <CustomTable
          data={statistics}
          columns={columns}
          query={query}
          setQuery={setQuery}
          smallHeight
          setActiveRowId={setActiveRowId}
          linkNavigate={null}
          actions={false}
          // isFetching={isFetching}
        />
      </div>
    </div>
  );
};

export default Statistics;
