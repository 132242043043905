import React, { Fragment, useState, useEffect, useRef } from "react";
import DateFromTo from "./dateFromTo";
import AddWidget from "./AddWidget";
import SelectDashboardTemplate from "./SelectDashboardTemplate";
import WidgetGenerator from "./widgetGenerator";
import Header from "../../reusableComponents/Header/Header";
import CreateTemplete from "./CreateTemplete";
import {
  useAddNewWidgetsMutation,
  useEditWidgetsMutation,
  useGetAllWidgetsQuery,
  useGetListTemplatesQuery,
  useGetWidgetsOptionsQuery,
  useSaveWidgetTemplateMutation,
  widgetsApi,
} from "../../../redux/api/widgets";
import { t } from "i18next";

const Default = (props) => {
  const [filteredWidgets, setFilteredWidgets] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [widgetTypes, setWidgetTypes] = useState([]);
  const [widgetDataOptions, setWidgetDataOptions] = useState(null);
  const [optionsArray, setOptionsArray] = useState([]);
  const [modal, setModal] = useState(false);
  const [addedWidget, setAddedWidget] = useState("");
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [params, setParams] = useState(null);
  const [widgetPopupType, setWidgetPopupType] = useState("");
  const [currentWidgetId, setCurrentWidgetId] = useState(null);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [modalTemplete, setModalTemplete] = useState(false);
  const [currentType, setCurrentType] = useState(null);
  const [errors, setErrors] = useState({});
  const [backgorundToast, setBackgroundToast] = useState("");

  const [singleWidgetData, setSingleWidgetData] = useState({
    name: null,
    type: null,
    width: 0,
    height: 0,
    params: null,
    ordering: null,
  });

  const [modifiedData, setModifiedData] = useState({
    name: "",
    type: "",
    width: 0,
    height: 0,
    params: null,
    ordering: null,
  });

  const { data: allWidgets } = useGetAllWidgetsQuery();
  const { data: getListTemplates, isFetching, refetch } = useGetListTemplatesQuery();
  const { data: widgetsOptions, isLoading, error } = useGetWidgetsOptionsQuery(currentType);

  const [editWidgets] = useEditWidgetsMutation();
  const [addNewWidgets] = useAddNewWidgetsMutation();
  const [saveWidgetTemplate] = useSaveWidgetTemplateMutation();

  const [trigger] = widgetsApi.useLazyGetTemplateDataQuery();
  const [getWidgetById] = widgetsApi.useLazyGetWidgetByIdQuery();
  const [getWidgetByType] = widgetsApi.useLazyGetWidgetByTypeQuery();
  const [getWidgetsOptions] = widgetsApi.useLazyGetWidgetsOptionsQuery();

  const modalTempleteToogle = () => {
    setTemplateName("");
    setTemplateDescription("");
    setModalTemplete(!modalTemplete);
  };

  useEffect(() => {
    loadTemplateData();
  }, []);

  useEffect(() => {
    if (!isLoading && !error && widgetsOptions && widgetsOptions.data) {
      const options = Object.entries(widgetsOptions?.data?.options).map((x) => x);
      setWidgetDataOptions(options);
    } else {
      setWidgetDataOptions([]);
    }
  }, [widgetsOptions]);

  useEffect(() => {
    setTemplates(getListTemplates);
  }, [getListTemplates]);

  const templateRef = useRef(null);

  useEffect(() => {
    modifiedData.params = params;
    singleWidgetData.params = params;
  }, [params]);

  useEffect(() => {
    if (addedWidget === "added" && !isNewTemplate) {
      handleSaveTemplate("", "addWidget");
    }
    setAddedWidget("");
  }, [addedWidget]);

  useEffect(() => {
    if (allWidgets) {
      const retrieveWidgetTypes = async () => {
        setWidgetTypes(
          Object.entries(allWidgets?.types).map((x) => ({
            label: x[0],
            options: Object.entries(x[1]).map((x) => ({
              value: x[0],
              label: x[1],
            })),
          }))
        );
      };
      retrieveWidgetTypes();
    }
  }, [allWidgets]);

  const handleTemplateChange = async (e) => {
    setIsNewTemplate(false);
    if (e === null) {
      templateRef.current = null;
    } else {
      templateRef.current = e.value;
    }

    loadTemplateData();
  };
  const validateFields = () => {
    const newErrors = {};
    if (widgetPopupType === "add" && !templateName) {
      if (!modifiedData.name) newErrors.name = true;
      if (!modifiedData.type) newErrors.type = true;
    } else if (widgetPopupType === "edit") {
      if (!singleWidgetData.name) newErrors.name = true;
      if (!singleWidgetData.type) newErrors.type = true;
    } else if (widgetPopupType === "add") {
      if (!templateName) newErrors.templateName = true;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShow(true);
      setMessage(t("caymland.core.error.not.valid"));
      setBackgroundToast("2");
      setTimeout(() => {
        setMessage("");
        setShow(false);
        setBackgroundToast("1");
      }, 3000);
      return;
    } else {
      setErrors({});
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitWidget = async () => {
    if (!validateFields()) return;
    if (!Array.isArray(filteredWidgets)) {
      setFilteredWidgets([filteredWidgets]);
    }

    const updatedWidgets = [...filteredWidgets];
    const updatedarr = { ...modifiedData };

    const singleWidget = { ...singleWidgetData };

    if (widgetPopupType === "add") {
      const isWidgetAlreadyAdded = filteredWidgets.some((widget) => widget.type === updatedarr.type);

      if (isWidgetAlreadyAdded) {
        setShow(true);
        setMessage("Widget is already added");
        setBackgroundToast("1");
        setTimeout(() => {
          setMessage("");
          setShow(false);
        }, 3000);
      } else {
        let arr = updatedarr;
        if (arr.width === 0) {
          arr.width = 100;
        }
        if (arr.height === 0) {
          arr.height = 330;
        }

        arr.ordering = filteredWidgets ? filteredWidgets.length : 0;

        let response = await addNewWidgets(arr);
        if (!response.error) {
          setShow(true);
          setBackgroundToast("1");
          setMessage(t("caymland.dashboard.saved.widget"));
          setTimeout(() => {
            setMessage("");
            setShow(false);
          }, 3000);

          let widgetByType = await getWidgetByType({
            type: response?.data?.widget?.type,
            filter: response?.data?.widget?.params,
          });

          let newWidget = {
            id: response?.data?.widget?.id,
            name: response?.data?.widget?.name,
            width: response?.data?.widget?.width,
            height: response?.data?.widget?.height,
            type: response?.data?.widget?.type,
            params: response?.data?.widget?.params,
            ordering: response?.data?.widget?.ordering,
            data: widgetByType?.data?.data,
          };
          updatedWidgets.unshift(newWidget);

          setFilteredWidgets([...updatedWidgets]);
        }
      }
    } else {
      let arr2 = singleWidget;

      if (arr2.width === 0) {
        arr2.width = 100;
      }
      if (arr2.height === 0) {
        arr2.height = 330;
      }

      arr2.ordering = filteredWidgets ? filteredWidgets.length : 0;

      const updatedData = {
        ...arr2,
      };

      const response = await editWidgets({ currentWidgetId, ...updatedData });
      // if (!response.error) {
      //   setShow(true);
      //   setMessage(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`);
      //   setTimeout(() => {
      //     setMessage("");
      //     setShow(false);
      //   }, 3000);
      // }

      let widgetByType = await getWidgetByType({
        type: response?.data?.widget?.type,
        filter: response?.data?.widget?.params,
      });

      let newWidget = {
        id: response?.data?.widget?.id,
        name: response?.data?.widget?.name,
        width: response?.data?.widget?.width,
        height: response?.data?.widget?.height,
        type: response?.data?.widget?.type,
        params: response?.data?.widget?.params,
        ordering: response?.data?.widget?.ordering,
        data: widgetByType?.data?.data,
      };

      const updatedW = updatedWidgets.map((w) => (w.id === response?.data?.widget?.id ? newWidget : w));
      const edited = [...updatedW];
      setFilteredWidgets(edited);
      setSingleWidgetData({
        name: null,
        type: null,
        width: 0,
        height: 0,
        params: null,
      });

      setModifiedData({
        name: "",
        type: "",
        width: 0,
        height: 0,
        params: null,
      });
    }
    setModal(false);
    setAddedWidget("added");
    setWidgetDataOptions(null);
  };

  const handleTypeChange = async (e) => {
    setParams(null);
    setModifiedData({ ...modifiedData, type: e.value });
    setCurrentType(e.value);
  };

  const createNewTemplate = (e) => {
    setIsNewTemplate(true);
    modalTempleteToogle();
  };
  const validateTemplateFields = () => {
    let isValid = true;
    const newErrors = {};
    if (!templateName.trim()) {
      newErrors.templateName = "Template name cannot be empty";
      isValid = false;
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setShow(true);
      setBackgroundToast("2");
      setMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setMessage("");
        setShow(false);
        setBackgroundToast("1");
      }, 3000);
      return;
    } else {
      setErrors({});
    }
    return isValid; // Return the overall validity
  };
  console.log(templateName);
  const decodeHtmlEntities = (str) => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(str, "text/html").body.textContent || str;
    return decodedString;
  };

  const handleSaveTemplate = async (propWidgets, params) => {
    let newTemplateData;
    if (params === "delete") {
      newTemplateData = {
        id: templateRef.current,
        name: decodeHtmlEntities(templateName),
        description: templateDescription,
        user_template: templateRef.current === null ? true : false,
        widgets: propWidgets,
      };
    } else if (params === "addWidget") {
      newTemplateData = {
        id: templateRef.current,
        name: decodeHtmlEntities(templateName),
        description: templateDescription,
        user_template: templateRef.current === null ? true : false,
        widgets: filteredWidgets,
      };
    } else {
      if (!validateTemplateFields()) return;
      newTemplateData = {
        name: decodeHtmlEntities(templateName),
        description: templateDescription,
        user_template: isNewTemplate ? false : true,
        widgets: "",
      };
    }
    const response = await saveWidgetTemplate(newTemplateData).unwrap();
    if (response) {
      setModalTemplete(false);
      setModal(false);
      setShow(true);
      setBackgroundToast("1");
      setMessage(
        response.widgets.length > 0 ? t("caymland.dashboard.saved.widget") : t("caymland.dashboard.saved.template")
      );
      refetch();
      templateRef.current = response.id;
      setTimeout(() => {
        setShow(false);
        setMessage("");
      }, 7000);
      setTimeout(() => {
        loadTemplateData();
      }, 500);
    }
  };

  const toggle = (type) => {
    setWidgetPopupType(type);
    setModifiedData({
      name: null,
      type: null,
      width: 0,
      height: 0,
      params: null,
    });
    setSingleWidgetData({
      name: null,
      type: null,
      width: 0,
      height: 0,
      params: null,
    });
    setModal(!modal);
    setErrors({});
  };

  const handleEditWidget = async (id) => {
    setCurrentWidgetId(id);
    toggle("edit");

    const widgetResponse = await getWidgetById(id).unwrap();

    if (widgetResponse) {
      let optionType = widgetResponse?.widget?.type;

      let response2 = await getWidgetsOptions(optionType);

      setSingleWidgetData({
        name: widgetResponse?.widget?.name,
        type: widgetResponse?.widget?.type,
        width: widgetResponse?.widget?.width,
        height: widgetResponse?.widget?.height,
        params: widgetResponse?.widget?.params,
        options: response2.data.success === 1 ? response2.data.data.options : [],
      });
    }
  };

  const handleDeleteWidget = (id) => {
    let arr = filteredWidgets.filter((x) => x.id !== id);
    setFilteredWidgets(arr);
    if (!isNewTemplate) {
      setTimeout(() => {
        handleSaveTemplate(arr, "delete");
      }, 500);
    }
  };

  const handleOptionsChange = async (e, name, isMultiple) => {
    if (isMultiple) {
      setParams({ ...params, [name]: e.map((x) => x.value) });
    } else {
      setParams({ ...params, [name]: e.value });
    }
  };

  useEffect(() => {
    setModifiedData({ ...modifiedData, params: params });
  }, [params]);

  const saveFromTo = () => {
    loadTemplateData();
  };

  const loadTemplateData = async () => {
    try {
      let dateFrom = localStorage.getItem("fromDate") ?? new Date(); // Use sessionStorage
      let dateTo = localStorage.getItem("toDate") ?? new Date(); // Use sessionStorage
      let templateData = await trigger({ template: templateRef.current, dateFrom, dateTo }).unwrap();
      console.log("templateData", templateData);
      setFilteredWidgets(templateData?.widgets);

      if (templateRef.current !== null) {
        setTemplateName(templateData?.name || "");
        setTemplateDescription(templateData?.description || "");
      }
    } catch (error) {
      console.error("Error loading template data:", error);
    }
  };

  const childTwo = (
    <SelectDashboardTemplate
      onChange={(e) => handleTemplateChange(e)}
      templates={templates}
      template={templateRef?.current ?? null}
      setShow={setShow}
      setMessage={setMessage}
      refetch={refetch}
      loadTemplateData={loadTemplateData}
    />
  );
  const childOne = <DateFromTo saveFromTo={saveFromTo} cls="mid-full-width" loading={isFetching} />;
  const extraButtonsRight = (
    <AddWidget
      cls="mid-full-width"
      errors={errors}
      handleSubmitWidget={handleSubmitWidget}
      modifiedData={modifiedData}
      setModifiedData={setModifiedData}
      toggle={() => toggle("add")}
      modal={modal}
      widgetDataOptions={widgetDataOptions}
      handleTypeChange={handleTypeChange}
      optionsArray={optionsArray}
      loading={isFetching}
      widgetTypes={widgetTypes}
      showMore={true}
      createNewTemplate={createNewTemplate}
      smallLoading={isFetching}
      setSingleWidgetData={setSingleWidgetData}
      singleWidgetData={singleWidgetData}
      handleOptionsChange={handleOptionsChange}
      widgetPopupType={widgetPopupType}
    />
  );

  return (
    <Fragment>
      <Header
        title={`Dashboard`}
        childTwo={childTwo}
        childOne={childOne}
        extraButtonsRight={extraButtonsRight}
        alertMessage={message}
        showAlert={show}
        setShowAlert={setShow}
        background={backgorundToast}
      />
      <CreateTemplete
        errors={errors}
        modalTemplete={modalTemplete}
        setModalTemplete={setModalTemplete}
        toggle={modalTempleteToogle}
        loading={isFetching}
        customSubmit={() => handleSaveTemplate(null)}
        nameChange={(e) => setTemplateName(e.target.value)}
        templateName={templateName}
        setTemplateDescription={setTemplateDescription}
        templateDescription={templateDescription}
      />
      <WidgetGenerator
        key={filteredWidgets}
        widgets={filteredWidgets}
        handleEdit={handleEditWidget}
        setFilteredWidgets={setFilteredWidgets}
        handleSaveTemplate={handleSaveTemplate}
        handleDeleteWidget={handleDeleteWidget}
        isFetching={isFetching}
      />
    </Fragment>
  );
};

export default Default;
