import React, { useEffect, useState } from "react";
import { ImCancelCircle } from "react-icons/im";
import { HiOutlineSaveAs } from "react-icons/hi";
import Button from "../../reusableComponents/Buttons/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Select, { components } from "react-select";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import MultipleSelects from "../../reusableComponents/MultipleSelects";
import { BiAddToQueue } from "react-icons/bi";
import { Label, Text } from "../../reusableComponents/Inputs";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';

const width = [
  { label: "25%", value: 25 },
  { label: "50%", value: 50 },
  { label: "75%", value: 75 },
  { label: "100%", value: 100 },
];
const height = [
  { label: "215px", value: 215 },
  { label: "330px", value: 330 },
  { label: "445px", value: 445 },
  { label: "560px", value: 560 },
  { label: "675px", value: 675 },
];

export default function AddWidget({
  handleSubmitWidget,
  modifiedData,
  setModifiedData,
  widgetTypes,
  cls,
  widgetDataOptions,
  handleTypeChange,
  optionsArray,
  loading,
  toggle,
  modal,
  showMore,
  createNewTemplate,
  smallLoading,
  singleWidgetData,
  setSingleWidgetData,
  handleOptionsChange,
  widgetPopupType,
  errors,
}) {
  const GroupHeading = (props) => <components.GroupHeading {...props} />;

  const { palette } = useTheme();

  const { rawPermissions: permissions = {}, isAdmin } = useSelector((state) => state?.auth?.user?.role || {});

  useEffect(() => {
    setModifiedData({
      ...modifiedData,
      width: width[3].value,
      height: height[1].value,
    });
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleE = () => setDropdownOpen((prevState) => !prevState);

  const buttons = [
    {
      name: "addWidget",
      title: t("caymland.dashboard.widget.add"),

      icon: <AiOutlineAppstoreAdd size={15} />,
      onClick: () => toggle(),
      childs: [
        {
          name: "createTemplate",

          title: t("caymland.dashboard.createTemplate"),
          icon: <BiAddToQueue size={17} />,
          onClick: (e) => createNewTemplate(e),
        },
      ],
    },
  ];

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: palette?.primary?.main,
      primary: palette?.primary?.main,
    },
  });

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: "15px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.003)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        borderColor: "#5A5A5A",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "white",
    }),
    group: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: "10px 2px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? palette?.primary?.main : "white",
      color: state.isSelected ? "white" : "#333",
      padding: "10px 2px",
      fontSize: "15px",
      transition: "background-color 0.2s ease",
      paddingLeft: "20px",
      "&:active": {
        color: "white",
        backgroundColor: palette?.primary?.main,
      },
      "&:hover": {
        color: "white",
        backgroundColor: palette?.primary?.main,
      },
      "&:focus": {
        color: "white",
      },
    }),
  };

  const findWidgetByValue = (widgetTypes, valueToFind) => {
    for (const widgetType of widgetTypes) {
      const option = widgetType.options.find((option) => option.value === valueToFind);
      if (option) {
        return option;
      }
    }
    return null;
  };
  const canAddWidget =
    isAdmin ||
    (permissions["dashboard:widgets"] &&
      ["full", "create"].some((perm) => permissions["dashboard:widgets"].includes(perm)));


  return (
    <>
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggleE}>
        <Button buttons={buttons} loading={loading} isDisabled={!canAddWidget} />

        <Modal
          size="lg"
          style={{
            maxWidth: "600px",
            dispaly: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          isOpen={modal}
          toggle={toggle}
          backdrop="static"
          keyboard={true}
        >
          <form>
            <ModalHeader toggle={toggle}>
              {" "}
              <h3>
                {" "}
                {widgetPopupType === "edit"
                  ? t("caymland.dashboard.widget.header.edit")
                  : t("caymland.dashboard.widget.add")}
              </h3>{" "}
            </ModalHeader>
            <ModalBody style={{ paddingBottom: "50px" }}>
              <div className="form-row">
                <div className="col-sm-6 col-md-6 grid place-center">
                  <div class="input-container" style={{ marginBottom: "38px" }}>
                    <Text
                      name={"Name"}
                      errors={errors.name}
                      label={t("caymland.dashboard.widget.form.name")}
                      value={singleWidgetData?.name || modifiedData?.name}
                      required={true}
                      onChange={(e) =>
                        singleWidgetData?.name
                          ? setSingleWidgetData({
                              ...singleWidgetData,
                              name: e.target.value,
                            })
                          : setModifiedData({
                              ...modifiedData,
                              name: e.target.value,
                            })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 mb-3">
                  <Label
                    name={"validationDefault02"}
                    label={t("caymland.dashboard.widget.form.type")}
                    required={true}
                    errors={errors.type}
                  />
                  <Select
                    name={"type"}
                    id="validationDefault02"
                    required
                    aria-errormessage={errors.type}
                    isDisabled={widgetPopupType === "edit" ? true : false}
                    value={findWidgetByValue(
                      widgetTypes,
                      widgetPopupType === "edit" ? singleWidgetData?.type : modifiedData.type
                    )}
                    isSearchable
                    options={widgetTypes}
                    defaultValue={{
                      label: singleWidgetData.type ? singleWidgetData?.type : "Select",
                      value: singleWidgetData.type ? singleWidgetData?.type : null,
                    }}
                    components={{ GroupHeading }}
                    onChange={(e) => handleTypeChange(e)}
                    theme={selectTheme}
                    styles={{
                      ...selectStyles,
                      control: (base) => ({
                        ...base,
                        fontSize: "15px",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        borderColor: errors.type ? "#d32f2f" : base.borderColor,
                        "&:hover": {
                          transform: "scale(1.003)",
                          boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
                          borderColor: errors.type ? "#d32f2f" : base["&:hover"].borderColor,
                        },
                      }),
                    }}
                  />
                </div>
                <div className="col-sm-6 col-md-6 mb-3">
                  <Label name={"validationDefault03"} label={t("caymland.dashboard.widget.form.width")} />
                  <Select
                    id="validationDefault03"
                    required
                    isSearchable
                    options={width}
                    value={width.find((w) => w.value === singleWidgetData.width)}
                    defaultValue={width[3]}
                    onChange={(e) =>
                      singleWidgetData?.width
                        ? setSingleWidgetData({
                            ...singleWidgetData,
                            width: e.value,
                          })
                        : setModifiedData({
                            ...modifiedData,
                            width: e.value,
                          })
                    }
                    theme={selectTheme}
                    styles={selectStyles}
                  />
                </div>

                <div className="col-sm-6 col-md-6 mb-3">
                  <Label name={"validationDefault04"} label={t("caymland.dashboard.widget.form.height")} />
                  <Select
                    id="validationDefault04"
                    required
                    isSearchable
                    options={height}
                    value={height.find((h) => h.value === singleWidgetData.height)}
                    // defaultValue={height[1]}
                    onChange={(e) =>
                      singleWidgetData?.height
                        ? setSingleWidgetData({
                            ...singleWidgetData,
                            height: e.value,
                          })
                        : setModifiedData({
                            ...modifiedData,
                            height: e.value,
                          })
                    }
                    theme={selectTheme}
                    styles={selectStyles}
                  />
                </div>
                <MultipleSelects
                  widgetDataOptions={widgetDataOptions}
                  singleWidgetData={singleWidgetData}
                  setSingleWidgetData={setSingleWidgetData}
                  GroupHeading={GroupHeading}
                  optionsArray={optionsArray}
                  selectedOptions={singleWidgetData?.params && singleWidgetData?.params}
                  handleOptionsChange={handleOptionsChange}
                  selectTheme={selectTheme}
                  selectStyles={selectStyles}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                buttons={[
                  {
                    name: "close",
                    title: t("caymland.core.form.close"),
                    icon: <ImCancelCircle size={16} />,
                    onClick: () => toggle(),
                  },
                  {
                    name: "save",
                    title: t("caymland.core.form.save"),
                    icon: <HiOutlineSaveAs size={16} />,
                    onClick: () => handleSubmitWidget(),
                  },
                ]}
                loading={loading}
              />
            </ModalFooter>
          </form>
        </Modal>
      </ButtonDropdown>
    </>
  );
}
