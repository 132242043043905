import React, { useEffect, useMemo, useState } from "react";
import {
  useGetReservationsQuery,
  useGetTransactionsSourcesQuery,
  useGetTranscationByIdQuery,
} from "../../../../../redux/api/contactsApi";
import { t } from "i18next";
import CustomTable from "../../CustomTable/CustomTable";
import moment from "moment";
import { MRT_ToggleSelect } from "../../../../reusableComponents/CustomTable/MRT_ToggleSelect";

const Reservation = ({ id }) => {
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,

    source: "",
  });

  const { data, refetch, isFetching } = useGetTranscationByIdQuery({ id: id, q: query });
  const { data: sourcesOptions } = useGetTransactionsSourcesQuery();

  console.log(data);
  const columns = useMemo(
    () => [
      {
        accessorKey: "orderId",
        accessorFn: (row) => row.transaction_order_id,
        header: t("caymland.transaction.column.transaction_order_id"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "tdate",
        accessorFn: (row) => {
          // Use Moment.js to format the timestamp
          return moment(row.item_created).format("DD.MM.YY HH:mm");
        },
        header: t("caymland.transaction.column.transaction_date"),
        enableColumnActions: false,
      },
      {
        accessorKey: "pname",
        accessorFn: (row) => row.product_name,
        header: t("caymland.transaction.column.product_name"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "itemType",
        accessorFn: (row) => row.item_subtype,
        header: t("Artikel-Subtyp"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "ttype",
        accessorFn: (row) => row.transaction_type,
        header: t("caymland.transaction.column.transaction_type"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "quantity",
        accessorFn: (row) => row.item_quantity,
        header: t("Artikel Menge"),
        enableColumnActions: false,
        size: 50,
      },
      {
        accessorKey: "amount",
        accessorFn: (row) => row.item_total_price,
        header: t("Gesamtbetrag"),
        enableColumnActions: false,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          // Ensure the value is a number and format it with two decimal places
          const formattedAmount = rowData?.item_total_price
            ? Number(rowData.item_total_price)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, "'")
            : "0.00";

          return <span>CHF {formattedAmount}</span>;
        },
        size: 50,
      },

      // {
      //   accessorKey: "visitDate",
      //   accessorFn: (row) => {
      //     // Use Moment.js to format the timestamp
      //     return moment(row.visit_date).format("DD.MM.YY HH:mm");
      //   },
      //   header: t("caymland.transaction.column.visit_date"),
      //   enableColumnActions: false,
      // },
      {
        accessorKey: "source",
        accessorFn: (row) => row.amount,
        header: t("caymland.transaction.column.source"),
        enableColumnActions: false,

        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <span>{rowData?.items[0]?.source}</span>;
        },
      },
    ],
    []
  );

  const handleSourceQuery = (selectedOptions) => {
    console.log(selectedOptions);
    setQuery({ ...query, source: selectedOptions });
  };

  console.log(query);

  useEffect(() => {}, [query]);
  const toolbarButtons = useMemo(() => [
    {
      name: "segments",
      component: MRT_ToggleSelect,
      props: {
        data: sourcesOptions,
        onChange: handleSourceQuery,
        value: query?.source,
        title: t("caymland.transaction.column.source"),
        // isMulti: true,
      },
    },
  ]);

  return (
    <CustomTable
      data={data} // Pass filtered data
      columns={columns}
      query={query}
      setQuery={setQuery}
      footer
      mediumHeight
      header
      actions={false}
      linkNavigate={null}
      showDetailPanel
      transactions
      isFetching={isFetching}
      toolbarButtons={toolbarButtons}
    />
  );
};

export default Reservation;
