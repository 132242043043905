import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import md5 from "blueimp-md5";

const DetailPanelView = ({ nonEmptyFields, avatarImg }) => {
  const [imageUrl, setImageUrl] = useState("");

  console.log(avatarImg);

  useEffect(() => {
    // Find the email in nonEmptyFields
    const emailField = Object.entries(nonEmptyFields).find(([label]) => label === "E-Mail");
    if (emailField) {
      const [, emailValue] = emailField;
      const newImageUrl = getGravatarURL(emailValue); // Pass the email value
      setImageUrl(newImageUrl);
    }
  }, [nonEmptyFields]);

  function getGravatarURL(email, size = 200) {
    const defaultImageURL = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

    if (!email) {
      return defaultImageURL;
    }
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${encodeURIComponent(defaultImageURL)}`;
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Fields to exclude
  const excludedKeys = [
    "last_active",
    "internal",
    "social_cache",
    "date_identified",
    "is_published",
    "date_added",
    "created_by",
    "date_modified",
    "modified_by",
    "modified_by_user",
    "checked_out_by_user",
    "points",
    // Add any other fields you want to exclude
  ];

  // Filter the nonEmptyFields to exclude specific keys
  const filteredFields = Object.entries(nonEmptyFields).filter(([key]) => !excludedKeys.includes(key));

  // Dynamic row calculation based on the total number of fields
  const fieldsPerRow = [5, 5, 5]; // Default for 15 fields (5 fields per row)
  const remainingFields = filteredFields.length % 5;
  let rows = [];

  const url = window.location.hostname;
  const isCaymLandDomain = url.endsWith("caym.land");
  const subdomainParts = url.split(".");
  const subdomain = subdomainParts[0];
  const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev` : process.env.REACT_APP_DEV_URL;

  // Split fields into rows of 5 or flexible if needed
  while (filteredFields.length > 0) {
    const row = filteredFields.splice(0, fieldsPerRow[0]);
    rows.push(row);
    fieldsPerRow.push(remainingFields > 0 ? remainingFields : 5);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "20px",
        backgroundColor: "#fafafa",
        borderRadius: "8px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        width: "100%",
      }}
    >
      {/* Profile Image and Data in Horizontal Rows */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          justifyContent: "flex-start",
          flexWrap: "wrap", // This allows the fields to wrap next to the image
        }}
      >
        {/* Profile Image Box */}
        <div
          style={{
            padding: "10px",
            height: "200px",
            width: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            alt="Profile"
            src={
              avatarImg && avatarImg !== "null" && avatarImg.trim() !== "" // Check that avatarImg is valid
                ? `${api}/${avatarImg}`
                : imageUrl || "https://cdn-icons-png.flaticon.com/512/149/149071.png"
            }
            width="100%"
            style={{
              borderRadius: "6px",
              height: "200px",
              objectFit: "cover",
              width: "200px",
            }}
          />
        </div>

        {/* Data Boxes */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            flex: 1, // This ensures the boxes take the remaining space
          }}
        >
          {rows.map((row, rowIndex) => (
            <div
              key={rowIndex}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start", // Align the fields to the left
                gap: "20px", // Add spacing between fields
                flexWrap: "wrap", // Allow wrapping within each row
                width: "100%",
              }}
            >
              {row.map(([key, value]) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    minWidth: "calc(33% - 10px)", // Ensures fields are aligned in three columns
                    flexWrap: "wrap", // Allow wrapping within each row
                  }}
                >
                  <strong>{capitalizeFirstLetter(key)}:</strong>
                  <span>{value}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailPanelView;
