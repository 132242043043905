import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { FaTrashAlt } from "react-icons/fa";
import { t } from "i18next";
import { useDeleteTemplateMutation } from "../../../redux/api/widgets";
import Swal from "sweetalert2";
import { Error, Success } from "../../reusableComponents/Alerts";
import { useTheme } from '@mui/material/styles';

const decodeHtmlEntities = (str) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(str, "text/html").body.textContent || str;
  return decodedString;
};

export default function SelectDashboardTemplate({
  templates,
  onChange,
  template,
  setShow,
  setMessage,
  refetch,
  loadTemplateData,
}) {
  const [resetValue, setResetValue] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [decodedTemplates, setDecodedTemplates] = useState();

  const { palette } = useTheme();
  

  useEffect(() => {
    const decodedTemplates =
      templates &&
      templates?.map((t) => ({
        ...t,
        label: decodeHtmlEntities(t.label),
      }));
    setDecodedTemplates(decodedTemplates);
  }, [templates]);


  const [deleteTemplate] = useDeleteTemplateMutation();

  const handleDelete = async (templateId) => {
    const name = templates?.find((t) => t.value === templateId);
    const result = await Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: `${t("caymland.message.delete", { name: name?.label })}`,
      icon: "question",
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      showCancelButton: true,
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    });
    if (result.isConfirmed) {
      let response;

      response = await deleteTemplate(templateId);
      if (!response.error) {
        setShow(true);
        setMessage(t("caymland.dashboard.template.delete"));
        setTimeout(() => {
          setMessage("");
          setShow(false);
        }, 3000);
        setResetValue(true);
        refetch();
      } else {
        Error(t("caymland.message.error"));
      }
      template = null;
      loadTemplateData();
    }
  };

  const CustomOption = (props) => {
    const customStyles = {
      ...props.getStyles("option", props),
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      ":hover": {
        backgroundColor: palette?.primary?.main,
        color: "white",
      },
      backgroundColor: props.isFocused ? palette?.primary?.main : null,
      color: props.isFocused ? "white" : "black",
    };

    return (
      <div
        onMouseEnter={() => setIsHovered(props.data.value)}
        onMouseLeave={() => setIsHovered(null)}
        {...props.innerProps}
        ref={props.innerRef}
        style={customStyles}
      >
        {props.data.label && <span style={{ marginRight: "auto" }}>{props.data.label}</span>}
        {isHovered === props.data.value && (
          <FaTrashAlt
            color="#f86b4f"
            style={{ cursor: "pointer", marginRight: "5px" }}
            className="selectIcon"
            title="Delete this template"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(props.data.value);
            }}
          />
        )}
      </div>
    );
  };
  return (
    <Select
      name="validationDefault02"
      key={decodedTemplates}
      options={decodedTemplates}
      placeholder={t("caymland.dashboard.selectPremadeTemplate")}
      onChange={onChange}
      value={decodedTemplates ? decodedTemplates.find((t) => t.value === template) || null : null}
      components={{ Option: CustomOption }}
      isMulti={false}
      isClearable
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 99999999 }),
        control: (base) => ({
          ...base,
          outline: "none !important",
          minHeight: "40px",
          fontSize: "15px",
          backgroundColor: "white",
          border: "",
          marginBottom: "0",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            transform: "scale(1.003)",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
            borderColor: base["&:hover"].borderColor,
          },
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#347AA6" : "white",
          color: state.isSelected ? "white" : "#333",
          padding: "10px 2px",
          fontSize: "15px",
          transition: "background-color 0.2s ease",
          paddingLeft: "20px",
          "&:active": {
            color: "white",
            backgroundColor: palette?.primary?.main,
          },
          "&:hover": {
            color: "white",
            backgroundColor: palette?.primary?.main,
          },
          "&:focus": {
            color: "white",
          },
        }),
      }}
    />
  );
}
